import { sliderAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { colorScheme } from "theme/names";
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);
// const baseStyle = definePartsStyle({
//   // define the part you're going to style
//   thumb: {
//     bg: "orange.400", // change the background of the thumb to orange.400
//   },
//   filledTrack: {
//     bg: "blue.600", // change the background of the filled track to blue.600
//   },
// });
export const Progress = defineMultiStyleConfig({
  defaultProps: { colorScheme: colorScheme.primaryChakraColorScheme },
});
