import * as Amplitude from '@amplitude/node';
import { Identify } from '@amplitude/identify';
import amplitude from 'amplitude-js';
import * as Sentry from "@sentry/nextjs";
import { isBrowser, isMobile } from 'react-device-detect';
import sha256 from 'crypto-js/sha256';
import ReactGA from "react-ga4";

const client = Amplitude.init('724fdbf01623539827dbb4db9d59940d');
const gaId = 'G-BH3EC90N3P';
ReactGA.initialize(gaId);

let userId = "";
let session: any = undefined;
let visitor: any = undefined;
let isInit: any = false;

export const pageView = async function (page) {
    ReactGA.send({ hitType: "pageview", page: page });
}

export const init = async function (actualSession) {

    try {
        // console.log("Amplitude init: " + JSON.stringify(actualSession));
        // console.log(actualSession);

        if (actualSession) {
            session = actualSession;
            if (actualSession.user)
                userId = actualSession.user.uid;
        }

        if (typeof window !== 'undefined') {
            amplitude.getInstance().init('724fdbf01623539827dbb4db9d59940d');
            amplitude.getInstance().setUserId(userId);
            isInit = true;
        }

        // console.log("init GA");
        // visitor = ua(gaId, userId, { strictCidFormat: false });

        //@ts-ignore
        // log("init");
    }
    catch (e) {
        console.error("Error in analytics init: " + e.message);
        Sentry.captureException(e);
    }
}

export const addUserProperties = async function (properties, user: any | undefined = undefined) {

    try {
        // console.log("addUserProperties ", properties);
        // console.log("addUserProperties");

        if (user?.id) {
            userId = user.id;
            if (typeof window !== 'undefined')
                amplitude.getInstance().setUserId(userId);
        }

        if (typeof window !== 'undefined') {
            await amplitude.getInstance().setUserProperties(properties);
        }
        else {
            const identify = new Identify();

            for (const [key, value] of Object.entries(properties)) {
                // console.log(`${key}: ${value}`);
                //@ts-ignore
                identify.set(key, value);
            }

            await client.identify(userId, null, identify);
        }

        Sentry.setUser({ ...properties, ...user });

        // Gleap.identify(userId, properties);
        try {
            if (user?.id) properties.user_id = userId;
            properties.email = user?.email;
            if (typeof window !== 'undefined') {
                //@ts-ignore
                window.Intercom("update", { ...properties, type: "Tweet Hunter User" })
            }
        }
        catch (e) {
            console.log("error in intercom update", e);
        }

        if (user.email) {
            await fetch("https://us-central1-ez4cast.cloudfunctions.net/tweetHunter-updateUserProperties", {
                method: "POST",
                headers: new Headers({ "Content-Type": "application/json" }),
                body: JSON.stringify({ email: user.email, data: properties }),
            });
        }
    }
    catch (e) {
        console.error("Error in addUserProperties: " + e.message);
        Sentry.captureException(e);
    }
}

export const log = async function (event, properties = {} as any, newUserId = "", actualSession = undefined as any) {

    try {

        if (!isInit && actualSession && typeof window !== 'undefined')
            init(actualSession)

        if (newUserId) {
            userId = newUserId;
            if (typeof window !== 'undefined')
                amplitude.getInstance().setUserId(userId);
        }

        if (actualSession) {
            session = actualSession;
        }

        // console.log(properties);

        //@ts-ignore
        if (session?.user?.data) {
            properties = {
                //@ts-ignore
                ...session.user.data,
                ...properties,
            }
        }

        properties.env_node = process.env.NODE_ENV ? process.env.NODE_ENV : "local";
        properties.env = process.env.CUSTOM_ENV ? process.env.CUSTOM_ENV : "local";

        if (typeof window !== 'undefined') {
            properties.isMobile = isMobile;
            properties.isBrowser = isBrowser;
        }

        // console.log(properties);

        let obj = {
            event_type: event,
            // user_id: userId,
            // location_lat: 37.77,
            // location_lng: -122.39,
            // ip: '127.0.0.1',
            event_properties: {
                ...properties,
            }
        }

        if (userId)
            //@ts-ignore
            obj.user_id = userId;
        else
            //@ts-ignore
            obj.user_id = "111111111";

        // console.log("Amplitude log: " + event + " / userId: " + userId + " / obj: " + JSON.stringify(obj));

        if (typeof window !== 'undefined')
            await amplitude.getInstance().logEvent(event, properties);
        else
            await client.logEvent(obj);

        // console.log("event: " + event + " / userId: " + userId);
        // console.log(session);
        if (userId)
            await sendFacebookEvent(event, userId, properties, session?.user);

        // if (!visitor)
        //     visitor = ua(gaId, userId, { strictCidFormat: false });
        // if (visitor)
        //     await visitor.event("app", event).send();
        // console.log("send ReactGA event");

        await ReactGA.event({ category: "app", action: event });

        // await Gleap.trackEvent(event, { userId, ...properties });
        try {
            //@ts-ignore
            if (typeof window !== 'undefined') window.Intercom("trackEvent", event, { ...properties });
        }
        catch (e) {
            console.log("error in intercom trackEvent", e);
        }
    }
    catch (e) {
        console.error("Error in log amplitude: " + e.message);
        Sentry.captureException(e);
    }
}


export const formatTweet = function (tweet) {

    if (!tweet)
        return {};

    let obj = {
        id_str: tweet.id_str,
        created_at: tweet.created_at,
        full_text: tweet.full_text,
        favorite_count: tweet.favorite_count,
        retweet_count: tweet.retweet_count,
        user: {
            followers_count: tweet.user?.followers_count,
            friends_count: tweet.user?.friends_count,
            name: tweet.user?.name,
            screen_name: tweet.user?.screen_name,
        }
    }

    return obj;
}


async function sendFacebookEvent(event, userId, properties = {} as any, user = {} as any) {

    try {
        // console.log("sendFacebookEvent");
        // console.log("userId: " + userId + " / type: " + typeof userId);
        // console.log("email: " + user?.data?.email);
        // console.log(user);

        let pixelId = "2991899637693143";
        const accessToken = 'EAATDQN5g3esBAPq2fJLovvyKeOrU1DGGaBGuSGSSTT7LSwRHq0r9VZAe9OxybjsGiJqWlkv29NeaoYekmXwMtOqdm42RIBWK0bjLjTmhacV2ZAl4ZAZCoToeL67BZAIsV3AiTwZBY5v5tZCxeo5neorlAL9dEAyLVMFRpimQzuxeD0K0OAAMp2D0mpCyISbpKkZD';
        let current_timestamp = Math.floor(Date.now() / 1000);

        let dataToSend = {
            access_token: accessToken,
            data: [
                {
                    "event_name": event,
                    "event_time": current_timestamp,
                    // "event_id": "event.id.123",
                    "event_source_url": "http://app.tweethunter.io",
                    "action_source": "app",
                    "user_data": {
                        // "client_ip_address": "192.19.9.9",
                        // "client_user_agent": "test ua",
                        // "em": [
                        //     "309a0a5c3e211326ae75ca18196d301a9bdbd1a882a4d2569511033da23f0abd"
                        // ],
                        // "ph": [
                        //     "254aa248acb47dd654ca3ea53f48c2c26d641d23d7e2e93a1ec56258df7674c4",
                        //     "6f4fcb9deaeadc8f9746ae76d97ce1239e98b404efe5da3ee0b7149740f89ad6"
                        // ],
                        // "fbc": "fb.1.1554763741205.AbCdEfGhIjKlMnOpQrStUvWxYz1234567890",
                        // "fbp": "fb.1.1558571054389.1098115397"
                        external_id: userId,
                        // em: [""],
                    },
                    "custom_data": {
                        ...properties,
                    },
                    "opt_out": false
                },
            ]
        } as any;

        if (user?.data?.email) {
            dataToSend.data[0].user_data.em = ["" + sha256(user?.data?.email)];
        }

        await fetch(`https://graph.facebook.com/v12.0/${pixelId}/events`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });
    }
    catch (e) {
        console.log("Error in Facebook analytics: " + e.message, e);
    }
}  