import firebaseClient from "firebase/app";
import { useState, useEffect } from 'react';
import { useSession } from "next-auth/client";
import { firebaseAuth } from "../firebaseClient";

export function useFirebaseUser() {
  const [isFirebaseAuthenticated, setIsFirebaseAuthenticated] = useState(false);
  const [isNextAuthenticated, setIsNextAuthenticated] = useState(false);
  const [session, loading] = useSession();

  useEffect(() => {
    if (session?.user?.uid) {
        setIsNextAuthenticated(true);
    }
    else {
      setIsNextAuthenticated(false);
    }
  }, [session]);

  useEffect(() => {
    return firebaseClient.auth().onAuthStateChanged((user) => {
      // console.log("onAuthStateChanged: " + JSON.stringify(user));
      if (user) {
        // console.log("Firebase - user authenticated: " + JSON.stringify(user));
        // If the user just signed in, we call the code to create the cookie.
        setIsFirebaseAuthenticated(true);
      } else {
        setIsFirebaseAuthenticated(false);

        if (session?.user?.firebaseToken) {
          firebaseAuth(session);
        }
      }
    });
  }, []);

  return (isFirebaseAuthenticated && isNextAuthenticated);
}