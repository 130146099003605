import React, { useContext, useEffect } from 'react';
import {
    Flex, Link, Icon, Text, useColorModeValue,
    FormControl,
    Box,
    Tooltip,
    Switch,
    FormLabel,
    IconButton,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { IconType } from "react-icons/lib";
import { useRouter } from "next/router"
import { TweetContext } from "../../context/tweetContext";
import { useSession } from "next-auth/client";
import { QuestionIcon } from '@chakra-ui/icons';
import { VscSettings } from "react-icons/vsc"
import { useFirebaseUser } from "../../utils/useFirebaseUser";
import { getAccount, updateUser } from "utils/sessionHelper";
import { SettingsIconComponent } from 'components/icons/SettingsIconComponent';
import { layerStyle } from 'theme/names';

interface Props {
    isBeta?: boolean;
    isEnable: boolean;
    setIsEnable: Function;
    saveKey: string;
    title: string | React.ReactElement;
    tooltip: string | React.ReactElement;
    content: React.ReactElement;
    extraOptions?: React.ReactElement;
    extraRefreshKeys?: Array<any>;
}

export function OptionItem({
    saveKey,
    isBeta = false,
    isEnable,
    setIsEnable,
    title,
    tooltip,
    content,
    extraOptions = undefined,
    extraRefreshKeys = [],
}: Props) {

    const router = useRouter();
    const [session] = useSession();
    const [isOpenSettings, setIsOpenSettings] = React.useState(false);
    const [isSaveSetting, setIsSaveSetting] = React.useState(false);
    const [hasInit, setHasInit] = React.useState(false);
    const isAuthenticated = useFirebaseUser();
    const tweetContext: any = React.useContext(TweetContext);

    useEffect(() => {
        if (isAuthenticated) {
            if (getAccount(session)?.[saveKey]) {
                setIsEnable(true);
            }
            else
                setIsEnable(false);

            if (!hasInit && getAccount(session)?.isSaveSettings?.[saveKey]) {
                setIsSaveSetting(true);
            }

            setHasInit(true);
        }
    }, [isAuthenticated, tweetContext.refresh])

    useEffect(() => {
        if (isAuthenticated) {
            // console.log("tweetContext.refresh - checking " + saveKey + ": ", tweetContext.textState?.[saveKey])
            if (tweetContext.textState?.[saveKey]) {
                setIsEnable(true);
            }
            extraRefreshKeys.forEach(key => {
                if (tweetContext.textState?.[key.name])
                    key.updateFunction(tweetContext.textState?.[key.name]);
            });
        }
    }, [tweetContext.refresh])

    if (isBeta && !session?.user?.data?.isBeta)
        return null;

    return (
        <FormControl mt={1}>
            <Box display="flex" alignItems="center" gap="2" mx="2" h="10">
                <Popover placement="bottom-end" isOpen={isOpenSettings} onClose={() => setIsOpenSettings(false)} >
                    <PopoverTrigger>
                        <IconButton
                            // w="40px"
                            ml={-1.5}
                            aria-label="Search database"
                            size="xs"
                            variant="action"
                            // variant="tertiary"
                            icon={<SettingsIconComponent/>}
                    // icon={<VscSettings />}
                            onClick={() => { setIsOpenSettings(!isOpenSettings); }}
                        />
                    </PopoverTrigger>
                    <PopoverContent
                        // layerStyle={layerStyle['bg.border.rounded']}
                    // _focus={{ boxShadow: "rgba(100, 100, 111, 0.15) 0px 2px 10px 0px" }}
                    // layerStyle={layerStyle['bg.border.rounded']}
                    // overflow="hidden"
                    >
                        <PopoverBody
                            // p="4"
                            layerStyle={layerStyle['bg.noBorder']}
                        borderRadius="5px"
                            // overflow="hidden"
                            {...{
                                
                            }}
                        >
                            <Flex>
                                <Text><QuestionIcon color="gray.400" mt={-1} mr={2}/>{tooltip}</Text>
                            </Flex>
                            <Flex mt={2}>
                                <FormLabel mt={1}>{"Save setting"}</FormLabel>
                                <Switch
                                    isChecked={isSaveSetting}
                                    m={2}
                                    mr={4}
                                    onChange={async (e) => {
                                        setIsSaveSetting(!isSaveSetting);
                                        let isSaveSettings = getAccount(session)?.isSaveSettings ?? {};
                                        isSaveSettings[saveKey] = !isSaveSetting;
                                        await updateUser(session, { isSaveSettings });
                                    }}
                                />
                            </Flex>
                            <Text fontSize={"xs"}>Default value: {getAccount(session)?.[saveKey] ? "enabled" : "disabled"}</Text>
                            {extraOptions !== undefined && (
                                <Flex mt={2} flexDir="column">
                                    {extraOptions}
                                </Flex>
                            )}
                        </PopoverBody>
                    </PopoverContent>
                </Popover>

                <Text textStyle="body.bold.standard" mt={0} ml={-1}>{title}{(isBeta ? (<Text ml={2} as="span" fontSize="xs">(beta)</Text>) : "")}</Text>
                <Box flex="1"/>
                <Switch
                    isChecked={isEnable}
                    // m={2}
                    // mr={4}
                    onChange={async (e) => {
                        setIsEnable(!isEnable);
                        if (isSaveSetting) {
                            await updateUser(session, { [saveKey]: !isEnable });
                        }
                    }}
                />
            </Box>
            {
                isEnable && (
                    <Box mt={2}>
                        {content}
                    </Box>
                )
            }
        </FormControl>
    );
}
