import NextLink from "next/link";
import {
  Image, Box, Link, Text, Flex, theme,
  Button, Icon, Tooltip, useClipboard,
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import { LinkIcon } from "@chakra-ui/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { roundUp } from "utils/helpers";
import twitter from "twitter-text";
import styled from "@emotion/styled";
import { layerStyle, textStyle, variant } from "theme/names";
import { AiOutlineMail } from "react-icons/ai";
// import { SlLocationPin } from "react-icons/sl";
import { FaIndustry } from "react-icons/fa";

const StyledText = styled(Text)`
  a {
    color: ${() => theme.colors.twitter[400]};
  }
`;
interface Props {
  contact: any;
  disableSendDM?: boolean;
}

export function Profile({
  contact,
  disableSendDM = false,
}: Props) {
  const imageUrl =
    contact?.image_400x400 ||
    contact?.profile_image_url_https?.replace("normal", "400x400") ||
    contact?.image;
  const handle = contact?.twUserName || contact?.screen_name;

  // console.log(contact);
  if (contact?.entities?.url?.urls?.[0]?.display_url) {
    contact.url = contact?.entities?.url?.urls?.[0]?.display_url;
    contact.urlAbsolute = contact?.entities?.url?.urls?.[0]?.expanded_url;
  }
  if (!contact?.descriptionFormatted && contact?.entities?.description?.urls?.length) {
    contact.descriptionFormatted = twitter.autoLink(contact.description, {
      //@ts-ignore
      // urlEntities: tweet.data?.extended_entities?.urls,
      // urlEntities: tweet.data?.extended_entities?.urls ?? tweet.data?.extended_entities?.media,
      // urlEntities: tweet.data?.extended_entities?.media,
      urlEntities: contact?.entities?.description?.urls,
      // mentionEntities: tweet.data?.user_mentions,
      usernameIncludeSymbol: true,
      targetBlank: true,
    });
  }

  return (
    <>
      <Flex flexDir="column" overflow="hidden">
        {/* image and DM */}
        <Flex gap="4">
          <Flex direction="column" align="center">
            <Image
              src={imageUrl}
              alt="profile image"
              h="130"
              w="130"
              borderRadius="50%"
              maxW="unset"
              fallbackSrc="/emptyProfile.png"
            />
            {
              !disableSendDM && (
                <Button
                  size="sm"
                  mt="5"
                  height="40px"
                  variant={variant.Button.primary}
                  leftIcon={<AiOutlineMail />}
                  // onClick={handleSendDm}
                  as="a"
                  target="_blank"
                  href={`https://twitter.com/messages/compose?recipient_id=${contact?.id_str}`}
                >
                  Send DM
                </Button>
              )
            }
          </Flex>
          {/* desc */}
          <Flex
            direction="column"
            // gap={4}
            // ml="5"
            mt="2"
            // alignContent="flex-start"
            // justifyContent="flex-start"
            alignItems="flex-start"
            gap="1"
            textStyle="h2.bold.standard"
          >
            <NextLink
              href={`https://twitter.com/${handle}`}
              passHref
              legacyBehavior
            >
              <Link fontWeight="bold" isExternal fontSize="lg">
                @{handle?.replace("@", "")}
              </Link>
            </NextLink>
            <Text textStyle={textStyle["body.medium.primary"]} noOfLines={1}>
              {contact?.occupation}
            </Text>
            {contact?.email && (
              <Text textStyle={textStyle["body.medium.light"]}>
                {contact?.email}
              </Text>
            )}
            {contact?.location && (
              <Tooltip label="location">
                <Text
                  textStyle={textStyle["body.medium.light"]}
                  fontSize="sm"
                  noOfLines={1}
                  display="flex"
                  gap="2"
                >
                  <svg
                    width="12"
                    height="17"
                    viewBox="0 0 12 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.25 15.625C3.625 13.5938 0 8.75 0 6C0 2.6875 2.65625 0 6 0C9.3125 0 12 2.6875 12 6C12 8.75 8.34375 13.5938 6.71875 15.625C6.34375 16.0938 5.625 16.0938 5.25 15.625ZM10 7.8125C10.3438 7 10.5 6.40625 10.5 6C10.5 3.53125 8.46875 1.5 6 1.5C3.5 1.5 1.5 3.53125 1.5 6C1.5 6.40625 1.625 7 1.96875 7.8125C2.28125 8.5625 2.75 9.4375 3.28125 10.3125C4.1875 11.75 5.21875 13.125 6 14.125C6.75 13.125 7.78125 11.75 8.6875 10.3125C9.21875 9.4375 9.6875 8.5625 10 7.8125Z"
                      fill="currentColor"
                    />
                  </svg>
                  {/* </Icon> */}
                  {contact.location}
                </Text>
              </Tooltip>
            )}
            <Text
              textStyle={textStyle["body.medium.light"]}
            // fontSize="md"
            // fontWeight="600"
            >
              {/* {contact?.followersCount} followers */}
              {roundUp(contact?.followers_count)}{" "}
              {contact?.followers_count > 1 ? "followers" : "follower"}
            </Text>
            {contact?.url && (
              <Link
                // mt="2"
                fontSize="sm"
                isExternal
                display="block"
                href={contact.urlAbsolute ?? contact.url ?? ""}
                _hover={{ textDecoration: "underline" }}
              >
                <Flex gap="1" alignItems="center">
                  <svg width="14" height="14" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.40625 4.125C7.15625 2.375 10 2.375 11.75 4.125C13.4375 5.78125 13.5 8.46875 11.9688 10.25L11.7812 10.4375C11.5 10.75 11.0312 10.7812 10.7188 10.5312C10.4062 10.25 10.375 9.78125 10.6562 9.46875L10.8125 9.28125C11.875 8.09375 11.8125 6.3125 10.6875 5.1875C9.53125 4 7.625 4 6.4375 5.1875L2.90625 8.71875C1.75 9.90625 1.75 11.7812 2.90625 12.9688C4.03125 14.0625 5.8125 14.125 7 13.0938L7.1875 12.9375C7.5 12.6562 7.96875 12.6875 8.25 13C8.53125 13.3125 8.5 13.7812 8.1875 14.0625L8 14.2188C6.21875 15.7812 3.53125 15.6875 1.84375 14.0312C0.09375 12.2812 0.09375 9.40625 1.84375 7.65625L5.40625 4.125ZM14.5938 11.9062C12.8125 13.6562 9.96875 13.6562 8.21875 11.9062C6.53125 10.2188 6.46875 7.5625 8 5.78125L8.15625 5.59375C8.4375 5.28125 8.90625 5.25 9.21875 5.53125C9.53125 5.8125 9.5625 6.28125 9.28125 6.59375L9.15625 6.75C8.09375 7.9375 8.15625 9.71875 9.28125 10.8438C10.4375 12 12.3438 12 13.5312 10.8438L17.0625 7.3125C18.2188 6.125 18.2188 4.25 17.0625 3.0625C15.9375 1.96875 14.1562 1.90625 12.9688 2.9375L12.7812 3.09375C12.4688 3.375 12 3.34375 11.7188 3.03125C11.4375 2.71875 11.4688 2.25 11.7812 1.96875L11.9688 1.8125C13.75 0.25 16.4375 0.34375 18.125 2C19.875 3.75 19.875 6.59375 18.125 8.375L14.5938 11.9062Z" fill="currentColor" />
                  </svg>
                  <Box textStyle={textStyle["body.medium.light"]} >{contact.url} </Box>
                </Flex>
              </Link>
            )}
          </Flex>
        </Flex>

        {/* <Text
          // as="i"
          // display="block"
          // mt="4"
          // fontSize="sm"
          // color="gray.500"
          // px="8"
          textStyle={textStyle["body.medium.light"]}
        >
          <StyledText
            p={3}
            pl={5}
            pr={4}
            whiteSpace="pre-wrap"
            dangerouslySetInnerHTML={{
              __html: contact?.descriptionFormatted ?? contact?.description,
            }}
          />
        </Text> */}
        <Flex
          w="100%"
          mt={6}
          p={4}
          flexDir="column"
          alignItems="flex-start"
          layerStyle={layerStyle["bg.border.rounded"]}
        >
          {/* <Text textStyle={textStyle["body.medium.standard"]}>Summary</Text> */}
          <Text
            // mt="4"
            textStyle={textStyle["body.medium.light"]}
            textAlign="left"
            // noOfLines={4}
            whiteSpace="pre-wrap"
            // wordBreak="break-word"
            dangerouslySetInnerHTML={{
              __html: contact?.descriptionFormatted ?? contact?.description,
            }}
          ></Text>
        </Flex>

        {contact?.emails?.length > 0 && (
          <Flex flexDir="column" w="100%" alignItems="start" px="4" mt={8}>
            <Text fontWeight="bold" fontSize="sm" color="gray.500">
              Emails:
            </Text>
            <Flex pl={4} flexDir="column" alignItems="start">
              {contact.emails.map((email, index) => (
                <CopyToClipboard
                  key={index}
                  text={email}
                  onCopy={() => {
                    toast.success("Email copied to clipboard");
                  }}
                >
                  <Text
                    noOfLines={1}
                    wordBreak="break-all"
                    ml="2"
                    mt={4}
                    cursor="pointer"
                    maxW="250px"
                  >
                    {email}
                  </Text>
                </CopyToClipboard>
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>

      {/* <Image
        src={imageUrl}
        alt="profile image"
        h="28"
        w="28"
        mx="auto"
        borderRadius="50%"
        maxW="unset"
        fallbackSrc="/assets/resources/emptyProfile.png"
      /> */}
      {/* <Box mt="4">
        <NextLink
          href={`https://twitter.com/${handle}`}
          passHref
          legacyBehavior
        >
          <Link fontWeight="bold" isExternal>
            @{handle?.replace("@", "")}
          </Link>
        </NextLink>
      </Box> */}
      {/* <Text mt="2" fontWeight="bold" fontSize="sm" color="gray.500">
      {roundUp(contact?.followers_count)}{" "}
      {contact?.followers_count > 1 ? "followers" : "follower"}
    </Text> */}
      {/* {contact?.location && (
      <Text mt="2" fontSize="sm">
        📍{contact.location}
      </Text>
    )} */}
      {/* {contact?.url && (
        <Link
          mt="2"
          fontSize="sm"
          isExternal
          display="block"
          href={contact.urlAbsolute ?? contact.url ?? ""}
          _hover={{ textDecoration: "underline" }}
        >
          <LinkIcon mr="1" /> {contact.url}
        </Link>
      )} */}
      {/* <Text as="i" display="block" mt="4" fontSize="sm" color="gray.500" px="8">
        {/* {contact?.description} */}
      {/* <StyledText
          p={3}
          pl={5}
          pr={4}
          whiteSpace="pre-wrap"
          dangerouslySetInnerHTML={{
            __html: contact?.descriptionFormatted ?? contact?.description,
          }}
        /> */}
      {/* </Text>  */}

      {/* {contact?.emails?.length > 0 && (
        <Flex flexDir="column" w="100%" alignItems="start" px="4" mt={8}>
          <Text fontWeight="bold" fontSize="sm" color="gray.500">
            Emails:
          </Text>
          <Flex pl={4} flexDir="column" alignItems="start">
            {contact.emails.map((email, index) => (
              <CopyToClipboard
                key={index}
                text={email}
                onCopy={() => {
                  toast.success("Email copied to clipboard");
                }}
              >
                <Text
                  noOfLines={1}
                  wordBreak="break-all"
                  ml="2"
                  mt={4}
                  cursor="pointer"
                  maxW="250px"
                >
                  {email}
                </Text>
              </CopyToClipboard>
            ))}
          </Flex>
        </Flex>
      )} */}
    </>
  );
}
