import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  Button,
  Box,
} from "@chakra-ui/react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function RevampPopup({ isOpen, onClose }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        background="linear-gradient(316.85deg, #1CA1F2 2.89%, #144462 79.03%), #D9D9D9"
        _after={{ height: 0 }}
        _before={{ height: 0 }}
      >
        <ModalHeader color="white" justifyContent="center" mt="5">
          <VStack spacing="1">
            <Text fontSize="xl">Something has changed!</Text>
            <Text fontSize="xl">Tweet Hunter has a new design 😎</Text>
          </VStack>
        </ModalHeader>
        <ModalCloseButton border="none" bg="transparent" color="white" />
        <ModalBody color="white" borderBottom="none" pb="0" mb="0">
          <VStack spacing="4" mt="3">
            <Text textAlign="center">
              We’ve revamped Tweet Hunter with a more modern and catchy design
              that puts you in a good mood.
            </Text>
            <Text>Hope you like it!</Text>
            <Box>
              <Button
                mt="2"
                color="white"
                bg="#1CA1F2"
                border="1px solid white"
                colorScheme="none"
                _hover={{ bg: "#1CA1F2" , color: "white"}}
                onClick={onClose}
              >
                Got it!
              </Button>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
