import { Flex, FlexProps } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { BsTags } from "react-icons/bs";

interface Props extends FlexProps {}

export function AddTagIcon({ onClick = () => {}, ...rest }: Props) {
  return (
    <Flex onClick={onClick} {...rest}>
      <BsTags />
      <AddIcon w="2" h="2" />
    </Flex>
  );
}
