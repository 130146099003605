import { useEffect } from "react";
import { Box, Flex, Divider } from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import { useChatContext } from "context/chatContext";
import { useTweetContext } from "context/tweetContext";
import { GRADIENT_COLOR } from "./utils";
import { layerStyles } from "theme/layerStyles/layerStyles";
import { radius } from "theme/names";

interface Props {
  isSidebarOpen?: boolean;
  sidebar?: JSX.Element;
  chatWindowControls: JSX.Element;
  chatWindow: JSX.Element;
  editor: JSX.Element;
}

export function Layout({
  isSidebarOpen = true,
  sidebar,
  chatWindowControls,
  chatWindow,
  editor,
}: Props) {
  const chatContext: any = useChatContext();
  const tweetContext: any = useTweetContext();
  const screenWidth = useWindowWidth();
  const isTooSmall = screenWidth < 800;

  // close the scheduler when screen is small and chat assist and scheduler both are open
  useEffect(() => {
    if (!chatContext?.isOpen || !tweetContext.isOpen) {
      return;
    }

    const popupMaxWidth = Math.min(900, screenWidth);
    const schedulerWidth = 400;
    const leftSideNavWidth = 60;
    if (
      tweetContext?.isOpen &&
      schedulerWidth + leftSideNavWidth + popupMaxWidth > screenWidth
    ) {
      tweetContext?.close();
    }
  }, [screenWidth]);

  const popupMaxWidth = Math.min(900, screenWidth);
  const sidebarMaxWidth = popupMaxWidth / 3;
  const onlyDefaultPanelMaxWidth = (popupMaxWidth * 2) / 3;
  const schedulerWidth = 400;

  const popupLeft =
    "50vw" +
    " - " +
    onlyDefaultPanelMaxWidth / 2 +
    "px" +
    (tweetContext?.isOpen ? " - " + schedulerWidth / 2 + "px" : "") +
    (isSidebarOpen ? " - " + sidebarMaxWidth / 2 + "px" : "");
  // 50vw will center the center left side of the popup
  // ("50vw" + " - " + onlyDefaultPanelMaxWidth / 2 + "px") - will center the actual popup by sharing the width, on both sides of center line (that's y divided by 2)
  // (tweetContext?.isOpen ? " - " + schedulerWidth / 2 + "px" : "") - will center considering the right sidebar scheduler. Same thing goes here, split the width in half
  //  (isSidebarOpen ? " - " + sidebarMaxWidth / 2 + "px" : "") - will center when action library is open

  return (
    <Box
      position="fixed"
      zIndex="101"
      top="0"
      left="0"
      bg={"rgba(80,80,80,0.5)"}
      w={!isTooSmall && tweetContext.isOpen ? `calc(100% - 400px)` : "100vw"}
      h="100vh"
      onClick={() => {
        chatContext?.onClose();
      }}
    >
      <Box position="relative" h="100vh" w="100%">
        <Flex
          flexDirection={isTooSmall ? "column-reverse" : "row"}
          overflow={isTooSmall ? "auto" : "inherit"}
          position="absolute"
          top="50%"
          transform={"translateY(-50%)"}
          left={isTooSmall && !isSidebarOpen ? "0" : "calc(" + popupLeft + ")"}
          pt={isTooSmall ? "10" : "00"}
          maxW={popupMaxWidth}
          h="100%"
          maxH={isTooSmall ? "100vh" : "80vh"}
          minH="400px"
          onClick={(e) => {
            e.stopPropagation();
          }}
          bg="neutral.lightMode.neutral"
          border="1px solid"
          borderColor="border.lightMode.light"
          borderRadius={radius.lg}
          // chin of the modal part 1
          _before={{
            content: "''",
            position: "absolute",
            bottom: "-5px",
            left: "-1px",
            width: "calc(100% + 2px)",
            height: "16px",
            borderBottomRadius: "10px",
            background: GRADIENT_COLOR,
          }}
          // chin of the modal part 2
          _after={{
            content: "''",
            position: "absolute",
            bottom: "-1px",
            left: "-1px",
            width: "calc(100% + 0px)",
            height: "16px",
            borderBottomRadius: "10px",
            background: "white",
            ...layerStyles.bgBorderRounded,
            borderTopRadius: "0px",
            borderTop: "none",
          }}
          _dark={{
            bg: "neutral.darkMode.neutral",
            border: "1px solid",
            borderColor: "border.darkMode.light",
            _after: {
              background: "#1E1E1E",
              borderTopRadius: "0px",
              border: "1px solid",
              borderColor: "border.darkMode.light",
              borderTop: "none",
            },
          }}
        >
          {isSidebarOpen && (
            <>
              <Box
                w={isTooSmall ? "auto" : sidebarMaxWidth}
                overflow={isTooSmall ? "unset" : "hidden"}
              >
                {sidebar}
                <Divider
                  orientation="vertical"
                  zIndex={999} // for chin
                  height={"calc(100% + 0px)"} // for chin
                />
              </Box>
            </>
          )}
          <Flex
            flexDir="column"
            w={isTooSmall ? popupMaxWidth : (popupMaxWidth * 2) / 3}
            p="4"
          >
            <Flex w="100%" alignItems="center" justifyContent={{ base: "center", md: "flex-end" }} pb="2">
              {chatWindowControls}
            </Flex>
            <Flex
              direction="column"
              height="100%"
              maxH="calc(100% - var(--chakra-space-7) - 2px)"
              justifyContent="space-between"
              overflow="hidden"
            >
              {chatWindow}
              <Box
                position="relative"
                // placeSelf="flex-end"
                w={
                  isTooSmall
                    ? "100%"
                    : "calc(" +
                      (popupMaxWidth * 2) / 3 +
                      "px" +
                      " - " +
                      "var(--chakra-space-8)" +
                      ")"
                }
              >
                {editor}
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}
