import { createContext, useContext, useRef, useState } from "react"
import { useSession } from "next-auth/client";
import { useDisclosure } from "@chakra-ui/react"
import { ChatAssist } from "components/chat-assist";
import * as analytics from "utils/analytics";

const ChatContext = createContext({});

export function useChatContext() {
    return useContext(ChatContext);
}

export function ChatWrapper({ children }) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const chatAssistRef = useRef(null);
    const { isOpen, onOpen: onOpenInternal, onClose } = useDisclosure();
    const [session] = useSession();

    const onOpen = () => {
        analytics.log("open_chat_assist", {}, session?.user?.uid, session);
        onOpenInternal();
    }

    const sharedValue = {
        chatAssistRef,
        isOpen,
        onOpen,
        onClose,
        isLoading,
        setIsLoading
    }

    return (
        <ChatContext.Provider value={sharedValue}>
            {children}
            <ChatAssist ref={chatAssistRef} />
        </ChatContext.Provider>
    )

}