export function Shorter() {
  return(<svg width="14" height="14" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.75 8C10.3125 8 10 7.6875 9.96875 7.25V3.03125C9.96875 2.625 10.1875 2.25 10.5625 2.09375C10.6875 2.03125 10.8125 2.03125 10.9688 2.03125C11.2188 2.03125 11.4688 2.125 11.6562 2.3125L13.125 3.78125L15.6875 1.25C15.9688 0.9375 16.4375 0.9375 16.75 1.25C17.0312 1.53125 17.0312 2 16.75 2.28125L14.1875 4.84375L15.6562 6.3125C15.9375 6.59375 16.0312 7.03125 15.875 7.40625C15.7188 7.78125 15.375 8 14.9688 8H10.75ZM7.25 10C7.65625 10 8 10.3438 8 10.75V15C8 15.4062 7.75 15.7812 7.375 15.9375C7.25 16 7.125 16 7 16C6.71875 16 6.46875 15.9062 6.28125 15.7188L4.8125 14.25L2.25 16.7812C2.125 16.9375 1.9375 17 1.75 17C1.53125 17 1.34375 16.9375 1.21875 16.7812C0.90625 16.4688 0.90625 16 1.21875 15.7188L3.75 13.1562L2.28125 11.6875C2 11.4062 1.90625 10.9688 2.0625 10.5938C2.21875 10.2188 2.59375 10 3 10H7.25ZM7.375 2.09375C7.75 2.25 8 2.625 7.9375 3V7.28125C7.9375 7.6875 7.625 8 7.21875 8H3C2.59375 8 2.21875 7.78125 2.0625 7.40625C2 7.28125 2 7.15625 2 7C2 6.75 2.09375 6.5 2.28125 6.3125L3.75 4.84375L1.21875 2.28125C0.90625 2 0.90625 1.53125 1.21875 1.25C1.5 0.9375 1.96875 0.9375 2.25 1.25L4.8125 3.78125L6.28125 2.28125C6.46875 2.09375 6.71875 2 7 2C7.125 2 7.25 2.03125 7.375 2.09375ZM14.2188 13.1875L16.75 15.75C17.0625 16.0312 17.0625 16.5 16.75 16.7812C16.625 16.9375 16.4375 17 16.25 17C16.0312 17 15.8438 16.9375 15.75 16.75L13.2188 14.1875L11.75 15.6562C11.5625 15.8438 11.3125 15.9688 11.0312 15.9688C10.9062 15.9688 10.7812 15.9375 10.6562 15.875C10.2812 15.7188 10.0312 15.375 10.0312 14.9688V10.75C10.0312 10.3438 10.3438 10.0312 10.75 10.0312H15C15.375 10.0312 15.75 10.25 15.9062 10.625C16.0625 11 15.9688 11.4375 15.6875 11.7188L14.2188 13.1875Z" fill="url(#g)"/>
  <linearGradient
          id="g"
          x1="-5"
          y1="-5"
          x2="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
    </linearGradient> </svg>
  )
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8333 0H1.16672C0.525171 0 0 0.525342 0 1.16689V12.8334C0 13.475 0.525171 14 1.16672 14H12.8333C13.4748 14 14 13.475 14 12.8334V1.16689C14 0.525342 13.4748 0 12.8333 0ZM6.58769 6.00441C6.58769 6.32642 6.32553 6.58786 6.00424 6.58786H2.50424V5.42148L4.59601 5.4208L1.75 2.57513L2.57496 1.75L5.421 4.59621L5.42049 2.50445H6.58772V6.00441H6.58769ZM11.425 12.2507L8.579 9.40447V11.4962H7.41228V7.99624C7.41228 7.67457 7.67372 7.41313 7.99572 7.41313L11.4957 7.41241V8.57897L9.40396 8.57968L12.25 11.4259L11.425 12.2507Z"
        fill="url(#paint0_linear_0_88)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_88"
          x1="7"
          y1="0"
          x2="7"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
