export function CopyWriting() {
  return (
    <svg width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.75 5.5C13.75 6.21875 13.1875 6.75 12.5 6.75C11.7812 6.75 11.25 6.21875 11.25 5.5C11.25 4.8125 11.7812 4.25 12.5 4.25C13.1875 4.25 13.75 4.8125 13.75 5.5ZM13 10.7812V13.3438C13 14.125 12.5625 14.875 11.875 15.2812L9.125 16.9062C8.875 17.0312 8.59375 17.0625 8.375 16.9062C8.125 16.7812 8 16.5312 8 16.25V12.6875C8 11.9688 7.71875 11.2812 7.21875 10.7812C6.71875 10.2812 6.03125 10 5.3125 10H1.75C1.46875 10 1.21875 9.875 1.09375 9.625C0.9375 9.40625 0.9375 9.125 1.09375 8.875L2.71875 6.125C3.125 5.4375 3.875 5 4.65625 5H7.21875C9.78125 0.90625 13.75 0.75 16.0938 1.1875C16.4688 1.25 16.75 1.53125 16.8125 1.90625C17.25 4.25 17.0938 8.21875 13 10.7812ZM7.15625 8.9375C7.5625 9.15625 7.9375 9.40625 8.25 9.71875C8.59375 10.0625 8.84375 10.4375 9.0625 10.8438C12.3438 9.90625 13.9375 8.34375 14.7188 6.875C15.5 5.4375 15.5625 3.84375 15.4062 2.59375C14.1562 2.4375 12.5625 2.5 11.0938 3.28125C9.65625 4.0625 8.09375 5.65625 7.15625 8.9375ZM2.28125 11.8125C3.375 10.75 5.125 10.75 6.1875 11.8125C7.25 12.875 7.25 14.625 6.1875 15.6875C4.65625 17.25 1 17 1 17C1 17 0.75 13.3438 2.28125 11.8125ZM3 15C3 15 4.21875 15.0938 4.71875 14.5625C5.09375 14.2188 5.09375 13.625 4.71875 13.2812C4.375 12.9062 3.78125 12.9062 3.4375 13.2812C2.90625 13.7812 3 15 3 15Z" fill="url(#gradient)"/>
    <linearGradient
          id="gradient"
          x1="-5"
          y1="-5"
          x2="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
    </svg>

  )
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5622 3.78073L2.34895 3.87444C2.01136 3.90052 1.71069 4.07741 1.52399 4.35973L0.124783 6.47536C-0.0168141 6.68946 -0.0393242 6.9574 0.0645283 7.19212C0.168408 7.42683 0.381884 7.5904 0.635566 7.6297L1.74643 7.80169C2.0063 6.42302 2.62837 5.04775 3.5622 3.78073Z"
        fill="url(#paint0_linear_0_56)"
      />
      <path
        d="M6.2052 12.254L6.37727 13.3646C6.41657 13.6182 6.58018 13.8316 6.81494 13.9355C6.91261 13.9787 7.016 14 7.11887 14C7.26336 14 7.40685 13.9579 7.53192 13.8752L9.64819 12.4764C9.93059 12.2898 10.1075 11.9891 10.1336 11.6517L10.2273 10.4388C8.9599 11.3724 7.58427 11.9943 6.2052 12.254Z"
        fill="url(#paint1_linear_0_56)"
      />
      <path
        d="M5.78144 11.4843C5.82 11.4843 5.85879 11.4811 5.89749 11.4747C6.47526 11.3781 7.03213 11.2154 7.56334 11.0025L2.99761 6.43799C2.7846 6.96903 2.62186 7.52574 2.52525 8.10338C2.48718 8.33107 2.56357 8.56302 2.72683 8.72626L5.27443 11.2732C5.40998 11.4086 5.59293 11.4843 5.78144 11.4843Z"
        fill="url(#paint2_linear_0_56)"
      />
      <path
        d="M12.8869 6.20708C14.0042 4.04764 14.0456 1.76566 13.9872 0.695429C13.9672 0.328725 13.6747 0.036367 13.3079 0.0163788C13.1336 0.00686324 12.927 0 12.6942 0C11.4971 0 9.60302 0.181261 7.79472 1.11633C6.35764 1.85945 4.47322 3.49571 3.36145 5.64607C3.37458 5.65632 3.38741 5.6672 3.3995 5.67929L8.32271 10.6011C8.3348 10.6132 8.34566 10.626 8.35591 10.6391C10.5069 9.52765 12.1436 7.64376 12.8869 6.20708ZM8.14203 2.95967C8.9418 2.16012 10.2432 2.16003 11.043 2.95967C11.4305 3.34699 11.6439 3.86201 11.6439 4.40978C11.6439 4.95756 11.4305 5.47257 11.043 5.85989C10.6432 6.25963 10.1178 6.45954 9.59254 6.45959C9.06715 6.45962 8.54198 6.25974 8.14203 5.85989C7.75457 5.47257 7.54118 4.95756 7.54118 4.40978C7.54118 3.86201 7.75457 3.34699 8.14203 2.95967Z"
        fill="url(#paint3_linear_0_56)"
      />
      <path
        d="M8.72405 5.27982C9.20393 5.75957 9.98481 5.75959 10.4647 5.27982C10.6971 5.0474 10.8252 4.73841 10.8252 4.40974C10.8252 4.08107 10.6971 3.77208 10.4647 3.53969C10.2248 3.2998 9.90957 3.17987 9.59437 3.17987C9.2792 3.17987 8.964 3.2998 8.72407 3.53969C8.49162 3.77208 8.36356 4.08107 8.36356 4.40974C8.36356 4.73841 8.49159 5.04742 8.72405 5.27982Z"
        fill="url(#paint4_linear_0_56)"
      />
      <path
        d="M0.417578 11.5373C0.52258 11.5373 0.627582 11.4973 0.707667 11.4172L2.04715 10.078C2.20737 9.91787 2.20737 9.65818 2.04715 9.498C1.88695 9.33782 1.62717 9.33782 1.46694 9.498L0.127462 10.8371C-0.0327624 10.9973 -0.0327624 11.257 0.127462 11.4172C0.207574 11.4972 0.312576 11.5373 0.417578 11.5373Z"
        fill="url(#paint5_linear_0_56)"
      />
      <path
        d="M3.27564 10.7252C3.11545 10.5651 2.85566 10.5651 2.69544 10.7252L0.120168 13.2998C-0.0400561 13.46 -0.0400561 13.7197 0.120168 13.8798C0.200281 13.9599 0.305283 14 0.410285 14C0.515287 14 0.620289 13.9599 0.700374 13.8798L3.27562 11.3053C3.43587 11.1451 3.43587 10.8854 3.27564 10.7252Z"
        fill="url(#paint6_linear_0_56)"
      />
      <path
        d="M3.92419 11.9524L2.58474 13.2915C2.42451 13.4517 2.42451 13.7114 2.58474 13.8716C2.66485 13.9517 2.76985 13.9917 2.87483 13.9917C2.9798 13.9917 3.08483 13.9517 3.16492 13.8716L4.5044 12.5325C4.66462 12.3723 4.66462 12.1126 4.5044 11.9524C4.3442 11.7923 4.08441 11.7923 3.92419 11.9524Z"
        fill="url(#paint7_linear_0_56)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_56"
          x1="1.7811"
          y1="3.78073"
          x2="1.7811"
          y2="7.80169"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_56"
          x1="8.21625"
          y1="10.4388"
          x2="8.21625"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_56"
          x1="5.03948"
          y1="6.43799"
          x2="5.03948"
          y2="11.4843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_56"
          x1="8.6825"
          y1="0"
          x2="8.6825"
          y2="10.6391"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_0_56"
          x1="9.59437"
          y1="3.17987"
          x2="9.59437"
          y2="5.63964"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_0_56"
          x1="1.0873"
          y1="9.37787"
          x2="1.0873"
          y2="11.5373"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_0_56"
          x1="1.6979"
          y1="10.6051"
          x2="1.6979"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_0_56"
          x1="3.54457"
          y1="11.8323"
          x2="3.54457"
          y2="13.9917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
