import { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Menu,
  MenuButton,
  Icon,
  Button,
  Text,
  MenuList,
  MenuItem,
  Tag,
  TagCloseButton,
  TagLabel,
  IconButton,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useSession } from "next-auth/client";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/nextjs";
import { getAccount } from "utils/sessionHelper";
import { useMiscellaneousContext } from "context/miscellaneousContext";
import { firebaseClient } from "firebaseClient";
import { useFirebaseUser } from "utils/useFirebaseUser";
import { addRemovePeopleToList } from "controllers/list";
import { getFormattedContacts } from "components/add-people/utils";
import { ListTag } from "components/person-sidebar/listTag";
import { ContactListDropdown } from "components/contact-list-dropdown";
import { RiDeleteBinLine } from "react-icons/ri";
import { PlusIcon } from "components/icons/PlusIcon";
import { layerStyle, textStyle, variant } from "theme/names";

interface Props {
  contact: any;
}

export function List({ contact }: Props) {
  const [personLists, setPersonLists] = useState<number[]>([]);
  const [selectedList, setSelectedList] = useState<any>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [refresh, refresher] = useState({});
  const [session] = useSession();
  const miscellaneousContext: any = useMiscellaneousContext();
  const isAuthenticated = useFirebaseUser();
  const contactLists = getAccount(session)?.contactLists;

  const checkForLists = async () => {
    setPersonLists(miscellaneousContext?.sidebarPerson?.lists || []);
    if (miscellaneousContext?.sidebarPerson?.lists === undefined) {
      const db = firebaseClient.firestore();

      const data = await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("contacts")
        .doc(miscellaneousContext?.sidebarPerson?.id_str)
        .get();

      const contact = data.data();
      if (contact && contact.lists) {
        setPersonLists(contact.lists);
      }
    }
  };

  const handleClick = async () => {
    try {
      if (!isAuthenticated) {
        toast.error("User is not Authenticated");
        return;
      }
      if (personLists.includes(selectedList.id)) {
        toast.error(`${contact.name} already exist in the list`);
        return;
      }

      setIsSaving(true);
      const newContact = {...contact};
      delete newContact.actions;
      let formattedContacts = [newContact];
      // if contact already has image_400x400 that means its already a contact so no need to format it
      if (!newContact?.image_400x400) {
        formattedContacts = getFormattedContacts([newContact]);
      }

      await addRemovePeopleToList(
        getAccount(session)?.id,
        selectedList?.id,
        formattedContacts,
        "save"
      );
      const updatedPersonList = [...personLists, selectedList.id];
      setPersonLists(updatedPersonList);
      miscellaneousContext.setSidebarPerson({ ...miscellaneousContext?.sidebarPerson, lists: updatedPersonList })
      refresher({});
      setSelectedList(null);
    } catch (e) {
      console.log("Error in adding person to the list: ", e);
      toast.error("Error in adding person to the list. " + e.message);
      Sentry.captureException(e);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (miscellaneousContext.sidebarPerson) {
      checkForLists();
      setSelectedList(null);
    }
  }, [miscellaneousContext.sidebarPerson.id_str, refresh]);

  // const listsUI = contactLists
  //   ?.filter((cl) => personLists?.includes(cl.id))
  //   .map((c) => (
  //     <ListTag contact={contact} list={c} />
  //   ));
  
  const listsUI = contactLists
  ?.filter((cl) => personLists?.includes(cl.id))
  .map((c) => (
    <Flex
      key={c.id}
      mt={1}
      layerStyle={layerStyle["bg.border.rounded"]}
      textStyle={textStyle["body.medium.light"]}
      // variant="outline"
      px="4"
      py="2"
      // m="1"
      display="flex"
      alignItems="center"
      textAlign="center"
    >
      {/* {c.name} */}
      <ListTag contact={contact} list={c} />
    </Flex>
  ));

  return (
    <>
      {/* {listsUI?.length > 0 && (
        <Flex gap={4} mt="8" justifyContent="center" flexWrap="wrap">
          {listsUI}
        </Flex>
      )} */}
           {listsUI?.length > 0 && (
        <Flex
        // spacing={4}
          mt="8"
          // pt="4"
          flexDirection={"column"}
        // justifyContent="center"
        // flexWrap="wrap"
        >
          <Text mb={2} textStyle={textStyle["body.medium.standard"]} textAlign="start">Lists</Text>
          {listsUI}
        </Flex>
      )}
          {contactLists && contactLists?.length > 0 && (
        <Flex mt="4" w="full" justifyContent="flex-start">
          <ContactListDropdown
            placeholder="Add to a contact list"
            onSelectList={(list) => setSelectedList(list)}
            reset={!selectedList?.name}
            isDisabled={isSaving}
          />
          <Button
            // size="sm"
            ml="4"
            px="4"
            variant={variant.Button.primary}
            // colorScheme="twitter"
            isDisabled={!selectedList?.name}
            isLoading={isSaving}
            onClick={handleClick}
          >
            Add
          </Button>
        </Flex>
      )}
      {/* {contactLists && contactLists?.length > 0 && (
        <Box mt="4">
          <ContactListDropdown
            placeholder="Add to a contact list"
            onSelectList={(list) => setSelectedList(list)}
            reset={!selectedList?.name}
            isDisabled={isSaving}
          />
          <Button
            size="sm"
            ml="4"
            px="4"
            variant={"primary"}
            isDisabled={!selectedList?.name}
            isLoading={isSaving}
            onClick={handleClick}
            leftIcon={PlusIcon}
          >
            Add
          </Button>
        </Box>
      )} */}
    </>
  );
}
