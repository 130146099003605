import { useState, useEffect } from "react";
import {
  Flex,
  Box,
  Menu,
  MenuButton,
  Icon,
  Button,
  Text,
  MenuList,
  MenuItem,
  Tag,
  TagCloseButton,
  TagLabel,
  IconButton,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useSession } from "next-auth/client";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/nextjs";
import { getAccount } from "utils/sessionHelper";
import { firebaseClient } from "firebaseClient";
import { useFirebaseUser } from "utils/useFirebaseUser";
import { addRemovePeopleToList } from "controllers/list";
import { getFormattedContacts } from "components/add-people/utils";
import { ContactListDropdown } from "components/contact-list-dropdown";
import { RiDeleteBinLine } from "react-icons/ri";
import { useMiscellaneousContext } from "context/miscellaneousContext";
import { DeleteIcon } from "components/icons/DeleteIcon";
import { DeleteIconComponent } from "components/icons/DeleteIconComponent";
import { textStyle } from "theme/names";



export const ListTag = ({contact, list}) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [session] = useSession();
    const miscellaneousContext: any = useMiscellaneousContext();

    return (
        <Tag
            key={list.id}
            variant={"secondary"}
            // px="2"
            // py="1"
            // m="1"
            display="flex"
            alignItems="center"
            textAlign="center"
            gap="2"
        >
            <TagLabel
                textStyle={textStyle["body.medium.light"]}
            >{list.name}</TagLabel>
            <IconButton
                isLoading={isLoading} aria-label="remove-list" size="xs" variant="tertiary" ml={1} icon={<DeleteIconComponent width={9} />}
                onClick={async (e) => {
                    setIsLoading(true);
                    // console.log("idList: " + list.id);
                    // console.log("contact lists: " + contact.lists);
                    await addRemovePeopleToList(getAccount(session)?.id, list.id, [contact], "remove");
                    // console.log(contact.lists);
                    miscellaneousContext.setSidebarPerson({...contact});
                    setIsLoading(false);
                }}
            />
        </Tag>
    )
}