import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Link,
} from "@chakra-ui/react";
import { ImageEditor } from "components/image-editor";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  imageId: string;
  onSave?: (newMedia) => void;
}

export function ImageEditPopup({ isOpen, onClose, imageId, onSave }: Props) {
  return (
    <Modal variant="pika" size="6xl" trapFocus={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Image Editor</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ImageEditor imageId={imageId} callback={onSave} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
