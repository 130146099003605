import { useMemo, useState, useRef } from "react";
import { useSession } from "next-auth/client";
import {
  Box,
  AccordionItem,
  AccordionButton,
  Icon,
  AccordionPanel,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { layerStyle, textStyle } from "theme/names";
import { SavedAction, GRADIENT_COLOR } from "./utils";
import { ActionCaretRight } from "./icons/ActionCaretRight";
import { colors } from "theme/colors/colors";
import { hexToHexa } from "utils/helpers";
import { BsFillCaretDownFill } from "react-icons/bs";
import { AiOutlineSave } from "react-icons/ai";
import { updateUser } from "utils/sessionHelper";
import { RiDeleteBin5Line } from "react-icons/ri";
import toast from "react-hot-toast";
import { EditorRef } from "./imperative-handle";
import { GrEdit } from "react-icons/gr";
import { MdOutlineEdit } from "react-icons/md";

interface Props {
  actions: SavedAction[];
  onUpdate: (updatedActions: SavedAction[]) => void;
  onSelect: (prompt: string) => void;
  editorInstance: any;
  imperativeHandleInstance: any;
}

export function SavedActions({
  actions,
  onUpdate,
  onSelect,
  editorInstance,
  imperativeHandleInstance,
}: Props) {
  const [session] = useSession();
  const sortedActions = useMemo(
    () => actions.sort((a, b) => b.createdAt.valueOf() - a.createdAt.valueOf()),
    [actions]
  );

  const accordionExpandedBg = useColorModeValue(
    "linear-gradient(91deg, #FFEFF2 0%, #EEF0FF 100%)",
    "#1a202c"
  );
  const savedActionIconColor = useColorModeValue(
    "rgb(86, 111, 143)",
    "text.darkMode.light"
  );

  return (
    <AccordionItem border="none">
      {({ isExpanded }) => (
        <Box>
          <Box bg={isExpanded ? accordionExpandedBg : "inherit"}>
            <AccordionButton
              alignItems="center"
              fontSize="sm"
              fontWeight="semibold"
              _expanded={{ bgGradient: GRADIENT_COLOR, bgClip: "text" }}
              minH="50px"
              textStyle={textStyle["body.medium.light"]}
              {...{
                pl: 6,
                pr: 6,
                color: "text.lightMode.light",
                _hover: {
                  color: isExpanded ? undefined : colors.ai[700],
                  bg: isExpanded ? undefined : colors.ai[50],
                },
                _dark: {
                  color: "text.darkMode.light",
                  _hover: {
                    color: isExpanded ? undefined : colors.ai[200],
                    bg: isExpanded ? undefined : hexToHexa(colors.ai[900], 0.2),
                  },
                },
              }}
            >
              <Box
                as="span"
                flex="1"
                display="flex"
                alignItems="center"
                textAlign="left"
              >
                <AiOutlineSave
                  size={15}
                  style={{
                    display: "inline",
                    marginRight: "6",
                  }}
                  color={isExpanded ? "#ED6282" : savedActionIconColor}
                />{" "}
                Saved Actions
              </Box>
              <motion.div
                animate={{
                  rotate: isExpanded ? 180 : 0,
                }}
                transition={{
                  type: "spring",
                  duration: 0.5,
                  bounce: 0,
                }}
                style={{ height: "14px" }}
              >
                <Icon
                  as={BsFillCaretDownFill}
                  h="3.5"
                  w="3.5"
                  color={isExpanded ? `#606AE2` : "inherit"}
                  opacity={".65"}
                />
              </motion.div>
            </AccordionButton>
          </Box>

          <AccordionPanel p="0">
            {sortedActions.length > 0 ? (
              sortedActions.map((a) => (
                <Action
                  key={a.id}
                  {...a}
                  onDelete={async (id) => {
                    const newActions = actions.filter((act) => act.id !== id);
                    await updateUser(session, { savedActions: newActions });
                    onUpdate(newActions);
                  }}
                  onSelect={onSelect}
                  onSave={async ({ title, prompt }) => {
                    const newActions = [...actions];
                    const index = newActions.findIndex((na) => na.id === a.id);
                    newActions[index].title = title;
                    newActions[index].prompt = prompt;
                    await updateUser(session, { savedActions: newActions });
                    onUpdate(newActions);
                  }}
                  editorInstance={editorInstance}
                  imperativeHandleInstance={imperativeHandleInstance}
                />
              ))
            ) : (
              <Flex p="2" pl="6" minH="50px" alignItems="center">
                <Text fontWeight="normal">No saved actions found!</Text>
              </Flex>
            )}
          </AccordionPanel>
        </Box>
      )}
    </AccordionItem>
  );
}

interface ActionProps extends SavedAction {
  onDelete: (id: string) => Promise<void>;
  onSelect: (prompt: string) => void;
  editorInstance: any;
  imperativeHandleInstance: any;
  onSave: ({
    title,
    prompt,
  }: {
    title: string;
    prompt: string;
  }) => Promise<void>;
}

function Action({
  id,
  title: savedTitle,
  prompt,
  onDelete,
  onSelect,
  onSave,
  editorInstance: Editor,
  imperativeHandleInstance: ImperativeHandle,
}: ActionProps) {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(savedTitle);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const editorRef = useRef<EditorRef | null>(null);

  const handleDelete = async (e) => {
    try {
      e.stopPropagation();
      setIsDeleting(true);
      await onDelete(id);
    } catch (e) {
      console.error("Error in deleting action: ", e);
      toast.error("Error in deleting action: " + e.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSave = async (callback?: () => void) => {
    try {
      const editorText = editorRef.current?.getHTML();
      if (!title || !editorText || editorText === "<p></p>") {
        toast.error("Title & action text is required");
        return;
      }

      setIsSaving(true);
      await onSave({ title, prompt: editorRef.current?.getHTML() || prompt });
      callback && callback();
    } catch (e) {
      console.error("Error in saving action: " + e);
      toast.error("Error in saving action: ", e.message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Flex
      fontSize="sm"
      p="2"
      pl="8"
      cursor="pointer"
      minH="50px"
      alignItems="center"
      textStyle={textStyle["body.medium.light"]}
      {...{
        gap: 2,
        color: "text.lightMode.light",
        _hover: {
          color: colors.ai[700],
          bg: colors.ai[50],
        },
        _dark: {
          color: "text.darkMode.light",
          _hover: {
            color: colors.ai[200],
            bg: hexToHexa(colors.ai[900], 0.2),
          },
        },
      }}
      onClick={() => onSelect(prompt)}
    >
      <Box width="6px" color={colors.ai[300]}>
        <ActionCaretRight />
      </Box>
      <Text isTruncated>{savedTitle}</Text>

      <Popover placement="right-start" closeOnBlur={!isSaving}>
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <IconButton
                aria-label="edit action"
                variant="secondaryAI"
                borderColor="transparent"
                _hover={{
                  borderColor: "border.lightMode.hover",
                }}
                icon={<MdOutlineEdit size={16} />}
                color="#D792FF !important"
                size="sm"
                ml="2"
                onClick={(e) => e.stopPropagation()}
              />
            </PopoverTrigger>

            {isOpen ? (
              <Portal>
                <PopoverContent pb="1" onClick={(e) => e.stopPropagation()}>
                  <PopoverHeader fontWeight="semibold">
                    Edit action
                  </PopoverHeader>
                  <PopoverCloseButton isDisabled={isSaving} />
                  <PopoverBody>
                    <VStack>
                      <FormControl>
                        <FormLabel htmlFor="title" pl="1">
                          Title
                        </FormLabel>
                        <Input
                          id="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </FormControl>
                      <Text
                        textAlign="left"
                        w="full"
                        pl="1"
                        fontWeight="medium"
                      >
                        Action
                      </Text>
                      <Box
                        w="100%"
                        layerStyle={layerStyle["bg.border.rounded"]}
                        py="2"
                        px="3"
                        pb="10"
                      >
                        <Editor
                          initialValue={prompt}
                          classNames={["edit-sent-msg"]}
                          placeholder="Start typing..."
                        >
                          <ImperativeHandle ref={editorRef} />
                        </Editor>
                      </Box>
                      <Flex justifyContent="flex-end" w="100%" pt="2">
                        <Button
                          variant="primary"
                          size="sm"
                          isLoading={isSaving}
                          onClick={() => handleSave(onClose)}
                        >
                          Save
                        </Button>
                      </Flex>
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            ) : null}
          </>
        )}
      </Popover>

      <IconButton
        aria-label="delete action"
        variant="secondaryAI"
        icon={<RiDeleteBin5Line />}
        borderColor="transparent"
        _hover={{
          borderColor: "border.lightMode.hover",
        }}
        color="#D792FF !important"
        size="sm"
        isLoading={isDeleting}
        onClick={handleDelete}
      />
    </Flex>
  );
}
