export function Assertive() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.75 2C11.9688 2 13 3.03125 13 4.28125V9.78125C13 11.3438 12.25 12.8125 11 13.75V15.25C11 15.6875 10.6562 16 10.25 16C9.8125 16 9.5 15.6875 9.5 15.25V13.3438C9.5 13.0938 9.625 12.8438 9.84375 12.7188C10.875 12.0625 11.5 10.9688 11.5 9.75V8.375C11.25 8.46875 11 8.5 10.75 8.5C10.2812 8.5 9.84375 8.375 9.5 8.125C9.125 8.375 8.6875 8.5 8.25 8.5C8 8.5 7.8125 8.46875 7.59375 8.40625C7.28125 9.34375 6.40625 10 5.375 10H3.75C3.3125 10 3 9.6875 3 9.25C3 8.84375 3.3125 8.5 3.75 8.5H5.375C5.84375 8.5 6.25 8.125 6.25 7.625C6.25 7.15625 5.84375 6.75 5.375 6.75H2.75C2.03125 6.75 1.5 7.3125 1.5 8V10.7812C1.5 11.1562 1.65625 11.5312 1.96875 11.75L3.03125 12.5938C3.09375 12.625 3.5 12.8125 3.5 13.2812V15.25C3.5 15.6875 3.15625 16 2.75 16C2.3125 16 2 15.6875 2 15.25V13.6875L1.03125 12.9375C0.375 12.4375 0 11.625 0 10.7812V8C0 7.125 0.40625 6.34375 1.0625 5.84375C1.03125 5.65625 1 5.46875 1 5.25V3.25C1 2.03125 2 1 3.25 1C3.4375 1 3.625 1.03125 3.8125 1.09375C4.21875 0.4375 4.9375 0 5.75 0C6.53125 0 7.25 0.4375 7.65625 1.09375C7.84375 1.03125 8.03125 1 8.25 1C9.03125 1 9.75 1.4375 10.1562 2.09375C10.3438 2.03125 10.5312 2 10.75 2ZM5 2.25V5.25H5.375C5.75 5.25 6.09375 5.375 6.4375 5.53125C6.46875 5.4375 6.5 5.375 6.5 5.25V2.25C6.5 1.84375 6.15625 1.5 5.75 1.5C5.3125 1.5 5 1.84375 5 2.25ZM2.5 5.25C2.5 5.28125 2.5 5.28125 2.5 5.28125C2.5625 5.28125 2.65625 5.25 2.75 5.25H4V3.25C4 2.84375 3.65625 2.5 3.25 2.5C2.8125 2.5 2.5 2.84375 2.5 3.25V5.25ZM9 6.25V3.25C9 2.84375 8.65625 2.5 8.25 2.5C7.8125 2.5 7.5 2.84375 7.5 3.25V6.25C7.5 6.6875 7.8125 7 8.25 7C8.65625 7 9 6.6875 9 6.25ZM10.75 7C11.1562 7 11.5 6.6875 11.5 6.25V4.25C11.5 3.84375 11.1562 3.5 10.75 3.5C10.3125 3.5 10 3.84375 10 4.25V6.25C10 6.6875 10.3125 7 10.75 7Z" fill="url(#g)"/>
<linearGradient
          id="g"
          x1="-5"
          y1="-5"
          x2="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
    </linearGradient>
    </svg>

  )
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4706 3.52939C13.4198 3.52939 15 5.10955 15 7.05882C15 8.89515 13.5976 10.4039 11.8054 10.5726C11.2499 12.8491 8.61258 14.7794 5.95588 14.7794C5.9376 14.7794 5.91932 14.7783 5.90115 14.776C3.88084 14.5235 0 7.50097 0 4.41176C0 1.75702 0.993106 0 2.20588 0C2.66271 0 3.40389 0.228076 4.48421 0.68294C4.97499 0.8896 5.29412 1.37024 5.29412 1.90274V2.54291C5.29412 3.04424 5.01088 3.50253 4.56249 3.72671L4.52827 3.74203L2.67639 4.47879C2.86567 5.46088 3.96747 6.94228 5.87001 8.80213C6.17118 7.81772 6.95206 7.03421 7.95527 6.74136C8.11576 4.94086 9.62836 3.52939 11.4706 3.52939Z"
        fill="url(#paint0_linear_0_83)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_83"
          x1="7.5"
          y1="0"
          x2="7.5"
          y2="14.7794"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
