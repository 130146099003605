// as recommended in https://chakra-ui.com/docs/styled-system/customize-theme#scaling-out-your-project
// # all my button overrides

import { defineStyleConfig } from "@chakra-ui/react";
import { radius } from "theme/foundations/radius";
import { primary, primaryDestructive, primaryWarning } from "./button/primary";
import { secondary, secondarySelected, secondaryAccent, secondaryDestructive, secondaryAI, secondaryBookmark } from "./button/secondary";
import { action, actionAI, actionBg, actionEditPost } from "./button/action";
import { actionGray } from "./button/actionGray";
import { light, lightSelected } from "./button/light";
import { rounded } from "./button/rounded";
import { tertiary, tertiaryNoBackground, tertiaryLight } from "./button/tertiary";
import { invisible } from "./button/invisible";
import { feather } from "./button/feather";
import { suggestion } from "./button/suggestion";
import { filter } from "./button/filter";
import { ai } from "./button/ai";
import { text } from "theme/foundations/text";
import { border } from "theme/foundations/border";
import { colors } from "theme/colors/colors";

const Button = defineStyleConfig({
  baseStyle: {
    minW: "fit-content",
  },
  sizes: {
    composer: {
      minW: "32px",
      minH: "32px",
      w: "32px",
      h: "32px",
    },
    table: {
      minW: "24px",
      minH: "24px",
      w: "24px",
      h: "24px",
    },
  },
  variants: {
    taplioPayment: {
      bg: "taplio.600",
      color: "white",
      _hover: {
        bg: "taplio.500",
      },
    },
    taplioPaymentOutline: {
      bg: "white",
      color: "taplio.600",
      border: "2px",
      borderColor: "taplio.600",
      _hover: {
        bg: "taplio.100",
      },
    },
    primary,
    primaryAI: {
      ...primary,
      background: "radial-gradient(120% 150% at 50% 100%, #EC6181 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), #316BFF",
      boxShadow: `0px 3px 4px #3a367a44`,
      backgroundBlendMode: "normal",
      border: "1px solid #3a367a",
      _before: {
        content: '""', // required for visibility
        border: "1px solid #bea5f6",
        position: "absolute",
        top: "0",
        left: "0",
        width: "calc(100% - 2px)",
        height: "calc(100% - 2px)",
        borderRadius: "9px",
      },
      _hover: {
        // prettier-ignore
        // background: "radial-gradient(50% 50% at 50% 100%, rgba(255, 255, 255, 0.45) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), #0A66C2",
        background: "radial-gradient(110% 120% at 50% 100%, #EC6181 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), #316BFF",
        textDecor: "none",
        _disabled: {
          background: "#F3F6F9",
        },
      },
      _active: {
        background: "#967ef6",
      },
      _dark: {
        _before: {
          content: '""', // required for visibility
        border: "1px solid #a885f5",
        // border: `1px solid ${colors.primary.lightMode[400]}`,
          position: "absolute",
          top: "0",
          left: "0",
          width: "calc(100% - 2px)",
          height: "calc(100% - 2px)",
          borderRadius: "9px",
        },
      },
    },
    primaryAffiliate: {
      ...primary,
      boxShadow: undefined,
      background: "radial-gradient(120% 150% at 50% 100%, hsl(203, 100%, 36%) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), hsl(203, 100%, 15%)",
      backgroundBlendMode: "normal",
      border: "1px solid hsl(203, 100%, 12%)",
      // _before: {
      //   content: '""', // required for visibility
      //   border: "1px solid hsl(203, 100%, 56%)",
      //   position: "absolute",
      //   top: "0",
      //   left: "0",
      //   width: "calc(100% - 2px)",
      //   height: "calc(100% - 2px)",
      //   borderRadius: "9px",
      // },
      _hover: {
        // prettier-ignore
        // background: "radial-gradient(50% 50% at 50% 100%, rgba(255, 255, 255, 0.45) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), #0A66C2",
        background: "radial-(110% 120% at 50% 100%, hsl(203, 100%, 36%) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), hsl(203, 100%, 15%)",
        textDecor: "none",
        _disabled: {
          background: "#F3F6F9",
        },
      },
      _active: {
        background: "hsl(203, 100%, 12%)",
      },

    },
    primaryDestructive,
    primaryWarning,
    primaryLeft: {
      ...primary,
      borderTopLeftRadius: radius.lg,
      borderBottomLeftRadius: radius.lg,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      _before: {
        content: '""', // required for visibility
        border: `1px solid ${colors.primary.lightMode[300]}`,
        // border: "1px solid #54A9FF",
        position: "absolute",
        top: "0",
        left: "0",
        width: "calc(100% - 2px)",
        height: "calc(100% - 2px)",
        borderRadius: "9px",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      _dark: {
        borderTopLeftRadius: radius.lg,
        borderBottomLeftRadius: radius.lg,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        _before: {
          content: '""', // required for visibility
          border: `1px solid ${colors.primary.lightMode[400]}`,
          position: "absolute",
          top: "0",
          left: "0",
          width: "calc(100% - 2px)",
          height: "calc(100% - 2px)",
          borderRadius: "9px",
          borderTopLeftRadius: radius.lg,
        borderBottomLeftRadius: radius.lg,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        },
      }
    },
    primaryRight: {
      ...primary,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: radius.lg,
      borderBottomRightRadius: radius.lg,
      _before: {
        content: '""', // required for visibility
        border: `1px solid ${colors.primary.lightMode[300]}`,
        // border: "1px solid #54A9FF",
        position: "absolute",
        top: "0",
        left: "0",
        width: "calc(100% - 2px)",
        height: "calc(100% - 2px)",
        borderRadius: "9px",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      _dark: {
        border: `1px solid ${colors.primary.lightMode[700]}`,
        background: `radial-gradient(70% 90% at 50% 100%, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), ${colors.primary.lightMode[600]}`,
        backgroundBlendMode: "overlay, normal",
  borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: radius.lg,
        borderBottomRightRadius: radius.lg,
        _before: {
          content: '""', // required for visibility
          border: `1px solid ${colors.primary.lightMode[400]}`,
          position: "absolute",
          top: "0",
          left: "0",
          width: "calc(100% - 2px)",
          height: "calc(100% - 2px)",
          borderRadius: "9px",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: radius.lg,
          borderBottomRightRadius: radius.lg,
        },
      }
    },
    secondary,
    secondarySelected,
    secondaryDestructive,
    secondaryAccent,
    secondaryAI,
    secondaryBookmark,
    ai,
    tertiary,
    tertiaryLight,
    tertiaryNoBackground,
    invisible,
    ghost: invisible,
    switcher: {
      ...secondary,
      ...text.body.medium.light.light,
      _hover: {
        ...text.body.medium.standard.light,
        ...border.default.hover.light,
      },
      _active: {
        ...text.body.medium.standard.light,
        ...border.default.hover.light,
      },
      _dark: {
        _hover: {
          ...text.body.medium.standard.dark,
        },
        _active: {
          ...text.body.medium.standard.dark,
        },
      },
    },
    action,
    actionAI,
    actionBg,
    actionEditPost,
    actionDestructive: {
      ...action,
      color: "alert.lightMode.default",
      _hover: {
        bg: "alert.lightMode.100",
        color: "alert.lightMode.default",
        _dark: {
        bg: "alert.darkMode.100",
        color: "alert.darkMode.default",
        },
      },
      _active: {
        bg: "alert.lightMode.100",
        color: "alert.lightMode.default",
        _dark: {
          color: "alert.darkMode.default",
        },
      },
    },
    actionGray,
    // actionAI: {
    //   ...action,
    //   bgGradient: "linear-gradient(180deg, #EC6181 0%, #316BFF 100%)",
    //   bgClip: "text",
    // },
    suggestion,
    filter,
    light,
    lightSelected,
    rounded,
    feather,
    featherLight: {
      ...feather,
      bg: "primary.lightMode.100",
      borderColor: "primary.lightMode.50",
      color: "primary.lightMode.default",
      _hover: {
        bg: "primary.lightMode.200",
        color: "white",
        borderColor: "primary.lightMode.100",
      },
      _active: {
        bg: "primary.lightMode.200",
        color: "white",
        borderColor: "primary.lightMode.100",
      },
      _dark: {
        bg: "neutral.darkMode.neutral",
        color: "primary.darkMode.default",
        borderColor: "border.darkMode.light",
        _hover: {
          bg: "primary.darkMode.200",
          color: "white",
          borderColor: "primary.darkMode.100",
        },
        _active: {
          bg: "primary.darkMode.200",
          color: "white",
          borderColor: "primary.darkMode.100",
        },
      },
    },
  },
});


export { Button };
