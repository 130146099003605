import { useMemo, useState } from "react";
import isEqual from "react-fast-compare";

export default function useUndoableState({initState, initStates}) {
  const initialStates = initStates ?  initStates : [initState];
  const stateIndex = initStates ? initStates?.length - 1 : 0;
  const [states, setStates] = useState(initialStates); // Used to store history of all states
  const [index, setIndex] = useState(stateIndex); // Index of current state within `states`
  const state = useMemo(() => states[index], [states, index]); // Current state
    const setState = (value) => {
        // If state has not changed, return to avoid triggering a re-render
        if (isEqual(state, value)) {
        return;
        }
        const copy = states.slice(0, index + 1); // This removes all future (redo) states after current index
        copy.push(value);
        setStates(copy);
        setIndex(copy?.length - 1);
    };
    // Clear all state history
    const resetState = (initState) => {
        setIndex(0);
        setStates([initState]);
    };
    // Allows you to go back (undo) N steps
    const goBack = (steps = 1) => {
        setIndex(Math.max(0, Number(index) - (Number(steps) || 1)));
    };
    // Allows you to go forward (redo) N steps
    const goForward = (steps = 1) => {
        setIndex(Math.min(states?.length - 1, Number(index) + (Number(steps) || 1)));
    };
    return {
        state,
        states,
        setState,
        resetState,
        index,
        lastIndex: states?.length - 1,
        goBack,
        goForward,
    };
}