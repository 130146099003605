import { useSession } from "next-auth/client";
import toast from "react-hot-toast";
import * as analytics from "utils/analytics";
import { getStripePlans } from "utils/stripeConfig";
import { postData } from "utils/helpers";

const stripePlans = getStripePlans();

export function useSubscription() {
  const [session] = useSession();

  const updatePlan = async (plan: string, discount="", callback = () => {}) => {
    if (!session?.user?.data?.subscription?.subscription) {
      analytics.log("error", { message: "subscription to update not found" });
      toast.error("subscription to update not found, please contact us");
      return;
    }

    const data = {
      isLocal: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
      newPrice: stripePlans[plan],
      subscription: session.user.data.subscription.subscription,
      idUser: session.user.uid,
      discount,
    };

    const stripeUpdate = await postData({
      url: "payment-updateSubscription",
      token: "",
      data: data,
    });

    if (stripeUpdate.success) {
      toast.success("Subscription updated successfully");
      analytics.log("subscription_update", {...data, plan});
      // @ts-ignore
      session.user.data.subscription.price = stripePlans[plan];
      callback && callback();
      return true;
    } else {
      analytics.log("error", {...stripeUpdate, message: "Failed to update subscription",});
      toast.error("Failed to update subscription please contact us");
      return false;
    }
  };

  return { updatePlan };
}
