// as recommended in https://chakra-ui.com/docs/styled-system/customize-theme#scaling-out-your-project
// # my main theme entrypoint

// theme/index.js
import { extendTheme } from "@chakra-ui/react";
import { theme as chakraTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { StepsTheme as Steps } from "chakra-ui-steps";
import { MultiSelectTheme as MultiSelect } from "chakra-multiselect";
import { fonts } from "./fonts/fonts";
import { colors } from "./colors/colors";
// global styles
import { styles } from "./styles/styles";
// Foundational style overrides
// textStyles
import { textStyles } from "./textStyles/textStyles";
import { layerStyles } from "./layerStyles/layerStyles";
// Component style overrides
import { Button } from "./components/button";
import { Input } from "./components/input";
import { Modal } from "./components/modal";
import { Heading } from "./components/heading";
import { Menu } from "./components/menu";
import { Alert } from "./components/alert";
import { Tabs } from "./components/tabs";
import { Link } from "./components/link";
import { Tooltip } from "./components/tooltip";
import { Select } from "./components/select";
import { Table } from "./components/table";
import { Checkbox } from "./components/checkbox";
import { Divider } from "./components/divider";
import { Slider } from "./components/slider";
import { Progress } from "./components/progress";
import { Switch } from "./components/switch";
import { TextArea } from "./components/textarea";
import { Text } from "./components/text";


export const customTheme = extendTheme({
  styles, // Global style overrides
  fonts,
  colors,
  textStyles,
  layerStyles,
  components: {
    Steps,
    MultiSelect,
    Button,
    Input,
    Modal,
    Heading,
    Menu,
    Alert,
    Tabs,
    Link,
    Tooltip,
    Select,
    Table, // doesn't work 100%
    Checkbox,
    Divider,
    Slider,
    Progress,
    Switch,
    TextArea,
    Text
  },
});


