import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";
import { text } from "theme/foundations/text";
import { textStyles } from "theme/textStyles/textStyles";

const pageTitle = defineStyle({
  ...textStyles.h1.bold.standard,
});

export const Heading = defineStyleConfig({
  baseStyle: {
    // ...text.h1.bold.standard.light,
    // _dark: {
    //   ...text.h1.bold.standard.dark,
    // },
    // ...textStyles.h2.bold.standard,
  },
  sizes: {
    // xl: {},
  },
  variants: {
    pageTitle,
  },
  defaultProps: {
    // size: "xl",
  },
});
