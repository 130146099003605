import { text } from "../foundations/text";

// can be used as a textStyle prop in chakra components to define common text styles
// https://chakra-ui.com/docs/styled-system/text-and-layer-styles
export const textStyles = {
  h1: {
    bold: {
      standard: {
        ...text.h1.bold.standard.light,
        _dark: {
          ...text.h1.bold.standard.dark,
        },
      },
    },
  },
  h2: {
    bold: {
      standard: {
        ...text.h2.bold.standard.light,
        _dark: {
          ...text.h2.bold.standard.dark,
        },
      },
    },
  },
  h3: {
    bold: {
      standard: {
        ...text.h3.bold.standard.light,
        _dark: {
          ...text.h3.bold.standard.dark,
        },
      },
      light: {
        ...text.h3.bold.light.light,
        _dark: {
          ...text.h3.bold.light.dark,
        },
      },
    },
  },
  h4: {
    medium: {
      standard: {
        ...text.h4.medium.standard.light,
        _dark: {
          ...text.h4.medium.standard.dark,
        },
      },
    },
    bold: {
      standard: {
        ...text.h4.bold.standard.light,
        _dark: {
          ...text.h4.bold.standard.dark,
        },
      },
      light: {
        ...text.h4.bold.light.light,
        _dark: {
          ...text.h4.bold.light.dark,
        },
      },
      white: {
        ...text.h4.bold.white.light,
        _dark: {
          ...text.h4.bold.white.dark,
        },
      },
    },
  },
  body: {
    medium: {
      light: {
        ...text.body.medium.light.light,
        _placeholder: {
          ...text.body.medium.inactive.light,
        },
        _dark: {
          ...text.body.medium.light.dark,
          _placeholder: {
            ...text.body.medium.inactive.dark,
          },
        },
      },
      white: {
        ...text.body.medium.white.light,
        // _placeholder: {
        //   ...text.body.medium.inactive.light,
        // },
        _dark: {
          ...text.body.medium.white.dark,
          // _placeholder: {
          //   ...text.body.medium.inactive.light,
          // },
        },
      },
      standard: {
        ...text.body.medium.standard.light,
        _placeholder: {
          ...text.body.medium.inactive.light,
        },
        _dark: {
          ...text.body.medium.standard.dark,
          _placeholder: {
            ...text.body.medium.inactive.dark,
          },
        },
      },
      primary: {
        ...text.body.medium.primary.light,
        _placeholder: {
          ...text.body.medium.inactive.light,
        },
        _dark: {
          ...text.body.medium.primary.dark,
          _placeholder: {
            ...text.body.medium.inactive.dark,
          },
        },
      },
      inactive: {
        ...text.body.medium.inactive.light,
        _placeholder: {
          ...text.body.medium.inactive.light,
        },
        _dark: {
          ...text.body.medium.inactive.dark,
          _placeholder: {
            ...text.body.medium.inactive.dark,
          },
        },
      },
      neutral: {
        ...text.body.medium.neutral.light,
        _placeholder: {
          ...text.body.medium.inactive.light,
        },
        _dark: {
          ...text.body.medium.neutral.dark,
          _placeholder: {
            ...text.body.medium.inactive.dark,
          },
        },
      },
    },
    bold: {
      standard: {
        ...text.body.bold.standard.light,
        _dark: {
          ...text.body.bold.standard.dark,
        },
      },
      light: {
        ...text.body.bold.light.light,
        _dark: {
          ...text.body.bold.light.dark,
        },
      },
    },
  },
  small: {
    bold: {
      standard: {
        ...text.h3.bold.standard.light,
        _dark: {
          ...text.h3.bold.standard.dark,
        },
      },
    },
    medium: {
      standard: {
        ...text.small.medium.standard.light,
        _dark: {
          ...text.small.medium.standard.dark,
        },
      },
      light: {
        ...text.small.medium.light.light,
        _dark: {
          ...text.small.medium.light.dark,
        },
      },
    },
  },
};
