import React from 'react';
import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/popover";
import { Box, Center, Divider, Flex, Stack, Text } from "@chakra-ui/layout";
import { Button, IconButton, Checkbox, CheckboxGroup,
    useDisclosure,
    Portal,
    useColorModeValue,
    Link,
} from "@chakra-ui/react";
import  FocusLock from "react-focus-lock";
import { getList, toggleSaveTweetInList } from "../../controllers/bookmark";
import { useSession } from "next-auth/client";
import { AddIcon, CheckIcon, LockIcon } from '@chakra-ui/icons';
import { UpsertCollection } from "components/collection-upsert";
import tweets from 'components/tweets';
import { useOnClickOutside } from "../../utils/helpers";
import toast from "react-hot-toast";
import { getPlan } from "../../controllers/subscription";
import { useRouter } from "next/dist/client/router";
import { getAccount } from "../../utils/sessionHelper";



export const CollectionDropdown = ({tweet, trigger, inLists, refresher=null, isOpenPopOver, closePopOver, setIsSaved, isOpenBookmark=false, refBookmark, setCollectionLoading}) => {
    const [session, loading] = useSession();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const router = useRouter();
    const bookmarkBg = useColorModeValue("white", "#1E1E1E");

    // console.log(inLists);

    const renderList = (list, index) => {
        return (
            <Box key={index}>
                <Checkbox variant={"secondary"} defaultChecked={inLists.includes(list.id) || (list.id == -3 && !getPlan(session?.user).includes("enterprise"))}
                    onChange={async (e) => {
                        // console.log(e.target.value);
                        setCollectionLoading(true);
                        if (session?.user?.uid) {

                            if (list.isEvergreen && tweet.user.id_str !== list.idAccount && e.target.checked) {
                                toast.error("You can only add your own tweet in the evergreen collection");
                                setCollectionLoading(false);
                                return;
                            }

                            // console.log(tweet);
                            await toggleSaveTweetInList(tweet, getAccount(session).id, list.id, e.target.checked ? "add" : "remove");
                            //@ts-ignore
                            // refresher && refresher({}); // make the experience really bad
                            if (e.target.checked) {
                                if (!tweet.lists)
                                    tweet.lists = []

                                tweet.lists.push(list.id);
                                setIsSaved(true);
                                setCollectionLoading(false);
                            }
                            else {
                                if (!tweet.lists) {
                                    setIsSaved(false);
                                    setCollectionLoading(false);
                                }
                                else {
                                    tweet.lists.splice(tweet.lists.indexOf(list.id), 1);
                                    if (tweet.lists?.length == 0)
                                        setIsSaved(false);
                                        setCollectionLoading(false);
                                    }
                            }
                        }
                    }}
                    isDisabled={list.id == -3 && !getPlan(session?.user).includes("enterprise")}
                    icon={list.id == -3 && !getPlan(session?.user).includes("enterprise") ? <LockIcon/> : <CheckIcon/>} 

                    // color={list.id == -3 && !getPlan(session?.user).includes("enterprise") ? "gray.400": useColorModeValue("black", "white")}
                >
                    {
                        list.id == -3 && !getPlan(session?.user).includes("enterprise") ? (
                            <Link onClick={(e) => {
                                router.push("/collections?tab=customai");
                            }}>
                                {list.name}
                            </Link>
                        ) : (
                                <Text textStyle="body.medium.standard"
                                    // fontWeight={list.id < 0 ? "600" : "400"}
                                >{list.name}</Text>
                        )
                    }
                </Checkbox>
            </Box>
        )
    }

    const listEvergreen = getAccount(session)?.lists?.filter(x => x.isEvergreen) ?? [];
    const listRegular = getAccount(session)?.lists?.filter(x => !x.isEvergreen) ?? [];

    return (
        <Box position="relative">
            {trigger}
            {
                isOpenBookmark && (
                <Box 
                    position="absolute" 
                    top="40px" 
                    right="0" 
                    w="300px"
                    bg={bookmarkBg} 
                    boxShadow="rgba(100, 100, 111, 0.15) 0px 2px 19px 0px;" 
                    rounded="md" 
                    zIndex="1"
                    ref={refBookmark}
                >
                    <Stack p={5} justifyContent="left" textAlign="left" justifySelf="left">
                        {
                            getAccount(session)?.lists ? (
                                    <Stack>
                                        {
                                            listEvergreen?.length && (
                                                <Box> 
                                                    <Text
                                                        textStyle="h4.bold.standard"
                                                        pb={4}
                                                        // fontWeight="600" color="gray.400"
                                                    // layerStyle="evergreen"
                                                    >Evergreen Collections</Text>
                                                    {
                                                        listEvergreen.map((list, index) => (
                                                            renderList(list, index)
                                                        ))
                                                    }
                                                </Box>
                                            )
                                        }
                                        <Box>
                                            <Divider mt={2} mb={2} />
                                        </Box>
                                        <Text
                                            textStyle="h4.bold.standard"
                                            py={2}
                                            // fontWeight="600" color="gray.400"
                                        >Regular Collections</Text>
                                        {
                                            listRegular.map((list, index) => (
                                                renderList(list, index)
                                            ))
                                        }
                                        <Box p={0}>
                                            <Button
                                                pl={2}
                                                pr={2}
                                                mt={4}
                                                w="100%"
                                                leftIcon={<AddIcon fontSize="14px" />}
                                                variant="primary"
                                                onClick={() => {
                                                    onOpen();
                                                }}
                                            >
                                                Create Collection
                                            </Button>
                                        </Box>
                                    </Stack>
                                ) : (
                                    <Box>
                                        Error
                                    </Box>
                                )
                        }
                    </Stack>
                </Box>
                )
            }

            <UpsertCollection refresher={refresher} isUpdate={false} isOpen={isOpen} onClose={onClose} />
        </Box>
    )

} 