import { Box, Flex } from "@chakra-ui/react";

interface Props {
  medias: string[];
}

export function ImagePreview({ medias }: Props) {
  let imagesView: any = medias.map((m, index) => (
    <Box
      key={"media-" + index}
      backgroundImage={m}
      backgroundSize="cover"
      borderRadius="xl"
      backgroundPosition="center"
      h="32"
      m="1"
      w={medias?.length > 1 ? "47%" : "100%"}
    />
  ));

  if (medias?.length === 3) {
    imagesView = (
      <>
        <Box
          backgroundImage={medias[0]}
          backgroundSize="cover"
          borderRadius="xl"
          backgroundPosition="center"
          m="1"
          w={medias?.length > 1 ? "47%" : "100%"}
        />
        <Flex flexDirection="column" w="45%">
          <Box
            backgroundImage={medias[1]}
            backgroundSize="cover"
            borderRadius="xl"
            backgroundPosition="center"
            h="32"
            m="1"
          />
          <Box
            backgroundImage={medias[2]}
            backgroundSize="cover"
            borderRadius="xl"
            backgroundPosition="center"
            h="32"
            m="1"
          />
        </Flex>
      </>
    );
  }

  return <Flex flexWrap="wrap">{imagesView}</Flex>;
}
