import { colors } from "theme/colors/colors";
import { border } from "theme/foundations/border";
import { radius } from "theme/foundations/radius";
import { color } from "theme/names";

export const layerStyles = {
  bg: {
    bg: "neutral.lightMode.neutral",
    _hover: {
      // ...border.default.hover.light,
    },
    _dark: {
      bg: "neutral.darkMode.neutral",
      // ...border.default.dark,
      _hover: {
        // ...border.default.hover.dark,
      },
    },
  },
  BgGray: {
    bg: color["background.lightMode.light"],
    ...border.default.light,
    _hover: {
      // ...border.default.hover.light,
    },
    _dark: {
      bg: color["background.darkMode.light"],
      ...border.default.dark,
      _hover: {
        // ...border.default.hover.dark,
      },
    },
  },
  bgBorderGray: {
    bg: color["background.lightMode.light"],
    ...border.default.light,
    _hover: {
      // ...border.default.hover.light,
    },
    _dark: {
      bg: color["background.darkMode.light"],
      ...border.default.dark,
      _hover: {
        // ...border.default.hover.dark,
      },
    },
  },
  bgBorderGrayRounded: {
    bg: color["background.lightMode.light"],
    borderRadius: radius.lg,
    ...border.default.light,
    _hover: {
      // ...border.default.hover.light,
    },
    _dark: {
      bg: color["background.darkMode.light"],
      ...border.default.dark,
      _hover: {
        // ...border.default.hover.dark,
      },
    },
  },
  bgRounded: {
    bg: "neutral.lightMode.neutral",
    borderRadius: radius.lg,
    _hover: {
      // ...border.default.hover.light,
    },
    _dark: {
      bg: "neutral.darkMode.neutral",
      ...border.default.dark,
      _hover: {
        // ...border.default.hover.dark,
      },
    },
  },
  bgBorder: {
    bg: "neutral.lightMode.neutral",
    ...border.default.light,
    _hover: {
      // ...border.default.hover.light,
    },
    _dark: {
      bg: "neutral.darkMode.neutral",
      ...border.default.dark,
      _hover: {
        // ...border.default.hover.dark,
      },
    },
  },
  bgBorderRounded: {
    bg: "neutral.lightMode.neutral",
    ...border.default.light,
    borderRadius: radius.lg,
    _hover: {
      // ...border.default.hover.light,
    },
    _dark: {
      bg: "neutral.darkMode.neutral",
      ...border.default.dark,
      _hover: {
        // ...border.default.hover.dark,
      },
    },
  },
  bgBorderRoundedHoverShadow: {
    bg: "neutral.lightMode.neutral",
    ...border.default.light,
    borderRadius: radius.lg,
    // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)",
    _hover: {
      // bg: "red.500",
      // ...border.default.hover.light,
      // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)"
    },
    _dark: {
      bg: "neutral.darkMode.neutral",
      ...border.default.dark,
      _hover: {
        // ...border.default.hover.dark,
      },
    },
  },
  border: {
    ...border.default.light,
    _hover: {
      // ...border.default.hover.light,
    },
    _dark: {
      ...border.default.dark,
      _hover: {
        // ...border.default.hover.dark,
      },
    },
  },
  borderHover: {
    ...border.default.light,
    _hover: {
      ...border.default.hover.light,
    },
    _dark: {
      ...border.default.dark,
      _hover: {
        ...border.default.hover.dark,
      },
    },
  },
  footerShadowTop: {
    zIndex: 1,
    transitionDuration: '1s',
    transitionTimingFunction: "ease-in-out",
    boxShadow: "0 -10px 10px #566F8F11", // based on text.lightMode.light
    _dark: {
    boxShadow: "0 -10px 10px #81818111", // based on text.darkMode.light
    }
  },
  evergreen: {
    px: 1.5,
    borderRadius: radius.md,
    fontSize: "2xs",
    color: colors.crm.lightMode[300],
    bg: colors.crm.lightMode[20],
    _dark: {
      color: colors.crm.darkMode[300],
      bg: colors.crm.darkMode[40]
    }
    // color: color["secondary.lightMode.500"],
    // bg: color["secondary.lightMode.100"],
    // _dark: {
    //   color: color["secondary.darkMode.500"],
    //   bg: color["secondary.darkMode.100"]
    // }
  }
};
