import { radius } from "theme/foundations/radius";
import { color } from "theme/names";
import { secondary } from "./secondary";

export const rounded = {
    borderRadius: "full",
    py:2, px: 4,
    color: color["text.lightMode.standard"],
    bg: "white",
    border: "1px solid",
    borderColor: "border.lightMode.light",
    _hover: {
      bg: color["primary.lightMode.default"],
      color: "white",
    },
    _dark: {
      color: "text.darkMode.standard",
      _hover: {
        color: "white",
        bg: "primary.darkMode.default",
      },
      borderColor: "border.darkMode.light",
      background: "#1E1E1E",
    //   _hover: {
    //     borderColor: "border.darkMode.hover",
    //     background: "#1E1E1E",
    //   },
      _active: {
        color: "primary.darkMode.400",
      },
      _disabled: {
        color: "text.darkMode.inactive",
        borderColor: "border.darkMode.inactive",
        _hover: {
          borderColor: "border.darkMode.hover",
        },
      },
    },
  //   ...secondary,
  //   borderRadius: radius.sm,
//   color: "text.lightMode.light",
//   border: "1px solid",
//   borderColor: "border.lightMode.light",
//   bg: "white",
//   _selected: {
//     // todo check if works
//     bg: "primary.lightMode.100",
//     _dark: {
//       bg: "primary.darkMode.100",
//     },
//   },
//   _dark: {
//     bg: "#1E1E1E",
//     borderColor: "border.darkMode.light",
//     // color: "text.darkMode.standard",
//     // bg: "primary.darkMode.100",
//   },
//   _hover: {
//     color: "text.lightMode.standard",
//     bg: "primary.lightMode.100",
//     // color: "primary.lightMode.default",
//     _dark: {
//       color: "text.darkMode.standard",
//       bg: "primary.darkMode.100",
//       //   color: "primary.darkMode.default",
//     },
//   },
//   _active: {
//     color: "white",
//     bg: "primary.lightMode.200",
//     // color: "primary.lightMode.default",
//     _dark: {
//       color: "white",
//       bg: "primary.darkMode.200",
//       //   color: "primary.darkMode.default",
//     },
//   },
};
