import { firebaseClient, getToken } from "../firebaseClient";
import { postData } from "../utils/helpers";
import * as analytics from "../utils/analytics";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/nextjs";
import { isAllowed } from "./subscription";
import { useCredit } from "controllers/subscription";
import { getAccount } from "utils/sessionHelper";

export const generate = async (session, tweet, count = 1, tempLevel = 0, mainContext = undefined) => {
  try {
    let url = `https://api.tweetbutler.com/variations`;

    let dataToSend: any = {
      text: tweet?.full_text,
      count: count,
      version: 3,
      tempLevel: tempLevel ?? 0,
      isAdmin: session?.user?.data?.isAdmin ?? false,
      description: getAccount(session)?.description,
    }

    // if (session?.user?.data?.isAdmin) {
    if (session?.user?.data?.creditsAuto > 0) {
      dataToSend.model = "j1-jumbo/tweethunter-jumbo-v0.";
      dataToSend.version = "1";
    }

    // if (session?.user?.data?.creditsAuto < 1) {
    if ((!session?.user?.data?.subscription?.isSubscribed || !isAllowed(session?.user, "ai_variation"))
      && session?.user?.data?.creditsAuto < 1
    ) {
      toast.error("not enough credit, please upgrade");
      return;
    }
    const token = await getToken(session, "generate");
    let response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        tokenuserid: session?.user?.uid ?? "",
      },
      body: JSON.stringify({
        ...dataToSend,
        useBestAi: await useCredit(session, mainContext, "creditsBestai", false),
        fineTunedModel: getAccount(session)?.fineTunedModel || "",
      }),
    });

    let data = await response.json();

    if (data.success === 0) {
      toast.error(data.error);
      analytics.log("hit_ai_variation_error", { ...session.user, tweet: analytics.formatTweet(tweet), output: data });
    }
    else if (data?.data?.variations?.length == 0) {
      toast.error("Generating the variation failed");
    }
    else {
      if (session?.user?.data?.creditsAuto > 0) {
        const db = firebaseClient.firestore();
        await db.collection("users").doc(session.user.uid).update({ creditsAuto: firebaseClient.firestore.FieldValue.increment(-1) });
        session.user.data.creditsAuto--;
      }
      analytics.log("hit_ai_variation_success", { ...session.user, tweet: analytics.formatTweet(tweet), output: data });
    }

    analytics.log("hit_ai_variation", { ...session.user, tweet: analytics.formatTweet(tweet) });

    return data;
  }
  catch (e) {
    console.log("Error in fetch generate: " + e.message);
    toast.error("An error occurred, please try again", { style: { background: "gray.600", color: "#222" } });
    Sentry.captureException(e);
  }
};
