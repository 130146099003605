import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import dynamic from "next/dynamic";
import {
  Button,
  Box,
  Text,
  IconButton,
  useColorModeValue,
  Flex,
  HStack,
  useDisclosure,
  Tooltip,
  Divider,
  useColorMode,
} from "@chakra-ui/react";
import { useSession } from "next-auth/client";
import toast from "react-hot-toast";
import { useWindowWidth } from "@react-hook/window-size";
import { isAllowed } from "controllers/subscription";
import { getToken, firebaseClient } from "firebaseClient";
import { TweetContext } from "../../context/tweetContext";
import { getAccount, updateUser } from "../../utils/sessionHelper";
import { getRandomInList } from "utils/tweetUtils";
import * as analytics from "../../utils/analytics";
import { ImperativeHandle, EditorRef } from "./imperative-handle";
import { SettingsButton } from "./settings-button";
import { ActionLibrary } from "./action-library";
import { GRADIENT_COLOR, Prompt, SavedAction } from "./utils";
import { useCredit } from "controllers/subscription";
import { MainContext } from "context/mainContext";
import { RefreshIcon } from "./icons/refresh-icon";
import { useChatContext } from "context/chatContext";
import { layerStyle, textStyle, variant } from "theme/names";
import { CloseIconSVG } from "components/icons/CloseIconSVG";
import { SendIconComponent } from "components/icons/SendIconComponent";
import { colors } from "theme/colors/colors";
import { FiSquare } from "react-icons/fi";
import { Messages } from "./messages";
import { Layout } from "./layout";
import { AiOutlineSave } from "react-icons/ai";
import { v4 as uuid } from "uuid";
import { History } from "./history";
import { CloseLibrary } from "./icons/close-library";
import { LibraryIcon2 } from "./icons/library-icon-2";
import { MdOutlineHistory } from "react-icons/md";
import { Conversation } from "./history";
import { useFirebaseUser } from "utils/useFirebaseUser";
import moment from "moment";
import { CloseIcon } from "@chakra-ui/icons";
import { get_encoding } from "@dqbd/tiktoken";

const Editor = dynamic(() => import("./editor"), { ssr: false });

const encoding = get_encoding("cl100k_base");

type Panels = "action library" | "history";

export const ChatAssist = forwardRef((_, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<any>([]);
  const [htmlPrompt, setHtmlPrompt] = useState<string>("");
  const [actionsLibrarySearchTerm, setActionsLibrarySearchTerm] = useState("");
  const [activeIndex, setActiveIndex] = useState<any>();
  const [finishedAudio, setfinishedAudio] = useState<HTMLAudioElement | null>(
    null
  );
  const [isSavingAction, setIsSavingAction] = useState<boolean>(false);
  const [savedActions, setSavedActions] = useState<SavedAction[]>([]);
  const [hasSetSavedAction, setHasSetSavedAction] = useState<boolean>(false);
  const [activePanel, setActivePanel] = useState<Panels>("action library");
  const [historySearchTerm, setHistorySearchTerm] = useState<string>("");
  const [currentConversation, setCurrentConversation] = useState<
    Conversation | undefined
  >(undefined);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [hasFetchedConversations, setHasFetchedConversations] =
    useState<boolean>(false);
  const [useMainSystemPrompt, setUseMainSystemPrompt] = useState<boolean>(true);
  const [session] = useSession();
  const dialogRef = useRef<any>(null);
  const editorRef = useRef<EditorRef | null>(null);
  const controllerRef = useRef<AbortController | null>();
  const tweetContext: any = useContext(TweetContext);
  const {
    isOpen: isSidebarOpen,
    onToggle: onToggleSidebar,
    onOpen: onOpenSidebar,
    onClose: onCloseSidebar,
  } = useDisclosure();
  const popupBg = useColorModeValue("white", "#1E1E1E");
  const isAuthenticated = useFirebaseUser();
  const mainContext: any = useContext(MainContext);
  const chatContext: any = useChatContext();
  const screenWidth = useWindowWidth();
  const isTooSmall = screenWidth < 800;

  useEffect(() => {
    scrollToBottom();
    handleCopyAndEditPost();
  }, [messages]);

  useEffect(() => {
    setfinishedAudio(new Audio("/assets/audio/finished_chat_response.mp3"));
  }, []);

  useEffect(() => {
    handleCopyAndEditPost();
  }, [tweetContext?.isOpenChatAssist, tweetContext?.refComposer]);

  const handleCopyAndEditPost = () => {
    // on click on chat-to-copy, copy the text to clipboard
    const elements = document.getElementsByClassName("formatted-chat");
    // console.log('elements:', elements)

    if (elements?.length) {
      for (var i = 0; i < elements?.length; i++) {
        // console.log('elements:', elements[i])
        // console.log('elements:', elements[i].childNodes)

        let elementText = elements[i].childNodes[0];
        // let elementButtonCopy = elements[i].childNodes[0];
        let elementButtonCopy = elements[i].childNodes[1].childNodes[0];
        let elementButtonEdit = elements[i].childNodes[1].childNodes[1];

        let elementButtonCopyClone = elementButtonCopy.cloneNode(true);
        elementButtonCopy?.parentNode?.replaceChild(
          elementButtonCopyClone,
          elementButtonCopy
        );
        elementButtonCopyClone.addEventListener("click", async (event) => {
          event.preventDefault();
          const linkText = elementText.textContent;
          if (linkText) {
            try {
              await navigator.clipboard.writeText(linkText);
              toast.success("text copied to clipboard!");
            } catch (err) {
              console.error("Failed to copy link: ", err);
            }
          }
        });

        let elementButtonEditClone = elementButtonEdit.cloneNode(true);
        elementButtonEdit?.parentNode?.replaceChild(
          elementButtonEditClone,
          elementButtonEdit
        );
        elementButtonEditClone.addEventListener("click", async (event) => {
          event.preventDefault();
          const linkText = elementText.textContent;
          if (linkText) {
            try {
              tweetContext.newTweet(
                { text: linkText },
                undefined,
                undefined,
                undefined,
                () => {
                  tweetContext.setIsTweetTextChanged(true);
                }
              );
              tweetContext.open();
              tweetContext.refComposer.current?.focus();
            } catch (err) {
              console.error("Failed to copy link: ", err);
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    const composerElements = document.getElementsByClassName("with-tooltip");
    for (let i = 0; i < composerElements?.length; i++) {
      const element = composerElements.item(i);

      element?.addEventListener("mouseover", (e) => {
        // @ts-ignore
        const text = e?.target?.dataset?.tooltip;
        const tooltipElement = document.getElementById("chat-assist-tooltip");
        if (tooltipElement && text) {
          tooltipElement.innerHTML = text;
          tooltipElement.style.visibility = "visible";
          // @ts-ignore
          tooltipElement.style.top = e.y + "px";
          // @ts-ignore
          tooltipElement.style.left = e.x + "px";
        }
      });

      element?.addEventListener("mouseout", () => {
        const tooltipElement = document.getElementById("chat-assist-tooltip");
        if (tooltipElement) {
          tooltipElement.innerHTML = "";
          tooltipElement.style.visibility = "hidden";
        }
      });
    }
  }, [messages, chatContext?.isOpen]);

  // silent prompt is used here to pass system prompt which comes directly from tweet-composer AI options
  const sendMessage = async function (
    forceText = "",
    systemPrompt?: string,
    forceMessages?: any[],
    forcedComposerContent?: string
  ) {
    try {
      if (!getAccount(session)?.subscription?.isSubscribed) {
        mainContext?.onOpenUpgrade();
        return;
      }

      if (!session?.user?.uid || !isAllowed(session?.user, "chat")) {
        toast.error("You are not allowed to do that");
        return;
      }

      if (isLoading || chatContext?.isLoading) {
        toast.error("You can ask one thing at a time");
        return;
      }

      const composerContentTag = `<highlight-input class="remirror-mention-atom remirror-mention-atom-highlight-input" data-mention-atom-id="[composer content]" data-mention-atom-name="highlight-input">[composer content]</highlight-input>`;
      const tagsToIgnore = [composerContentTag];
      let htmlPromptAfterIgnoring = htmlPrompt;
      tagsToIgnore.forEach((tag) => {
        htmlPromptAfterIgnoring = htmlPromptAfterIgnoring.replaceAll(tag, "");
      });

      if (
        !forceText &&
        htmlPromptAfterIgnoring.includes("<highlight-input class")
      ) {
        toast.error("Please update your prompt");
        return;
      }

      let textToSend = forceText || editorRef.current?.getTextCustom();
      textToSend = textToSend?.trim();

      if (!textToSend) {
        toast.error("Please input something");
        return;
      }

      let content = textToSend;
      let contentFormatted, composerContent;
      let editorText = htmlPrompt;

      if (textToSend.includes("[composer content]")) {
        content = textToSend.replaceAll(
          "[composer content]",
          forcedComposerContent
            ? `"${forcedComposerContent}"`
            : `"${tweetContext?.refComposer?.current?.textState()?.text}"`
        );
        composerContent =
          forcedComposerContent ||
          tweetContext?.refComposer?.current?.textState()?.text;
        if (forceText) {
          editorText = textToSend.replaceAll(
            "[composer content]",
            composerContentTag
          );
        }

        const tooltipText =
          forcedComposerContent ||
          tweetContext?.refComposer?.current?.textState()?.text;
        const newText = `<div class="with-tooltip" data-tooltip="${tooltipText}">[composer content]</div>`;
        contentFormatted =
          textToSend.replaceAll("[composer content]", newText) || content;
      }

      let formattedMessage = {
        role: "user",
        content,
        contentFormatted,
        editorText,
        composerContent,
      };
      const updatedMessages = forceMessages ?? [...messages];

      if (updatedMessages.length === 0) {
        updatedMessages.push({
          role: "system",
          content: `User self description: "${getAccount(session)?.description}"\nUser main topics: "${getAccount(session)?.keywords.join(", ")}"\nUser Twitter handle: "@${getAccount(session)?.twUserName}"`,
        });
      }

      if (systemPrompt) {
        updatedMessages.push({ role: "system", content: systemPrompt });
        setUseMainSystemPrompt(false);
      }

      let formattedMessages = updatedMessages?.slice();

      formattedMessages.push(formattedMessage);

      const MAX_RESPONSE_TOKEN_COUNT = 500; // decided on the backend here - https://github.com/tbll75/ez4cast/blob/master/functions/src/Workflows/TweetChat.js#L169
      const BACKEND_SYSTEM_PROMPT_TOKEN_COUNT = 1200; // assumed token length system prompt from here - https://github.com/tbll75/ez4cast/blob/master/functions/src/Workflows/TweetChat.js#L125
      let tokenCount = 0;
      let messagesToSend: any[] = [];

      for (let i = formattedMessages.length - 1; i >= 0; i--) {
        const message = formattedMessages[i];
        const token = encoding.encode(message.content);

        if (tokenCount + token.length + MAX_RESPONSE_TOKEN_COUNT + BACKEND_SYSTEM_PROMPT_TOKEN_COUNT > 4096) { // 4096 is max token limit for openai model.
          break;
        }

        tokenCount += token.length;
        messagesToSend = [message, ...messagesToSend];
      }

      // remove property contentFormatted of all elements
      messagesToSend = messagesToSend.map((message: any) => {
        const updatedMsg = { ...message };
        delete updatedMsg.contentFormatted;
        delete updatedMsg.editorText;
        delete updatedMsg.composerContent;
        return updatedMsg;
      });

      updatedMessages.push(formattedMessage);
      setMessages([...updatedMessages]);
      setIsLoading(true);
      chatContext?.setIsLoading(true);

      const db = firebaseClient.firestore();
      let allConversations = conversations;
      let conversation = currentConversation;
      if (!conversation) {
        conversation = {
          id: uuid(),
          name: updatedMessages[0]?.content?.split(" ")?.[0],
          messages: updatedMessages,
          createdAt: new Date(),
          updatedAt: new Date(),
        };

        await db
          .collection("users")
          .doc(getAccount(session)?.id)
          .collection("chatAssistConversations")
          .doc(conversation.id)
          .set(conversation);
        setCurrentConversation(conversation);
        allConversations.push(conversation);
        setConversations([...allConversations]);
      } else {
        conversation.messages = updatedMessages;
        conversation.updatedAt = new Date();
        await db
          .collection("users")
          .doc(getAccount(session)?.id)
          .collection("chatAssistConversations")
          .doc(conversation.id)
          .update(conversation);

        setCurrentConversation(conversation);
        const index = allConversations.findIndex(
          (c) => c.id === conversation?.id
        );
        allConversations[index] = conversation;
        setConversations([...allConversations]);
      }

      editorRef.current?.setContent("");
      setHtmlPrompt("");
      scrollToBottom();

      analytics.log("generate_chat_assist_message", {
        message: textToSend,
        messageLength: textToSend?.length,
        nbChatAlreadySent: updatedMessages?.length,
      });

      // request should never get here but incase it does, the old request will be cancelled
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      const controller = new AbortController();
      controllerRef.current = controller;
      const token = await getToken(session, "approveIteration");
      const response = await fetch(
        // "https://us-central1-ez4cast.cloudfunctions.net/tweetChat-chatV2",
        "https://tweetchat-chatv3-yiipa2itwq-uc.a.run.app",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            tokenuserid: session?.user?.uid,
          },
          body: JSON.stringify({
            messages: messagesToSend,
            idUser: getAccount(session)?.id,
            description: getAccount(session)?.description,
            useBestAi: await useCredit(session, mainContext, "creditsBestai", false),
            fineTunedModel: getAccount(session)?.fineTunedModel || "",
            ...(systemPrompt || !useMainSystemPrompt) && { useMainSystemPrompt: false },
            language: getAccount(session)?.languagePreferred || "English"
          }),
          signal: controllerRef.current?.signal,
        }
      );

      if (response.status !== 200) {
        const data = await response.json()
        throw new Error(data?.error ?? `server responded with status ${response.status}`)
      }

      // no of tokens used is not implemented in backend
      // do it there itself if you need it cause doing it here is a bit buggy when I tested it
      const reader = response.body?.getReader();
      let fullMessage = "";
      setIsLoading(false);
      for await (const chunk of readChunks(reader)) {
        const value = Buffer.from(chunk).toString("utf-8");
        fullMessage = fullMessage + value;
        let lastMessage = updatedMessages[updatedMessages?.length - 1];
        // response of the api is always an AI response so this works but in case in future it changes.
        // This logic will need to be updated
        if (lastMessage?.role !== "assistant") {
          lastMessage = {
            role: "assistant",
            content: value,
            contentFormatted: formatOutput(value),
          };
          updatedMessages.push(lastMessage);
        } else {
          lastMessage.content = fullMessage;
          lastMessage.contentFormatted = formatOutput(fullMessage);
        }
        setMessages([...updatedMessages]);
      }

      toast.success("Chat Assist response generated!");
      finishedAudio?.play();
      chatContext?.setIsLoading(false);
      controllerRef.current = null;

      conversation.messages = updatedMessages;
      conversation.updatedAt = new Date();

      db.collection("users")
        .doc(getAccount(session)?.id)
        .collection("chatAssistConversations")
        .doc(conversation.id)
        .update(conversation);

      setCurrentConversation(conversation);
      const index = allConversations.findIndex(
        (c) => c.id === conversation?.id
      );
      allConversations[index] = conversation;
      setConversations([...allConversations]);
    } catch (e) {
      console.error("Send message failed: ", e);
      if (!e.message?.includes("aborted")) {
        toast.error("Error in sending message: " + e.message);
      }
      setIsLoading(false);
      chatContext.setIsLoading(false);
    }
  };

  function readChunks(reader) {
    return {
      async *[Symbol.asyncIterator]() {
        let readResult = await reader.read();
        while (!readResult.done) {
          yield readResult.value;
          readResult = await reader.read();
        }
      },
    };
  }

  const onSelectPrompt = function (prompt: Prompt) {
    // if (
    //   prompt?.requiresTweet &&
    //   tweetContext?.refComposer?.current.textState()?.text?.length < 2
    // ) {
    //   tweetContext.open();
    //   toast.error("Please write a tweet first");
    //   return;
    // }

    let p = prompt.prompt;
    p = p.replace(
      "{{keyword}}",
      `"${getRandomInList(getAccount(session)?.keywords, 1 ?? "marketing")}"`
    );

    // if (p.includes("highlight-input")) {
    const currentPostAsArray = tweetContext?.refComposer?.current
      .textState()
      ?.text?.split("\n");
    const currentPostAsHtml = currentPostAsArray
      .map(
        (p, i, arr) =>
          `<p>${i === 0 ? `"` : ""}${p}${i === arr?.length - 1 ? `"` : ""}</p>`
      )
      .join("");

    p = p.replace("{{current_post}}", currentPostAsHtml);
    editorRef.current?.setContent(p);
    setHtmlPrompt(editorRef.current?.getHTML() || "");
    // } else {
    //   sendMessage(p.replace("{{current_post}}", `"${tweetContext?.refComposer?.current.textState()?.text}"`));
    // }
  };

  const { colorMode } = useColorMode();

  const formatOutput = function (text) {
    // console.log('text:', text)
    // get texts between quotes "
    let regexForList = /"(.*?)"/g;
    let matches = text.match(regexForList);

    if (!matches || matches?.length === 0) {
      let regexForWholeContent = /"((.|\n|\r)*)"/g;
      matches = text.match(regexForWholeContent);
    }

    if (matches?.length > 0) {
      matches.forEach((match) => {
        // console.log('match:', match, getAccount(session)?.keywords);
        if (getAccount(session)?.keywords?.includes(match.replace(/"/g, "")))
          // avoid unwanted highlights
          return;

        // remove first and last quotes
        if (match[0] === '"') match = match.substring(1);
        if (match[match?.length - 1] === '"')
          match = match.substring(0, match?.length - 1);

        // make sure highlighted texts always end with "\n"
        // let isEndWithBreakLine = (match[match?.length-1] === "\n" || match[match?.length-2] === "\n");
        const editIcon = `<svg width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.8125 11.125L12.3125 1.625C13.0938 0.84375 14.375 0.84375 15.1562 1.625L16.375 2.84375C16.4688 2.9375 16.5625 3.0625 16.625 3.15625C17.1562 3.9375 17.0625 5 16.375 5.6875L6.875 15.1875C6.84375 15.2188 6.78125 15.25 6.75 15.3125C6.4375 15.5625 6.09375 15.75 5.71875 15.875L1.9375 16.9688C1.6875 17.0625 1.40625 17 1.21875 16.7812C1 16.5938 0.9375 16.3125 1 16.0625L2.125 12.2812C2.25 11.8438 2.5 11.4375 2.8125 11.125ZM3.5625 12.7188L2.84375 15.1562L5.28125 14.4375C5.46875 14.375 5.65625 14.2812 5.8125 14.125L12.9688 6.96875L11 5.03125L3.875 12.1875C3.84375 12.1875 3.84375 12.2188 3.8125 12.25C3.6875 12.375 3.625 12.5312 3.5625 12.7188Z" fill="currentColor"/>
        </svg>`;
        const copyIconFontAwesome = `<svg width="16" height="16" viewBox="0 -2 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6875 2.21875C16.875 2.40625 17 2.65625 17 2.9375V10C17 11.125 16.0938 12 15 12H9C7.875 12 7 11.125 6.96875 10V2C6.96875 0.90625 7.84375 0 8.96875 0H14.0625C14.3438 0 14.5938 0.125 14.7812 0.3125L16.6875 2.21875ZM15.5 10H15.4688V4H14C13.4375 4 13 3.5625 13 3L12.9688 1.53125H8.96875C8.6875 1.53125 8.46875 1.75 8.46875 2.03125V10C8.46875 10.2812 8.6875 10.5 8.96875 10.5H15C15.25 10.5 15.5 10.2812 15.5 10ZM9.5 14V13H11V14C11 15.125 10.0938 16 9 16H3C1.875 16 1 15.125 1 14L0.96875 6C0.96875 4.90625 1.875 4 2.96875 4H6V5.53125H2.96875C2.71875 5.53125 2.46875 5.75 2.46875 6.03125V14C2.46875 14.2812 2.6875 14.5 2.96875 14.5H9C9.25 14.5 9.5 14.2812 9.5 14Z" fill="currentColor"/>
        </svg>`;
        let formattedText = `<span class="formatted-chat" id="formatted-chat" ><span class="${colorMode === "light"
          ? "formatted-chat-text"
          : "formatted-chat-text-dark"
          }">${match}</span><span class="formatted-chat-buttons"><button class="formatted-chat-copy">${copyIconFontAwesome}copy</button><button class="formatted-chat-edit">${editIcon}edit & tweet</button></span></span>`;
        // let formattedText = `<span class="formatted-chat" id="formatted-chat" ><span class="formatted-chat-text">${match}</span><span class="formatted-chat-buttons"><button class="formatted-chat-copy">${copyIcon}copy</button><button class="formatted-chat-edit">${editIconLight}edit & tweet</button></span></span>`;

        // if (!isEndWithBreakLine)
        //     formattedText += "\n";

        text = text.replace('"' + match + '"', formattedText);
      });
    }

    return text;
  };

  const scrollToBottom = async function () {
    await new Promise((resolve) => setTimeout(resolve, 200));
    dialogRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSaveAction = async (text: string) => {
    try {
      setIsSavingAction(true);
      const action = {
        id: uuid(),
        createdAt: new Date(),
        title: text.replace(/<[^>]*>?/gm, ""),
        prompt: text,
      };

      const newSavedActions = [...savedActions];
      newSavedActions.push(action);

      await toast.promise(
        updateUser(session, { savedActions: newSavedActions }),
        {
          loading: "Saving...",
          success: () => {
            setSavedActions(newSavedActions);
            return "Action saved!";
          },
          error: () => {
            return "Failed to save action";
          },
        }
      );
    } catch (e) {
      console.error("Error in saving action: ", e);
      toast.error("Error in saving action: " + e.message);
    } finally {
      setIsSavingAction(false);
    }
  };

  useEffect(() => {
    if (session && !hasSetSavedAction) {
      setHasSetSavedAction(true);
      let actions = getAccount(session)?.savedActions ?? [];
      actions.forEach((a) => {
        a.createdAt = new Date(a.createdAt?._seconds * 1000);
      });
      setSavedActions(actions);
    }
  }, [session]);

  // fetch conversations
  // delete them if they are more than 60 days old
  useEffect(() => {
    const getConversations = async () => {
      const db = firebaseClient.firestore();
      const docs = await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("chatAssistConversations")
        .get();

      let conversationsFromLast60Days: Conversation[] = [];
      let conversationsOlderThan60Days: Conversation[] = [];
      docs.docs.forEach((doc) => {
        let data = doc.data() as Conversation;
        // @ts-ignore
        data.updatedAt = data.updatedAt.toDate();
        // @ts-ignore
        data.createdAt = data.createdAt.toDate();
        const date60DaysOld = moment().subtract(60, "days").toDate();
        if (data.updatedAt < date60DaysOld) {
          conversationsOlderThan60Days.push(data);
        } else {
          conversationsFromLast60Days.push(data);
        }
      });

      setConversations(conversationsFromLast60Days);
      // delete converations older than 60 days
      if (conversationsOlderThan60Days.length > 0) {
        conversationsOlderThan60Days.forEach((c) => {
          db.collection("users")
            .doc(getAccount(session)?.id)
            .collection("chatAssistConversations")
            .doc(c.id)
            .delete();
        });
      }
    };

    if (session && isAuthenticated && !hasFetchedConversations) {
      setHasFetchedConversations(true);
      getConversations();
    }
  }, [session, isAuthenticated]);

  useImperativeHandle(ref, () => ({
    sendMessage,
    setMessages,
    messages,
    controllerRef,
    handleSaveAction,
  }));

  const toggleSidebar = (panel: Panels) => {
    setActivePanel(panel);
    if (panel === activePanel) {
      onToggleSidebar();
    } else {
      onOpenSidebar();
    }
  };

  if (!chatContext?.isOpen) {
    return <></>;
  }

  return (
    <Layout
      isSidebarOpen={isSidebarOpen}
      sidebar={
        <Flex direction="column" maxH="100%" h="100%">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            p="4"
            pr="0"
            pl="3"
          >
            <Flex alignItems="center">
              <Button
                size="sm"
                variant="unstyled"
                display="flex"
                alignItems="center"
                borderBottom="2px solid transparent"
                borderRadius="none"
                borderColor={
                  activePanel === "action library" ? "#566F8F" : "transparent"
                }
                onClick={() => setActivePanel("action library")}
              >
                <LibraryIcon2 />
                <Text
                  bgGradient={GRADIENT_COLOR}
                  bgClip="text"
                  ml="1"
                  fontWeight="semibold"
                >
                  Actions Library
                </Text>
              </Button>
              <Button
                size="sm"
                variant="unstyled"
                display="flex"
                alignItems="center"
                ml="2"
                borderBottom="2px solid transparent"
                borderRadius="none"
                borderColor={
                  activePanel === "history" ? "#566F8F" : "transparent"
                }
                onClick={() => setActivePanel("history")}
              >
                <MdOutlineHistory color="#0A65C1" />
                <Text
                  bgGradient={GRADIENT_COLOR}
                  bgClip="text"
                  ml="1"
                  fontWeight="semibold"
                >
                  History
                </Text>
              </Button>
            </Flex>
            <IconButton
              aria-label="Close sidebar"
              variant="actionAI"
              size="xs"
              onClick={() => {
                onCloseSidebar();
                setActiveIndex(undefined);
              }}
              icon={isTooSmall ? <CloseIcon /> : <CloseLibrary />}
              mr={isTooSmall ? "2" : "0"}
            />
          </Flex>
          <Divider />
          {activePanel === "action library" ? (
            <ActionLibrary
              onSelect={onSelectPrompt}
              searchTerm={actionsLibrarySearchTerm}
              setSearchTerm={setActionsLibrarySearchTerm}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              savedActions={savedActions}
              onUpdateSavedActions={(actions) => setSavedActions([...actions])}
              editorInstance={Editor}
              imperativeHandleInstance={ImperativeHandle}
            />
          ) : null}
          {activePanel === "history" ? (
            <History
              searchTerm={historySearchTerm}
              setSearchTerm={setHistorySearchTerm}
              conversations={conversations}
              onSelect={(conversation) => {
                setCurrentConversation(conversation);
                setMessages(conversation.messages);
              }}
              onUpdate={(conversations, options) => {
                setConversations(conversations);
                if (
                  options?.type === "delete" &&
                  currentConversation?.id === options?.id
                ) {
                  setCurrentConversation(undefined);
                }
              }}
            />
          ) : null}
        </Flex>
      }
      chatWindowControls={
        <>
          {messages?.length > 0 ? (
            <Tooltip label="Resetting will start a new conversation">
              <IconButton
                aria-label="reset chat"
                variant="secondaryAI"
                icon={<RefreshIcon />}
                color="#D792FF !important"
                size="sm"
                mr="2"
                onClick={(e) => {
                  setMessages([]);
                  controllerRef.current?.abort();
                  setIsLoading(false);
                  setCurrentConversation(undefined);
                  setUseMainSystemPrompt(true);
                }}
              />
            </Tooltip>
          ) : null}
          <SettingsButton />
          <Tooltip label="Close" placement="top">
            <IconButton
              aria-label="Close chat assist"
              icon={CloseIconSVG}
              variant="secondary"
              ml="2"
              size="sm"
              onClick={() => {
                chatContext?.onClose();
              }}
            />
          </Tooltip>
        </>
      }
      chatWindow={
        <Messages
          messages={messages}
          isLoadingMessage={isLoading}
          onSelectHomeScreenPrompt={onSelectPrompt}
          bottomMostBlock={<Box ref={dialogRef} mb="10" />}
          editorInstance={Editor}
          imperativeHandleInstance={ImperativeHandle}
        />
      }
      editor={
        <>
          {chatContext?.isLoading ? (
            <Button
              size="sm"
              fontWeight="normal"
              position="absolute"
              top="-12"
              transform="translateX(-50%)"
              left="50%"
              borderRadius="2xl"
              w="fit-content"
              color={colors.ai[500]}
              bg={popupBg}
              leftIcon={<FiSquare />}
              onClick={() => {
                controllerRef.current?.abort();
                chatContext.setIsLoading(false);
              }}
            >
              Stop Generating
            </Button>
          ) : null}
          {messages?.length > 0 &&
            messages.filter((m) => m.role !== "user")?.length > 0 ? (
            <HStack
              spacing="2"
              alignItems="center"
              pl="6"
              pb="2"
              pt="2"
              flexWrap="wrap"
            >
              <Text textStyle={textStyle["body.medium.light"]} fontSize="sm">
                Make it...
              </Text>
              {quickFeedbackButtons.map((btn) => (
                <Button
                  key={btn.title}
                  variant="secondaryAI"
                  bgGradient={GRADIENT_COLOR}
                  bgClip="text"
                  size="sm"
                  fontSize="xs"
                  onClick={() => sendMessage(btn.textToSend)}
                >
                  {btn.title}
                </Button>
              ))}
              <IconButton
                aria-label="Open action library"
                variant="actionAI"
                size="sm"
                icon={roundOpenLibraryIcon}
                onClick={() => {
                  onOpenSidebar();
                  setActionsLibrarySearchTerm("");
                  setActiveIndex(2);
                  setActivePanel("action library");
                }}
              />
            </HStack>
          ) : null}
          <Box
            layerStyle={layerStyle["bg.border.rounded"]}
            bg={popupBg}
            borderRadius={"18px"}
            p={3}
          >
            <Editor
              initialValue={htmlPrompt}
              onChange={(parameter) => {
                setHtmlPrompt(parameter.helpers.getHTML(parameter.state));
              }}
            >
              <ImperativeHandle
                ref={editorRef}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
                    e.preventDefault();
                    sendMessage();
                    return true;
                  }

                  return false;
                }}
              />
            </Editor>

            <Flex
              justifyContent="space-between"
              mt="1"
              borderTop="1px solid"
              borderColor={colors.border.lightMode.light}
              _dark={{
                borderColor: colors.border.darkMode.light,
              }}
              pt="3"
            >
              <Box>
                <Button
                  variant={variant.Button.secondaryAI}
                  borderColor="transparent"
                  px="2"
                  _hover={{
                    borderColor: "border.lightMode.hover",
                  }}
                  bgGradient={GRADIENT_COLOR}
                  bgClip="text"
                  onClick={() => toggleSidebar("action library")}
                  leftIcon={openLibraryIcon}
                >
                  Actions Library
                </Button>
                <Button
                  variant={variant.Button.secondaryAI}
                  borderColor="transparent"
                  px="2"
                  _hover={{
                    borderColor: "border.lightMode.hover",
                  }}
                  bgGradient={GRADIENT_COLOR}
                  bgClip="text"
                  onClick={() => toggleSidebar("history")}
                  leftIcon={<MdOutlineHistory color="#0A65C1" />}
                >
                  History
                </Button>
              </Box>
              <Box>
                {!!editorRef.current?.getTextCustom() ? (
                  <Button
                    variant={variant.Button.secondaryAI}
                    bgGradient={GRADIENT_COLOR}
                    bgClip="text"
                    borderColor="transparent"
                    _hover={{
                      borderColor: "border.lightMode.hover",
                    }}
                    mr="2"
                    leftIcon={<AiOutlineSave color="#0A65C1" />}
                    isLoading={isSavingAction}
                    onClick={() => handleSaveAction(htmlPrompt)}
                  >
                    Save
                  </Button>
                ) : null}
                <IconButton
                  aria-label="send message"
                  variant="primaryAI"
                  icon={
                    <Box {...{ marginLeft: -0.5 }}>
                      <SendIconComponent {...{ width: 18, height: 18 }} />
                    </Box>
                  }
                  isLoading={isLoading}
                  onClick={() => sendMessage()}
                />
              </Box>
            </Flex>
          </Box>
        </>
      }
    />
  );
});

const quickFeedbackButtons = [
  { title: "Shorter", textToSend: "Make it shorter" },
  { title: "Longer", textToSend: "Make it longer" },
  { title: "bolder", textToSend: "Make it bolder" },
  { title: "More casual", textToSend: "Make it more casual" },
  { title: "More formal", textToSend: "Make it formal" },
];

const roundOpenLibraryIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25 10.75V8.75H5.25C4.8125 8.75 4.5 8.4375 4.5 8C4.5 7.59375 4.8125 7.25 5.25 7.25H7.25V5.25C7.25 4.84375 7.5625 4.5 8 4.5C8.40625 4.5 8.75 4.84375 8.75 5.25V7.25H10.75C11.1562 7.25 11.5 7.59375 11.5 8C11.5 8.4375 11.1562 8.75 10.75 8.75H8.75V10.75C8.75 11.1875 8.40625 11.5 8 11.5C7.5625 11.5 7.25 11.1875 7.25 10.75ZM16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8 1.5C4.40625 1.5 1.5 4.4375 1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5Z"
      fill="url(#g)"
    />
    <linearGradient
      id="g"
      x1="-5"
      y1="-5"
      x2="25"
      y2="25"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#EC6181" />
      <stop offset="1" stopColor="#5C69E3" />
    </linearGradient>
  </svg>
);

const openLibraryIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9375 13.625C16.1562 14.4062 15.6875 15.2188 14.875 15.4375L12.9375 15.9688C12.8125 16 12.6875 16 12.5625 16C11.875 16 11.2812 15.5625 11.0938 14.9062L8.5 5.1875V14.5C8.5 15.3438 7.8125 16 7 16H5C4.71875 16 4.46875 15.9375 4.25 15.8125C4 15.9375 3.75 16 3.5 16H1.5C0.65625 16 0 15.3438 0 14.5V1.5C0 0.6875 0.65625 0 1.5 0H3.5C3.75 0 4 0.09375 4.25 0.21875C4.46875 0.09375 4.71875 0 5 0H7C7.5625 0 8.0625 0.34375 8.3125 0.8125C8.4375 0.71875 8.625 0.625 8.8125 0.59375L10.7188 0.0625C10.875 0.03125 11 0 11.125 0C11.7812 0 12.375 0.46875 12.5625 1.125L15.9375 13.625ZM7 1.5H5V3H7V1.5ZM5 4.5V11.5H7V4.5H5ZM3.5 11.5V4.5H1.5V11.5H3.5ZM3.5 1.5H1.5V3H3.5V1.5ZM1.5 14.5H3.5V13H1.5V14.5ZM5 14.5H7V13L5 13.0312V14.5ZM9.1875 2.03125L9.5625 3.46875L11.5 2.9375L11.125 1.53125L9.1875 2.03125ZM9.96875 4.90625L11.7812 11.625L13.7188 11.125L11.9062 4.40625L9.96875 4.90625ZM12.5625 14.5312L14.5 14L14.0938 12.5625L12.1562 13.0938L12.5625 14.5312Z"
      fill="url(#paint0_linear_486_30534)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_486_30534"
        x1="0.4"
        y1="-0.2"
        x2="16.6"
        y2="16.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EC6181" />
        <stop offset="1" stopColor="#316BFF" />
      </linearGradient>
    </defs>
  </svg>
);
