export function ActionCaretRight() {
  return (
    <svg
      width="6"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6875 3.3125L7.6875 7.28125C7.875 7.5 8 7.75 8 8C8 8.21875 7.875 8.5 7.6875 8.6875L3.6875 12.6562C3.40625 12.9375 2.96875 13.0312 2.59375 12.875C2.21875 12.7188 2 12.4062 2 12V4C2 3.625 2.21875 3.25 2.59375 3.09375C2.96875 2.9375 3.40625 3.03125 3.6875 3.3125Z"
        fill="currentColor"
      />
    </svg>
  );
}
