import { radius } from "theme/foundations/radius";
import { secondary } from "./secondary";
import { defineStyleConfig } from "@chakra-ui/react";
import { text } from "theme/foundations/text";

export const light = (props) => {
  
  return {
    ...text.body.medium.light.light,
    color: "text.lightMode.light",
    border: "1px solid",
    borderColor: "border.lightMode.light",
    bg:   "white",
    _dark: {
      bg:  "#1E1E1E",
      borderColor: "border.darkMode.light",
    },
    _hover: {
      color: "text.lightMode.standard",
      bg: "primary.lightMode.100",
      _dark: {
        color: "text.darkMode.standard",
        bg: "primary.darkMode.100",
      },
    },
    _active: {
      color: "white",
      bg: "primary.lightMode.200",
      _dark: {
        color: "white",
        bg: "primary.darkMode.200",
      },
    },
  };
};
export const lightSelected = (props) => {
  
  return {
    ...text.body.medium.light.light,
    color: "text.lightMode.light",
    border: "1px solid",
    borderColor: "border.lightMode.light",
    bg:  "primary.lightMode.100",
    _dark: {
      bg:  "primary.darkMode.100",
      borderColor: "border.darkMode.light",
    },
    _hover: {
      color: "text.lightMode.standard",
      bg: "primary.lightMode.100",
      _dark: {
        color: "text.darkMode.standard",
        bg: "primary.darkMode.100",
      },
    },
    _active: {
      color: "white",
      bg: "primary.lightMode.200",
      _dark: {
        color: "white",
        bg: "primary.darkMode.200",
      },
    },
  };
};
