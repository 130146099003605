import { Button, IconButton, useColorMode, Icon, Spinner, Box } from "@chakra-ui/react"
import { ReactElement, ReactFragment, ReactNode } from "react";
import { AiIcon2, AiSpinner } from "./AiIcon2"
import { AnimatedBg3 } from "./AnimatedBg3"

export const AiButton = (
  {
    icon,
    containerProps,
    iconProps,
    children,
    isLoading,
    ...props
  }
    : {
      icon?: ReactElement | string,
      children?: any,
      containerProps?: any,
      iconProps?: any,
      isLoading?: boolean,
      [x: string]: any;
    }
) => {
  const { colorMode } = useColorMode();
  return (
    <AnimatedBg3
      {...{
        h: "40px",
        ...containerProps
      }}
    >
      <Button
        {...{
          background: "transparent",
          fontSize: "sm",
          bgGradient: "linear(to-b, #EC6181 10%, #316BFF 100%)",
          bgClip: "text",
          transition: '1s',
          _hover: {
            bgGradient: "linear(to-b, #EC6181 30%, #316BFF 90%)",
            filter: "brightness(1.1)",
          },
          _active: {
            bgGradient: "linear(to-b, #EC6181 40%, #316BFF 80%)",
            filter: "brightness(1.1)",
          },
          _dark: {
            bgGradient: "linear(to-b, #FE7A9A 30%, #537FF0 80%)",
          },
          leftIcon: (() => {
            if (isLoading) return <AiSpinner />
            if (typeof icon === "string") return <>{icon}</>
            if (icon) return <Icon viewBox="0 0 13 16"
              {...{
                width: "16px",
                height: "16px",
              }}
            >
              {icon}
            </Icon>
            return <AiIcon2 {...{ glow: false, dark: colorMode === "dark", ...iconProps }} />
          })(),
          p: "4",
          filter: colorMode === "dark"
            ? "drop-shadow(0px 0px 1px #000) drop-shadow(0px 0px 4px #000a)"
            : "drop-shadow(0px 0px 1px #fff) drop-shadow(0px 0px 4px #fff) drop-shadow(0px 0px 6px #fff)",
          ...props
        }}
      >
        <>
          {children}
        </>
      </Button>
    </AnimatedBg3>
  )
}

export const AiIconButton = ({
  icon,
  containerProps,
  iconProps,
  isLoading,
  ...props }
  : {
    icon?: ReactElement | string,
    containerProps?: any,
    iconProps?: any,
    isLoading?: boolean,
    [x: string]: any;
  }) => {
  const { colorMode } = useColorMode();
  return (
    <AnimatedBg3
      {...{
        w: "40px",
        h: "40px",
        square: true,
        ...containerProps
      }}
    >
      <IconButton
        aria-label="label"
        {...{
          background: "transparent",
          fontSize: "sm",
          p: "4",
          bgGradient: "linear(to-b, #EC6181 20%, #316BFF 100%)",
          bgClip: "text",
          transition: '1s',
          _hover: {
            bgGradient: "linear(to-b, #EC6181 30%, #316BFF 90%)",
            filter: "brightness(1.1)",
          },
          _active: {
            bgGradient: "linear(to-b, #EC6181 40%, #316BFF 80%)",
            filter: "brightness(1.1)",
          },
          filter: colorMode === "dark" ? "drop-shadow(0px 0px 1px #000) drop-shadow(0px 0px 4px #000a)" : "drop-shadow(0px 0px 1px #fff) drop-shadow(0px 0px 4px #fff) drop-shadow(0px 0px 6px #fff)",
          icon: (() => {
            if (isLoading) return <AiSpinner />
            if (typeof icon === "string") return <>{icon}</>
            if (icon) return <Icon viewBox="0 0 13 16"
              {...{
                width: "16px",
                height: "16px",
              }}
            >
              {icon}
            </Icon>
            return <AiIcon2 {...{ glow: false, dark: colorMode === "dark", ...iconProps }} />
          })(),
          ...props
        }}
      />
    </AnimatedBg3>
  )
}

export const IconButtonAi = AiIconButton
export const ButtonAi = AiButton

