import {
    Box,
    Center,
    Stack,
    Text,
    Link,
} from "@chakra-ui/layout";

import {
    Switch,
    useColorModeValue as mode,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    FormControl,
    Input,
    ModalCloseButton,
    FormLabel,
    ModalFooter,
    Img,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Flex,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Checkbox,
} from "@chakra-ui/react";
import theme from "@chakra-ui/theme";
import SearchBar from "components/search-bar";
import Tweets from "components/tweets";
import { CustomHead } from "components/custom-head";
import React, { useEffect, memo } from "react";
import { useSession } from "next-auth/client";
import { firebaseClient } from "../../firebaseClient";
import { getAccount } from "../../utils/sessionHelper";
import { useRouter } from "next/dist/client/router";
import { useFirebaseUser } from "../../utils/useFirebaseUser";
import { QuestionIcon } from '@chakra-ui/icons';
import { Tooltip } from "@chakra-ui/tooltip";
import { isAllowed, getPlan } from "../../controllers/subscription";
import { generate } from "../../controllers/variations";
import toast from "react-hot-toast";
import { useTweetContext } from "context/tweetContext"

export const AiVariationPopup = ({ isOpen, onClose, selectedTweet, currentTempLevel, newTweet, onOpenScheduler }) => {

    const initialRef = React.useRef<HTMLInputElement>(null);
    const [session, loading] = useSession();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingVariation, setIsLoadingVariation] = React.useState(false);
    const [tempLevel, setTempLevel] = React.useState(3);
    const focusRefVariationAuto = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
    const router = useRouter();
    const tweetContext: any = useTweetContext();

    React.useEffect(() => {
        setTempLevel(currentTempLevel ?? 0);
        // console.log("change in currentTempLevel: " + currentTempLevel);
    }, [currentTempLevel]);

    // React.useEffect(() => {
    //     if (isAuthenticated && !hasInit) {
    //         setTempLevel(session?.user?.data?.tempLevel ? session.user.data.tempLevel : 0);
    //         setHasInit(true);
    //     }
    // }, [session, isAuthenticated]);


    return (
        <AlertDialog
            isOpen={isOpen}
            //@ts-ignore
            leastDestructiveRef={focusRefVariationAuto}
            onClose={onClose}
        >
            <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Variations Generation
                </AlertDialogHeader>

                <AlertDialogBody>
                    {
                        //@ts-ignore
                        (session?.user?.data?.creditsAuto && session?.user?.data?.creditsAuto > 0) || isAllowed(session?.user, "ai_variation") ? (
                            <>
                                <Text mt={2}>
                                    Our AI will write an alternative tweet while trying to stay on the same topic. 
                                </Text>
                                <Box mt={4} display="flex" alignItems="flex-start">
                                    <Text>
                                        Choose the freedom you want to give to the AI to come up with new ideas (change this anytime in your Settings).
                                    </Text>
                                    <Tooltip hasArrow label={"Conservative: will attempt to stay as close as possible to the original tweet with small variations. Standard: will attempt to stay close to the general idea of the tweet while offering more impactful variations. Wild: let the AI do its own thing, but you might get a few crazy guesses"}>
                                        <QuestionIcon mt={2}  color="gray.400" mr={2}/>
                                    </Tooltip>
                                </Box>
                                <Flex fontWeight="600" justifyContent="space-between">
                                    <Text mt={4}>Conservative</Text>
                                    {/* <Text mt={4}>Standard</Text> */}
                                    <Text mt={4}>Wild</Text>
                                </Flex>
                                <Box pl={6} pr={6}>
                                    <Slider mt={5} css={{marginTop:"-10px"}} width="100%" min={-2} max={2} step={1} variant={"primary"} aria-label="temp-selector"
                                        value={tempLevel} onChange={(val) => {setTempLevel(val);}}>
                                        <SliderTrack>
                                            <SliderFilledTrack />
                                        </SliderTrack>
                                        <SliderThumb />
                                    </Slider>
                                </Box>
                                <Checkbox mt={4} onChange={async (e) => {
                                    if (session?.user?.uid) {
                                        const db = firebaseClient.firestore();
                                        await db.collection("users").doc(session?.user?.uid).update({skipPopupVariationAuto: e.target.checked});
                                        session.user.data.skipPopupVariationAuto = e.target.checked;
                                    }
                                }}>Skip this popup next time</Checkbox>
                            </>
                        )
                        : (
                            // isAllowed(session?.user, "ai_variation") ? "You can't perform this action, not enough credits. Credits will be reseted on the 1st of each month." : "You can't perform this action, not enough credits."
                            <Text>
                                You can't perform this action, you need to upgrade to a higher plan. 
                            </Text>
                        )
                    }
                </AlertDialogBody>
                <AlertDialogFooter>
                    <Button variant="tertiary" onClick={onClose}>
                        Cancel
                    </Button>
                    {
                        (session?.user?.data?.creditsAuto && session?.user?.data?.creditsAuto > 0) || isAllowed(session?.user, "ai_variation") ? (
                            <Button isLoading={isLoadingVariation} ref={focusRefVariationAuto} variant={"secondary"} onClick={async () => {
                                setIsLoadingVariation(true);

                                let newTempLevel = tempLevel ?? 0;
                                
                                if (session?.user?.data) {
                                    const db = firebaseClient.firestore();
                                    db.collection("users").doc(session?.user?.uid).update({tempLevel: newTempLevel});
                                    session.user.data.tempLevel = newTempLevel;
                                }

                                let result = await generate(session, selectedTweet, 1, newTempLevel);
                                setIsLoadingVariation(false);

                                if (result?.success && result?.data?.variations?.length) {
                                    let newVariation = result?.data?.variations[0];
                                    newTweet({ text: newVariation }, undefined, false, undefined, () => {
                                      tweetContext.setIsTweetTextChanged(true);
                                    });
                                    
                                    onOpenScheduler();
                                    toast.success("Variation pasted in tweet composer");
                                }
                                onClose();
                            }} ml={3}>
                                Generate
                            </Button>
                        ) : (
                            !isAllowed(session?.user, "ai_variation") ? (
                                <Button ref={focusRefVariationAuto} ml={3} variant={"secondary"} onClick={() => {
                                    router.push("/pricing");
                                    onClose();
                                }}>
                                    Upgrade
                                </Button>
                            ) : (
                                <Box>

                                </Box>
                            )
                        )
                    }
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}
