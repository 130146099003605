import toast from "react-hot-toast";
import {
    Box,
    Button,
    Link,
} from "@chakra-ui/react";
import { firebaseClient, getToken } from "../firebaseClient";
import { postData, shuffleArray, cleanTweetForCountChar } from "../utils/helpers";
import moment from "moment-timezone";
import dateFormat from 'dateformat';
import * as Sentry from "@sentry/nextjs";
import { getAccount, updateUser, hasEditRight } from "../utils/sessionHelper";
import { format } from "date-fns";
import { convertToAccountTimezone, convertToAccountTimezoneReversed } from "../utils/helpers";
import StringSimilarity from "string-similarity";
import {
    Icon,
} from "@chakra-ui/react";
import { AiOutlineWarning } from 'react-icons/ai';
import twitterText from "twitter-text";
import { TweetContext } from "context/tweetContext";
import { predict } from "controllers/popularity";
import { v4 as uuidv4 } from 'uuid';


export const defaultAddToQueue = async (session, tweetContext, text, scheduledTime = null) => {

    let newId = uuidv4();
    await tweetContext.createDraft({ text: text }, newId)

    let dataToSend = {
        idUser: getAccount(session)?.id,
        idAccount: getAccount(session)?.idAccount,
        twUserName: getAccount(session)?.twUserName,
        id: newId,
        text: text,
        isScheduled: true,
        scheduledTime: scheduledTime || tweetContext.nextSlot,
        isAutoPlug: false,
        // autoPlugText: getAccount(session)?.autoPlugText ?? false,
        // autoPlugTrigger: getAccount(session)?.autoPlugTrigger ?? 20,
        isAutoRetweet: getAccount(session)?.isAutoRetweet ?? false,
        autoRetweetHours: getAccount(session)?.autoRetweetHours ?? 12,
        autoRetweetTimes: getAccount(session)?.autoRetweetTimes ?? 1,
        isPreventLinkExpand: getAccount(session)?.isPreventLinkExpand ?? 1,
        isPreventLinkExpandAutoPlug: getAccount(session)?.isPreventLinkExpand ?? 1,
        isRetweeted: false,
        isPlugged: false,
        autoRtWithOtherAccounts: getAccount(session)?.autoRtWithOtherAccounts ?? false,
    }

    if (!(await checkTweetValid([], session, tweetContext, dataToSend, undefined))) {
        return;
    }

    await scheduleTweet(session, undefined, undefined, dataToSend, getAccount(session)?.timezone, tweetContext);

    tweetContext.refreshNextSlot();
}

export const pushTaplio = async (session, dataToSend, isScheduled, scheduledTime = undefined) => {

    // console.log("pushing content to Taplio: " + dataToSend.text);

    if (!getAccount(session)?.keyTaplio) {
        console.log("cancel pushTaplio because no Taplio key");
        return;
    }

    let data = {
        type: isScheduled ? "queue" : "now",
        tweet: {
            id: dataToSend.id,
            text: dataToSend.text,
            isScheduled,
        }
    } as any;

    if (scheduledTime) {
        data.tweet.scheduledTime = scheduledTime;
        if (isScheduled)
            data.type = "scheduled";
    }

    let response = await fetch('https://app.taplio.com/api/public/push', {
        // let response = await fetch('http://localhost:300s0/api/public/push?', {
        method: 'POST',
        headers: { "Content-Type": "application/json", "Authorization": getAccount(session)?.keyTaplio },
        body: JSON.stringify(data),
    });

    if (response?.status != 200) {
        let json = await response?.json();
        toast.error("An error happened when posting your tweet to Taplio" + (json?.error ? ": " + json.error : ""));
        console.log("response from TH:", json);
    }
    else {
        let json = await response?.json();
        // console.log(json);
        let sentTweet = json?.sentTweet;

        if (sentTweet?.scheduledTime) {
            let date = new Date(sentTweet?.scheduledTime);
            let dateConverted = convertToAccountTimezone(date, getAccount(session).timezone).date;
            toast.success("Linkedin post scheduled on Taplio on " + dateFormat(dateConverted, 'mmm d h:MM tt') + " (" + getAccount(session).timezone + ")");
        }
        else {
            toast.success("Post published on Linkedin");
        }
    }
}

export const pushTweet = async (setIsPosting, dataToSend) => {

    try {
        const db = firebaseClient.firestore();

        setIsPosting(true);
        let response = await fetch('/api/pushTweet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });
        let data = await response.json();
        setIsPosting(false);

        if (data.success) {
            toast.success("Tweet pushed successfully", { style: { background: "gray.600", color: "#222" } });
        }
        else {
            toast.error("An error happened. Your tweet has not been pushed.", { style: { background: "gray.600", color: "#222" } });
            console.log("Error: " + data.error);
        }
    }
    catch (e) {
        toast.error("An error happened. Your tweet has not been posted.", { style: { background: "gray.600", color: "#222" } });
        console.log("Error: " + e.message, e);
        Sentry.captureException(e);
    }
};

export const unschedule = async (session, tweet, tweetContext) => {

    const db = firebaseClient.firestore();
    // await db.collection("users").doc(session?.user?.uid).collection("tweets").doc(tweet.id).update({status: "draft"});
    await db.collection("users").doc(getAccount(session).id).collection("tweets").doc(tweet.id).update({ status: "draft" });
    // getTweetsFromStatus("draft", setDrafts);
    // getTweetsFromStatus("scheduled", setScheduleds);
    // console.log("unscheduling at: " + tweet.scheduledTime.toDate());
    // console.log("unscheduling at: " + convertToAccountTimezone(tweet.scheduledTime.toDate(), getAccount(session).timezone).date);

    tweetContext.setTabIndex(0);
    tweetContext.newTweet(
        tweet,
        tweet.id,
        false,
        convertToAccountTimezone(tweet.scheduledTime.toDate(), getAccount(session).timezone).date,
    );
    tweetContext.setForceScheduledTime(true);
    // tweetContext.setNextSlot(tweet.scheduledTime.toDate());
    // tweetContext.setTextState({
    //     ...tweetContext.textState,
    //     scheduledTime: convertToAccountTimezone(tweet.scheduledTime.toDate(), getAccount(session).timezone).date,
    //     // scheduledTime: tweet.scheduledTime.toDate(),
    // });
    tweetContext.refresher({});
    tweetContext.setIsTweetTextChanged(true);
}

export const postTweet = async (setIsPosting, setIsOpenConfirm, dataToSend, session) => {

    try {
        const db = firebaseClient.firestore();
        await db.collection("users").doc(dataToSend.idUser).collection("tweets").doc(dataToSend.id).update(dataToSend);

        setIsPosting(true);
        let response = await fetch('/api/scheduler', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...dataToSend,
                tokenUserId: session.user.uid,
                token: await getToken(session, "scheduler"),
            }),
        });
        let data = await response.json();
        setIsPosting(false);

        if (data.success) {

            if (data.id_str) {
                toast((t) => (
                    <Box>
                        ✅&nbsp;&nbsp;Tweet posted successfully
                        <Button ml={2} variant={"secondary"} size="xs" as={Link} href={"https://twitter.com/" + (data.twUserName ?? "tibo_maker") + "/status/" + data.id_str} isExternal>
                            view
                        </Button>
                    </Box>
                ), { duration: 6000 });
            }
            else
                toast.success("Tweet posted successfully");

            // dataToSend.status = "sent";
            // dataToSend.dateSent = new Date();
            // await db.collection("users").doc(dataToSend.idUser).collection("tweets").doc(dataToSend.id).update(dataToSend);
        }
        else {
            toast.error("An error happened. Your tweet has not been posted.", { style: { background: "gray.600", color: "#222" } });
            console.log("Error: " + data.error);
        }

        setIsOpenConfirm(false);
    }
    catch (e) {
        toast.error("An error happened. Your tweet has not been posted.", { style: { background: "gray.600", color: "#222" } });
        console.log("Error: " + e.message, e);
        Sentry.captureException(e);
    }
};

export const postReply = async (setIsPosting, dataToSend, session) => {

    try {

        const db = firebaseClient.firestore();

        dataToSend.idUser = getAccount(session)?.id;
        dataToSend.thCustomClientId = getAccount(session)?.thCustomClientId;
        dataToSend.thCustomClientSecret = getAccount(session)?.thCustomClientSecret;
        dataToSend.thWriteAccessToken = getAccount(session).thWriteAccessToken;
        dataToSend.thWriteSecretToken = getAccount(session).thWriteSecretToken;
        dataToSend.thApp = getAccount(session).thApp;

        setIsPosting && setIsPosting(true);
        let response = await fetch('/api/reply', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });
        let data = await response.json();

        setIsPosting && setIsPosting(false);

        if (data.success) {
            // toast.success("Reply posted");
            toast((t) => (
                <Box>
                    ✅&nbsp;&nbsp;Reply posted
                    <Button ml={2} variant={"secondary"} size="xs" as={Link} href={"https://twitter.com/" + (getAccount(session)?.twUserName ?? "tibo_maker") + "/status/" + data.id_str} isExternal>
                        view
                    </Button>
                </Box>
            ), { duration: 6000 });
            return true;
        }
        else {
            toast.error(data.error ? "Error: " + data.error : "An error happened. Your tweet has not been posted.");
            console.log("Error: " + data.error);
            return false;
        }
    }
    catch (e) {
        toast.error("An error happened. Your tweet has not been posted.", { style: { background: "gray.600", color: "#222" } });
        console.log("Error: " + e.message, e);
        Sentry.captureException(e);
    }

    return false;
};


export const scheduleTweet = async (session, setIsPosting, setIsOpenConfirm, dataToSend, timezone, tweetContext, alreadyTimezoned = false, conf = {} as any) => {

    // let start = new Date();

    try {

        if (!hasEditRight(session))
            return;

        console.log("scheduleTweet: " + dataToSend.id);
        setIsPosting && setIsPosting(true);
        dataToSend.status = "scheduled";

        // let stringDate = dateFormat(dataToSend.scheduledTime, 'yyyy-mm-dd HH:MM');
        // // console.log("actual time: " + stringDate);
        // let newScheduledTime = moment.tz(stringDate, timezone);
        // // console.log("newScheduledTime: " + newScheduledTime.format());
        // dataToSend.scheduledTime = new Date(newScheduledTime.format());

        if (!alreadyTimezoned) {
            // dataToSend.scheduledTime = convertToUserTimezone(dataToSend.scheduledTime, timezone);
            let { date: dateTimezoned } = convertToAccountTimezoneReversed(dataToSend.scheduledTime, timezone);
            dataToSend.scheduledTime = dateTimezoned;
        }

        // console.log(dataToSend);
        const db = firebaseClient.firestore();
        if (dataToSend.id) {
            try {
                await db.collection("users").doc(dataToSend.idUser).collection("tweets").doc(dataToSend.id).update(dataToSend);
            }
            catch (e) {
                // console.log("Error in scheduling: " + e.message);
                if (e.message.includes("No document to update")) {
                    console.log("No document to update - re-saving");
                    // await new Promise(resolve => setTimeout(resolve, 200));
                    await db.collection("users").doc(dataToSend.idUser).collection("tweets").doc(dataToSend.id).set(dataToSend);
                    console.log("No document to update - saved");
                }
            }
            toast.success(conf.customSuccessMessage ?? "Tweet scheduled successfully", { duration: 6000 });
        }
        else {
            throw new Error("tweet id not available");
        }

        setIsOpenConfirm && setIsOpenConfirm(false);
        setIsPosting && setIsPosting(false);

        // silently save prediction
        // silentPrediction(session, dataToSend);
    }
    catch (e) {
        toast.error("An error happened. Your tweet has not been scheduled.", { style: { background: "gray.600", color: "#222" } });
        console.log("Error: " + e.message, e);
        setIsPosting && setIsPosting(false);
        setIsOpenConfirm && setIsOpenConfirm(true);
        Sentry.captureException(e);
    }

    // console.log("scheduleTweet - duration: " + (new Date().getTime() - start.getTime()) + "ms");
}

// const silentPrediction = async (session, tweet) => {
//     try {
//         let prediction = await predict(session, tweet.text);
//         const db = firebaseClient.firestore();
//         await db.collection("users").doc(tweet.idUser).collection("tweets").doc(tweet.id).update({ prediction });
//     }
//     catch (e) {
//         console.log("Error in getting prediction post scheduling: " + e.message, e);
//         Sentry.captureException(e);
//     }
// }

export const getScheduleSlots = async (session, now = new Date(), nbDaysToGet = 60, conf = {} as any) => {

    // let start = new Date();
    // console.log("findNextSlot");

    let slots: Array<Date> = [];
    let timezoneDiff;
    let firstEmptyDay: any = undefined;
    let nbSlots = 0;
    let lastDay = new Date();

    const db = firebaseClient.firestore();
    let docs = await db.collection("users").doc(getAccount(session).id).collection("tweets").where("status", "==", "scheduled").get();
    let scheduleTweets = docs.docs.map(x => x.data());

    scheduleTweets = scheduleTweets.filter(x => isTweetFromAccount(x, session));

    // if (docs?.docs?.length)
    //     firstEmptyDay = docs?.docs?.sort((a, b) => b.data().scheduledTime.toDate().getTime() - a.data().scheduledTime.toDate().getTime())[0].data().scheduledTime.toDate();
    // console.log("lastTweetDate: " + firstEmptyDay);


    if (getAccount(session).schedule) {
        let schedule = getAccount(session).schedule;
        // console.log(schedule);

        let count = 0;

        ({ date: now, timezoneDiff } = convertToAccountTimezone(now, getAccount(session)?.timezone));

        let current = new Date(now);

        current.setSeconds(0);
        current.setMilliseconds(0);

        // console.log("findNextSlot init: " + current);
        // console.log("findNextSlot ini: " + current);
        // console.log("findNextSlot ini: " + current.getHours());
        // firstEmptyDay.setTime(firstEmptyDay.getTime() - (1000*60*timezoneDiff));

        // nbDaysToGet = 90;
        let extremeLimit = 1000;

        // will now go as far as possible
        while (count < extremeLimit && (count < nbDaysToGet || (scheduleTweets?.filter(x => !x.hasSlot)?.length > 0 && !conf?.noTempSlots))) {
            // while (count < nbDaysToGet) {

            // console.log(count + " - " + scheduleTweets?.filter(x => !x.hasSlot)?.length);

            let newDay: any = {};
            lastDay = newDay;
            newDay.time = new Date(current);
            newDay.slotsOfTheDay = [];
            // console.log(newDay);
            slots.push(newDay);

            let startOfDay = new Date(current.getFullYear(), current.getMonth(), current.getDate(), 0, 0, 0, 0);
            let endOfDay = new Date(current.getFullYear(), current.getMonth(), current.getDate(), 23, 59, 59, 999);

            let scheduledTweetsOfTheDay = scheduleTweets.filter(x =>
            ((x.scheduledTime.toDate().getTime() - (1000 * 60 * timezoneDiff)) >= startOfDay.getTime()
                && (x.scheduledTime.toDate().getTime() - (1000 * 60 * timezoneDiff)) < endOfDay.getTime()));
            // console.log("For " + current.toString() + " - nb scheduledTweetsOfTheDay: " + scheduledTweetsOfTheDay?.length);
            let isEmptyDay = true;

            // console.log("nb scheduledTweetsOfTheDay: " + scheduledTweetsOfTheDay?.length);

            // schedule.forEach(t => {
            for (let i = 0; i < schedule?.length; i++) {

                nbSlots++;
                current.setHours(schedule[i].hour);
                current.setMinutes(schedule[i].minute ? schedule[i].minute : 0);
                // let dateTimezoned = convertToUserTimezone(current, session?.user?.data?.timezone);
                // let dateTimezoned = new Date(current.getTime() - (1000*60*timezoneDiff));
                let dateTimezoned = current;
                // console.log("current: " + current)
                // console.log("dateTimezoned: " + dateTimezoned)

                let dayIndex = (dateTimezoned.getDay() + 6) % 7;
                // console.log("dateTimezoned: " + dateTimezoned);
                // console.log("dayIndex: " + dayIndex);
                // console.log(schedule[i].hour + " / " + schedule[i].days[dayIndex]);

                if (schedule[i].days[dayIndex] && current > now) {

                    let newSlot: any = {
                        time: new Date(dateTimezoned),
                        id: "" + dateTimezoned.getTime(),
                        dayIndex: count,
                        slotIndex: i,
                        isEvergreen: schedule[i].isEvergreen,
                        labels: schedule[i].labels
                    }

                    if (!schedule[i].isEvergreen) {
                        let match = scheduledTweetsOfTheDay.find(x => (x.scheduledTime.toDate().getTime() - (1000 * 60 * timezoneDiff)) == dateTimezoned.getTime());
                        if (match) {
                            isEmptyDay = false
                            newSlot.tweet = match;
                            newSlot.id = match.id;
                            match.hasSlot = true;
                        }
                    }

                    newDay.slotsOfTheDay.push(newSlot);
                }
            };

            if (isEmptyDay && !firstEmptyDay && count !== 0) {
                firstEmptyDay = new Date(current.getTime() - (1000 * 60 * 60 * 24));
                // console.log("setting firstEmptyDay to " + firstEmptyDay);
            }

            if (!conf?.noTempSlots) {
                let tweetWithoutSlots = scheduledTweetsOfTheDay.filter(x => !x.hasSlot);
                tweetWithoutSlots.forEach(tw => {
                    let timeTimezoned = new Date(tw.scheduledTime.toDate().getTime() - (1000 * 60 * timezoneDiff));
                    let newSlot: any = {
                        time: timeTimezoned,
                        id: tw.id,
                        dayIndex: count,
                        slotIndex: newDay.slotsOfTheDay?.length,
                        tweet: tw,
                    }
                    newDay.slotsOfTheDay.push(newSlot);
                    tw.hasSlot = true;
                    nbSlots++;
                });
            }

            newDay.slotsOfTheDay.sort((a, b) => a.time - b.time);
            newDay.slotsOfTheDay.forEach((slot, index) => {
                slot.slotIndex = index;
            });

            // console.log({current})
            // current.setTime(current.getTime() + (1000*60*60*24)); // cause bug when time changes twice a year
            current.setDate(current.getDate() + 1); // add 1 day
            // console.log({current})
            count++;
        }

    }

    if (!firstEmptyDay && scheduleTweets.length) {
        firstEmptyDay = lastDay;
    }

    // console.log("getScheduleSlots - duration: " + (new Date().getTime() - start.getTime()) + "ms");
    // console.log("slots", slots);

    return { slots, timezoneDiff, firstEmptyDay, scheduleTweets, nbSlots };
}

export const findNextSlot = (session, tweets, conf: any = {}) => {

    // console.log("findNextSlot");

    if (getAccount(session).schedule) {
        let schedule = getAccount(session).schedule.filter(x => !x.isEvergreen);
        const confLabels = conf.labels ?? [];

        let count = 0;
        let nextSlot = undefined;

        // if code launched from the server, reverse the timezone
        // if (typeof window == "undefined") {
        //     var {timezoneDiff, date: now} = convertToAccountTimezoneReversed(new Date(), getAccount(session)?.timezone);
        //     var current = new Date(now);
        //     // timezoneDiff = -timezoneDiff;
        //     console.log("findNextSlot", {timezoneDiff, now})
        // }
        // else {
        var { timezoneDiff, date: now } = convertToAccountTimezone(new Date(), getAccount(session)?.timezone);
        var current = new Date(now);
        // }

        current.setSeconds(0);
        current.setMilliseconds(0);

        // console.log("findNextSlot init: " + current);
        // console.log("findNextSlot ini: " + current);
        // console.log("findNextSlot ini: " + current.getHours());

        // loop until 400 days
        while (count < 400 && !nextSlot) {
            // schedule.forEach(t => {
            for (let i = 0; i < schedule?.length; i++) {

                current.setHours(schedule[i].hour);
                current.setMinutes(schedule[i].minute ? schedule[i].minute : 0);
                // let dateTimezoned = convertToUserTimezone(current, session?.user?.data?.timezone);
                // let dateTimezoned = new Date(current.getTime() - (1000*60*totalDiff));
                let dateTimezoned = current;
                // console.log("current: " + current)
                // console.log("dateTimezoned: " + dateTimezoned)

                let dayIndex = (dateTimezoned.getDay() + 6) % 7;

                if (typeof window == "undefined") {
                    console.log("dateTimezoned: " + dateTimezoned);
                    console.log("dayIndex: " + dayIndex);
                    console.log(schedule[i].hour + " / " + schedule[i].days[dayIndex]);
                    console.log(schedule[i].hour + " / " + schedule[i].days[dayIndex]);
                }

                if (schedule[i].days[dayIndex]) {
                    const scheduleLabels = schedule[i].labels ?? [];
                    const hasMatchingLabels = scheduleLabels.some(sl => confLabels.includes(sl))
                    let match = tweets.find(x => (x.scheduledTime.toDate().getTime() - (1000 * 60 * timezoneDiff)) == dateTimezoned.getTime());

                    if (current > now && !match && (hasMatchingLabels || (scheduleLabels?.length === 0 && confLabels?.length === 0))) {
                        return current;
                    }
                }
            };

            current.setTime(current.getTime() + (1000 * 60 * 60 * 24)); // add 1 day
            count++;
        }
    }

    return null;
}

export const shareNext = async (session, tweetToSchedule, tweetContext) => {

    // console.log("shareNext");

    let tempTweet = tweetToSchedule;
    let { slots, scheduleTweets } = await getScheduleSlots(session, new Date(), 120, { noTempSlots: true });
    // console.log({scheduleTweets});
    // console.log(scheduleTweets.map(x => x.scheduledTime.toDate()));
    // console.log({slots});
    const db = firebaseClient.firestore();
    let timezone = getAccount(session)?.timezone;
    let { timezoneDiff } = convertToAccountTimezone(new Date(), getAccount(session)?.timezone);

    let allSlots = [];
    slots.forEach((day: any) => {
        allSlots = allSlots.concat(day.slotsOfTheDay.filter(x => !x.isEvergreen));
    })

    // console.log({allSlots});

    for await (let slot of allSlots as any) {

        if (tempTweet) {
            let match = scheduleTweets.find(x => x?.scheduledTime?.toDate && (x.scheduledTime.toDate().getTime() - (1000 * 60 * timezoneDiff)) == slot.time.getTime());
            // let match = scheduleTweets.find(x => x?.scheduledTime?.toDate && x.scheduledTime.toDate().getTime() == slot.time.getTime());
            // console.log(scheduleTweets.map(x => x.scheduledTime.toDate().getTime));
            // console.log(slot.time.getTime());

            // console.log
            let { date: dateTimezoned } = convertToAccountTimezoneReversed(slot.time, timezone);
            // console.log("tw: " + tempTweet.text + " / moved to " + dateTimezoned);
            tempTweet.scheduledTime = dateTimezoned;
            await db.collection("users").doc(getAccount(session).id).collection("tweets").doc(tempTweet.id).update({ scheduledTime: dateTimezoned });

            if (match) {
                // console.log("already a tweet at this slot: " + slot.time);
                tempTweet = match;
            }
            else {
                // console.log("the end: " + slot.time);
                tempTweet = undefined;
            }
        }

    }

    tweetContext?.refresher && tweetContext.refresher({});
}

export const rebuildQueue = async (session, scheduleTweets, refresher, isShuffle = false) => {

    let error: any = undefined;
    let timezone = getAccount(session)?.timezone;

    const db = firebaseClient.firestore();
    let docs = await db.collection("users").doc(getAccount(session).id).collection("tweets").where("status", "==", "scheduled").orderBy("scheduledTime", "asc").get();
    let tweets = docs.docs.map(doc => doc.data());
    tweets = tweets.filter(x => isTweetFromAccount(x, session));

    if (isShuffle) {
        tweets = shuffleArray(tweets);
    }

    tweets.forEach(tw => {
        tw.scheduledTime = { toDate: () => { return new Date() } };
    });

    for await (let tw of tweets) {
        try {
            if (!error) {
                // console.log(tweets.map(x => {return {t: x.text, scheduledTime: x.scheduledTime.toDate()}}));
                let slot: any = findNextSlot(session, tweets, { labels: tw.labels || [] });
                console.log("slot: " + slot);
                // let newDate = new Date(slot);
                // tw.scheduledTime = {toDate: () => {return newDate}};

                if (!slot) {
                    console.log("slot not found for this label - removing label");
                    slot = findNextSlot(session, tweets, { labels: [] });
                    console.log("slot without label: " + slot);
                }

                if (!slot?.getMonth)
                    throw new Error("Some tweets could not be scheduled because there is no slot available with the same label. Please check your schedule and try again.");

                let { date: dateTimezoned } = convertToAccountTimezoneReversed(slot, timezone);
                await db.collection("users").doc(getAccount(session).id).collection("tweets").doc(tw.id).update({ scheduledTime: dateTimezoned });
                let newTweet = await db.collection("users").doc(getAccount(session).id).collection("tweets").doc(tw.id).get();
                //@ts-ignore
                tw.scheduledTime = newTweet.data().scheduledTime;
            }
        }
        catch (e) {
            console.log("error in rebuildQueue: " + e.message, JSON.stringify(e));
            error = e;
        }
    };

    if (error) {
        toast.error("Error while rebuilding queue: " + error.message);
    }

    refresher({});
}

export const buildSchedule = async (session, showToast = false, refresher = undefined as any) => {

    try {
        let url = `https://api.tweetbutler.com/activity?twUserName=${getAccount(session).twUserName}&id=${getAccount(session).idAccount}&timezone=${getAccount(session).timezone}`;

        if (getAccount(session)?.thWriteAccessToken)
            url += `&sizeSample=200&accessToken=${getAccount(session)?.thWriteAccessToken}&secretToken=${getAccount(session)?.thWriteSecretToken}&app=${getAccount(session)?.thApp}`;
        else
            url += `&sizeSample=100`;

        if (getAccount(session)?.thCustomClientId && getAccount(session)?.thCustomClientSecret) {
            url += "&customClientId" + getAccount(session)?.thCustomClientId;
            url += "&customClientSecret" + getAccount(session)?.thCustomClientSecret;
        }

        const token = await getToken(session, "buildSchedule");
        let response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                tokenuserid: session.user.uid,
                Authorization: `Bearer ${token}`,
            },
        });

        let data = await response.json();

        let times: Array<any> = [14, 16, 18];

        if (data?.data) {
            let indexMax = data.data.indexOf(Math.max(...data.data));
            times = [
                (indexMax - 4 + 24) % 24,
                (indexMax - 2 + 24) % 24,
                indexMax % 24,
            ];
        }
        else {
            console.log("failed to build schedule automatically");
        }

        let dataToUpdate: any = {};

        if (!getAccount(session)?.schedule) {
            let schedule = [];
            times.forEach(t => {
                let obj = {
                    hour: t >= 0 && t <= 23 ? t : 0,
                    minute: 0,
                    days: [],
                }
                for (let i = 0; i < 7; i++)
                    //@ts-ignore
                    obj.days[i] = true;

                //@ts-ignore
                schedule.push(obj);
            });
            dataToUpdate.schedule = schedule;
            getAccount(session).schedule = schedule;
        }

        let engagementDistribution = data?.data ?? [];

        if (engagementDistribution?.length && engagementDistribution.some(x => x > 0)) {
            dataToUpdate.engagementDistribution = engagementDistribution;
            getAccount(session).engagementDistribution = engagementDistribution;
            // if (showToast)
            //     toast.success("Engagement graph updated");
            if (refresher)
                refresher({});
        }
        else {
            if (showToast)
                toast.error("Failed to update engagement graph");
        }

        // console.log(schedule);
        // const db = firebaseClient.firestore();
        // await db.collection("users").doc(getAccount(session).id).update(dataToUpdate);
        await updateUser(session, dataToUpdate);

        // console.log(data);

    }
    catch (e) {
        console.log("Error in buildSchedule: " + e.message, e);
        Sentry.captureException(e);
    }
}


export const saveSchedule = async (session, newSchedule, refresher) => {

    try {
        // getAccount(session).schedule = newSchedule;
        // const db = firebaseClient.firestore();
        // await db.collection("users").doc(getAccount(session).id).update({schedule: newSchedule});

        toast.promise(
            new Promise(async (resolve, reject) => {
                await updateUser(session, { schedule: newSchedule });
                refresher && refresher({});
                resolve(newSchedule);
            }),
            {
                loading: 'Updating schedule ... ',
                success: 'Schedule updated successfully',
                error: "Unknown error",
            }
        );
    }
    catch (e) {
        console.log("Error in buildSchedule: " + e.message, e);
        Sentry.captureException(e);
    }
}

export const isTweetFromAccount = (tweet, session) => {

    return (
        (tweet.idAccount == getAccount(session).idAccount)
        || (!tweet.idAccount && getAccount(session).idAccount == session?.user?.uid)
    )
}

export const saveTweet = async (session, id, dataToSave, createDraft, customSave) => {

    let hasSaved = false;
    // dataToSave.files = updatedFiles ? updatedFiles : refData.current.files;

    const db = firebaseClient.firestore();

    if (getAccount(session)?.id) {
        if (createDraft) {
            try {
                // console.log("saveTweet: will update " + id + " - text: " + dataToSave.text);
                await db.collection("users").doc(getAccount(session).id).collection("tweets").doc(id).update(dataToSave);
                hasSaved = true;
            }
            catch (e) {
                hasSaved = await createDraft(dataToSave, id);
            }
        }
    }

    if (customSave) {
        await customSave(dataToSave.text);
    }

    return hasSaved;
}

export const cancelPostPublishActions = async (tweet) => {
    try {
        const dataToSend = {
            autoDmStatus: "done",
            isRetweeted: true,
            isPlugged: true,
            isManuallyCancelled: true
        }

        const db = firebaseClient.firestore();
        await db.collection("users").doc(tweet.idUser).collection("tweets").doc(tweet.id).update(dataToSend);

        toast.success("Successfully removed all post-publish actions")
    } catch (e) {
        toast.error("An error happened. Post-Publish actions are not cancelled.");
        console.log("Error: " + e.message, e);
        Sentry.captureException(e);
    }
}

function convertToUserTimezone(date, timezone) {
    let stringDate = dateFormat(date, 'yyyy-mm-dd HH:MM');
    console.log("actual time: " + stringDate);
    let newDateMoment = moment.tz(stringDate, timezone);
    let newDate = new Date(newDateMoment.format());

    return newDate;
}

export const checkTweetValid = async (threadTweets, session, tweetContext, tweet = {} as any, mainContext, conf = {} as any) => {

    let isValid = true;
    let message = "";
    let countMention = (tweet.text.match(/@/g) || [])?.length;
    let countHashtag = (tweet.text.match(/\B(\#[a-zA-Z]+\b)(?!;)/g) || [])?.length;
    let limit = 5 + ((threadTweets?.length ? threadTweets?.length : 0) * 5);

    if (tweetContext?.selectedTweet?.full_text && !tweetContext?.selectedTweet?.isVariation) {
        let similarity = StringSimilarity.compareTwoStrings(tweetContext?.selectedTweet?.full_text, tweet.text)
        console.log("similarity score: " + similarity);
        if (similarity > 0.85) {
            isValid = false;
            message = "Error: your tweet is too similar to the original tweet.";
        }
    }

    if (countHashtag > limit) {
        isValid = false;
        message = "Error: you cannot add that much hashtags";
    }

    if (conf.checkLinkedin) {
        if (tweet.text?.length > 2800) {
            isValid = false;
            message = "Error: the Linkedin post is too long";
        }
        if (!tweet.text?.length) {
            isValid = false;
            message = "Error: the Linkedin post is empty";
        }
    }


    if (countMention > limit) {
        isValid = false;
        message = "Error: you cannot add that much mentions";
    }

    if (!conf.disableCheckTwitter) {

        if (threadTweets?.length > 30 && tweet.isDelayBetweenTweets && tweet.delayBetweenTweetsMinutes > 0 && tweet.delayBetweenTweetsMinutes < 1) {
            isValid = false;
            message = "Error: you cannot use a thread delay of 5 or 10 sec with more than 30 tweets";
        }

        threadTweets.map(tw => {
            let matchs = tw.match(/(?:\[img:)(.*?)(?=\])/g);
            if (matchs) {
                if (matchs?.length > 4) {
                    isValid = false;
                    message = "Error: each tweet can have a maximum of 4 images";
                }

                // now allowed by Twitter 
                // if (matchs.some(x => x.includes("gif-") || x.includes("vid-")) && matchs?.length > 1) {
                //     isValid = false;
                //     message = "Error: tweet with gif or video can't have more than 1 media";
                // }
            }


            tw = cleanTweetForCountChar(tw);
            let nbChar = twitterText.parseTweet(tw).weightedLength;
            const userIsPremium = getAccount(session)?.isPremium;
            const charLimit = (userIsPremium) ? 25000 : 280;
            const charLimitLink = (userIsPremium) ? 24982 : 263;
            if (nbChar > charLimit) {
                // console.log("too long: ", tw);
                isValid = false;
                message = "Error: one of your tweet is too long";
            }
            else if (tw.includes("[tweet]") && nbChar > charLimitLink) {
                isValid = false;
                message = "Error: one of your tweet will be too long when [tweet] will be replaced by the first tweet URL";
            }
            console.log(tw + "\nSize: " + nbChar);

            if (!tw.replace(/[\s\n]/g, '')?.length && !matchs?.length) {
                isValid = false;
                message = "Error: one of your tweet is empty";
            }
        });

        if (tweet.isAutoDm) {

            let regex = /dm|message|inbox|send|comment|reply|retweet/g;
            let textLowerCase = tweet.text.toLowerCase();

            if (!regex.test(textLowerCase)) {
                isValid = false;
                message = "Error: when using auto-DM, you need to be very clear that you'll send a DM if a specific action is done";
            }

            let mediasInAutoDm = tweet.autoDmText.match(/(?:\[img:)(.*?)(?=\])/g);
            if (mediasInAutoDm?.length > 1) {
                isValid = false;
                message = "Error: only one media is allowed in the auto-DM message";
            }

            console.log('autoDmLeadMagnetUrl:', tweet.autoDmLeadMagnetUrl)
            console.log('autoDmLeadMagnetListId:', tweet.autoDmLeadMagnetListId)
            if (tweet.isAutoDmLeadMagnet && (!tweet.autoDmLeadMagnetUrl || tweet.autoDmLeadMagnetListId === -1)) {
                isValid = false;
                message = "Error: auto-DM not properly configured - make sure you have selected a list and a lead magnet URL";
            }
        }
    }

    if (tweet.isAutoPlug && !tweet.autoPlugText) {
        isValid = false;
        message = "Error: auto-plug is activated but no message has been set. Deactivate it or add an auto-plug text.";
    }

    if (tweet.hasOwnProperty("autoDmText")) { // no need to check for autoDmTrigger, both properties are added at same time in prepareTweet
        const { autoDmText, autoDmTrigger } = tweet;
        if (!autoDmText || !(autoDmTrigger.retweet || autoDmTrigger.reply || autoDmTrigger.like)) {
            isValid = false;
            message = "Error: auto DM is activated but looks like you forgot to set message or trigger(s). Deactivate it or add text and trigger(s).";
        }
    }

    if (!conf.disableCheckImage && isValid) {
        // check images
        let checkingImage = "";
        let matchs = tweet.text.match(/(?:\[img:)(.*?)(?=\])/g);
        // console.log(matchs);
        // let limit = 14 * (tweet.isDelayBetweenTweets && tweet.delayBetweenTweetsMinutes ? tweet.delayBetweenTweetsMinutes + 1 : 1);
        let limit = 28 * (tweet.isDelayBetweenTweets && tweet.delayBetweenTweetsMinutes ? tweet.delayBetweenTweetsMinutes + 1 : 1);
        if (matchs && matchs.length > limit) {
            isValid = false;
            message = `Error: you can't publish with more than ${Math.floor(limit)} medias (you have ${matchs?.length}). Add thread delays to increase this limit.`;
        }
        else if (matchs && matchs?.length > 0) {
            for await (let match of matchs) {
                try {
                    if (!match.includes("vid-")) {
                        console.log("checking image: " + match);
                        match = match.replace("[img:", "");
                        checkingImage = "[img:" + match + "]";
                        // match = "N7Up98jXE";
                        let url = "https://ez4cast.s3.eu-west-1.amazonaws.com/userUpload/" + match;
                        const response = await fetch(url, { method: "GET" });
                        if (!response.ok) {
                            throw response.status;
                        }
                    }
                }
                catch (e) {
                    if (e?.message?.includes("403") || e === 403) {
                        console.log("Error in fetching image: " + e.message);
                        isValid = false;
                        message = `Error: the image ${checkingImage} is invalid, remove it to publish your tweet`;
                    }
                }
            }
        }
    }

    if (!session?.user?.data?.subscription?.isSubscribed && mainContext?.onOpenUpgrade) {
        isValid = false;
        message = "";
        tweetContext.close();
        mainContext.onOpenUpgrade();
    }

    if (!isValid && message) {
        console.log(message);
        toast.error(message);
    }

    if (isValid && !getAccount(session)?.thWriteAccessToken) {
        tweetContext.onOpenCredentials();
        isValid = false;
    }

    if (isValid && tweet.text[0] === "@") {
        toast((t) => (
            <Box>
                <Icon pt={1} as={AiOutlineWarning} color="orange.400" fontWeight="lg" fontSize="20px" /> Your tweet starts with a @mention. Its reach may be limited by Twitter, and it won’t show on your main timeline.
                {/* <Button ml={2} variant={"secondary"} variant={"primary"} size="xs" as={Link} href={"https://twitter.com/" + (data.twUserName ?? "tibo_maker") + "/status/" + data.id_str} isExternal>
                    view
                </Button> */}
            </Box>
        ), { duration: 10000 });
    }

    // return false;

    return isValid;
}

export const deleteTweet = async (session, dataToSend, tweet) => {
    try {
        const url = "https://us-central1-ez4cast.cloudfunctions.net/tweetScheduler-deleteTweet";
        const token = await getToken(session, "deleteTweet");
        let res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                tokenuserid: session?.user?.uid ?? "",
            },
            body: JSON.stringify(dataToSend),
        });
        let data = await res.json();

        if (data.success === 1) {
            const db = firebaseClient.firestore();
            await db.collection("users").doc(getAccount(session)?.id).collection("tweets").doc(tweet.id).update({ isDeleted: true });
        } else {
            toast.error("There was an error in deleting the tweeting");
        }

        return data;
    } catch (e) {
        console.log("Error: " + e.message, e);
        toast.error("Error in deleting tweet: " + e.message)
        Sentry.captureException(e);
    }
};