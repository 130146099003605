import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";
import { textStyles } from "theme/textStyles/textStyles";

const noUnderline = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    _hover: {
      textDecoration: "none",
      textDecorationStyle: "none",
    },
  };
});
const light = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    ...textStyles.body.medium.light
  };
});

export const Link = defineStyleConfig({
  variants: {
    noUnderline,
    light
  },
});
