export function Hook() {
  return(<svg width="14" height="14" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.75 1.21875C1.21875 0.9375 1.8125 0.9375 2.28125 1.25L11.2812 6.71875C11.7188 7 12 7.5 12 8C12 8.53125 11.7188 9.03125 11.2812 9.28125L2.28125 14.7812C1.8125 15.0625 1.21875 15.0938 0.75 14.8125C0.28125 14.5625 0 14.0625 0 13.5V2.5C0 1.96875 0.28125 1.46875 0.75 1.21875ZM1.5 13.5L10.5 8L1.5 2.5V13.5Z" fill="url(#g)"/>
  <linearGradient
          id="g"
          x1="-5"
          y1="-5"
          x2="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
    </linearGradient></svg>
  )
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.76246 5.38719C8.6187 5.32768 8.45317 5.36054 8.34312 5.47061L5.45809 8.35567C5.34804 8.46571 5.31513 8.6312 5.37467 8.77501C5.43423 8.91879 5.57453 9.01255 5.73016 9.01255H6.58182V9.45208C6.51885 11.1259 4.11552 11.1269 4.05418 9.45208V5.44737C6.79251 4.14046 5.89234 0.0291155 2.84508 0C-0.202375 0.0292438 -1.10208 4.14112 1.63603 5.44737V9.45205C1.82173 14.3312 8.81607 14.3275 9 9.45205V5.74271C9 5.58705 8.90627 5.44678 8.76246 5.38719ZM2.84513 4.06676C2.17845 4.06676 1.63603 3.52436 1.63603 2.85768C1.69683 1.25587 3.99365 1.25628 4.05421 2.85771C4.05419 3.52436 3.51182 4.06676 2.84513 4.06676Z"
        fill="black"
      />
      <path
        d="M8.76246 5.38719C8.6187 5.32768 8.45317 5.36054 8.34312 5.47061L5.45809 8.35567C5.34804 8.46571 5.31513 8.6312 5.37467 8.77501C5.43423 8.91879 5.57453 9.01255 5.73016 9.01255H6.58182V9.45208C6.51885 11.1259 4.11552 11.1269 4.05418 9.45208V5.44737C6.79251 4.14046 5.89234 0.0291155 2.84508 0C-0.202375 0.0292438 -1.10208 4.14112 1.63603 5.44737V9.45205C1.82173 14.3312 8.81607 14.3275 9 9.45205V5.74271C9 5.58705 8.90627 5.44678 8.76246 5.38719ZM2.84513 4.06676C2.17845 4.06676 1.63603 3.52436 1.63603 2.85768C1.69683 1.25587 3.99365 1.25628 4.05421 2.85771C4.05419 3.52436 3.51182 4.06676 2.84513 4.06676Z"
        fill="url(#paint0_linear_8_90)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8_90"
          x1="4.5"
          y1="0"
          x2="4.5"
          y2="13.11"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
