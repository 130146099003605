import {
  Box,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { useSession } from "next-auth/client";
import { getAccount } from "utils/sessionHelper";

interface Props {
  contact: any;
}

export function PersonData({ contact }: Props) {

  const [session] = useSession();

  if (!getAccount(session)?.isAdmin) {
    return null;
  }

  return (
    <Box textAlign="left" mt="6" px="4" w="330px">
      {
        contact?.score && (
          <VStack spacing={2} w="100%" alignItems="flex-start">
            <Text fontWeight="bold" fontSize="sm" color="gray.500">
              Other data (admin only):{" "}
            </Text>
            <Flex pl={5}>
              <Text fontWeight="600">Score: </Text>
              <Text ml={2}>{Math.round(contact?.score*100)/100}</Text>
            </Flex>
          </VStack>
        )
      }
    </Box>
  );
}
