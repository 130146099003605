import {
    Switch,
    useColorModeValue as mode,
    Box,
    Stack,
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useColorModeValue,
    IconButton,
} from "@chakra-ui/react";
import theme from "@chakra-ui/theme";
import React, { useEffect, memo } from "react";
import { useSession } from "next-auth/client";
import { firebaseClient } from "../../firebaseClient";
import { getAccount } from "../../utils/sessionHelper";
import { useRouter } from "next/dist/client/router";
import { useFirebaseUser } from "../../utils/useFirebaseUser";
import toast from "react-hot-toast";
import Thread from "../../components/tweets/masonry/thread";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FiShare2 } from 'react-icons/fi';
import { variant } from "theme/names";

export const TweetPopup = ({ isOpen, onClose, text, id, tweet=undefined as any}) => {

    const initialRef = React.useRef<HTMLInputElement>(null);
    const [session, loading] = useSession();
    const router = useRouter();

    const cancelRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;

    let threadTweets = tweet?.type == "linkedin" ? [text] : text.split("\n\n\n");

    return (
        <AlertDialog
            isOpen={isOpen}
            //@ts-ignore
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    {tweet?.type == "linkedin" ? "Your Linkedin post" : "Your tweet"}
                </AlertDialogHeader>

                <AlertDialogBody>
                    <Stack>
                        {
                            threadTweets && (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    // m={2}
                                    // border={"solid 1px " + useColorModeValue(theme.colors.gray[200], theme.colors.gray[600])}
                                    // rounded="lg"    
                                >
                                    <Thread
                                            threadTweets={threadTweets}
                                            disableNbChar={true}
                                            tweet={tweet}
                                            showOutline={true}
                                            disablePreview={tweet?.isPreventLinkExpand}
                                            image={tweet?.profile_image_url ?? undefined}
                                            name={tweet?.twUserName ?? undefined}
                                    />
                                </Box>
                            )
                        }
                    </Stack>
                </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button
                        ref={cancelRef}
                        variant="tertiary"
                        // colorScheme="blue"
                        // bg="blue.700"
                        // color="white"
                        // _hover={{ bg: "blue.600" }}
                        onClick={async () => {
                            onClose();
                        }}
                        // ml={3}
                        >
                        Cancel
                    </Button>
                    {
                        id ? (
                            <CopyToClipboard
                                text={"https://tweethunter.io/public?id=" + id}
                                onCopy={() => {
                                    // analytics.log("hit_copy_clipboard", analytics.formatTweet(tweet.data));
                                    toast.success("Public url copied to clipboard", {
                                        style: {
                                        background: "gray.600",
                                        color: "#222",
                                        },
                                    });
                                }}
                            >
                                <Button
                                    aria-label="GitHub"
                                    leftIcon={<FiShare2 fontSize="20px" />} 
                                    variant={variant.Button.primary}
                                >Share</Button>
                            </CopyToClipboard>
                        ) : (
                            <>
                            </>
                        )
                    }
                    {/* <Button 
                        ref={cancelRef}
                        variant={"primary"} 
                        onClick={async () => {
                            onClose();
                        }} ml={3}
                    >
                        Done
                    </Button> */}
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}
