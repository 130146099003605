const stripePlans = {
  dev: {
    // startMonthly: "price_1IqDp8LQWMadComQN1IcH4Sq", // 19
    // startYearly: "price_1ItAo6LQWMadComQ6QBZxDdf",
    // startMonthly: "price_1JbJmYLQWMadComQWCUrFci0", // 29
    startMonthly: "price_1KblivLQWMadComQ3GARESQC", // 49
    // startYearly: "price_1JbJyILQWMadComQQ4Rcu6R7", // 24
    startYearly: "price_1KblkwLQWMadComQfouzVbPj", // 36

    // startMonthlyBeta: "price_1IxUApLQWMadComQ08IE7HDr",
    // startMonthlyBeta: "price_1J0LjfLQWMadComQjY5e5lOB",
    // startYearlyBeta: "price_1J4kW4LQWMadComQL2C3X3rt",
    // startMonthlyBeta: "price_1IqDp8LQWMadComQN1IcH4Sq",
    // startYearlyBeta: "price_1ItAo6LQWMadComQ6QBZxDdf",
    growMonthly: "price_1JFeBuLQWMadComQLDpolxiu",
    growYearly: "price_1KblmiLQWMadComQDGbP9tkL",
    // growLaunchMonthly: "price_1JYrPTLQWMadComQTxy8qFed",
    // growLaunchYearly: "price_1Jc0PULQWMadComQGoSGHWCk",
    enterpriseMonthly: "price_1JubQNLQWMadComQecIjICQN", // 200 tax is additional
    enterpriseYearly: "price_1JubQNLQWMadComQrMzZ4Iex", // 167 tax is additional
    marketplace1000: "price_1Lc7vrLoPhoWKxMPBfQND356", // 1000 tax is additional
  },
  prod: {
    // startMonthly: "price_1IqDjVLQWMadComQCYm7Q7rJ", // 19
    // startYearly: "price_1IujfELQWMadComQbASnq3gd", // 14
    // startMonthly: "price_1JbJc0LQWMadComQtnSAj2h6", // 29 with tax
    // startYearly: "price_1JbJz4LQWMadComQDNqBEaib", // 24 with tax
    // startMonthly: "price_1JhrWpLQWMadComQsNZy3w1M", // 29 tax is additional
    // startYearly: "price_1JhrXiLQWMadComQMYCB5zeE", // 24 tax is additional
    // startMonthly: "price_1KblywLQWMadComQNtToGCTE", // 49 tax is additional
    // startYearly: "price_1Kbm10LQWMadComQhzZKLFBB", // 36 tax is additional
    startMonthly: "price_1PZAIVLQWMadComQC1BM5SgS", // 29 USD - new starter monthly
    startYearly: "price_1PZAazLQWMadComQvEBhLDNO", // 261 USD - new starter yearly

    // startMonthlyBeta: "price_1IxUDSLQWMadComQOARthaso",
    // startMonthlyBeta: "price_1J0LiWLQWMadComQOGbD1KmJ",
    // startYearlyBeta: "price_1J4kUxLQWMadComQEc0NL6By",
    // startMonthlyBeta: "price_1IqDjVLQWMadComQCYm7Q7rJ",
    // startYearlyBeta: "price_1IujfELQWMadComQbASnq3gd",
    // growMonthly: "price_1JFeAeLQWMadComQaw6xg695", // 49 with tax
    // growYearly: "price_1JFeAeLQWMadComQBbaBftbd", // 36 with tax
    growMonthly: "price_1JhrgFLQWMadComQ8IJquUcQ", // 49 tax is additional
    growYearly: "price_1KblwmLQWMadComQDQtyZaxa", // 36 tax is additional
    // growLaunchMonthly: "price_1JYrQ9LQWMadComQArr2sduK",
    // growLaunchYearly: "price_1Jc0OULQWMadComQwEhYfn6I",
    enterpriseMonthly: "price_1JPSjTLQWMadComQzgHWdoI6", // 200 tax is additional
    enterpriseYearly: "price_1JPSjTLQWMadComQaVPAdTPk", // 167 tax is additional
    marketplace1000: "price_1Lc7vrLoPhoWKxMPBfQND356", // 1000 tax is additional
    marketplace2000: "price_1Ld7DQLoPhoWKxMP9ygvTJvQ", // 1000 tax is additional
    marketplace3000: "price_1Ld7FNLoPhoWKxMPFYnyAvtJ", // 1000 tax is additional
  },
};

export function getStripePlans() {
  return stripePlans.prod;
  // return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  //   ? stripePlans.dev
  //   : stripePlans.prod;
}

const stripeKey = {
  test: "pk_test_51HlZOTLQWMadComQlcV3KtEZVeIJFSU3gHkFWWr9CYrT5DVW6ChpnxGHNfjAjtIlo7iXDKhXTRqDdbi9LuxE1rPn00k6tmVQzH",
  live: "pk_live_51HlZOTLQWMadComQOUTvvWGKEaFVqmZ3xmP423BvCrpjYSvZZCqtBPSQvBkhspy81wJR896roMwVMdeEnsvyFEFf00kwLNJxuT",
};

export function getStripeKey() {
  return stripeKey.live;
  // return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  //   ? stripeKey.test
  //   : stripeKey.live;
}
