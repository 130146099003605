import * as Sentry from "@sentry/nextjs";
import toast from "react-hot-toast";
import queryString from "query-string";
import { firebaseClient, getToken } from "../firebaseClient";
import { getAccount, updateUser } from "utils/sessionHelper";
import { isAllowed } from "controllers/subscription";

export const enrich = async (
  session,
  selectedPeople,
  contacts,
  setContacts = undefined as any,
  data,
  setEmails = undefined as any,
  mainContext,
  fromTable = false,
  columnRefresher
) => {
  const db = firebaseClient.firestore();

  // console.log(selectedPeople);
  selectedPeople = selectedPeople.filter(
    (x) => !x.emails || x.emails?.length === 0
  );
  if (!selectedPeople?.length) {
    toast.error("No email to look for");
    return;
  }
  // console.log(selectedPeople);

  if (selectedPeople?.length > 10 && !getAccount(session).isAdmin) {
    toast.error("Can't search for more than 10 emails at once");
    return;
  }

  const dataToSend: any = {
    idUser: getAccount(session)?.id,
    thCustomClientId: getAccount(session)?.thCustomClientId,
    thCustomClientSecret: getAccount(session)?.thCustomClientSecret,
    thWriteAccessToken: getAccount(session)?.thWriteAccessToken,
    thWriteSecretToken: getAccount(session)?.thWriteSecretToken,
    thApp: getAccount(session)?.thApp,
    ids: selectedPeople.map((p) => p.id_str).join(","),
  };

  if (!isAllowed(session?.user, "enrich")) {
    mainContext.onOpenUpgrade();
    return;
  }

  if (getAccount(session).creditsEmail === undefined) {
    await updateUser(session, { creditsEmail: 100 });
  }

  if (
    getAccount(session).creditsEmail < selectedPeople?.length &&
    !getAccount(session).isAdmin
  ) {
    toast.error(
      "Not enough email credits, wait for reset or contact us to get more."
    );
    return;
  }

  // selectedPeople.map((x) => x.emails = ["toto"]);
  // console.log(contacts);
  // contacts[0].emails = ["toto"];
  // console.log({data});
  // console.log({contacts});
  // console.log({selectedPeople});
  // setContacts([...contacts]);
  // contacts[0].emails = contact.emails;
  // return;

  const updateContacts = new Promise(async (resolve, reject) => {
    try {
      const url = queryString.stringifyUrl({
        url: "https://us-central1-ez4cast.cloudfunctions.net/tweetContacts-enrich",
        query: dataToSend,
      });
      const token = await getToken(session, "enrich");
      let fetchObject = fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          tokenuserid: session?.user?.uid ?? "",
        },
      });

      if (selectedPeople?.length <= 10) {
        const res = await fetchObject;
        const data = await res.json();

        if (data?.success) {
          let nbEmailFound = 0;
          for (const contact of selectedPeople) {
            let match = data.contacts.find(
              (x) => contact.id_str == x.contact.id_str
            );
            let dataToSave = {} as any;
            if (match) {
              contact.emails = match.contact.emails;
              dataToSave = { ...match.contact };
            }

            if (!dataToSave?.emails?.length) {
              contact.emails = ["Not found"];
              dataToSave.emails = ["Not found"];
              dataToSave.noEmailFound = true;
            } else {
              nbEmailFound += contact.emails?.length;
            }

            console.log(dataToSave);
            await db
              .collection("users")
              .doc(getAccount(session).id)
              .collection("contacts")
              .doc(contact.id_str)
              .update(dataToSave);

            let index = selectedPeople.indexOf(contact);
            if (setEmails && setEmails[index]) {
              console.log("set email to: " + dataToSave.emails[0]);
              setEmails[index](dataToSave.emails[0]);
            }
          }

          columnRefresher({});

          if (nbEmailFound > 0) {
            toast.success(
              fromTable ? nbEmailFound + " emails found" : "Email found!"
            );
            await updateUser(session, {
              creditsEmail: getAccount(session).creditsEmail - nbEmailFound,
            });

            // global refresh
            if (setContacts) {
              selectedPeople.forEach((p) => {
                let matchAllList = contacts.find((x) => p.id_str == x.id_str);
                if (matchAllList) {
                  matchAllList.emails = p.emails;
                }
              });
              setContacts && setContacts([...contacts]);
            }
            resolve(data?.contacts);
          } else reject("No email found :(");
        } else reject("No email found :(");
      } else {
        toast.success(
          "Currently getting emails, please come back in a few minutes"
        );
        resolve({});
      }
    } catch (e) {
      console.log("Error in searching emails: " + e.message, e);
      toast.error("Error in searching emails - try with a smaller batch");
      Sentry.captureException(e);
      reject("An error happened");
    }
  });

  if (fromTable) {
    return updateContacts
      .then((data) => {
        toast.success("Contact updated");
      })
      .catch((err) => {
        toast.error(err);
      });
  } else {
    await toast.promise(updateContacts, {
      loading: "loading ... ",
      success: "Some emails has been found",
      error: "No email found :(",
    });
  }
};

export const searchPeople = async (session, dataToSend) => {
  try {
    const url = queryString.stringifyUrl({
      url: "https://us-central1-ez4cast.cloudfunctions.net/tweetContacts-get",
      query: dataToSend,
    });
    const res = await fetch(url);
    const data = await res.json();
    return data;
  } catch (e) {
    console.log("Error in searching users: " + e.message, e);
    toast.error("Error in searching users: " + e.message);
    Sentry.captureException(e);
  }
};

export const addRemovePeopleToList = async (
  idUser: string,
  listId: number,
  peopleList: any[],
  toggle: "save" | "remove",
  setDataLoaderAdd?: Function,
  firebaseServer: any = firebaseClient,
) => {
  if (!idUser) {
    throw new Error("Invalid idUser");
  }

  const db = firebaseServer.firestore();

  let countDone = 0;
  setDataLoaderAdd && setDataLoaderAdd({ total: peopleList?.length, done: 0 });

  for (const p of peopleList) {
    const contactDoc = await db
      .collection("users")
      .doc(idUser)
      .collection("contacts")
      .doc(p.id_str)
      .get();

    if (!contactDoc.exists) {
      p.lists = [listId];
      p.created_at = new Date();
      await db
        .collection("users")
        .doc(idUser)
        .collection("contacts")
        .doc(p.id_str)
        .set(p);
    } else {
      let data: any = contactDoc.data();

      if (toggle === "save") {
        if (data.lists) {
          data.lists = data.lists.concat([listId]);
        }
      } else {
        data.lists = data.lists.filter((l) => l !== listId);
      }

      if (data.lists?.length > 0) {
        await db
          .collection("users")
          .doc(idUser)
          .collection("contacts")
          .doc(p.id_str)
          .update({ ...p, lists: data.lists });
      } else {
        await db
          .collection("users")
          .doc(idUser)
          .collection("contacts")
          .doc(p.id_str)
          .delete();
      }
    }

    countDone++;
    // console.log('countDone:', countDone);
    setDataLoaderAdd &&
      setDataLoaderAdd({ total: peopleList?.length, done: countDone });
  }
};

export const deleteList = async (session, listId: number) => {
  const idUser = getAccount(session)?.id;
  const lists = getAccount(session)?.contactLists
    ? [...getAccount(session).contactLists]
    : [];
  const selectedList = lists.find((x) => x.id === listId);

  lists.splice(lists.indexOf(selectedList), 1);
  await updateUser(session, { contactLists: lists });

  const db = firebaseClient.firestore();
  const contactDocs = await db
    .collection("users")
    .doc(idUser)
    .collection("contacts")
    .where("lists", "array-contains", listId)
    .get();

  const contacts: any[] = [];
  contactDocs.forEach((c) => {
    contacts.push(c.data());
  });

  for (const c of contacts) {
    c.lists.forEach((l, index) => {
      if (l === listId) {
        c.lists.splice(index, 1);
      }
    });

    if (c.lists?.length > 0) {
      await db
        .collection("users")
        .doc(idUser)
        .collection("contacts")
        .doc(c.id_str)
        .update({ lists: c.lists });
    } else {
      await db
        .collection("users")
        .doc(idUser)
        .collection("contacts")
        .doc(c.id_str)
        .delete();
    }
  }
};

export const getContacts = async (idUser: string, listId: number) => {
  try {
    const db = firebaseClient.firestore();
    const contactDocs = await db
      .collection("users")
      .doc(idUser)
      .collection("contacts")
      .where("lists", "array-contains", listId)
      .orderBy("name")
      .get();

    let people: any = [];
    contactDocs.forEach((c) => {
      const d = c.data();
      // @ts-ignore
      people.push(d);
    });

    return people;
  } catch (err) {
    console.log("Error in fetching contacts: ", err);
    toast.error("Error in fetching contacts: " + err.message);
  }
};

export const moveContacts = async (
  idUser: string,
  contacts: any[],
  currentListId: number,
  newListId: number,
  setLoader?: Function
) => {
  try {
    if (!idUser) {
      throw new Error("Invalid idUser");
    }

    const db = firebaseClient.firestore();
    let countDone = 0;
    setLoader && setLoader({ total: contacts?.length, done: 0 });

    for (const contact of contacts) {
      let newList = [...contact?.lists].filter((l) => l !== currentListId);
      newList.push(newListId);

      await db
        .collection("users")
        .doc(idUser)
        .collection("contacts")
        .doc(contact?.id_str)
        .update({ lists: newList });

      countDone++;
      setLoader && setLoader({ total: contacts?.length, done: countDone });
    }
  } catch (err) {
    console.log("Error happened in moving contacts: ", err);
    toast.error("Error happened in moving contacts: " + err);
  }
};
