import { Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { BulbIcon } from "./icons/bulb-icon";
import { WriteIcon } from "./icons/write-icon";
import { colors } from "theme/colors/colors";

export const GRADIENT_COLOR =
  "linear-gradient(93.67deg, #EC6181 -40%, #5C69E3 140%), #E7B5FF;";
// export const GRADIENT_COLOR = "linear-gradient(93.67deg, #EC6181 3.32%, #5C69E3 97.43%), #E7B5FF;";
export const CHAT_ASSIST_TOGGLE_GRADIENT =
  "linear-gradient(267.99deg, #7069D6 13.63%, #E86285 71.06%)";

export interface Prompt {
  label: JSX.Element | string;
  prompt: string;
  category?: string;
  requiresTweet?: boolean;
}

interface HomeScreenSection {
  icon: JSX.Element;
  title: string;
  prompts: Prompt[];
}

// IMP: don't ever think about beautifying this code or adding a line break. The string format of prompt is the key! It is very very IMP in getting proper AI replies.
export const homeScreenSections: HomeScreenSection[] = [
  {
    icon: <BulbIcon />,
    title: "Get topic ideas for my tweets",
    prompts: [
      {
        label: (
          <Text as="span">
            As a <Tag label="[topic]" /> expert posting on Twitter, what could I
            post about?
          </Text>
        ),
        prompt: `As a ${generateHighlightInput(
          "[topic]"
        )} expert posting on Twitter, what could I post about?`,
      },
      {
        label: (
          <Text as="span">
            List 5 different pain points that many{" "}
            <Tag label="[target audience]" /> have.
          </Text>
        ),
        prompt: `List 5 different pain points that many ${generateHighlightInput(
          "[target audience]"
        )} have.`,
      },
      {
        label: <Text as="span">Generate 5 topic ideas for my tweets.</Text>,
        prompt: `Generate 5 topic ideas for my tweets.`,
      },
    ],
  },
  {
    icon: <WriteIcon />,
    title: "Create tweets from scratch",
    prompts: [
      {
        label: (
          <Text as="span">
            Write a tweet, twitter thread about <Tag label="[topic]" />.
          </Text>
        ),
        prompt: `Write a tweet / twitter thread about ${generateHighlightInput(
          "[topic]"
        )}.`,
      },
      {
        label: (
          <Text as="span">
            Give a controversial opinion about <Tag label="[topic]" />, then
            make it a tweet, twitter thread.
          </Text>
        ),
        prompt: `Give a controversial opinion about ${generateHighlightInput(
          "[topic]"
        )}, then make it a tweet, twitter thread.`,
      },
      {
        label: (
          <Text as="span">
            Write a tweet, twitter thread about the "do's and don'ts" of{" "}
            <Tag label="[topic]" />.
          </Text>
        ),
        prompt: `Write a tweet, twitter thread about the "do's and don'ts" of ${generateHighlightInput(
          "[topic]"
        )}.`,
      },
    ],
  },
];

export const prompts: Prompt[] = [
  {
    category: "Get topic ideas for my tweets",
    label: "Generate tweet ideas for me",
    prompt: `Generate 5 topic ideas for my tweets.`,
  },
  {
    category: "Get topic ideas for my tweets",
    label: "Generate tweet ideas about any topic",
    prompt: `As a ${generateHighlightInput(
      "[topic]"
    )} expert posting on Twitter, what could I post about?`,
  },
  {
    category: "Get topic ideas for my tweets",
    label: "Find audience pain points",
    prompt: `List 5 different pain points that many ${generateHighlightInput(
      "[target audience]"
    )} have.`,
  },
  {
    category: "Get topic ideas for my tweets",
    label: "Find common mistakes around my topic",
    prompt: `List 5 different pitfalls to avoid when trying to ${generateHighlightInput(
      "[topic]"
    )}.`,
  },
  {
    category: "Get topic ideas for my tweets",
    label: "Find common misconceptions people have about a topic",
    prompt: `List 5 common misconceptions people have about ${generateHighlightInput(
      "[topic]"
    )}.`,
  },
  {
    category: "Get topic ideas for my tweets",
    label: "Brainstorm post topics",
    prompt: `Write 5 different article titles about ${generateHighlightInput(
      "[topic]"
    )}. Each article title must have a unique and specific angle on the topic.`,
  },
  {
    category: "Get topic ideas for my tweets",
    label: "Brainstorm book recommendations about a topic",
    prompt: `What are 5 books every ${generateHighlightInput(
      "[profession]"
    )} should read?`,
  },
  {
    category: "Get topic ideas for my tweets",
    label: "Brainstorm documentary recommendations about a topic",
    prompt: `What are 5 documentaries you can learn from as a ${generateHighlightInput(
      "[profession]"
    )}?`,
  },
  {
    category: "Get topic ideas for my tweets",
    label: "Brainstorm useful tools about a topic",
    prompt: `What are the 5 best online tools to improve ${generateHighlightInput(
      "[skill]"
    )}?`,
  },
  {
    category: "Create content from scratch",
    label: "Create a post about a topic",
    prompt: `Write a tweet, twitter thread about ${generateHighlightInput(
      "[topic]"
    )}.`,
  },
  {
    category: "Create content from scratch",
    label: "Create a controversial post about a topic",
    prompt: `Give a controversial opinion about ${generateHighlightInput(
      "[topic]"
    )}, then make it a tweet, twitter thread.`,
  },
  {
    category: "Create content from scratch",
    label: `Create a "top mistakes" post about a topic`,
    prompt: `Write a tweet, twitter thread about top mistakes ${generateHighlightInput(
      "[target audience]"
    )} make when trying to ${generateHighlightInput("[topic]")}.`,
  },
  {
    category: "Create content from scratch",
    label: `Create a "daily routine" post about a topic`,
    prompt: `Write a tweet, twitter thread about a daily routine to improve at ${generateHighlightInput(
      "[topic]"
    )}`,
  },
  {
    category: "Create content from scratch",
    label: `Create a "how to start" post about a topic`,
    prompt: `Write a tweet, twitter thread about how to start ${generateHighlightInput(
      "[topic]"
    )} as a beginner`,
  },
  {
    category: "Create content from scratch",
    label: `Create a motivational post about a topic`,
    prompt: `Write a motivational tweet for people trying to achieve ${generateHighlightInput(
      "[topic]"
    )}.`,
  },
  {
    category: "Create content from scratch",
    label: `Create a "skills to become successful" post about a topic`,
    prompt: `Write a tweet, twitter thread about the skills you need to become a successful ${generateHighlightInput(
      "[topic]"
    )}.`,
  },
  {
    category: "Create content from scratch",
    label: `Create a "do's and don'ts" post about a topic`,
    prompt: `Write a tweet, twitter thread about the do’s and don’ts of ${generateHighlightInput(
      "[topic]"
    )}.`,
  },
  {
    category: "Create content from scratch",
    label: `Create a "5 tips to improve skills" post about a topic`,
    prompt: `Write an engaging tweet, twitter thread containing 5 tips to improve your ${generateHighlightInput(
      "[topic]"
    )} skills.`,
  },

  {
    category: "Improve generated content",
    label: `Change the tone of a post`,
    prompt: `Re-write using a more ${generateHighlightInput("[tone]")} tone.`,
  },
  {
    category: "Improve generated content",
    label: `Be more concise`,
    prompt: `Shorten the tweet. Use short sentences and skip lines between each sentence. Brevity is key.`,
  },
  {
    category: "Add extra content",
    label: `Add a hook`,
    requiresTweet: false,
    prompt: `Add an attention-grabbing one liner to motivate people to read the tweet, twitter thread. Use 120 characters or less.`,
  },
  {
    category: "Add extra content",
    label: `Add a CTA`,
    requiresTweet: false,
    prompt: `Add an engaging, straightforward call to action for this tweet, twitter thread.`,
  },
  {
    category: "Add extra content",
    label: `Add specific examples`,
    requiresTweet: false,
    prompt: `Add specific examples for each item listed in the tweet, twitter thread.`,
  },
];

function generateHighlightInput(label: string) {
  return `<highlight-input data-mention-atom-id="${label}" data-mention-atom-name="${label}">${label}</highlight-input>`;
}

export interface SavedAction {
  id: string;
  createdAt: Date;
  title: string;
  prompt: string;
}

interface Props {
  label: string;
}

function Tag({ label }: Props) {
  return (
    <Text as="span" color="#0A65C1" fontWeight="bold">
      {label}
    </Text>
  );
}

export const StyledText = styled(Text)`
  .formatted-chat-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }
  .formatted-chat-text {
    color: ${() => colors.text.lightMode.standard};
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .formatted-chat-text-dark {
    color: ${() => colors.text.darkMode.standard};
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .formatted-chat-copy {
    font-size: 0.9em;
    cursor: pointer;
    margin-left: 12px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 5px;
    color: ${() => colors.ai[500]};
    &:hover {
      background: ${() => colors.ai[100]};
    }

    svg {
      margin-right: 4px;
    }
  }
  .formatted-chat-edit {
    font-size: 0.9em;
    cursor: pointer;
    margin-left: 4px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 5px;
    color: ${() => colors.ai[500]};

    &:hover {
      background: ${() => colors.ai[100]};
    }

    svg {
      margin-right: 4px;
    }
  }
`;
