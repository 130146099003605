export function Emoji() {
  return(<svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8 11C9.15625 11 9.96875 10.4688 10.4375 9.96875C10.7188 9.65625 11.1875 9.625 11.5 9.90625C11.8125 10.1875 11.8125 10.6562 11.5312 10.9688C10.8438 11.7188 9.65625 12.5 8 12.5C6.3125 12.5 5.125 11.7188 4.4375 10.9688C4.15625 10.6562 4.15625 10.1875 4.46875 9.90625C4.78125 9.625 5.25 9.65625 5.53125 9.96875C6 10.4688 6.8125 11 8 11ZM6.5 6.5C6.5 7.0625 6.0625 7.5 5.5 7.5C4.9375 7.5 4.5 7.0625 4.5 6.5C4.5 5.96875 4.9375 5.5 5.5 5.5C6.0625 5.5 6.5 5.96875 6.5 6.5ZM9.5 6.5C9.5 5.96875 9.9375 5.5 10.5 5.5C11.0625 5.5 11.5 5.96875 11.5 6.5C11.5 7.0625 11.0625 7.5 10.5 7.5C9.9375 7.5 9.5 7.0625 9.5 6.5ZM16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8 1.5C4.40625 1.5 1.5 4.4375 1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5Z" fill="url(#g)"/>
  <linearGradient
          id="g"
          x1="-5"
          y1="-5"
          x2="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
    </linearGradient></svg>
  )
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C5.61553 0 4.26215 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32122C0.00303297 5.6003 -0.13559 7.00776 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0ZM9.56237 4.4846C9.74006 4.48442 9.91381 4.53696 10.0616 4.63557C10.2095 4.73417 10.3247 4.87441 10.3928 5.03855C10.4609 5.20268 10.4787 5.38332 10.4441 5.5576C10.4095 5.73189 10.3239 5.89198 10.1983 6.01763C10.0726 6.14328 9.91255 6.22882 9.73826 6.26345C9.56398 6.29807 9.38334 6.28021 9.2192 6.21212C9.05507 6.14404 8.91483 6.02879 8.81623 5.88097C8.71762 5.73315 8.66508 5.5594 8.66527 5.38171C8.66527 5.14378 8.75978 4.9156 8.92802 4.74736C9.09626 4.57912 9.32444 4.4846 9.56237 4.4846ZM4.43763 4.4846C4.61532 4.48442 4.78908 4.53696 4.9369 4.63557C5.08472 4.73417 5.19996 4.87441 5.26805 5.03855C5.33613 5.20268 5.35399 5.38332 5.31937 5.5576C5.28475 5.73189 5.1992 5.89198 5.07355 6.01763C4.94791 6.14328 4.78781 6.22882 4.61352 6.26345C4.43924 6.29807 4.2586 6.28021 4.09447 6.21212C3.93034 6.14404 3.7901 6.02879 3.69149 5.88097C3.59288 5.73315 3.54035 5.5594 3.54053 5.38171C3.54077 5.14386 3.63537 4.91582 3.80355 4.74763C3.97174 4.57944 4.19978 4.48485 4.43763 4.4846ZM10.9421 8.785C10.6483 10.4622 8.995 11.6255 7 11.6255C5.005 11.6255 3.35263 10.4622 3.05882 8.785C3.05566 8.76797 3.05381 8.75073 3.05329 8.73342C3.05329 8.49118 3.34803 8.3346 3.60592 8.42302C4.60066 8.76658 5.76119 8.94158 7.00092 8.94158C8.24066 8.94158 9.40119 8.76658 10.3959 8.42302C10.6529 8.33092 10.9486 8.49026 10.9486 8.73342C10.9477 8.75075 10.9456 8.768 10.9421 8.785Z"
        fill="url(#paint0_linear_0_91)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_91"
          x1="7"
          y1="0"
          x2="7"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
