export function Ideas() {
  return(<svg width="14" height="14" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.5 14.2188V13H8.5L8.46875 14.2188C8.46875 14.375 8.40625 14.625 8.3125 14.75L7.78125 15.5625C7.625 15.8125 7.25 16 6.9375 16H5.03125C4.71875 16 4.34375 15.8125 4.1875 15.5625L3.65625 14.75C3.53125 14.5938 3.5 14.4062 3.5 14.2188ZM6 0C9.03125 0.03125 11.5 2.46875 11.5 5.5C11.5 6.90625 10.9688 8.15625 10.125 9.09375C9.59375 9.6875 8.8125 10.9375 8.5 11.9688C8.5 11.9688 8.5 11.9688 8.5 12H3.46875C3.46875 11.9688 3.46875 11.9688 3.46875 11.9688C3.15625 10.9375 2.375 9.6875 1.84375 9.09375C1 8.125 0.5 6.875 0.5 5.5C0.5 2.59375 2.8125 0.03125 6 0ZM9 8.15625C9.625 7.40625 10 6.46875 10 5.5C10 3.3125 8.1875 1.5 5.96875 1.5C3.5 1.53125 2 3.5625 2 5.5C2 6.46875 2.34375 7.40625 2.96875 8.15625C3.46875 8.6875 4.0625 9.59375 4.5 10.5H7.46875C7.90625 9.59375 8.5 8.6875 9 8.15625ZM5.5 2.5C5.75 2.5 6 2.75 6 3.03125C6 3.28125 5.75 3.5 5.5 3.5C4.65625 3.5 4 4.1875 4 5C4 5.28125 3.75 5.5 3.5 5.5C3.21875 5.5 3 5.28125 3 5C3 3.625 4.09375 2.5 5.5 2.5Z" fill="url(#g)"/>
  <linearGradient
          id="g"
          x1="-5"
          y1="-5"
          x2="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
    </linearGradient></svg>
  )
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.89814 4.15244L3.40675 2.66105C3.20077 2.45507 2.86703 2.45507 2.66105 2.66105C2.45507 2.86703 2.45507 3.20077 2.66105 3.40675L4.15244 4.89814C4.35843 5.10412 4.69216 5.10412 4.89814 4.89814C5.10412 4.69216 5.10412 4.35843 4.89814 4.15244Z"
        fill="black"
      />
      <path
        d="M4.89814 4.15244L3.40675 2.66105C3.20077 2.45507 2.86703 2.45507 2.66105 2.66105C2.45507 2.86703 2.45507 3.20077 2.66105 3.40675L4.15244 4.89814C4.35843 5.10412 4.69216 5.10412 4.89814 4.89814C5.10412 4.69216 5.10412 4.35843 4.89814 4.15244Z"
        fill="url(#paint0_linear_8_59)"
      />
      <path
        d="M2.67188 8.47266H0.527344C0.235863 8.47266 0 8.70852 0 9C0 9.29148 0.235863 9.52734 0.527344 9.52734H2.67188C2.96336 9.52734 3.19922 9.29148 3.19922 9C3.19922 8.70852 2.96336 8.47266 2.67188 8.47266Z"
        fill="black"
      />
      <path
        d="M2.67188 8.47266H0.527344C0.235863 8.47266 0 8.70852 0 9C0 9.29148 0.235863 9.52734 0.527344 9.52734H2.67188C2.96336 9.52734 3.19922 9.29148 3.19922 9C3.19922 8.70852 2.96336 8.47266 2.67188 8.47266Z"
        fill="url(#paint1_linear_8_59)"
      />
      <path
        d="M17.4727 8.47266H15.3281C15.0366 8.47266 14.8008 8.70852 14.8008 9C14.8008 9.29148 15.0366 9.52734 15.3281 9.52734H17.4727C17.7641 9.52734 18 9.29148 18 9C18 8.70852 17.7641 8.47266 17.4727 8.47266Z"
        fill="black"
      />
      <path
        d="M17.4727 8.47266H15.3281C15.0366 8.47266 14.8008 8.70852 14.8008 9C14.8008 9.29148 15.0366 9.52734 15.3281 9.52734H17.4727C17.7641 9.52734 18 9.29148 18 9C18 8.70852 17.7641 8.47266 17.4727 8.47266Z"
        fill="url(#paint2_linear_8_59)"
      />
      <path
        d="M15.3389 2.66105C15.133 2.45507 14.7992 2.45507 14.5932 2.66105L13.1018 4.15244C12.8959 4.35843 12.8959 4.69216 13.1018 4.89814C13.3078 5.10412 13.6416 5.10412 13.8475 4.89814L15.3389 3.40675C15.5449 3.20077 15.5449 2.86706 15.3389 2.66105Z"
        fill="black"
      />
      <path
        d="M15.3389 2.66105C15.133 2.45507 14.7992 2.45507 14.5932 2.66105L13.1018 4.15244C12.8959 4.35843 12.8959 4.69216 13.1018 4.89814C13.3078 5.10412 13.6416 5.10412 13.8475 4.89814L15.3389 3.40675C15.5449 3.20077 15.5449 2.86706 15.3389 2.66105Z"
        fill="url(#paint3_linear_8_59)"
      />
      <path
        d="M9 0C8.70852 0 8.47266 0.235863 8.47266 0.527344V2.67188C8.47266 2.96336 8.70852 3.19922 9 3.19922C9.29148 3.19922 9.52734 2.96336 9.52734 2.67188V0.527344C9.52734 0.235863 9.29148 0 9 0Z"
        fill="black"
      />
      <path
        d="M9 0C8.70852 0 8.47266 0.235863 8.47266 0.527344V2.67188C8.47266 2.96336 8.70852 3.19922 9 3.19922C9.29148 3.19922 9.52734 2.96336 9.52734 2.67188V0.527344C9.52734 0.235863 9.29148 0 9 0Z"
        fill="url(#paint4_linear_8_59)"
      />
      <path
        d="M11.9531 5.27694C10.8035 4.36991 9.31639 4.04299 7.86092 4.39104C6.17342 4.78127 4.81291 6.12069 4.40154 7.79768C3.99021 9.48514 4.46482 11.1937 5.67768 12.3855C6.11014 12.818 6.36326 13.4965 6.36326 14.1926V14.3086C6.36326 14.6039 6.59526 14.836 6.8906 14.836H11.1094C11.4047 14.836 11.6367 14.6039 11.6367 14.3086V14.1926C11.6367 13.507 11.9004 12.8074 12.3644 12.3539C13.2504 11.4574 13.7461 10.2656 13.7461 9.00002C13.7461 7.54455 13.0922 6.18397 11.9531 5.27694ZM8.99998 7.41799C8.20439 7.41799 7.62523 7.94572 7.47357 8.55663C7.40455 8.83447 7.12499 9.0118 6.83446 8.94184C6.55226 8.87181 6.37971 8.58546 6.44978 8.30326C6.71106 7.24857 7.70169 6.3633 8.99998 6.3633C9.29146 6.3633 9.52732 6.59916 9.52732 6.89064C9.52732 7.18213 9.29146 7.41799 8.99998 7.41799Z"
        fill="black"
      />
      <path
        d="M11.9531 5.27694C10.8035 4.36991 9.31639 4.04299 7.86092 4.39104C6.17342 4.78127 4.81291 6.12069 4.40154 7.79768C3.99021 9.48514 4.46482 11.1937 5.67768 12.3855C6.11014 12.818 6.36326 13.4965 6.36326 14.1926V14.3086C6.36326 14.6039 6.59526 14.836 6.8906 14.836H11.1094C11.4047 14.836 11.6367 14.6039 11.6367 14.3086V14.1926C11.6367 13.507 11.9004 12.8074 12.3644 12.3539C13.2504 11.4574 13.7461 10.2656 13.7461 9.00002C13.7461 7.54455 13.0922 6.18397 11.9531 5.27694ZM8.99998 7.41799C8.20439 7.41799 7.62523 7.94572 7.47357 8.55663C7.40455 8.83447 7.12499 9.0118 6.83446 8.94184C6.55226 8.87181 6.37971 8.58546 6.44978 8.30326C6.71106 7.24857 7.70169 6.3633 8.99998 6.3633C9.29146 6.3633 9.52732 6.59916 9.52732 6.89064C9.52732 7.18213 9.29146 7.41799 8.99998 7.41799Z"
        fill="url(#paint5_linear_8_59)"
      />
      <path
        d="M6.89062 15.8906V16.418C6.89062 17.2903 7.60029 18 8.47266 18H9.52734C10.3997 18 11.1094 17.2903 11.1094 16.418V15.8906H6.89062Z"
        fill="black"
      />
      <path
        d="M6.89062 15.8906V16.418C6.89062 17.2903 7.60029 18 8.47266 18H9.52734C10.3997 18 11.1094 17.2903 11.1094 16.418V15.8906H6.89062Z"
        fill="url(#paint6_linear_8_59)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8_59"
          x1="3.7796"
          y1="2.50656"
          x2="3.7796"
          y2="5.05263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="0.427083" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8_59"
          x1="1.59961"
          y1="8.47266"
          x2="1.59961"
          y2="9.52734"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="0.427083" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8_59"
          x1="16.4004"
          y1="8.47266"
          x2="16.4004"
          y2="9.52734"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="0.427083" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8_59"
          x1="14.2204"
          y1="2.50656"
          x2="14.2204"
          y2="5.05263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="0.427083" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8_59"
          x1="9"
          y1="0"
          x2="9"
          y2="3.19922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="0.427083" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8_59"
          x1="9.00124"
          y1="4.25476"
          x2="9.00124"
          y2="14.836"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="0.427083" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_8_59"
          x1="9"
          y1="15.8906"
          x2="9"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="0.427083" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
