interface MeasureIndex {
  location: number;
  prefix: string;
}

interface MeasureConfig {
  measureLocation: number;
  prefix: string;
  targetText: string;
  selectionStart: number;
  split: string;
}

export function getBeforeSelectionText(input: HTMLTextAreaElement) {
  const { selectionStart } = input;
  return input.value.slice(0, selectionStart);
}

export function getLastMeasureIndex(
  text: string,
  prefix = "@"
): MeasureIndex {
  let regex;
  switch (prefix) {
    case "":
      regex = /(^|,)/g;
      break;
    case "@":
      regex = /(^|\s)@/g;
      break;
  }
  if (text) {
    const matches = text.matchAll(regex)
    const iteratable = matches && [...matches]
    let lastIndex = iteratable?.length > 0 ? (iteratable[iteratable?.length - 1]?.index || 0) : -1
    if (lastIndex > -1) {
      return {
        location: lastIndex > 0 ? lastIndex + 1 : 0, // "@" index calculation lags by 1. "lastIndexOf" method would give a true value
        prefix: prefix,
      };
    }
  }
  return { location: -1, prefix: "" };
}

export function validateSearch(text: string) {
  return !(/\s/.test(text));
}

export function replaceWithMeasure(text: string, measureConfig: MeasureConfig) {
  const { measureLocation, prefix, targetText, selectionStart, split } =
    measureConfig;
  let beforeMeasureText = text.slice(0, measureLocation);

  // Cut duplicate string with current targetText
  let restText = reduceText(
    text.slice(selectionStart),
    targetText.slice(selectionStart - measureLocation - prefix?.length),
    split
  );
  if (restText.slice(0, split?.length) === split) {
    restText = restText.slice(split?.length);
  }

  const connectedStartText = `${beforeMeasureText}${prefix}${targetText}${split}`;

  return {
    text: `${connectedStartText}${restText}`,
    selectionLocation: connectedStartText?.length,
  };
}

function reduceText(text: string, targetText: string, split: string) {
  const firstChar = text[0];
  if (!firstChar || firstChar === split) {
    return text;
  }

  // Reuse rest text as it can
  let restText = text;
  const targetTextLen = targetText?.length;
  for (let i = 0; i < targetTextLen; i += 1) {
    if (lower(restText[i]) !== lower(targetText[i])) {
      restText = restText.slice(i);
      break;
    } else if (i === targetTextLen - 1) {
      restText = restText.slice(targetTextLen);
    }
  }

  return restText;
}

function lower(char: string | undefined): string {
  return (char || "").toLowerCase();
}

export function setInputSelection(
  input: HTMLTextAreaElement,
  location: number
) {
  input.setSelectionRange(location, location);

  /**
   * Reset caret into view.
   * Since this function always called by user control, it's safe to focus element.
   */
  input.blur();
  input.focus();
}
