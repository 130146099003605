import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { radius } from "theme/foundations/radius";
import { text } from "theme/foundations/text";
import { cssVar } from "@chakra-ui/theme-tools";
import { color } from "theme/names";

const $arrowBg = cssVar("popper-arrow-bg"); // https://github.com/chakra-ui/chakra-ui/issues/4695
// define the base component styles
const baseStyle = {
  px: 4,
  py: 2,
  borderRadius: radius.sm,
  bg: color["text.lightMode.standard"],
  [$arrowBg.variable]: "colors.text.lightMode.standard", // arrow color // specific syntax
  ...text.body.regular.neutral.light,
  color: "white",
  // _dark: {
  //   bg: color["text.lightMode.standard"],
  //   [$arrowBg.variable]: "text.lightMode.standard", // arrow color
  //   ...text.body.regular.neutral.dark,
  //   color: "white",
  // },
};

export const Tooltip = defineStyleConfig({
  baseStyle,
  defaultProps: {
    // @ts-ignore
    hasArrow: true
  },
});
