import { Box, Flex, Text } from "@chakra-ui/layout";
import {
  Button,
  IconButton,
  HStack,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  theme,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import * as Sentry from "@sentry/nextjs";
import Autolinker from 'autolinker';
import { AltTextPopup } from "components/popups/altTextPopup";
import { ImageEditPopup } from "components/popups/imageEditPopup";
import GenerateImage from "components/tweet-composer/generate-image";
import { TwitterCard } from "components/twitter-card";
import { TweetContext } from "context/tweetContext";
import { getTweet } from "controllers/search";
import { firebaseClient } from "firebaseClient";
import { useSession } from "next-auth/client";
import { useContext, useEffect, useRef, useState } from "react";
import { BsCardText } from "react-icons/bs";
import { FaMagic, FaTrash } from "react-icons/fa";
import twitter from "twitter-text";
import urlRegex from "url-regex";
import { formatUrl, isNumeric } from "utils/helpers";
import { getAccount } from "../../../utils/sessionHelper";
import toast from "react-hot-toast"
import { textStyle, color } from "theme/names";

type PreviewKeys = "tweetText" | "cardData" | "medias" | "cardType" | "url";

const StyledText = styled(Text)`
  a {
    color: ${() => theme.colors.twitter[400]};
  }
`;

const Tweet = ({
  text,
  image = "",
  name = "",
  isLast = false,
  isNotThread = false,
  disableNbChar = false,
  disablePreview = false,
  index = 0,
  mode = "tweet",
  isDisablePicture = false,
  preFetchedTweets = [] as any,
  tweet = {} as any,
  showSeeMoreDivider = false,
  dividerCss = {},
  dividerAfterLineCount = 10,
  displayImageOptions = false,
  selectTweetInThread = undefined as any,
  showGenerateImage = false as boolean,
}) => {
  const tweetContext: any = useContext(TweetContext);
  const [data, setData] = useState<any>();
  const [tweetText, setTweetText] = useState(text);
  const [session, loading] = useSession();
  const [medias, setMedias] = useState<string[]>();
  const [cardType, setCardType] = useState<"tweet" | "metacard" | "">("");
  const [nbChar, setNbChar] = useState<number>(0);
  const [maxNbChar, setMaxNbChar] = useState<number>(280);
  const [currentPreviewUrl, setCurrentPreviewUrl] = useState<string>("");
  const [lineHeight, setLineHeight] = useState<number>(0);
  const [lineCount, setLineCount] = useState<number>(0);
  const [marginTop, setMarginTop] = useState<number>(0);
  const [afterLineCount, setAfterLineCount] = useState<number>(dividerAfterLineCount);
  const metaControllerRef = useRef<AbortController | null>();
  const tweetControllerRef = useRef<AbortController | null>();
  const dividerColor = useColorModeValue(color["border.lightMode.hover"], color["border.darkMode.hover"]);
  const tweetId = tweetContext?.textState?.id;
  const tweetTextRef: any = useRef(null);
  let bgBox = useColorModeValue("border.lightMode.light", "border.darkMode.light");

  // handle entities
  tweet?.urls?.forEach(url => {
    text = text.replace(url.url, url.display_url);
  });
  tweet?.entities?.media?.forEach(media => {
    // console.log(media.url);
    text = text.replace(media.url, "");
  });
  text = text.replace(/https:\/\/t\.co\/\S+\s*$/g, "");
  // console.log("text: " + text);

  if (tweet?.media?.length > 0) {
    tweet?.media.forEach(media => {
      text += "[img:" + (media.url ?? media.preview_image_url) + "]";
    })
  }

  useEffect(() => {
    try {
      let textCount = text;
      let matchs = text.match(/(?:\[img:)(.*?)(?=\])/g);
      const medias: string[] = [];
      let tweetTextCopy = text;

      if (matchs && matchs?.length > 0) {
        matchs.forEach(match => {
          textCount = textCount.replace(match + "]", "");
          match = match.replace("[img:", "");
          medias.push(match?.includes("http") ? match : "https://ez4cast.s3.eu-west-1.amazonaws.com/userUpload/" + match);
          tweetTextCopy = tweetTextCopy.replace("[img:" + match + "]", "");
        });
      }
      setMedias(medias)
      setTweetText(tweetTextCopy)
      setNbChar(twitter.parseTweet(tweetTextCopy).weightedLength);

      fetchImageAltText(medias)

      let urls: string[] = []
      const arrayOfTexts = text.split(/\s/g);
      arrayOfTexts.forEach(t => {
        if (urlRegex({ strict: false, exact: true }).test(t)) {
          urls.push(t);
        }
      })

      if (urls?.length > 0) {
        urls.forEach(url => {
          tweetTextCopy = tweetTextCopy.replace(url, formatUrl(url))
        })
      }

      tweetTextCopy = tweetTextCopy.replace("[tweet]", `https://twitter.com/${getAccount(session)?.twUserName}/status/...`);

      setTweetText(tweetTextCopy)

      if (medias?.length === 0 && urls?.length > 0) {
        const twitterUrls = urls.filter(url => (url.includes("twitter.com") || url.includes("x.com")) && url.includes("/status/"))
        if (twitterUrls?.length > 0) {
          const selectedUrl = twitterUrls[twitterUrls?.length - 1];

          if (selectedUrl !== currentPreviewUrl) {
            setCurrentPreviewUrl(selectedUrl);
            let formattedUrl = selectedUrl.split("?")[0];
            let split = formattedUrl.split("/status/")
            if (isNumeric(split[split?.length - 1])) {
              if (tweetControllerRef.current) {
                tweetControllerRef.current?.abort();
              }
              fetchTweet(split[split?.length - 1], tweetTextCopy, selectedUrl);
            }
          } else {
            removeUrlIfLast(tweetTextCopy, selectedUrl)
          }
        } else {
          if (!disablePreview) {
            const selectedUrl = urls[urls?.length - 1];

            if (selectedUrl !== currentPreviewUrl) {
              if (metaControllerRef.current) {
                metaControllerRef.current?.abort();
              }
              setCurrentPreviewUrl(selectedUrl)
              fetchMetaData(selectedUrl, tweetTextCopy)
            } else {
              removeUrlIfLast(tweetTextCopy, selectedUrl)
            }
          } else {
            setData(null);
            setCardType("");
            setCurrentPreviewUrl("");
          }
        }
      }

      if (!urls || urls?.length === 0) {
        if (metaControllerRef.current) {
          metaControllerRef.current?.abort();
        }
        if (tweetControllerRef.current) {
          tweetControllerRef.current.abort();
        }
        setCurrentPreviewUrl("");
        setData(null);
        setCardType("");
      }
    }
    catch (e) {
      console.log("Error in threadTweet: " + e.message);
      Sentry.captureException(e);
    }
  }, [text, disablePreview])

  // calculate line count
  useEffect(() => {
    if (!showSeeMoreDivider) {
      return;
    }
    const lineHeight = +window
      .getComputedStyle(tweetTextRef.current)
      .getPropertyValue("line-height").replace("px", "");
    const divHeight = +tweetTextRef.current.offsetHeight;
    const marginTop = +window
      .getComputedStyle(tweetTextRef.current)
      .getPropertyValue("margin-top").replace("px", "");

    setLineHeight(lineHeight);
    setLineCount(divHeight / lineHeight)
    setMarginTop(marginTop)
  }, [tweetText, dividerAfterLineCount]);

  useEffect(() => {
    setMaxNbChar((getAccount(session).isPremium) ? 25000 : 280)
  }, [text, tweetText, session]);

  const fetchTweet = async (tweetId: string, tweetText: string, url: string) => {
    const tweetController = new AbortController();
    tweetControllerRef.current = tweetController;

    let newTweet;

    newTweet = preFetchedTweets.find(t => t.id_str === tweetId);

    if (!newTweet) {
      const tweet = await getTweet(session, tweetId, tweetController.signal);
      newTweet = tweet?.tweet;
    }

    setData(newTweet || {});
    setCardType("tweet");

    removeUrlIfLast(tweetText, url);
  }

  const removeUrlIfLast = (tweetText: string, url: string) => {
    const trimText = tweetText.trim()
    const formattedUrl = formatUrl(url);
    const startLength = trimText?.length - formattedUrl?.length;

    if (trimText.trim().substring(startLength, trimText?.length) === formattedUrl) {
      setTweetText(trimText.substring(0, startLength - 1));
    }
  }

  async function fetchMetaData(url: string, tweetText: string) {
    if (disablePreview) {
      return;
    }

    const metaController = new AbortController();
    metaControllerRef.current = metaController;

    try {
      const response = await fetch("/api/metadata", {
        signal: metaControllerRef.current?.signal,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url }),
      });
      const data = await response.json()
      if (data.success) {
        delete data.success;
        setCardType("metacard");
        setData(data);
        removeUrlIfLast(tweetText, url)
      } else {
        setData(null);
        setCardType("");
      }
    }
    catch (e) {
      console.log("Error in fetchMetaData: " + e.message);
      setData(null);
      setCardType("");
    }
  }

  const handleGeneratedImage = async (fileName: string) => {
    if (tweetContext.refComposer && tweetContext.refComposer.current) {
      tweetContext.refComposer.current.pushOnThread(`[img:${fileName}]`, index);
    } else {
      console.error('handleGeneratedImage: tweetContext.refComposer.current is not initialized');
    }
  }


  const [altTextMap, setAltTextMap] = useState<Record<string, string>>({})

  const fetchImageAltText = async (currMedias: string[]) => {

    // check if currMedias is different than medias
    if (medias?.length === currMedias.length && currMedias.every((value, index) => value === medias[index])) {
      return
    }

    if (!currMedias.length) {
      return
    }

    const mediaIds = currMedias.map(media => media.split("/userUpload/")[1])

    try {
      const db = firebaseClient.firestore();
      const docs = await db.collection("twitterMedias").where("id", "in", mediaIds).get()

      const currAltTextMap: Record<string, string> = {}
      docs.forEach(doc => {
        const data = doc.data()
        currAltTextMap[data.id] = data.altText
      })

      // return currAltTextMap
      setAltTextMap(currAltTextMap)
    } catch (error) {
      const newError = new Error('Error in fetchImageAltText: ' + error.message);
      newError.stack = error.stack; // Preserve the original stack trace
      console.error(newError); // Log the new error
      return
    }
  }

  return (
    <Flex justifyContent="start" w="100%" ml={0}
      onClick={() => {
        console.log("click");
        selectTweetInThread && selectTweetInThread(text, tweet);
      }}
    >
      {
        !isDisablePicture && (
          <Flex justifyContent="start" alignItems="center" flexDirection="column" m={0}>
            <Box>
              <Image
                width={10}
                height={10}
                borderRadius={20}
                ml={0}
                //@ts-ignore
                src={image}
                fallbackSrc="/assets/resources/emptyProfile.png"
              />
            </Box>
            {
              !isLast && mode == "tweet" && (
                <Box
                  m={2}
                  bg={bgBox}
                  h="100%"
                  w="2px"
                >
                </Box>
              )
            }
          </Flex>
        )
      }
      <Flex
        flexDirection="column"
        w={mode == "blog" ? "100%" : "85%"}
        m={mode == "blog" ? 0 : 2}
        mt={mode == "blog" ? 0 : 2}
        position="relative"
      >

        {
          !isDisablePicture && (mode == "tweet" || index == 0) && (
            <Text fontWeight="700" maxW="180px" noOfLines={1} wordBreak="break-all">
              {name}
            </Text>
          )
        }
        {/* <Text 
            mt={2} 
            mb={5} 
            whiteSpace="pre-line"
            dir={getAccount(session)?.isRtl ? "rtl" : "ltr"}
          >
              {tweetText}
          </Text> */}

        {/* <Box
            mt={2} 
            mb={5} 
            whiteSpace="pre-line"
          >
            <Linkify properties={{target: '_blank', style: {color: theme.colors.twitter[400]}}}>{tweetText}</Linkify>
          </Box> */}

        <StyledText
          ref={tweetTextRef}
          mt={mode == "blog" ? 0 : 2}
          mb={5}
          whiteSpace="pre-line"
          dir={getAccount(session)?.isRtl ? "rtl" : "ltr"}
          dangerouslySetInnerHTML={{
            __html: Autolinker.link(tweetText, { newWindow: true }),
          }}
        />
        {showSeeMoreDivider && lineCount > afterLineCount && (
          <Flex
            position="absolute"
            top={lineHeight * afterLineCount + marginTop + 0 + "px"}
            flexDir="column"
            alignItems="flex-end"
            {...dividerCss}
          >
            <Text
              mr={-0.5}
              bg="transparent"
              textStyle={textStyle["body.medium.light"]}
              fontSize="xs"
            >
              ...show more
            </Text>
            <Box
              border={"dashed 1px"}
              borderColor={dividerColor}
              w="100%"
              h="1px"
            />
          </Flex>
        )}
        {showGenerateImage && !text.includes("[img:") && <GenerateImage content={text} onImageGenerated={handleGeneratedImage} isHorizontal />}
        {
          !!medias?.length ? medias?.map((media, index) => (
            <Box key={media}>
              {
                media.includes("vid-") ? (
                  <video style={{ marginBottom: "30px", borderRadius: "10px" }} controls>
                    <source src={media} />
                  </video>
                ) : (
                  <ImageWithOptions
                    mediaUrl={media}
                    displayOptions={displayImageOptions}
                    tweetId={tweetId}
                    altText={altTextMap?.[media.split("/userUpload/")[1]]}
                  />
                )
              }
            </Box>
          )) : (cardType && data) ? (
            <Box mb={disableNbChar ? 2 : 6}>
              <TwitterCard
                type={cardType}
                data={data}
                isRetweet={isNotThread && cardType === "tweet" && currentPreviewUrl === text?.trim()}
              />
            </Box>) : null
        }
        {
          !disableNbChar && (
            <Text
              textAlign={"end"}
              color={nbChar > maxNbChar ? "red" : "gray.400"}
              fontWeight={nbChar > maxNbChar ? "bold" : "regular"}
            >
              {nbChar}
            </Text>
          )
        }
      </Flex>
    </Flex>
  );
};

export default Tweet;

interface ImageWithOptions {
  mediaUrl: string;
  displayOptions: boolean;
  onChange?: () => void;
  tweetId: string;
  altText?: string;
}

export function ImageWithOptions({
  tweetId, mediaUrl, displayOptions, onChange = () => { }, altText
}: ImageWithOptions) {
  const tweetContext: any = useContext(TweetContext);
  const [queryString, setQueryString] = useState(`#dt=${Math.floor(Math.random() * 100000).toString()}`);
  const [refComposer, setRefComposer] = useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isAltTextPopupOpen, onOpen: openAltTextPopup, onClose: closeAltTextPopup } = useDisclosure();

  const iconButtonBg = useColorModeValue("gray.100", "gray.600")
  const iconButtonHoverBg = useColorModeValue("gray.200", "gray.700");
  const isNotGif = !mediaUrl.includes("gif-")
  const imageId = mediaUrl.split("/userUpload/")[1]

  const handleRemoveImage = () => {
    if (refComposer) {
      const textState = refComposer.textState();
      textState.text = textState.text.replace(`[img:${imageId}]`, "");
      refComposer.editText(textState.text);
    } else {
      toast.error('Something went wrong, please try again');
    }
  }

  const handleImageEdit = (newImageId) => {
    if (refComposer) {
      const textState = refComposer.textState();
      textState.text = textState.text.replace(imageId, newImageId);
      refComposer.editText(textState.text);
    } else {
      toast.error('Something went wrong, please try again');
    }
  }
  useEffect(() => {
    if (tweetContext.refComposer && tweetContext.refComposer.current) {
      setRefComposer(tweetContext.refComposer.current)
    }
  }, [tweetContext.refComposer])

  return (
    <Box position="relative">
      <Image
        objectFit="cover"
        src={mediaUrl + queryString} // to fetch the new edited image
        mb={2}
        borderRadius={8}
      />
      {/* {altText?.length && <Text>{altText}</Text>} */}

      {!displayOptions && !!altText?.length &&
        <Popover placement="left">
          <PopoverTrigger>
            <IconButton
              size="sm"
              aria-label="alt Text"
              icon={<BsCardText />}
              position="absolute"
              bottom="2"
              right="2"
              borderRadius="full"
              bg={iconButtonBg}
              _hover={{
                bg: iconButtonHoverBg
              }}
            />
          </PopoverTrigger>
          <PopoverContent maxW={"calc(100% - 4rem)"} ml='auto'>
            <PopoverBody>
              <Text>{altText?.length ? altText : "No alt text"}</Text>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      }

      {
        displayOptions &&
        <HStack
          position="absolute"
          bottom={2}
          right={2}
          bgColor={"blackAlpha.300"}
          backgroundBlendMode={"multiply"}
          borderRadius={"xl"}
          p={2}
          backdropFilter={"blur(2px)"}>
          <Button
            size="xs"
            fontSize={"xs"}
            aria-label="delete"
            leftIcon={<FaTrash />}
            onClick={handleRemoveImage}
            variant={"secondary"}
          >Delete</Button>
          {isNotGif && (
            <>
              <Button
                size="xs"
                aria-label="edit"
                leftIcon={<FaMagic />}
                onClick={onOpen}
                variant={"secondary"}
                fontSize={"xs"}
              >
                Edit
              </Button>


              <ImageEditPopup
                isOpen={isOpen}
                onClose={onClose}
                imageId={imageId}
                onSave={(newMedia) => {
                  if (newMedia) {
                    setQueryString(`#dt=${Math.floor(Math.random() * 100000).toString()}`)
                    handleImageEdit(newMedia.id);
                    onChange();
                    onClose();
                  }
                }}
              />
            </>
          )}
        </HStack>
      }

      {isNotGif && <><IconButton
        size="sm"
        aria-label="alt text"
        icon={<BsCardText />}
        position="absolute"
        bottom="2"
        left="2"
        borderRadius="full"
        onClick={openAltTextPopup}
        bg={iconButtonBg}
        _hover={{
          bg: iconButtonHoverBg
        }}
      />
        <AltTextPopup
          isOpen={isAltTextPopupOpen}
          onClose={closeAltTextPopup}
          imageId={imageId}
        /></>
      }
    </Box>
  )
}