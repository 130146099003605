import React from 'react';
import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/popover";
import { Box, Center, Divider, Flex, Stack, Text } from "@chakra-ui/layout";
import { 
  useDisclosure, 
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  FormControl,
  Input,
  ModalCloseButton,
  FormLabel,
  ModalFooter,
  Select,
  Button,
} from "@chakra-ui/react"
import  FocusLock from "react-focus-lock";
import { getList, toggleSaveTweetInList } from "../../controllers/bookmark";
import { firebaseClient } from "../../firebaseClient";
import { useSession } from "next-auth/client";
import { getAccount, updateUser } from "../../utils/sessionHelper";
import { SaveIcon } from 'components/icons/SaveIcon';


export const UpsertCollection = ({isOpen, onClose, isUpdate=false, id=-1, name="My Collection", refresher}) => {
    const [session, loading] = useSession();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [text, setText] = React.useState(name);

    const save = async () => {
      setIsLoading(true)
      if (session?.user?.data) {

        let nbListsCreated = getAccount(session).nbListsCreated ? getAccount(session).nbListsCreated : 1;
        let lists = getAccount(session).lists;
        
        if (isUpdate) {
          let list = lists.find(x => x.id === id);
          list.name = text;
        }
        else {
          //@ts-ignore
          nbListsCreated++;
          let nextId = getAccount(session).lists && getAccount(session).lists?.length > 0 ? Math.max(...getAccount(session).lists.map(x => x.id)) + 1 : 2;
          nextId = Math.max(2, nextId);
          lists.push({name: text, id: nextId});
        }

        // const db = firebaseClient.firestore();
        // await db.collection("users").doc(session.user.uid).update({lists: lists, nbListsCreated: nbListsCreated});
        await updateUser(session, {lists: lists, nbListsCreated: nbListsCreated});
      }

      //@ts-ignore
      if (refresher)
        //@ts-ignore
        await refresher({});

      onClose();
      setIsLoading(false)
    } 

    return (
        <Box>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{isUpdate ? "Update collection" : "Create collection"}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody pb={6}>
                    <FormControl
                      as="form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        save();
                      }}
                    >
                      <FormLabel>Your collection's name</FormLabel>
                      <Input placeholder="My awesome collection" defaultValue={name} onChange={(e) => setText(e.target.value)} />
                    </FormControl>
                  </ModalBody>

                  <ModalFooter>
                    <Button variant="tertiary" onClick={onClose}>Cancel</Button>
                    <Button variant="primary" onClick={save} isLoading={isLoading}
                      leftIcon={SaveIcon}
                    >Save</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
        </Box>
    )

} 