import { mode } from "@chakra-ui/theme-tools";
// https://chakra-ui.com/docs/styled-system/customize-theme#scaling-out-your-project
// # all my global style overrides
const styles = {
  global: (props) => ({
    body: {
      bg: mode(
        "url('./theme/grain-texture.png')",
        "url('./theme/grain-texture-dark.png')"
      )(props),
    },
  }),
};

export { styles };
