import { Box, Text } from "@chakra-ui/react";

interface Props {
  type: string;
  value: number;
  endsWithColon?: boolean;
}

export function TimeCounter({ type, value, endsWithColon = true }: Props) {
  return (
    <>
      <Box textAlign="center">
        <Text fontWeight="bold" fontSize="3xl">
          {value.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}
        </Text>
        <Text fontSize="xs" mt="-1">
          {type}
        </Text>
      </Box>
      {endsWithColon && (
        <Text fontWeight="bold" fontSize="4xl" mx="2">
          :
        </Text>
      )}
    </>
  );
}
