import React, { createContext, useState, useEffect } from 'react';
import {
    Button,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Box,
    Flex,
    Text,
    Tag,
    Link,
    FlexProps
} from "@chakra-ui/react";
import { useSession } from "next-auth/client";
import { useRouter } from "next/dist/client/router";
import toast from "react-hot-toast";
import { getAccount, updateUser, getAuthUrl } from 'utils/sessionHelper';
import { useFirebaseUser } from "utils/useFirebaseUser";

interface Props {
    isInPopup?: boolean
    footerCss?: FlexProps
}

export function AuthCustom({ isInPopup = true, footerCss = {} }: Props) {

    const [isSendingKeys, setIsSendingKeys] = useState<boolean>(false);
    const [clientId, setClientId] = useState("");
    const [clientSecret, setClientSecret] = useState("");
    const [hasLaunchedAuth, setHasLaunchedAuth] = useState(false);
    const [session, loading] = useSession();
    const router = useRouter();
    const isAuthenticated = useFirebaseUser();
    const [hasFetched, setHasFetched] = useState(false);

    const sendAuth = async () => {
      try {
        if (!clientId || !clientSecret) {
          toast.error("Please enter both values.")
          return;
        }

        if (!isAuthenticated) {
          toast.error("You are not authenticated");
          return;
        }

        setHasLaunchedAuth(true);
        setIsSendingKeys(true);
        await updateUser(session, { thCustomClientId: clientId, thCustomClientSecret: clientSecret });
      } catch (e) {
        console.error("Failed to send: ", e);
      } finally {
        setIsSendingKeys(false);
      }
    }

    useEffect(() => {
      if (session && isAuthenticated && !hasFetched) {
        setHasFetched(true);
        setClientId(getAccount(session)?.thCustomClientId || "");
        setClientSecret(getAccount(session)?.thCustomClientSecret || "");
      }
    }, [session, isAuthenticated])

    return (
        <Box>
            <Box>
                <VStack spacing="5">
                <Text>Get 1 month free by using your own Twitter API key (it’s free). <Link textDecoration="underline" href="https://ponyexpress.notion.site/Using-my-own-Twitter-API-key-with-Tweet-Hunter-92b1cc3477dd49b1890c2f762962c28f" isExternal>Here’s how to set it up.</Link> {isInPopup && "You may also configure this later in your settings."}</Text>
                    <FormControl>
                        <FormLabel>API Key</FormLabel>
                        <Input value={clientId} onChange={e => setClientId(e.target.value)} placeholder="3ew6vxvfak4kokjrwpva9pkap3vf02b0m5" />
                    </FormControl>
                    <FormControl>
                        <FormLabel>API Key Secret</FormLabel>
                        <Input value={clientSecret} onChange={e => setClientSecret(e.target.value)} placeholder='b354xv2ytimybux697lmea5zp9lsb05yxgz1o0zom7ypmpa7u9' />
                    </FormControl>
                </VStack>
            </Box>
            {
                hasLaunchedAuth && (
                    getAccount(session)?.thApp == "CUSTOM" && getAccount(session)?.thCustomClientId && getAccount(session)?.thCustomClientSecret ? (
                        <Tag colorScheme="green" mt={4}>Custom Twitter integration correctly setup. {isInPopup && "You can close this now."}</Tag>
                    ) : (
                        <Tag colorScheme="red" mt={4}>⚠️ Invalid integration - please try again or refresh this page</Tag>
                    )
                )
            }
            <Flex mt={8} mb={4} w="100%" justifyContent="flex-end" {...footerCss}>
                <Button variant={"primary"} 
                    isLoading={isSendingKeys} 
                    onClick={sendAuth} 
                    isDisabled={!clientId || !clientSecret}
                    as="a" 
                    href={"https://authcustom.tweethunter.io/signth?masterUserId=" + session?.user?.uid + "&clientId=" + clientId + "&clientSecret=" + clientSecret}
                    target="_blank"
                >
                    Launch Twitter Authorization
                </Button>
            </Flex>
        </Box>
    );
}