export function Improve() {
  return(<svg width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.75 1.15625C7.78125 1.0625 7.875 1 8 1C8.09375 1 8.1875 1.0625 8.21875 1.15625L8.65625 2.34375L9.84375 2.78125C9.9375 2.8125 10 2.90625 10 3C10 3.125 9.9375 3.21875 9.84375 3.25L8.65625 3.6875L8.21875 4.84375C8.1875 4.9375 8.09375 5 8 5C7.875 5 7.78125 4.9375 7.75 4.84375L7.3125 3.6875L6.125 3.25C6.03125 3.21875 6 3.125 6 3C6 2.90625 6.03125 2.8125 6.125 2.78125L7.3125 2.34375L7.75 1.15625ZM15.4688 1.46875L16.5312 2.53125C17.125 3.125 17.125 4.0625 16.5312 4.65625L4.625 16.5625C4.03125 17.1562 3.09375 17.1562 2.5 16.5625L1.4375 15.5C0.84375 14.9062 0.84375 13.9688 1.4375 13.375L13.3438 1.46875C13.9375 0.875 14.875 0.875 15.4688 1.46875ZM14.4062 2.5L10.9688 5.9375L12.0625 7.03125L15.4688 3.59375L14.4062 2.5ZM3.5625 15.5L11 8.09375L9.90625 7L2.5 14.4375L3.5625 15.5ZM0.21875 4.6875L2 4L2.65625 2.25C2.6875 2.09375 2.84375 2 3 2C3.125 2 3.28125 2.09375 3.3125 2.25L4 4L5.75 4.6875C5.90625 4.71875 6 4.875 6 5C6 5.15625 5.90625 5.3125 5.75 5.34375L4 6L3.3125 7.78125C3.28125 7.90625 3.125 8 3 8C2.84375 8 2.6875 7.90625 2.65625 7.78125L2 6L0.21875 5.34375C0.0625 5.3125 0 5.15625 0 5C0 4.875 0.0625 4.71875 0.21875 4.6875ZM11.2188 12.6875L13 12L13.6562 10.25C13.6875 10.0938 13.8438 10 14 10C14.125 10 14.2812 10.0938 14.3125 10.25L15 12L16.75 12.6875C16.9062 12.7188 17 12.875 17 13C17 13.1562 16.9062 13.3125 16.75 13.3438L15 14L14.3125 15.7812C14.2812 15.9062 14.125 16 14 16C13.8438 16 13.6875 15.9062 13.6562 15.7812L13 14L11.2188 13.3438C11.0625 13.3125 11 13.1562 11 13C11 12.875 11.0625 12.7188 11.2188 12.6875Z" fill="url(#gradient)"/>
  <linearGradient
          id="gradient"
          x1="-5"
          y1="-5"
          x2="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
    </svg>
  )
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 3.5C12.25 2.53347 13.0335 1.75 14 1.75C13.0335 1.75 12.25 0.9665 12.25 0C12.25 0.966471 11.4665 1.75 10.5 1.75C11.4664 1.75 12.25 2.53344 12.25 3.5Z"
        fill="black"
      />
      <path
        d="M12.25 3.5C12.25 2.53347 13.0335 1.75 14 1.75C13.0335 1.75 12.25 0.9665 12.25 0C12.25 0.966471 11.4665 1.75 10.5 1.75C11.4664 1.75 12.25 2.53344 12.25 3.5Z"
        fill="url(#paint0_linear_8_19)"
      />
      <path
        d="M12.6875 5.24997C12.6875 5.97483 12.0998 6.56246 11.375 6.56246C12.0999 6.56246 12.6875 7.15009 12.6875 7.87496C12.6875 7.15007 13.2751 6.56246 14 6.56246C13.2751 6.56249 12.6875 5.97486 12.6875 5.24997Z"
        fill="black"
      />
      <path
        d="M12.6875 5.24997C12.6875 5.97483 12.0998 6.56246 11.375 6.56246C12.0999 6.56246 12.6875 7.15009 12.6875 7.87496C12.6875 7.15007 13.2751 6.56246 14 6.56246C13.2751 6.56249 12.6875 5.97486 12.6875 5.24997Z"
        fill="url(#paint1_linear_8_19)"
      />
      <path
        d="M6.12499 5.24997C6.12499 3.80022 7.30022 2.62499 8.74997 2.62499C7.30022 2.62499 6.12499 1.44975 6.12499 0C6.12499 1.44975 4.94975 2.62499 3.5 2.62499C4.94975 2.62499 6.12499 3.80025 6.12499 5.24997Z"
        fill="black"
      />
      <path
        d="M6.12499 5.24997C6.12499 3.80022 7.30022 2.62499 8.74997 2.62499C7.30022 2.62499 6.12499 1.44975 6.12499 0C6.12499 1.44975 4.94975 2.62499 3.5 2.62499C4.94975 2.62499 6.12499 3.80025 6.12499 5.24997Z"
        fill="url(#paint2_linear_8_19)"
      />
      <path
        d="M9.62499 2.625L0 12.25L1.75 14L11.375 4.375L9.62499 2.625ZM8.13133 5.43115L9.36861 4.19386L9.80612 4.63137L8.56883 5.86866L8.13133 5.43115Z"
        fill="black"
      />
      <path
        d="M9.62499 2.625L0 12.25L1.75 14L11.375 4.375L9.62499 2.625ZM8.13133 5.43115L9.36861 4.19386L9.80612 4.63137L8.56883 5.86866L8.13133 5.43115Z"
        fill="url(#paint3_linear_8_19)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8_19"
          x1="12.25"
          y1="0"
          x2="12.25"
          y2="3.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8_19"
          x1="12.6875"
          y1="5.24997"
          x2="12.6875"
          y2="7.87496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8_19"
          x1="6.12499"
          y1="0"
          x2="6.12499"
          y2="5.24997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8_19"
          x1="5.68749"
          y1="2.625"
          x2="5.68749"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.75 5.5C13.75 6.21875 13.1875 6.75 12.5 6.75C11.7812 6.75 11.25 6.21875 11.25 5.5C11.25 4.8125 11.7812 4.25 12.5 4.25C13.1875 4.25 13.75 4.8125 13.75 5.5ZM13 10.7812V13.3438C13 14.125 12.5625 14.875 11.875 15.2812L9.125 16.9062C8.875 17.0312 8.59375 17.0625 8.375 16.9062C8.125 16.7812 8 16.5312 8 16.25V12.6875C8 11.9688 7.71875 11.2812 7.21875 10.7812C6.71875 10.2812 6.03125 10 5.3125 10H1.75C1.46875 10 1.21875 9.875 1.09375 9.625C0.9375 9.40625 0.9375 9.125 1.09375 8.875L2.71875 6.125C3.125 5.4375 3.875 5 4.65625 5H7.21875C9.78125 0.90625 13.75 0.75 16.0938 1.1875C16.4688 1.25 16.75 1.53125 16.8125 1.90625C17.25 4.25 17.0938 8.21875 13 10.7812ZM7.15625 8.9375C7.5625 9.15625 7.9375 9.40625 8.25 9.71875C8.59375 10.0625 8.84375 10.4375 9.0625 10.8438C12.3438 9.90625 13.9375 8.34375 14.7188 6.875C15.5 5.4375 15.5625 3.84375 15.4062 2.59375C14.1562 2.4375 12.5625 2.5 11.0938 3.28125C9.65625 4.0625 8.09375 5.65625 7.15625 8.9375ZM2.28125 11.8125C3.375 10.75 5.125 10.75 6.1875 11.8125C7.25 12.875 7.25 14.625 6.1875 15.6875C4.65625 17.25 1 17 1 17C1 17 0.75 13.3438 2.28125 11.8125ZM3 15C3 15 4.21875 15.0938 4.71875 14.5625C5.09375 14.2188 5.09375 13.625 4.71875 13.2812C4.375 12.9062 3.78125 12.9062 3.4375 13.2812C2.90625 13.7812 3 15 3 15Z" fill="url(#paint0_linear_8_19"/>
      <defs>
        <linearGradient
          id="paint0_linear_8_19"
          x1="12.25"
          y1="0"
          x2="12.25"
          y2="3.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8_19"
          x1="12.6875"
          y1="5.24997"
          x2="12.6875"
          y2="7.87496"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8_19"
          x1="6.12499"
          y1="0"
          x2="6.12499"
          y2="5.24997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8_19"
          x1="5.68749"
          y1="2.625"
          x2="5.68749"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
