export function CloseLibrary() {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.54095 4.65475C3.57777 4.66094 3.61504 4.6638 3.65233 4.6633L10.0017 4.6633L9.8632 4.73004C9.72787 4.79643 9.60476 4.88679 9.49937 4.99702L7.71886 6.84247C7.48437 7.07449 7.44496 7.44773 7.62549 7.72682C7.8356 8.02423 8.23853 8.0888 8.52549 7.87103C8.54867 7.85343 8.5707 7.83424 8.59141 7.81359L11.8111 4.47642C12.0628 4.21591 12.063 3.79332 11.8116 3.53252L8.59141 0.194827C8.33959 -0.0654516 7.93187 -0.0648689 7.68073 0.19614C7.66097 0.216684 7.64252 0.238543 7.62549 0.261569C7.44496 0.540661 7.48437 0.913903 7.71886 1.14592L9.49615 2.99471C9.59063 3.09274 9.69926 3.17494 9.81813 3.23833L10.0113 3.32843L3.68776 3.32843C3.35881 3.31577 3.0702 3.55394 3.0084 3.88908C2.95147 4.25293 3.18989 4.59572 3.54095 4.65475Z"
        fill="currentColor"
        // fill="url(#paint0_linear_1902_5063)"
      />
      <line x1="0.75" y1="3.27855e-08" x2="0.75" y2="8"
        // stroke="url(#paint0_linear_1902_5063)"
        stroke="currentColor"
        strokeWidth="1.5" />
      {/* <line x1="0.75" y1="3.27855e-08" x2="0.75" y2="8" stroke="#606AE1" strokeWidth="1.5" /> */}
      <defs>
        <linearGradient
          id="paint0_linear_1902_5063"
          x1="0"
          y1="0"
          x2="12"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
