import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSession } from "next-auth/client";
import { v4 as uuid } from "uuid";
import moment from "moment";
import * as Sentry from "@sentry/nextjs";
import { firebaseClient } from "firebaseClient";
import { useFirebaseUser } from "utils/useFirebaseUser";
import { useMiscellaneousContext } from "context/miscellaneousContext";

export interface EnterpriseCampaign {
  id: string;
  startDate: Date;
  endDate: Date;
  userAcquisition: number;
  type: "enterprise-campaign";
}

export function useEnterpriseCompaign() {
  const [activeCampaign, setActiveCampaign] = useState<EnterpriseCampaign>();
  const [campaigns, setCampaigns] = useState<EnterpriseCampaign[]>([]);
  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const [session] = useSession();
  const isAuthenticated = useFirebaseUser();
  const miscellaneousContext: any = useMiscellaneousContext();

  const activateCampaign = async (startTime: Date, duration: number) => {
    try {
      const db = firebaseClient.firestore();
      const data: EnterpriseCampaign = {
        id: uuid(),
        type: "enterprise-campaign",
        startDate: startTime < new Date() ? new Date() : startTime,
        endDate: moment(startTime).add(duration, "h").toDate(),
        userAcquisition: 0,
      };

      await db.collection("miscellaneous").doc(data.id).set(data);
      setCampaigns([...campaigns, data]);

      toast.success("Successfully started Enterprise compaign");
    } catch (err) {
      toast.error("Error in activating campaign: " + err.message);
      console.log("Error in activating campaign: ", err);
    }
  };

  const getCampaigns = async () => {
    try {
      if (miscellaneousContext.campaigns?.length > 0) {
        setCampaigns(miscellaneousContext.campaigns);
        return;
      }

      const db = firebaseClient.firestore();
      const docs = await db
        .collection("miscellaneous")
        .where("type", "==", "enterprise-campaign")
        .get();

      let campaigns: any[] = [];
      docs.forEach((doc) => {
        campaigns.push(doc.data());
      });

      setCampaigns(campaigns);
      miscellaneousContext.setCampaigns(campaigns);
    } catch (err) {
      toast.error("Failed to fetch campaigns: " + err.message);
      console.error("Failed to fetch campaigns: ", err);
    }
  };

  const updateUserAcquisition = async () => {
    try {
      const userAcquisitionCounter = activeCampaign?.userAcquisition ?? 0;

      const db = firebaseClient.firestore();
      await db
        .collection("miscellaneous")
        .doc(activeCampaign?.id)
        .update({ userAcquisition: userAcquisitionCounter + 1 });
    } catch (err) {
      console.log("Error in updating user acquistion: ", err);
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    if (campaigns?.length > 0) {
      const currentlyRunning = campaigns.filter(
        // @ts-ignore
        (c) => new Date() < (c.endDate.toDate ? c.endDate.toDate() : c.endDate)
      );

      if (currentlyRunning?.length === 1) {
        setActiveCampaign(currentlyRunning[0]);
      }
    }
  }, [campaigns]);

  useEffect(() => {
    if (session && isAuthenticated && !hasFetched) {
      setHasFetched(true);
      getCampaigns();
    }
  }, [session, isAuthenticated]);

  return { campaigns, activeCampaign, activateCampaign, updateUserAcquisition };
}
