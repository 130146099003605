import { Box, Text, Divider, VStack, Flex } from "@chakra-ui/react";
import { GRADIENT_COLOR, homeScreenSections, Prompt } from "./utils";
import { textStyle } from "theme/names";
import { colors } from "theme/colors/colors";
import { ActionCaretRight } from "./icons/ActionCaretRight";
import { hexToHexa } from "utils/helpers";
import { actionAI } from "theme/components/button/action";

interface Props {
  onSelectPrompt: (prompt: Prompt) => void;
}

export function HomeScreen({ onSelectPrompt }: Props) {
  return (
    <>
      <Box>
        <Text
          mt={4}
          textStyle={textStyle["h1.bold.standard"]}
          textAlign="center"
        >
          Welcome to Chat Assist
        </Text>
        <Text
          textStyle={textStyle["body.medium.light"]}
          fontSize="md"
          lineHeight="1.5"
          textAlign="center"
          maxW="450px"
          mx="auto"
          mt="6"
        >
          Your{" "}
          <Text
            as="span"
            bgClip="text"
            bgGradient={GRADIENT_COLOR}
            fontWeight="semibold"
          >
            AI-powered
          </Text>{" "}
          chat assistant to help you create better tweets, replies and DMs.
        </Text>
        <Divider w="33%" mx="auto" mt="5" mb="4" />
      </Box>

      <Box overflowY="auto" flexGrow="1" className="noScrollBar">
        <VStack spacing={3} width="100%" h="fit-content">
          {homeScreenSections.map((section) => (
            <Box key={section.title} w="100%" px={["2", "12"]} py="2">
              <Box>
                <Flex
                  gap="2"
                  alignItems="center"
                  textStyle={textStyle["h4.bold.standard"]}
                  color="text.lightMode.light"
                  _dark={{
                    color: "text.darkMode.light",
                  }}
                >
                  {section.icon}
                  {section.title}
                </Flex>
                <Box fontSize="sm" mt="4" lineHeight="1.5">
                  {section.prompts.map((prompt, index) => (
                    <Flex
                      key={index}
                      {...actionAI}
                      gap="3"
                      alignItems="center"
                      px="3"
                      py="1.5"
                      cursor="pointer"
                      color="text.lightMode.light"
                      _hover={{
                        color: colors.ai[700],
                        background: colors.ai[50],
                      }}
                      _dark={{
                        color: "text.darkMode.light",
                        _hover: {
                          color: colors.ai[200],
                          background: hexToHexa(colors.ai[900], 0.2),
                        },
                      }}
                      onClick={() => onSelectPrompt(prompt)}
                    >
                      <Box width="6px" color={colors.ai[300]}>
                        <ActionCaretRight />
                      </Box>
                      {prompt.label}
                    </Flex>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </VStack>
      </Box>
    </>
  );
}
