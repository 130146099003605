import { useSession } from "next-auth/client";
import { CloseIcon } from "@chakra-ui/icons";
import {
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useDisclosure,
  Tag,
  TagProps,
  useColorModeValue
} from "@chakra-ui/react";
import { BsThreeDots } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import toast from "react-hot-toast";
import { updateUser, getAccount } from "utils/sessionHelper";
import { ConfirmPopup } from "components/popups/confirmPopup";
import { EditLabel } from "components/popups/edit-label";
import { useMiscellaneousContext } from "context/miscellaneousContext";
import { textStyle } from "theme/names";
import { DeleteIcon } from "components/icons/DeleteIcon";

export interface LabelProps {
  id: string;
  title: string;
  color: string;
}

interface Props {
  id: string;
  title: string;
  color: string;
  showOption?: boolean;
  showRemove?: boolean;
  isSelected?: boolean;
  size?: "xs" | "md";
  cursor?: string;
  onRemove?: (id: string) => void;
  onClick?: () => void;
}

export function Label({
  id,
  title,
  color,
  isSelected = false,
  onRemove = () => {},
  size = "md",
  showOption = true,
  showRemove = true,
  cursor = "default",
  onClick,
}: Props) {
  const [session] = useSession();
  const miscellaneousContext: any = useMiscellaneousContext();
  const {
    isOpen: isOpenEdit,
    onClose: onCloseEdit,
    onOpen: onOpenEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenDelete,
    onClose: onCloseDelete,
    onOpen: onOpenDelete,
  } = useDisclosure();
  const menuItemColor = useColorModeValue("gray.800", "white");

  const handleDelete = async () => {
    try {
      const tweetLabels = getAccount(session)?.tweetLabels;
      const index = tweetLabels.findIndex((tl) => tl.id === id);

      tweetLabels.splice(index, 1);

      const schedule = getAccount(session)?.schedule;
      schedule.forEach((s) => {
        if (s.labels?.includes(id)) {
          s.labels = [];
        }
      });

      await updateUser(session, { tweetLabels, schedule }, true);
      getAccount(session).tweetLabels = tweetLabels;
      miscellaneousContext.labelsRefresher({});
    } catch (err) {
      console.log("Error in deleting label: ", err);
      toast.error("Error in deleting label: " + err.message);
    }
  };

  let fontSize: string,
    settingIcon: string,
    closeIconHeight: string,
    closeIconWidth: string,
    spacing: string,
    paddingLeft: string,
    editFontSize: string,
    paddingRight: string;
  switch (size) {
    case "xs":
      fontSize = "xs";
      settingIcon = "12";
      closeIconHeight = "2";
      closeIconWidth = "2";
      spacing = "0.75";
      paddingLeft = "2";
      paddingRight = "1.5";
      editFontSize = "sm";
      break;
    case "md":
    default:
      fontSize = "md";
      settingIcon = "16";
      closeIconHeight = "2.5";
      closeIconWidth = "2.5";
      spacing = "2";
      paddingLeft = "4";
      paddingRight = "2";
      editFontSize = "md";
      break;
  }

  let tagStyle: TagProps = {
    bg: color + ".500",
    color: "white",
  };
  if (!isSelected) {
    tagStyle = {
      colorScheme: color,
      variant: "outline",
    };
  }

  return (
    <Tag {...tagStyle} p="0">
      <HStack
        rounded="md"
        width="fit-content"
        py="0.5"
        pr={paddingRight}
        alignItems="center"
        spacing={showOption ? spacing : "2"}
      >
        <Text
          fontSize={fontSize}
          pl={paddingLeft}
          onClick={onClick}
          cursor={cursor}
          maxW="40"
          noOfLines={1}
          wordBreak="break-all"
        >
          {title}
        </Text>
        {showOption && (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              border="none"
              color="inherit"
              icon={<BsThreeDots size={settingIcon} />}
              size="xs"
              mx="2"
              mt="2px !important"
              bg="inherit"
              _hover={{
                bg: "inherit",
              }}
              _focus={{
                boxShadow: "none",
              }}
              _active={{
                bg: "inherit",
              }}
            />
            <MenuList>
              <MenuItem
                color={menuItemColor}
                fontSize={editFontSize}
                icon={<FiEdit3 size="16" />}
                onClick={() => {
                  onOpenEdit();
                }}
                textStyle={textStyle['body.medium.light']}
              >
                Change name and color
              </MenuItem>
              <MenuItem
                color="red"
                fontSize={editFontSize}
                icon={DeleteIcon}
                // icon={<AiOutlineDelete size="16" />}
                onClick={onOpenDelete}
                textStyle={textStyle['body.medium.light']}
              >
                Delete label permanently
              </MenuItem>
            </MenuList>
          </Menu>
        )}
        {showRemove && (
          <CloseIcon
            w={closeIconWidth}
            h={closeIconHeight}
            mt="2px !important"
            cursor="pointer"
            onClick={() => onRemove(id)}
          />
        )}
      </HStack>
      <EditLabel
        isOpen={isOpenEdit}
        onClose={onCloseEdit}
        label={{ id, title, color }}
      />
      <ConfirmPopup
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        title={`Deleting ${title}`}
        body="This action will delete the label permanently. Even for the tweets in analytics."
        callback={handleDelete}
      />
    </Tag>
  );
}
