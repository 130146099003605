import { useState } from "react";
import { useSession } from "next-auth/client";
import * as Sentry from "@sentry/nextjs";
import { TaskTypes } from "./custom-list-item";
import { getAccount, updateUser } from "utils/sessionHelper";
import { TASKLIST } from "./index";

export function useOnboardingSteps() {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [session] = useSession();

  const updateOnboardSteps = async (task: TaskTypes, nextTask?: TaskTypes) => {
    try {
      const onboarding = getAccount(session)?.onboarding;
      const completedSteps: string[] = onboarding?.completedSteps;

      /**
       * allow earlier steps to be completed even when user has reached the last step.
       * "engage" is currently the last step. Its value comes from TASKLIST array in ./index.tsx
       */
      const stepCheckCondition =
        onboarding?.currentStep === task ||
        onboarding?.currentStep === "engage";

      if (onboarding && stepCheckCondition && !completedSteps?.includes(task)) {
        setIsUpdating(true);

        const stepThTokens = TASKLIST.find((t) => t.id === task)?.thTokens;
        let tokenCount = getAccount(session)?.thTokens || 0;
        tokenCount += stepThTokens;

        if (getAccount(session).thTokens) {
          getAccount(session).thTokens = tokenCount;
        }

        await updateUser(session, {
          onboarding: {
            ...onboarding,
            completedSteps: [...onboarding.completedSteps, task],
            currentStep: nextTask || onboarding.currentStep,
          },
          thTokens: tokenCount,
        });

        setIsUpdating(false);
      }
    } catch (e) {
      console.error("Error in updateOnboardSteps: " + e.message, e);
      Sentry.captureException(e);
    }
  };

  const hidePanel = async () => {
    const onboarding = getAccount(session)?.onboarding;

    if (onboarding) {
      setIsUpdating(true);

      await updateUser(session, {
        onboarding: {
          ...onboarding,
          showPanel: false,
        },
      });
      setIsUpdating(false);
    }
  };

  const skipStep = async (nextStep: TaskTypes) => {
    const onboarding = getAccount(session)?.onboarding;

    if (onboarding) {
      setIsUpdating(true);

      await updateUser(session, {
        onboarding: {
          ...onboarding,
          currentStep: nextStep,
        },
      });
      setIsUpdating(false);
    }
  };

  return { updateOnboardSteps, isUpdating, hidePanel, skipStep };
}
