export function Formal() {
  return(<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3 4C3 1.8125 4.78125 0 7 0C9.1875 0 11 1.8125 11 4C11 6.21875 9.1875 8 7 8C4.78125 8 3 6.21875 3 4ZM9.5 4C9.5 2.625 8.375 1.5 7 1.5C5.59375 1.5 4.5 2.625 4.5 4C4.5 5.40625 5.59375 6.5 7 6.5C8.375 6.5 9.5 5.40625 9.5 4ZM6.53125 11.25L5.5 9.5H8.5L7.4375 11.25L7.9375 13.0938L9.46875 10.0312C12 10.3125 14 12.4375 14 15.0625C14 15.5938 13.5625 16 13.0312 16H0.9375C0.40625 16 0 15.5938 0 15.0625C0 12.4375 1.96875 10.3125 4.5 10.0312L6.03125 13.0938L6.53125 11.25ZM10.2812 11.7812L8.90625 14.5H12.4375C12.25 13.2812 11.4062 12.25 10.2812 11.7812ZM1.53125 14.5H5.0625L3.6875 11.7812C2.5625 12.25 1.71875 13.2812 1.53125 14.5Z" fill="url(#g)"/>
  <linearGradient
          id="g"
          x1="-5"
          y1="-5"
          x2="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
    </linearGradient> </svg>
  )
  return (
    <svg
      width="14"
      height="7"
      viewBox="0 0 14 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.68086 1.1095H6.23381C5.38081 1.11348 4.69074 1.80484 4.68835 2.65784V4.1049C4.69074 4.95902 5.38258 5.65086 6.2367 5.65325H7.68376C8.53676 5.64927 9.22682 4.9579 9.22921 4.1049V2.65784C9.22682 1.80372 8.53499 1.11188 7.68086 1.1095Z"
        fill="black"
      />
      <path
        d="M7.68086 1.1095H6.23381C5.38081 1.11348 4.69074 1.80484 4.68835 2.65784V4.1049C4.69074 4.95902 5.38258 5.65086 6.2367 5.65325H7.68376C8.53676 5.64927 9.22682 4.9579 9.22921 4.1049V2.65784C9.22682 1.80372 8.53499 1.11188 7.68086 1.1095Z"
        fill="url(#paint0_linear_8_133)"
      />
      <path
        d="M13.9987 0.869305C13.9937 0.808059 13.9826 0.747427 13.9654 0.688423C13.9444 0.610427 13.9132 0.535506 13.8728 0.465577C13.6176 0.0232487 13.0521 -0.128403 12.6098 0.126822C12.6097 0.126858 12.6096 0.12693 12.6095 0.126966L10.41 1.39169L9.47375 1.93434C9.56818 2.16395 9.6173 2.40962 9.61846 2.65786V4.10492C9.61734 4.35316 9.56821 4.59887 9.47375 4.82845L10.4071 5.36675L12.5372 6.59675C12.9576 6.89086 13.5368 6.78845 13.8309 6.36804C13.9404 6.21154 13.9989 6.02512 13.9987 5.83415V0.921399C14.0004 0.904107 14.0004 0.886634 13.9987 0.869305Z"
        fill="black"
      />
      <path
        d="M13.9987 0.869305C13.9937 0.808059 13.9826 0.747427 13.9654 0.688423C13.9444 0.610427 13.9132 0.535506 13.8728 0.465577C13.6176 0.0232487 13.0521 -0.128403 12.6098 0.126822C12.6097 0.126858 12.6096 0.12693 12.6095 0.126966L10.41 1.39169L9.47375 1.93434C9.56818 2.16395 9.6173 2.40962 9.61846 2.65786V4.10492C9.61734 4.35316 9.56821 4.59887 9.47375 4.82845L10.4071 5.36675L12.5372 6.59675C12.9576 6.89086 13.5368 6.78845 13.8309 6.36804C13.9404 6.21154 13.9989 6.02512 13.9987 5.83415V0.921399C14.0004 0.904107 14.0004 0.886634 13.9987 0.869305Z"
        fill="url(#paint1_linear_8_133)"
      />
      <path
        d="M4.45826 1.89526L3.57121 1.383L1.38616 0.124064C0.943905 -0.131305 0.378396 0.0202016 0.123027 0.462421C0.122991 0.462494 0.122918 0.462566 0.122882 0.462675C0.0824369 0.532604 0.051289 0.607525 0.0302706 0.685521C0.0141721 0.746587 0.00447682 0.809136 0.00132948 0.872191C0.0150765 0.80418 0.0557749 0.74467 0.1142 0.707227L0.00132948 0.872191C-0.00044316 0.88952 -0.00044316 0.906957 0.00132948 0.924285V5.83269C0.0019083 6.34658 0.418986 6.76268 0.932835 6.7621C1.11994 6.76188 1.30263 6.70527 1.45707 6.59963L3.61462 5.34938L4.45681 4.86317C4.35443 4.6235 4.30176 4.36553 4.30198 4.10491V2.65786C4.30158 2.39569 4.35476 2.13616 4.45826 1.89526Z"
        fill="black"
      />
      <path
        d="M4.45826 1.89526L3.57121 1.383L1.38616 0.124064C0.943905 -0.131305 0.378396 0.0202016 0.123027 0.462421C0.122991 0.462494 0.122918 0.462566 0.122882 0.462675C0.0824369 0.532604 0.051289 0.607525 0.0302706 0.685521C0.0141721 0.746587 0.00447682 0.809136 0.00132948 0.872191C0.0150765 0.80418 0.0557749 0.74467 0.1142 0.707227L0.00132948 0.872191C-0.00044316 0.88952 -0.00044316 0.906957 0.00132948 0.924285V5.83269C0.0019083 6.34658 0.418986 6.76268 0.932835 6.7621C1.11994 6.76188 1.30263 6.70527 1.45707 6.59963L3.61462 5.34938L4.45681 4.86317C4.35443 4.6235 4.30176 4.36553 4.30198 4.10491V2.65786C4.30158 2.39569 4.35476 2.13616 4.45826 1.89526Z"
        fill="url(#paint2_linear_8_133)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8_133"
          x1="6.95878"
          y1="1.1095"
          x2="6.95878"
          y2="5.65325"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8_133"
          x1="11.7369"
          y1="0.00289917"
          x2="11.7369"
          y2="6.7646"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8_133"
          x1="2.22913"
          y1="0"
          x2="2.22913"
          y2="6.7621"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
