import { useState, useEffect } from "react";
import { Progress } from "@chakra-ui/react";

const ProgressBar = ({
  maxProgress = 97,
  duration = 30, // in seconds
}) => {
  const [progress, setProgress] = useState(3);

  useEffect(() => {
    const updateInterval = 100
    const progressIncrement = ((maxProgress / duration) * (updateInterval / 1000))
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        const newProgress = prevProgress + (prevProgress < 40 ? progressIncrement / 2 : progressIncrement * 2);
        if (newProgress >= maxProgress) {
          clearInterval(interval);
          return maxProgress;
        }
        return newProgress;
      });
    }, updateInterval);
    return () => clearInterval(interval);
  }, []);

  return (
    <Progress mb={4} sx={{
      '& > div': {
        background: 'linear-gradient(180deg, rgba(241, 138, 163, 0.50) 0%, rgba(98, 104, 223, 0.50) 100%)',
        transitionProperty: "width",
      },
    }} value={progress} borderRadius="full" />
  );
};


export default ProgressBar;