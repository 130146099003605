import { colors } from "theme/colors/colors";
import { radius } from "theme/foundations/radius";
import { text } from "theme/foundations/text";
import { hexToHexa } from "utils/helpers";
import { secondary } from "./secondary";

export const action = {
  ...secondary,
  borderRadius: radius.sm,
  border: "none",
  color: "text.lightMode.standard",
  bg: "transparent",
  _dark: {
    color: "text.darkMode.standard",
    bg: "transparent",
  },
  _hover: {
    bg: "primary.lightMode.100",
    color: "primary.lightMode.default",
    _dark: {
      bg: "primary.darkMode.100",
      color: "primary.darkMode.default",
    },
  },
  _active: {
    bg: "primary.lightMode.100",
    color: "primary.lightMode.default",
    _dark: {
      bg: "primary.darkMode.100",
      color: "primary.darkMode.default",
    },
  },
};
export const actionAI = {
  ...secondary,
  borderRadius: radius.sm,
  border: "none",
  color: "text.lightMode.standard",
  bg: "transparent",
  _hover: {
    color: colors.ai[700],
    bg: colors.ai[50],
  },
  _active: {
    color: colors.ai[900],
    bg: colors.ai[200],
  },
  _dark: {
    color: "text.darkMode.standard",
    bg: "transparent",
    _hover: {
      color: colors.ai[200],
      bg: hexToHexa(colors.ai[900], 0.2),
    },
    _active: {
      color: colors.ai[300],
      bg:hexToHexa(colors.ai[900], 0.4),
    },
  },
};

export const actionBg = {
  ...secondary,
  borderRadius: radius.sm,
  border: "none",
  color: "text.lightMode.standard",
  bg: "primary.lightMode.100",
  _dark: {
    color: "text.darkMode.standard",
    bg: "primary.darkMode.100",
  },
  _hover: {
    bg: "primary.lightMode.100",
    color: "primary.lightMode.default",
    _dark: {
      bg: "primary.darkMode.100",
      color: "primary.darkMode.default",
    },
  },
  _active: {
    bg: "primary.lightMode.100",
    color: "primary.lightMode.default",
    _dark: {
      bg: "primary.darkMode.100",
      color: "primary.darkMode.default",
    },
  },
};

export const actionEditPost = {
  ...secondary,
  borderRadius: radius.lg,
  border: "1px solid",
  borderColor: "transparent",
  ...text.body.medium.primary.light,
  bg: "transparent",
  _dark: {
    color: "text.darkMode.standard",
    bg: "transparent",
  },
  _hover: {
    bg: "primary.lightMode.100",
    color: "primary.lightMode.default",
    _dark: {
      bg: "primary.darkMode.100",
      color: "primary.darkMode.default",
    },
  },
  _active: {
    bg: "primary.lightMode.100",
    color: "primary.lightMode.default",
    _dark: {
      bg: "primary.darkMode.100",
      color: "primary.darkMode.default",
    },
  },
};


