import { Flex, Box, Portal } from "@chakra-ui/react";
import { Prompt } from "../utils";
import { HomeScreen } from "../home-screen";
import { UserMessage } from "./user-message";
import { AssistantMessage } from "./assistant-message";
import { LoadingMessage } from "./loading-message";

export interface Message {
  role: string;
  content: string;
  contentFormatted?: string;
  editorText?: string;
  composerContent?: string;
}

interface Props {
  messages: Message[];
  isLoadingMessage: boolean;
  bottomMostBlock?: JSX.Element;
  onSelectHomeScreenPrompt: (prompt: Prompt) => void;
  editorInstance: any;
  imperativeHandleInstance: any;
}

export function Messages({
  messages,
  isLoadingMessage,
  bottomMostBlock,
  onSelectHomeScreenPrompt,
  editorInstance,
  imperativeHandleInstance
}: Props) {
  if (messages.length === 0) {
    return <HomeScreen onSelectPrompt={onSelectHomeScreenPrompt} />;
  }

  return (
    <Flex direction="column" overflowY="auto" className="noScrollBar">
      {/* displays composer content in tooltip form */}
      <Portal>
        <Box
          id="chat-assist-tooltip"
          zIndex="102"
          w="max-content"
          maxW="350px"
          bg="gray.700"
          color="gray.100"
          boxShadow="lg"
          borderRadius="6px"
          fontSize="12px"
          textAlign="left"
          padding="8px 12px"
          position="absolute"
          top="200px"
          whiteSpace="pre-line"
          visibility="hidden"
        />
      </Portal>
      {messages.map((message, index) => {
        switch (message.role) {
          case "user":
            return (
              <UserMessage
                key={index}
                index={index}
                content={message.content}
                contentFormatted={message.contentFormatted}
                editorText={message.editorText}
                composerContent={message.composerContent}
                editorInstance={editorInstance}
                imperativeHandleInstance={imperativeHandleInstance}
              />
            );
          case "assistant":
            return (
              <AssistantMessage
                key={index}
                content={message.content}
                contentFormatted={message.contentFormatted}
              />
            );
          default:
            return null;
        }
      })}
      {isLoadingMessage ? <LoadingMessage /> : null}
      {bottomMostBlock}
    </Flex>
  );
}
