import { radius } from "theme/foundations/radius";
import { color } from "theme/names";
import { secondary } from "./secondary";


export const actionGray = {
  ...secondary,
  borderRadius: radius.sm,
  border: "none",
  color: "text.lightMode.light",
  bg: "background.lightMode.medium",
  _dark: {
    color: "text.darkMode.light",
    bg: color.background.darkMode.medium,
  },
  _hover: {
    bg: "background.lightMode.medium",
    color: "text.lightMode.standard",
    _dark: {
      bg: "background.darkMode.medium",
      color: "text.darkMode.standard",
    },
  },
  _active: {
    bg: "background.lightMode.medium",
    color: "text.lightMode.standard",
    _dark: {
      bg: "#1E1E1E",
      color: "text.darkMode.standard",
    },
  },
};
