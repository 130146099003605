import { useState, useEffect } from "react";
import {
  useColorModeValue,
  useMediaQuery,
  Slide,
  Box,
  Center,
  Button,IconButton,Icon,Divider
} from "@chakra-ui/react";
import { useMiscellaneousContext } from "context/miscellaneousContext";
import { CloseIcon } from "@chakra-ui/icons";
import { AiOutlineMail } from "react-icons/ai";
import { Profile } from "./profile";
import { List } from "./list";
import { Notes } from "./notes";
import { PersonData } from "./personData";
import { colors } from "theme/colors/colors";

export function PersonSidebar() {
  const [contact, setContact] = useState<any>();
  const [isTooSmall] = useMediaQuery("(max-width: 800px)");
  const miscellaneousContext: any = useMiscellaneousContext();
  const sidebarBg = useColorModeValue("white", "#1E1E1E");
  const sidebarBorderColor = useColorModeValue(colors.border.lightMode.light, colors.border.darkMode.light);
  const closeIconColor = useColorModeValue(colors.border.lightMode.active, colors.border.darkMode.active);
  const isSidebarOpen = miscellaneousContext.isPersonSidebarOpen;

  useEffect(() => {
    if (
      miscellaneousContext.sidebarPerson &&
      miscellaneousContext.sidebarPerson.id_str !== contact?.id_str
    ) {
      setContact(miscellaneousContext.sidebarPerson);
    }
  }, [miscellaneousContext.sidebarPerson]);

  if (!miscellaneousContext.sidebarPerson) {
    return null;
  }

  return (
    <Slide
      in={isSidebarOpen}
      style={{
        zIndex: 9,
        width: isTooSmall ? "100%" : "400px",
        overflow: "auto",
      }}
      className="noScrollBar"
    >
      <Box
        width={isTooSmall ? "100%" : "400px"}
        position="fixed"
        right="0"
        top="0"
        // height="100%"
        minH="100%"
        background={sidebarBg}
        borderLeft="1px"
        p="4"
        pl="4"
        pb="200px"
        borderColor={sidebarBorderColor}
      >
        {/* <Box textAlign="end" mt="2" mr="4">
          <CloseIcon
            color={closeIconColor}
            cursor="pointer"
            onClick={() => {
              miscellaneousContext.setIsPersonSidebarOpen(false);
            }}
          />
        </Box> */}
          <Box {...{ w: "full", pt: 2, pb: 0, alignSelf: "start" }}>
          <IconButton
            aria-label="Close"
            variant="action"
            size="table"
            mr={2}
            onClick={() => {
              miscellaneousContext.setIsPersonSidebarOpen(false);
            }}
            icon={
              <Icon viewBox="0 0 14 11">
                <svg
                  width="14"
                  height="11"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.12109 2.87891L10.1172 4.875H5.25C4.75781 4.875 4.375 5.28516 4.375 5.75C4.375 6.24219 4.75781 6.625 5.25 6.625H10.1172L8.12109 8.64844C7.95703 8.8125 7.875 9.03125 7.875 9.25C7.875 9.49609 7.95703 9.71484 8.12109 9.87891C8.44922 10.2344 9.02344 10.2344 9.35156 9.87891L12.8516 6.37891C13.207 6.05078 13.207 5.47656 12.8516 5.14844L9.35156 1.64844C9.02344 1.29297 8.44922 1.29297 8.12109 1.64844C7.76562 1.97656 7.76562 2.55078 8.12109 2.87891ZM2.625 10.125V1.375C2.625 0.910156 2.21484 0.5 1.75 0.5C1.25781 0.5 0.875 0.910156 0.875 1.375V10.125C0.875 10.6172 1.25781 11 1.75 11C2.21484 11 2.625 10.6172 2.625 10.125Z"
                    fill="currentColor"
                  />
                </svg>
              </Icon>
            }
          />
          <Divider {...{ mt: 4 }}></Divider>
          </Box>

        <Box mt="5" pb="20" >
          <Box >
            <Profile contact={contact} />
            {/* <Button
              size="sm"
              mt="6"
              variant={"primary"}
              leftIcon={<AiOutlineMail />}
              as="a"
              target="_blank"
              href={`https://twitter.com/messages/compose?recipient_id=${contact?.id_str}`}
            >
              Send a DM
            </Button> */}
            <List contact={contact} />
            <Notes contact={contact} setContact={setContact} />
            <PersonData contact={contact} />
          </Box>
        </Box>
      </Box>
    </Slide>
  );
}
