import * as analytics from "../utils/analytics";
import toast from "react-hot-toast";
import { getAccount, updateUser } from "../utils/sessionHelper";


let plans = {
    "prod_JTA33Qr972Xstv": "start", // live
    "prod_LIMknHcQqxispS": "start", // live yearly
    "prod_JtR2GTlQmSXyyP": "grow", // live
    "prod_LIMgr2UZaevx2g": "grow", // live yearly
    "prod_JTA9cTWLYbSEHR": "start", // dev
    "prod_LIMTXNSYAXZRz7": "start", // dev yearly
    "prod_JtR45LpCDGDy9v": "grow", // dev
    "prod_LIMIYHWA28fCFp": "grow", // dev yearly
    "prod_JvzNwu5J8cSTHz": "enterprise200", // live
    "prod_K3Zt2eDt3n1Sll": "enterprise100", // live
}

let monthlyPrices = [
    "price_1PZAIVLQWMadComQC1BM5SgS", //new starter price - 29 USD
    "price_1IqDp8LQWMadComQN1IcH4Sq",
    "price_1JFeBuLQWMadComQLDpolxiu",
    "price_1IqDjVLQWMadComQCYm7Q7rJ",
    "price_1JFeAeLQWMadComQaw6xg695",
    "price_1JbJc0LQWMadComQtnSAj2h6", // basic prod - 29
    "price_1JhrWpLQWMadComQsNZy3w1M", // basic prod - 29 additional tax
    "price_1KblywLQWMadComQNtToGCTE", // basic prod - 49 additional tax
    "price_1JhrgFLQWMadComQ8IJquUcQ", // grow prod - 49 additional tax
    "price_1JbJmYLQWMadComQWCUrFci0", // basic dev - 29
    "price_1KblivLQWMadComQ3GARESQC", // basic dev - 49
    "price_1JI7P3LQWMadComQWXg6UlLK", // Tweet Hunter Enterprise - 200 credits
    "price_1JPSjTLQWMadComQzgHWdoI6", // Tweet Hunter Enterprise - 100 credits
]

let featureGrow = [
    "variation",
    "generate",
    "ai_variation",
    "hook",
    "idea",
    "crm",
    "predict",
    // "ghostwriting",
    // "engage",
    "chat",
];

let featureEnterprise = [
    "ghostwriting",
    "enrich",
    "replyGeneration",
    "leadFinder",
];

export const useCredit = async (session, mainContext, creditKey = "creditsAuto", showUpgradeOnFail = true, creditsUsed = 1) => {

    if (session?.user?.data?.subscription?.isSubscribed
        && session?.user?.data?.[creditKey] > 0
    ) {
        let newValue = session.user.data[creditKey] - creditsUsed;
        if (newValue < 0) {
            newValue = 0
        }
        let dataToSave = { [creditKey]: newValue };
        await updateUser(session, dataToSave); // update the session
        return true;
    }
    else {
        if (showUpgradeOnFail) {
            if (mainContext?.onOpenUpgrade && creditKey == "creditsReplies") // can check using !session?.user?.data?.creditsReplies too. 
                mainContext.onOpenUpgrade("You used all your reply credits (reset the 1st of each month) - upgrade to get more.");
            else if (mainContext?.onOpenUpgrade)
                mainContext.onOpenUpgrade("You used all your AI credits (reset daily) - upgrade to get more.");
            else
                toast.error("You used all your AI credits - upgrade to get more.");
        }

        return false;
    }
}

export const getCreditBack = async (session, creditKey = "creditsAi") => {
    let newValue = session.user.data[creditKey] + 1;
    let dataToSave = { [creditKey]: newValue };
    await updateUser(session, dataToSave); // update the session
    return true;
}

export const getPeriodicity = (user) => {

    if (user?.data?.subscription?.isSubscribed && (!user?.data?.subscription?.price || monthlyPrices.includes(user.data.subscription.price)))
        return "monthly";
    else
        return "yearly";
}

export const getPlan = (user) => {

    if (user?.data?.subscription?.isSubscribed && !user?.data?.subscription?.product)
        return "start";
    else if (user?.data?.subscription?.isSubscribed && user?.data?.subscription?.product && plans[user.data.subscription.product])
        return plans[user.data.subscription.product];
    else
        return "";
}

export const isAllowedEmailFinder = (user) => {
    if (user?.data?.isAdmin)
        return false;
    else
        return false;
}

export const isAllowed = (user, feature) => {

    let plan = getPlan(user);
    // console.log("plan: " + plan);
    // console.log("isSubscribed: " + user?.data?.subscription?.isSubscribed);

    if (["enterprise100", "enterprise200"].includes(plan))
        return true;
    else if (featureEnterprise.includes(feature))
        return false;
    else if (["grow"].includes(plan))
        return true;
    else if (featureGrow.includes(feature))
        return false;
    else if (!user?.data?.subscription?.isSubscribed)
        return false;
    else
        return true;
}

export const resumeSubscription = async (session, setLoading, refresher = undefined as any) => {
    if (!session?.user?.data?.subscription?.subscription) {
        analytics.log("error", { message: "subscription to update not found" });
        toast.error("subscription to update not found, please contact us");
        return;
    }

    let data = {
        isLocal: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
        subscription: session.user.data.subscription.subscription,
        userId: session.user.uid,
    };

    setLoading(true);

    const response = await fetch("/api/subscriptionResume", {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json", token: "" }),
        credentials: "same-origin",
        body: JSON.stringify(data),
    });
    const stripeUpdate = await response.json();

    setLoading(false);

    if (stripeUpdate.success) {
        toast.success("Subscription resumed successfully");
        session.user.data.subStatus = "FreeTrial_CancelRequest" ? "FreeTrial" : "Subscribed";
        analytics.log("subscription_resume");
        refresher && refresher({});
    }
    else {
        analytics.log("error", { ...stripeUpdate, message: "Failed to update subscription" });
        toast.error("Failed to resume subscription please contact us");
    }
}