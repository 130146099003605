import xss from "xss";

export const sanitizeInput = (input: string) => {
  return xss(input, {
    whiteList: {}, // This removes all HTML tags
    stripIgnoreTag: true,
    stripIgnoreTagBody: ["script"],
  })
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/&amp;/g, "&");
};
