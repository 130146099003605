import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import { border } from "theme/foundations/border";
import { radius } from "theme/foundations/radius";
import { size, text } from "theme/foundations/text";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

// const outline = definePartsStyle({
//   field: {
//     fontFamily: "mono",
//     fontWeight: "bold",
//     borderRadius: 0,
//     borderColor: "purple.100",

//     _focusWithin: {
//       ringColor: "purple.200",
//       ring: "2px",
//       ringOffset: "1px",
//       ringOffsetColor: "purple.100",
//       borderColor: "purple.50",
//     },
//   },
// });

const baseStyle = definePartsStyle({
  field: {
    borderRadius: radius.lg,
  },
});

const switcher = definePartsStyle({
  field: {
    ...text.body.medium.standard,
    ...border.default.light,
    _hover: {
      ...text.body.medium.standard,
    },
  },
  icon: {
    ...size.body,
    ...text.body.medium.light,
    _hover: {
      ...text.body.medium.standard,
    },
  },
});

const xl = defineStyle({
  // fontSize: "lg",
  px: "2",
  h: "12",
});

const sizes = {
  xl: definePartsStyle({ field: xl }),
};

export const Select = defineMultiStyleConfig({
  baseStyle,
  variants: { switcher },
  sizes,
});
