import { useState, useEffect } from "react";
import { AiButton } from "components/common/AiButton/AiButton";
import { useSession } from "next-auth/client";
import { getToken } from "firebaseClient";
import { Text, Button, Menu, MenuButton, MenuItem, MenuList, Stack, Tooltip, MenuGroup } from "@chakra-ui/react";
import toast from "react-hot-toast";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiImage } from 'react-icons/fi'
import ProgressBar from "components/progress-bar";

const styles = [
  "Default",
  "Abstract",
  "Anime",
  "Flat Design",
  "Gothic",
  "Impressionism",
  "Minimalist",
  "Pixel Art",
  "Process",
  "Papercut",
  "Steampunk",
  "Surreal",
  "Van Gogh",
];

interface GenerateImageProps {
  content?: string;
  onImageGenerated: (imageURL: string) => void;
  isHorizontal?: boolean;
}

const GenerateImage: React.FC<GenerateImageProps> = ({
  content,
  onImageGenerated = (_) => { },
  isHorizontal = false,
}) => {
  const [session, sessionLoading] = useSession();
  const [style, setStyle] = useState(styles[0]);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const mediaRegex = /\[img:[^\]]+\]/g;

  const contentWithoutMedia = content?.replace(mediaRegex, "") ?? "";

  const handleGenerateImage = async () => {
    setIsGeneratingImage(true);
    try {
      const token = await getToken(session, "GenerateImage");
      const imageURLResponse = await fetch("https://us-central1-ez4cast.cloudfunctions.net/imageGeneration-generateImageFromPost", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          tokenUserId: session?.user.uid || "",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          "postContent": contentWithoutMedia,
          "postType": "Tweet",
          "style": style === "Default" ? "Flat Design" : style,
        }),
      });
      let generatedImage = await imageURLResponse.json();
      onImageGenerated(generatedImage.fileName);
      if (generatedImage.success === 0)
        throw new Error(generatedImage.message);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while generating image, please try again later.");
    }
    setIsGeneratingImage(false);
  }
  if (sessionLoading) return null;
  if (isGeneratingImage) return <ProgressBar maxProgress={97} duration={25} />
  const canGenerateImage = contentWithoutMedia?.length > 50;
  const menuOrder = isHorizontal ? 1 : 2;
  const buttonOrder = isHorizontal ? 2 : 1;
  return (
    <Tooltip as={"div"} hasArrow label='Your post needs to be at least 50 characters long for AI to be able to generate a relevant image.' isDisabled={canGenerateImage} textAlign="center">
      <Stack direction={isHorizontal ? "row" : "column"} align="center" justify="center" mb={4}>
        <AiButton containerProps={{ order: buttonOrder, h: "30px", }} size="sm" fontSize="xs" p={3} h="30px" iconProps={{
          width: 3,
          height: 3,
        }} onClick={handleGenerateImage} isDisabled={!canGenerateImage}>Generate Image</AiButton>
        <Menu
          variant="Ai"
          strategy="fixed"
        >
          <MenuButton as={Button} variant="ghost" size="xs" fontSize="xs" leftIcon={<FiImage />} rightIcon={isHorizontal ? undefined : <ChevronDownIcon />} isDisabled={!canGenerateImage} order={menuOrder}>
            {isHorizontal ? "" : "Style: "}{style}
          </MenuButton>
          <MenuList fontSize="xs">
            <MenuGroup title='Image style' fontSize="xs">
              {styles.map((style, index) => <MenuItem key={index} onClick={() => setStyle(style)}>{style}</MenuItem>)}
            </MenuGroup>
          </MenuList>
        </Menu>
      </Stack>
    </Tooltip>
  )
}


export default GenerateImage;