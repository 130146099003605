import { colors } from "theme/colors/colors";
import { radius } from "theme/foundations/radius";
import { secondary } from "./secondary";
import { layerStyles } from "theme/layerStyles/layerStyles";


export const suggestion = {
  // ...secondary,
  // borderRadius: radius.sm,
  // border: "none",
  // bg: "primary.lightMode.default",
  // fontWeight: "600",
  // p: 4,
  // color: "primary.lightMode.100",
  // _dark: {
  //   // color: "text.darkMode.standard",
  //   border: "none",
  //   color: "primary.darkMode.100",
  // },
  // _hover: {
  //   color: "primary.lightMode.default",
  //   border: "none",
  //   // border: "1px solid",
  //   // borderColor: "primary.lightMode.100",
  //   bg: "white",
  //   _dark: {
  //     border: "none",
  //     color: "primary.darkMode.default",
  //     // border: "1px solid",
  //     // borderColor: "primary.darkMode.100",
  //     bg: "#1E1E1E",
  //   },
  // },
  // _active: {
  //   border: "none",
  //   color: "primary.lightMode.default",
  //   bg: "white",
  //   _dark: {
  //     border: "none",
  //     color: "primary.darkMode.default",
  //     bg: "primary.darkMode.default",
  //   },
  // },
  // ...secondary,
    ...layerStyles.bgBorderRounded,
    p: "4",
    fontSize: "sm",
    color: colors.primary.lightMode[300],
    bg: colors.primary.lightMode[80],
    // borderRadius: radius.sm,
    // border: "none",
    // fontWeight: "600",
    // p: 4,
    _hover: {
      bg: colors.primary.lightMode[90],
    },
    _dark: {
      color: colors.primary.lightMode[80],
      bg: colors.primary.lightMode[300],
      _hover: {
        bg: colors.primary.lightMode[260],
      }
    },
};
