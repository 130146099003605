import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { border } from "theme/foundations/border";
import { text } from "theme/foundations/text";
import { color } from "theme/names";
import { textStyles } from "theme/textStyles/textStyles";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define the base component styles
const line = definePartsStyle({
  // define the part you're going to style
  tab: {
    ...textStyles.body.medium.light,
    borderBottom: "4px solid",
    _selected: {
      ...textStyles.body.medium.standard,
      borderColor: "#0A66C2",
    },
  },
  tablist: {
    borderBottom: "1px solid",
    boxSizing: "border-box",
    pb: "2px", // offset border
  },
  tabpanel: {},
});

const light = definePartsStyle({
  tab: {
    bg: "white",
    px: 4,
    height: "40px",
    ...textStyles.body.medium.light,
    ...border.default.light,
    _first: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    _last: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    _selected: {
      bg: "primary.lightMode.100",
      color: "primary.lightMode.default",
    },
    _hover: {
      // bg: "primary.lightMode.100",
      color: text.body.medium.standard.light.color,
    },
    _active: {
      bg: "primary.lightMode.200",
      color: "white",
    },
    _dark: {
      bg: "#1E1E1E",
    // ...textStyles.body.medium.,
      color: text.body.medium.standard.dark.color,
      ...border.default.dark,
      _selected: {
        bg: "primary.darkMode.100",
      },
      _hover: {
        bg: "primary.darkMode.100",
        color: text.body.medium.standard.dark.color,
      },
      _active: {
        bg: "primary.darkMode.200",
        color: "white",
      },
    },
  },
  tablist: {},
  tabpanel: {},
});

const rounded = definePartsStyle({
  // define the part you're going to style
  tab: {
    borderRadius: "full",
    py:2, px: 4,
    color: color["text.lightMode.standard"],
    bg: "white",
    border: "1px solid",
    borderColor: "border.lightMode.light",
    _selected: {
      bg: color["primary.lightMode.default"],
      color: "white",
    },
    _dark: {
      color: "text.darkMode.standard",
      _selected: {
        color: "white",
        bg: "primary.darkMode.default",
      },
      borderColor: "border.darkMode.light",
      background: "#1E1E1E",
      _hover: {
        borderColor: "border.darkMode.hover",
        background: "#1E1E1E",
      },
      _active: {
        color: "primary.darkMode.400",
      },
      _disabled: {
        color: "text.darkMode.inactive",
        borderColor: "border.darkMode.inactive",
        _hover: {
          borderColor: "border.darkMode.hover",
        },
      },
    },
  },
  tablist: {
  
  },
  tabpanel: {},
});

// export the component theme
export const Tabs = defineMultiStyleConfig({
  //   baseStyle,
  variants: { line, light, rounded }, // you need to overwrite the default variant (which is line) for changes to take effect. See https://chakra-ui.com/docs/theming/component-style#overriding-default-variantshttps://github.com/chakra-ui/chakra-ui/issues/5295
  defaultProps: { size: "sm" },
});
