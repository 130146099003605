import toast from "react-hot-toast";
import * as Sentry from "@sentry/nextjs";
import moment from "moment";
import { summary } from "date-streaks";
import { firebaseClient } from "firebaseClient";
import { updateUser, getAccount } from "utils/sessionHelper";

export async function getTotalTokens() {
  try {
    const db = firebaseClient.firestore();
    const doc = await db.collection("constants").doc("token").get();
    return doc.data();
  } catch (e) {
    console.log("Error in fetching total tokens: ", e);
    toast.error("Error in fetching total tokens: ", e.message);
    Sentry.captureException(e);
    return null;
  }
}

type TokenTask =
  | "shoutout"
  | "schedule"
  | "advanced setting"
  | "reply"
  | "import contacts";

const tokensPerTask = {
  shoutout: 20,
  schedule: 20,
  "advanced setting": 5,
  reply: 15,
  "import contacts": 15,
};

export async function completeTokenTask(task: TokenTask, session: any) {
  try {
    let tokenTasks = getAccount(session)?.tokenTasks || [];
    if (tokenTasks.includes(task)) {
      return;
    }

    let tokenCount = getAccount(session)?.thTokens || 0;
    tokenCount += tokensPerTask[task];
    if (getAccount(session).thTokens) {
      getAccount(session).thTokens = tokenCount;
    }
    tokenTasks.push(task);
    await updateUser(session, { tokenTasks, thTokens: tokenCount });
  } catch (e) {
    console.log("Error in fetching total tokens: ", e);
    Sentry.captureException(e);
  }
}

export function isTaskComplete(task: TokenTask, session: any) {
  return getAccount(session)?.tokenTasks?.includes(task);
}

export async function verifyScheduleTask(session: any) {
  if (isTaskComplete("schedule", session)) {
    return;
  }
  const db = firebaseClient.firestore();

  const docs = await db
    .collection("users")
    .doc(getAccount(session).id)
    .collection("accounts")
    .doc(getAccount(session).idAccount)
    .collection("tweetsStats")
    .where(
      "created_at",
      ">=",
      moment().subtract(14, "d").startOf("day").toDate()
    )
    .where("isFromTweetHunter", "==", true)
    .orderBy("created_at", "desc")
    .get();

  const tweets: any = [];
  docs.forEach((doc) => {
    let tw = doc.data();
    tweets.push(tw);
  });

  const dates = tweets.map((t) =>  {
    const createdAt = t.created_at.toDate();
    if (createdAt instanceof Date) {
      return new Date(createdAt.setHours(0, 0, 0, 0));
    }
    return createdAt;
  });

  const streak = summary({ dates }).longestStreak;

  if (streak >= 7) {
    await completeTokenTask("schedule", session);
  }
}

export async function verifyReply(session: any) {
  if (isTaskComplete("reply", session)) {
    return;
  }

  const db = firebaseClient.firestore();
  const docs = await db
    .collection("users")
    .doc(getAccount(session)?.id)
    .collection("replies")
    .where("replies", "!=", [])
    .get();

  const replies: any = [];
  docs.forEach((doc) => {
    let tw = doc.data();
    replies.push(tw);
  });

  if (replies?.length >= 5) {
    await completeTokenTask("reply", session);
  }
}
