import { checkboxAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import { radius } from "theme/foundations/radius";
import { textStyles } from "theme/textStyles/textStyles";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

// default base style from the Checkbox theme
const baseStyle = definePartsStyle((props) => {
  // console.log({props});
  
  return {
    control: {
      borderRadius: radius.sm,
    },
    icon: {
      // bg: "#1E1E1E",
      _dark: { color: "#1E1E1E" }
    },
    container: {},
    label: {
      ...textStyles.body.medium.standard,
      // color: "red.500"
    },
  }
})

const sizes = {
  md: definePartsStyle({
    label: defineStyle({
    ...textStyles.body.medium.standard,
    })
  }),
}


export const Checkbox = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    colorScheme: "primaryChakraColorScheme",
  },
});
