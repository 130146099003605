import { useEffect, useState } from "react";
import { useSession } from "next-auth/client";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Textarea,
  Button,
  Flex,
} from "@chakra-ui/react";
import toast from "react-hot-toast";

import { firebaseClient } from "firebaseClient";
import { textStyle } from "theme/names";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  imageId: string;
}

export function AltTextPopup({ isOpen, onClose, imageId }: Props) {

  const [session] = useSession()

  const [imageAltText, setImageAltText] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const fetchImageAltText = async () => {
    try {

      setIsLoading(true)

      const db = firebaseClient.firestore();
      const doc = await db.collection("twitterMedias").doc(imageId).get()

      const imageAltTextData = doc.data()?.altText;

      setImageAltText(imageAltTextData as string ?? "")
    } catch (error) {
      const newError = new Error('Error in altText Popup: ' + error.message);
      newError.stack = error.stack; // Preserve the original stack trace
      console.error(newError); // Log the new error
    } finally {
      setIsLoading(false)
    }
  }

  const handleImageAltTextSave = async () => {
    try {
      setIsLoading(true);
      const altTextToSave = imageAltText?.trim()

      if (altTextToSave.length >= 1000) {
        toast.error("Text should be less than 1000 characters");
        return;
      }

      const db = firebaseClient.firestore();
      
      await db.collection("twitterMedias").doc(imageId).set({
        id: imageId,
        altText: altTextToSave
      }, { merge: true })

      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (isOpen) {
      fetchImageAltText()
    }

    return () => {
      setImageAltText("")
    }
  }, [isOpen])

  return (
    <Modal size="xl" trapFocus={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Alt text</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
            textStyle={textStyle["body.medium.light"]}
            fontSize="sm"
            placeholder="Personal description..."
            value={imageAltText}
            autoFocus
            onChange={(e) =>
              setImageAltText(e.target.value)}
          />
          <Flex justifyContent={"center"} mt={4}>

            <Button onClick={handleImageAltTextSave} isLoading={isLoading}>Save</Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
