import { useMemo } from "react"
import { useSession } from "next-auth/client";
import {
  Text,
  Tr,
  Td,
  Checkbox,
  IconButton,
  useColorModeValue,
  Box
} from "@chakra-ui/react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { getAccount } from "utils/sessionHelper";
import { useMiscellaneousContext } from "context/miscellaneousContext";
import { color, layerStyle, radius } from "theme/names";

interface Props {
  time: any;
  index: number;
  onDelete: () => void;
  onChange: (indexDay: number, isChecked: boolean) => void;
}

export function Schedule({ time, index, onDelete, onChange }: Props) {
  const [session] = useSession();
  const evergreenScheduleBg = useColorModeValue("green.100", "green.800");
  const miscellaneousContext: any = useMiscellaneousContext();
  const labels = useMemo(() => getAccount(session)?.tweetLabels, [session, miscellaneousContext.refreshLabels]);
  const labelConfig = labels?.find(l => l.id === time?.labels?.[0])
  const scheduleBg = time.isEvergreen ? evergreenScheduleBg : "transparent";

  return (
    <Tr bg={scheduleBg} borderBottom="1px" borderColor="gray.100">
      <Td display="flex" alignItems="center" borderBottom="0" textStyle={"body.medium.standard"} pr={0}>
        {time.hour > 12 ? time.hour - 12 : time.hour === 0 ? 12 : time.hour}:
        {time.minute
          ? time.minute < 10
            ? "0" + time.minute
            : time.minute
          : "00"}
        {time.hour >= 12 ? " pm" : " am"}
        <IconButton
          aria-label="delete time"
          variant="secondary"
          size="xs"
          ml={2}
          mr={2}
          icon={<SmallCloseIcon />}
          opacity={0.6}
          onClick={onDelete}
        />
        {time.isEvergreen ?
          <Box {...{
            // layerStyle: layerStyle["bg.border.rounded"],
            // px: 1.5,
            // borderRadius: radius.md,
            // fontSize: "2xs",
            // color: color["secondary.lightMode.500"],
            // bg: color["secondary.lightMode.100"],
            // _dark: {
            //   color: color["secondary.darkMode.500"],
            //   bg: color["secondary.darkMode.100"]
            // }
            layerStyle: layerStyle["evergreen"],
          }}>Evergreen</Box>
          : labelConfig ? <Text as="span" color={`${labelConfig.color}.400`} fontWeight="semibold">{labelConfig.title}</Text> : ""}
      </Td>
      {time.days.map((d, indexDay) => (
        <Td key={"time" + index + indexDay} textAlign="center" borderBottom="0">
          <Checkbox
            isChecked={d}
            onChange={(e) => onChange(indexDay, e.target.checked)}
          />
        </Td>
      ))}
    </Tr>
  );
}
