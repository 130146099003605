export function KeepWriting() {
  return(<svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 3.03125C0 2.40625 0.46875 2 1 2C1.21875 2 1.4375 2.09375 1.625 2.25L7 7.125V3.03125C7 2.40625 7.46875 2 8 2C8.21875 2 8.4375 2.09375 8.65625 2.28125L14 7.15625V2.78125C14 2.375 14.3438 2.03125 14.75 2.03125C15.1562 2.03125 15.5 2.34375 15.5 2.78125V13.25C15.5 13.6875 15.1562 14 14.75 14C14.3125 14 14 13.6875 14 13.25V8.9375L8.625 13.7812C8.4375 13.9375 8.21875 14 8 14C7.46875 14 7 13.625 7 13V8.9375L1.625 13.7812C1.4375 13.9375 1.21875 14 1 14C0.46875 14 0 13.625 0 13V3.03125ZM8.5 4.15625V11.875L12.75 8.03125L8.5 4.15625ZM1.5 4.15625V11.875L5.75 8.03125L1.5 4.15625Z" fill="url(#g"/>
  <linearGradient
          id="g"
          x1="-5"
          y1="-5"
          x2="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
    </linearGradient>
  </svg>
  )
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.76231 5.12944L1.43701 0.105607C1.13012 -0.0489846 0.763818 -0.0322308 0.471389 0.145207C0.177437 0.321882 0 0.637157 0 0.975278V11.026C0 11.3634 0.177437 11.6779 0.471389 11.8553C0.629788 11.9513 0.80951 12 0.991517 12C1.14459 12 1.29689 11.965 1.43778 11.8949L7.76307 6.86955C8.06769 6.58321 8.30833 6.36846 8.30833 5.99912C8.30833 5.62977 8.10348 5.43101 7.76231 5.12944Z"
        fill="url(#paint0_linear_0_53)"
      />
      <path
        d="M13.8544 5.12944L7.52911 0.105607C7.22222 -0.0489846 6.85516 -0.0322308 6.56273 0.145207C6.26878 0.321882 6.09134 0.637157 6.09134 0.975278V11.026C6.09134 11.3634 6.26878 11.6779 6.56273 11.8553C6.72113 11.9513 6.90085 12 7.08286 12C7.23592 12 7.38823 11.965 7.52911 11.8949L13.8544 6.86955C14.159 6.58321 14.3997 6.36846 14.3997 5.99912C14.3997 5.62977 14.1956 5.43101 13.8544 5.12944Z"
        fill="url(#paint1_linear_0_53)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_53"
          x1="4.15417"
          y1="0"
          x2="4.15417"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_53"
          x1="10.2455"
          y1="0"
          x2="10.2455"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
