import Icon from "@chakra-ui/icon";
import { Box, Flex, Stack, Text, Link } from "@chakra-ui/layout";
import React from "react";
import styled from "@emotion/styled";
import { GoLocation, GoLink } from "react-icons/go";
import theme from "@chakra-ui/theme";
import { Image } from "@chakra-ui/image";
import twitter from "twitter-text";
import numbro from "numbro";

const StyledText = styled.div`
  a {
    color: ${() => theme.colors.twitter[400]};
  }
`;

const UserPopup = ({ user, tweet=undefined as any, linkOverride="" }) => {

  // console.log(user);

  if (user?.data)
    user = user.data;

  let link = linkOverride ? linkOverride : `https://twitter.com/${user.screen_name}`;

  return (
    <Box p={4} maxW={["xs", "md"]} borderWidth="1px" borderRadius="lg">
      <Flex justifyContent="space-between" >
        <Stack mb={2} direction="row" alignItems="center">
          <Link
            href={link}
            target="_blank"
          >
            <Image
              borderRadius="full"
              boxSize="40px"
              src={user.profile_image_url_https}
              fallbackSrc="/assets/resources/emptyProfile.png"
            />
          </Link>
          <Box>
            <Text noOfLines={1} wordBreak="break-all" maxWidth="200px">{user.name}</Text>
            <Link
              color={theme.colors.gray[400]}
              href={tweet?.type == "linkedin" ? `https://linkedin.com/in/${user.screen_name}` : link}
              target="_blank"
            >
              @{user.screen_name}
            </Link>
          </Box>
        </Stack>
      </Flex>
      <Box>
        <StyledText
          dangerouslySetInnerHTML={{
            __html: twitter.autoLink(user.description, {
              urlEntities: user.entities?.description?.urls,
              usernameIncludeSymbol: true,
              targetBlank: true,
            }),
          }}
        />
      </Box>
      <Box as="hr" my={2} />
      <Stack direction="row">
        <Flex alignItems="center">
          <Text mr={2} fontSize="md">{numbro(user.friends_count).format({
                // mantissa: 1,
                average: true,
                // totalLength: 3,
              }).replace(".0", "")}
          </Text>
          <Text fontSize="sm" color="gray.400">Following</Text>
        </Flex>
        <Flex alignItems="center">
          <Text ml={2} mr={2} fontSize="md">{numbro(user.followers_count).format({
                // mantissa: 1,
                average: true,
                // totalLength: 3,
              }).replace(".0", "")}</Text>
          <Text fontSize="sm" color="gray.400">Followers</Text>
        </Flex>
      </Stack>
      {(user.location || user.entities?.url?.urls[0].expanded_url) && (
        <Box as="hr" my={2} />
      )}
      <Stack direction="row">
        {user.location && (
          <Flex alignItems="center">
            <Icon as={GoLocation} mr={1} />
            <Text noOfLines={1} wordBreak="break-all" maxWidth="100px">
              {user.location}
            </Text>
          </Flex>
        )}
        {user.entities?.url?.urls[0].expanded_url && (
          <Flex alignItems="center">
            <Icon as={GoLink} mr={1} />
            <Link
              noOfLines={1} wordBreak="break-all"
              color={theme.colors.twitter[400]}
              href={user.entities?.url?.urls[0].url}
              target="_blank"
              maxWidth="120px"
            >
              {user.entities?.url?.urls[0].expanded_url}
            </Link>
          </Flex>
        )}
      </Stack>
    </Box>
  );
};

export default UserPopup;
