import { useState, useEffect } from "react";

export function useCountdown(targetDate: Date) {
  const countDownDate = new Date(targetDate).getTime();
  const [countDown, setCountDown] = useState<number>(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    if (targetDate < new Date() || !targetDate) {
      return;
    }
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
}

function getReturnValues(countDown) {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours =
    Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes =
    Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
}
