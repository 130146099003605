import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import { colors } from "theme/colors/colors";
import { radius } from "theme/foundations/radius";
import { text } from "theme/foundations/text";
import { primary } from "./button/primary";
import { secondary } from "./button/secondary";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    header: {},
  };
});

const variantInfo = definePartsStyle((props) => {
  return {
    dialog: {
      // px: 6,
      // pt: 6,
      // py: 3,
      p: 6,
      position: "relative",
      // chin of the modal part 1
      _before: {
        content: "''",
        position: "absolute",
        bottom: "-4px",
        left: "0",
        width: "100%",
        height: "16px",
        borderBottomRadius: "10px",
        background: "primary.lightMode.default",
      },
      // chin of the modal part 2
      _after: {
        content: "''",
        position: "absolute",
        bottom: "0px",
        left: "0",
        width: "100%",
        height: "16px",
        borderBottomRadius: "10px",
        background: "white",
      },
      _dark: {
        background: "#1E1E1E",
        border: "1px solid",
        borderColor: "border.darkMode.light", // dark mode
        _after: {
          background: "#1E1E1E",
        },
      },
    },
    header: {
      p: 0,
      display: "flex",
      alignItems: "center",
      gap: 2,
      ...text.h3.bold.standard.light,
      _dark: {
        ...text.h3.bold.standard.dark,
      },
    },
    body: {
      p: 0,
      mt: 4,
      mb: 4,
      ...text.body.medium.standard.light,
      _dark: {
        ...text.body.medium.standard.dark,
      },
    },
    closeButton: {
      right: "16px",
      top: "16px",
      // secondary button theme
      ...secondary,
      // secondary button theme overwrites
      borderRadius: radius.sm,
      // color: "text.lightMode.standard",
      fontSize: "10px",
      borderColor: "border.lightMode.light", // dark mode
      _dark: {
        bg: "#1E1E1E",
        color: "white",
        borderColor: "border.darkMode.light", // dark mode
      },
    },
    footer: {
      px: 0,
      pb: 0,
      pt: 4,
      display: "flex",
      justifyContent: "space-between",
      borderTop: "1px solid",
      borderColor: "border.lightMode.light",
      _dark: {
        borderColor: "border.darkMode.light",
      },
    },
  };
});

const upgrade = definePartsStyle((props) => {
  return {
    dialog: {
      width: "500px",
      // px: 6,
      // pt: 6,
      // py: 3,
      p: 6,
      position: "relative",
      background: `radial-gradient(80% 95% at 50% 100%, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), ${colors.primary.lightMode[600]}`,
      backgroundBlendMode: "overlay, normal",
      borderRadius: "10px",
      _hover: {
        // prettier-ignore
        background: `radial-gradient(60% 80% at 50% 110%, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%), ${colors.primary.lightMode[600]}`,
        textDecor: "none",
      },
    },
    header: {
      p: 6,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      placeSelf: "center",
      gap: 2,
      ...text.h3.bold.standard.light,
      color: colors.neutral.lightMode,
      borderBottom: "1px solid",
      borderColor: colors.primary.lightMode[400], // dark mode
    },
    body: {
      color: colors.neutral.lightMode,
      // px: 0,
      // pt: 0,
      // pb: 4,
      p: 8,
      // px: 20,
      // mt: 4,
      // mb: 4,
      // ...text.body.medium.standard.light,
    },
    closeButton: {
      color: "white",
      opacity: .5,
      right: "16px",
      top: "16px",
      borderRadius: radius.sm,
      fontSize: "10px",
      borderColor: colors.primary.lightMode[500], // dark mode
      bg: colors.primary.lightMode[600],
    },
    footer: {
      p: 0,
      display: "flex",
      justifyContent: "space-between",
    },
  };
});

const variantPika = definePartsStyle((props) => {
  return {
    dialog: {
      bg: "#fafafa",
      _dark: {
        bg: "#000",
      },
    },
  };
});
export const Modal = defineMultiStyleConfig({
  baseStyle,
  variants: { info: variantInfo, upgrade, pika: variantPika },
  defaultProps: {
    variant: "info",
    // @ts-ignore
    // isCentered: true, // make modal centered by default
  },
});
