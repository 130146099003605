export function Grammer() {
  return(<svg width="14" height="14" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.7812 9.75C19.0625 10.0312 19.0625 10.5312 18.6875 10.8125L12.4688 16.8125C12.3438 16.9375 12.1562 17.0312 11.9688 17.0312C11.75 17.0312 11.5625 16.9375 11.4375 16.7812L8.21875 13.5312C7.90625 13.25 7.90625 12.7812 8.21875 12.5C8.5 12.1875 8.96875 12.1875 9.25 12.5L12 15.2188L17.7188 9.71875C18 9.4375 18.5 9.4375 18.7812 9.75ZM9.75 9C9.3125 9 9 8.6875 9 8.25V1.75C9 1.34375 9.3125 1 9.75 1H12.125C13.4062 1 14.5 2.09375 14.5 3.375C14.5 3.90625 14.3125 4.375 14.0312 4.75C14.625 5.1875 15 5.875 15 6.625C15 7.9375 13.9062 9 12.625 9H9.75ZM10.5 2.5V4.25H12.125C12.5938 4.25 13 3.875 13 3.375C13 2.90625 12.5938 2.5 12.125 2.5H10.5ZM10.5 5.75V7.5H12.625C13.0938 7.5 13.5 7.125 13.5 6.625C13.5 6.15625 13.0938 5.75 12.625 5.75H10.5ZM7.9375 7.96875C8.09375 8.34375 7.90625 8.78125 7.53125 8.96875C7.4375 9 7.34375 9 7.21875 9C6.9375 9 6.65625 8.84375 6.53125 8.5625L6.21875 7.75H2.75L2.4375 8.5625C2.25 8.9375 1.8125 9.125 1.4375 8.96875C1.0625 8.78125 0.875 8.34375 1.03125 7.96875L3.78125 1.46875C4.03125 0.90625 4.9375 0.90625 5.1875 1.46875L7.9375 7.96875ZM3.40625 6.25H5.5625L4.5 3.6875L3.40625 6.25Z" fill="url(#g)"/>
  <linearGradient
          id="g"
          x1="-5"
          y1="-5"
          x2="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
    </linearGradient> </svg>
  )
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8953 1.74929C13.8445 1.58083 13.6321 1.52784 13.5082 1.65176L12.699 2.46091H11.5391V1.30094L12.3483 0.491398C12.4728 0.36682 12.4187 0.155153 12.2516 0.104321C12.0226 0.0347307 11.7849 0.0050354 11.5414 0.0050354C10.8817 0.0050354 10.2633 0.255504 9.79907 0.720949C9.23029 1.28932 8.97556 2.10203 9.11654 2.89549L9.15419 3.10576L3.10576 9.15417C2.86144 9.1105 2.71431 9.07845 2.46408 9.07845C1.8144 9.07845 1.17874 9.3412 0.720511 9.79904C0.0738313 10.4462 -0.154079 11.3937 0.10421 12.2507C0.155124 12.4195 0.367148 12.4724 0.491343 12.3482L1.30055 11.539H2.46641V12.6994L1.65156 13.5088C1.52712 13.6331 1.5808 13.8451 1.74844 13.896C1.97696 13.9651 2.21427 14 2.4573 14C3.98915 14 5.15233 12.6179 4.8834 11.1044L4.84575 10.8942L10.8938 4.84575C11.134 4.88868 11.2874 4.92174 11.5355 4.92106C12.1863 4.92106 12.8216 4.65869 13.279 4.20088C13.9261 3.55409 14.1538 2.60682 13.8953 1.74929Z"
        fill="black"
      />
      <path
        d="M13.8953 1.74929C13.8445 1.58083 13.6321 1.52784 13.5082 1.65176L12.699 2.46091H11.5391V1.30094L12.3483 0.491398C12.4728 0.36682 12.4187 0.155153 12.2516 0.104321C12.0226 0.0347307 11.7849 0.0050354 11.5414 0.0050354C10.8817 0.0050354 10.2633 0.255504 9.79907 0.720949C9.23029 1.28932 8.97556 2.10203 9.11654 2.89549L9.15419 3.10576L3.10576 9.15417C2.86144 9.1105 2.71431 9.07845 2.46408 9.07845C1.8144 9.07845 1.17874 9.3412 0.720511 9.79904C0.0738313 10.4462 -0.154079 11.3937 0.10421 12.2507C0.155124 12.4195 0.367148 12.4724 0.491343 12.3482L1.30055 11.539H2.46641V12.6994L1.65156 13.5088C1.52712 13.6331 1.5808 13.8451 1.74844 13.896C1.97696 13.9651 2.21427 14 2.4573 14C3.98915 14 5.15233 12.6179 4.8834 11.1044L4.84575 10.8942L10.8938 4.84575C11.134 4.88868 11.2874 4.92174 11.5355 4.92106C12.1863 4.92106 12.8216 4.65869 13.279 4.20088C13.9261 3.55409 14.1538 2.60682 13.8953 1.74929Z"
        fill="url(#paint0_linear_8_87)"
      />
      <path
        d="M13.4004 10.4989L10.5238 7.65076C10.3319 7.45919 10.0933 7.30921 9.83198 7.20459L7.17737 9.8592C7.28196 10.1206 7.43208 10.3594 7.62384 10.5515L10.5 13.3993C11.2995 14.1988 12.5972 14.2016 13.4004 13.3993C14.1999 12.5994 14.1999 11.2984 13.4004 10.4989ZM12.2312 12.2301C12.071 12.3903 11.8114 12.3903 11.6512 12.2301L9.63579 10.2431C9.47558 10.0829 9.47558 9.8233 9.63579 9.66309C9.796 9.50289 10.0556 9.50289 10.2158 9.66309L12.2312 11.6501C12.3914 11.8103 12.3914 12.0699 12.2312 12.2301Z"
        fill="black"
      />
      <path
        d="M13.4004 10.4989L10.5238 7.65076C10.3319 7.45919 10.0933 7.30921 9.83198 7.20459L7.17737 9.8592C7.28196 10.1206 7.43208 10.3594 7.62384 10.5515L10.5 13.3993C11.2995 14.1988 12.5972 14.2016 13.4004 13.3993C14.1999 12.5994 14.1999 11.2984 13.4004 10.4989ZM12.2312 12.2301C12.071 12.3903 11.8114 12.3903 11.6512 12.2301L9.63579 10.2431C9.47558 10.0829 9.47558 9.8233 9.63579 9.66309C9.796 9.50289 10.0556 9.50289 10.2158 9.66309L12.2312 11.6501C12.3914 11.8103 12.3914 12.0699 12.2312 12.2301Z"
        fill="url(#paint1_linear_8_87)"
      />
      <path
        d="M3.50776 2.34767L3.50716 2.34786L3.87707 1.97796C3.98428 1.87063 3.96175 1.69167 3.8322 1.61396L1.32822 0.0584535C1.16686 -0.0383706 0.960283 -0.012941 0.827256 0.120141L0.120256 0.827141C-0.0128804 0.960277 -0.0382554 1.16694 0.0586781 1.32832L1.61525 3.8311C1.69323 3.96081 1.87266 3.98244 1.97919 3.8758L2.34759 3.50746L4.97016 6.13002L6.13014 4.97004L3.50776 2.34767Z"
        fill="black"
      />
      <path
        d="M3.50776 2.34767L3.50716 2.34786L3.87707 1.97796C3.98428 1.87063 3.96175 1.69167 3.8322 1.61396L1.32822 0.0584535C1.16686 -0.0383706 0.960283 -0.012941 0.827256 0.120141L0.120256 0.827141C-0.0128804 0.960277 -0.0382554 1.16694 0.0586781 1.32832L1.61525 3.8311C1.69323 3.96081 1.87266 3.98244 1.97919 3.8758L2.34759 3.50746L4.97016 6.13002L6.13014 4.97004L3.50776 2.34767Z"
        fill="url(#paint2_linear_8_87)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8_87"
          x1="6.99983"
          y1="0.0050354"
          x2="6.99983"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8_87"
          x1="10.5887"
          y1="7.20459"
          x2="10.5887"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8_87"
          x1="3.06513"
          y1="0"
          x2="3.06513"
          y2="6.13002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
