import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { colorScheme } from "theme/names";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

// const baseStyle = definePartsStyle({
//   // define the part you're going to style
//   container: {
//     // ...
//   },
//   thumb: {
//     bg: "red.500",
//   },
//   track: {
//     bg: "gray.100",
//     _checked: {
//       bg: "gray.100",
//     },
//   },
// });

export const Switch = defineMultiStyleConfig({
    defaultProps: {
        colorScheme: colorScheme.primaryChakraColorScheme
    },
});
