export function RefreshIcon() {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0352 9.0001C16.0375 10.5116 15.5325 11.9801 14.6009 13.1705C13.6694 14.3608 12.3654 15.204 10.8976 15.5651C9.4299 15.9262 7.88345 15.7842 6.50602 15.1619C5.12859 14.5395 3.99988 13.4729 3.30072 12.1328C2.60155 10.7928 2.37239 9.25683 2.64994 7.77104C2.92749 6.28525 3.6957 4.93561 4.83149 3.93832C5.96728 2.94104 7.40493 2.35382 8.91413 2.27073C10.4233 2.18765 11.9168 2.61351 13.1552 3.4801L13.0727 3.2401C13.009 3.04616 13.025 2.83488 13.1171 2.65273C13.2093 2.47059 13.37 2.3325 13.5639 2.26885C13.7578 2.2052 13.9691 2.22119 14.1513 2.31332C14.3334 2.40545 14.4715 2.56616 14.5352 2.7601L15.2852 5.0101C15.3223 5.12289 15.3322 5.24289 15.314 5.36025C15.2957 5.4776 15.2499 5.58894 15.1802 5.6851C15.1076 5.78734 15.0105 5.86974 14.8978 5.92477C14.7851 5.9798 14.6604 6.0057 14.5352 6.0001H12.2852C12.0862 6.0001 11.8955 5.92108 11.7548 5.78043C11.6142 5.63978 11.5352 5.44901 11.5352 5.2501C11.5379 5.09135 11.5909 4.93756 11.6866 4.81087C11.7823 4.68417 11.9157 4.59112 12.0677 4.5451C11.0811 3.92847 9.91759 3.65704 8.75998 3.77342C7.60237 3.8898 6.51622 4.38741 5.67211 5.18809C4.82801 5.98877 4.27378 7.04716 4.09648 8.19701C3.91918 9.34687 4.12885 10.523 4.69255 11.5408C5.25626 12.5586 6.14207 13.3602 7.21085 13.8199C8.27963 14.2796 9.47083 14.3712 10.5973 14.0805C11.7239 13.7897 12.7219 13.1329 13.4346 12.2134C14.1474 11.2938 14.5345 10.1635 14.5352 9.0001C14.5352 8.80118 14.6142 8.61042 14.7548 8.46977C14.8955 8.32912 15.0862 8.2501 15.2852 8.2501C15.4841 8.2501 15.6748 8.32912 15.8155 8.46977C15.9561 8.61042 16.0352 8.80118 16.0352 9.0001Z"
        fill="url(#paint0_linear_1892_4092)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1892_4092"
          x1="-5"
          y1="-5"
          x2="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="0.9999" stopColor="#5C69E3" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
