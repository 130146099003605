import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { radius, color } from 'theme/names';

const baseStyle = defineStyle({
  borderRadius: radius.lg, // not being applied. to be investigated
  borderColor: 'border.lightMode.light',
  color: 'text.lightMode.standard',
  _dark: {
    borderColor: 'border.darkMode.light',
    color: 'text.darkMode.standard',
  },
});

const unstyled = defineStyle({
  border:"none",
  shodow:"none",
  _hover: {
    border:"none",
  },
});

const taplio = defineStyle({
  borderRadius: radius.lg, // not being applied. to be investigated
  borderColor: 'border.lightMode.light',
  color: 'text.lightMode.standard',
  _hover: {
    borderColor: color.border.lightMode.hover,
  },
  _dark: {
    borderColor: 'border.darkMode.light',
    color: 'text.darkMode.standard',
    _hover: {
      borderColor: color.border.darkMode.hover,
    },
  },
});

export const TextArea = defineStyleConfig({
  baseStyle,
  variants: { taplio, unstyled },
  defaultProps: {
    colorScheme: "primaryChakraColorScheme",
    variant: "taplio",
  },
})
