export function Cta() {
  return(<svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.625 4.625C8.9375 4.65625 9.25 4.75 9.53125 4.84375C9.59375 4.84375 9.65625 4.875 9.71875 4.875C10.0625 5 10.2812 5.375 10.1875 5.71875C10.0938 6.09375 9.71875 6.28125 9.34375 6.1875C9.3125 6.1875 9.25 6.15625 9.21875 6.15625C9 6.09375 8.78125 6.03125 8.5625 5.96875C8.125 5.90625 7.65625 5.9375 7.28125 6.09375C6.9375 6.25 6.65625 6.625 7.03125 6.875C7.34375 7.0625 7.71875 7.15625 8.0625 7.25C8.15625 7.28125 8.21875 7.3125 8.28125 7.3125C8.78125 7.46875 9.40625 7.625 9.875 7.96875C10.4688 8.375 10.75 9.0625 10.625 9.78125C10.5 10.4688 10 10.9375 9.4375 11.1562C9.1875 11.2812 8.90625 11.3438 8.625 11.375V11.8438C8.625 12.2188 8.34375 12.5 7.96875 12.5C7.59375 12.5 7.28125 12.2188 7.28125 11.8438V11.2812C6.84375 11.1875 6.375 11.0625 5.9375 10.9062C5.59375 10.7812 5.40625 10.4062 5.53125 10.0625C5.625 9.6875 6.03125 9.5 6.375 9.625C6.4375 9.65625 6.53125 9.6875 6.59375 9.6875C6.96875 9.8125 7.3125 9.9375 7.6875 10C8.21875 10.0938 8.625 10.0312 8.90625 9.90625C9.28125 9.78125 9.46875 9.3125 9.09375 9.0625C8.78125 8.84375 8.40625 8.75 8.03125 8.65625C7.9375 8.625 7.875 8.625 7.8125 8.59375C7.34375 8.46875 6.75 8.28125 6.3125 8C5.6875 7.625 5.375 6.96875 5.53125 6.25C5.65625 5.5625 6.1875 5.125 6.75 4.875C6.90625 4.78125 7.09375 4.75 7.28125 4.6875V4.1875C7.28125 3.8125 7.59375 3.5 7.96875 3.5C8.34375 3.5 8.625 3.8125 8.625 4.1875V4.625ZM16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8 1.5C4.40625 1.5 1.5 4.4375 1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5Z" fill="url(#g)"/>
  <linearGradient
          id="g"
          x1="-5"
          y1="-5"
          x2="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
    </linearGradient></svg>
  )
  return (
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.18078 6.48562C1.45777 5.92354 0.991682 5.04586 0.991682 4.06125C0.991682 2.36865 2.36868 0.991653 4.06125 0.991653C5.75381 0.991653 7.13078 2.36865 7.13078 4.06125C7.13078 4.74929 6.90323 5.38519 6.51942 5.89763C6.5268 5.9036 6.53442 5.90925 6.54171 5.91533C6.57608 5.91345 6.61056 5.91249 6.64518 5.91249C6.96279 5.91249 7.27273 5.99403 7.54711 6.14583C7.91232 5.53643 8.12246 4.82342 8.12246 4.06125C8.12246 1.81827 6.30417 0 4.06122 0C1.81827 0 0 1.81827 0 4.06125C0 5.59244 0.847517 6.9255 2.0989 7.61749C2.12611 7.61437 2.15337 7.61158 2.18078 7.60974V6.48562Z"
        fill="black"
      />
      <path
        d="M2.18078 6.48562C1.45777 5.92354 0.991682 5.04586 0.991682 4.06125C0.991682 2.36865 2.36868 0.991653 4.06125 0.991653C5.75381 0.991653 7.13078 2.36865 7.13078 4.06125C7.13078 4.74929 6.90323 5.38519 6.51942 5.89763C6.5268 5.9036 6.53442 5.90925 6.54171 5.91533C6.57608 5.91345 6.61056 5.91249 6.64518 5.91249C6.96279 5.91249 7.27273 5.99403 7.54711 6.14583C7.91232 5.53643 8.12246 4.82342 8.12246 4.06125C8.12246 1.81827 6.30417 0 4.06122 0C1.81827 0 0 1.81827 0 4.06125C0 5.59244 0.847517 6.9255 2.0989 7.61749C2.12611 7.61437 2.15337 7.61158 2.18078 7.60974V6.48562Z"
        fill="url(#paint0_linear_8_95)"
      />
      <path
        d="M4.04625 2.60688C5.07485 2.60688 5.91169 3.44375 5.91169 4.47235V5.30715C6.15216 4.95111 6.29272 4.52231 6.29272 4.06131C6.29272 2.83083 5.29167 1.82977 4.06122 1.82977C2.83077 1.82977 1.82971 2.83083 1.82971 4.06131C1.82971 4.50249 1.95894 4.91382 2.18075 5.26043V4.47235C2.18078 3.44372 3.01762 2.60688 4.04625 2.60688Z"
        fill="black"
      />
      <path
        d="M4.04625 2.60688C5.07485 2.60688 5.91169 3.44375 5.91169 4.47235V5.30715C6.15216 4.95111 6.29272 4.52231 6.29272 4.06131C6.29272 2.83083 5.29167 1.82977 4.06122 1.82977C2.83077 1.82977 1.82971 2.83083 1.82971 4.06131C1.82971 4.50249 1.95894 4.91382 2.18075 5.26043V4.47235C2.18078 3.44372 3.01762 2.60688 4.04625 2.60688Z"
        fill="url(#paint1_linear_8_95)"
      />
      <path
        d="M7.94462 7.58894C7.85638 7.58894 7.77069 7.59999 7.6887 7.6205C7.61241 7.113 7.17356 6.72263 6.64516 6.72263C6.50514 6.72263 6.37154 6.75026 6.24916 6.79999C6.0641 6.49428 5.72837 6.28948 5.34569 6.28948C5.26165 6.28948 5.17997 6.29965 5.10158 6.31831V4.47232C5.10158 3.89041 4.62817 3.41699 4.04626 3.41699C3.46432 3.41699 2.9909 3.89041 2.9909 4.47232V8.72056L2.95171 8.68242C2.77789 8.51326 2.54861 8.41866 2.30612 8.4161C2.30277 8.41604 2.2995 8.41604 2.29618 8.41604C1.99638 8.41604 1.71285 8.56012 1.53619 8.80281C1.35755 9.04817 1.30842 9.36649 1.4047 9.65427L1.77414 10.7585C1.97303 11.353 2.29118 11.8864 2.71978 12.3439L3.55401 13.2345V14.5414H7.87816V13.2754L9.00001 11.2938V8.64427C8.99998 8.06236 8.52656 7.58894 7.94462 7.58894Z"
        fill="black"
      />
      <path
        d="M7.94462 7.58894C7.85638 7.58894 7.77069 7.59999 7.6887 7.6205C7.61241 7.113 7.17356 6.72263 6.64516 6.72263C6.50514 6.72263 6.37154 6.75026 6.24916 6.79999C6.0641 6.49428 5.72837 6.28948 5.34569 6.28948C5.26165 6.28948 5.17997 6.29965 5.10158 6.31831V4.47232C5.10158 3.89041 4.62817 3.41699 4.04626 3.41699C3.46432 3.41699 2.9909 3.89041 2.9909 4.47232V8.72056L2.95171 8.68242C2.77789 8.51326 2.54861 8.41866 2.30612 8.4161C2.30277 8.41604 2.2995 8.41604 2.29618 8.41604C1.99638 8.41604 1.71285 8.56012 1.53619 8.80281C1.35755 9.04817 1.30842 9.36649 1.4047 9.65427L1.77414 10.7585C1.97303 11.353 2.29118 11.8864 2.71978 12.3439L3.55401 13.2345V14.5414H7.87816V13.2754L9.00001 11.2938V8.64427C8.99998 8.06236 8.52656 7.58894 7.94462 7.58894Z"
        fill="url(#paint2_linear_8_95)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8_95"
          x1="4.06123"
          y1="0"
          x2="4.06123"
          y2="7.61749"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8_95"
          x1="4.06122"
          y1="1.82977"
          x2="4.06122"
          y2="5.30715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8_95"
          x1="5.17815"
          y1="3.41699"
          x2="5.17815"
          y2="14.5414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
