export const size = {
  h1: {
    fontSize: "25px",
    lineHeight: "35px",
  },
  h2: {
    fontSize: "20px",
    lineHeight: "28px",
  },
  h3: {
    fontSize: "18px",
    lineHeight: "22px",
  },
  h4: {
    fontSize: "16px",
    lineHeight: "23px",
  },
  body: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  small: {
    fontSize: "12px",
    lineHeight: "15px",
  }
};

export const weights = {
  bold: {
    fontWeight: "600",
  },
  medium: {
    fontWeight: "500",
  },
  regular: {
    fontWeight: "400",
  },
};

export const colors = {
  standard: {
    light: {
      color: "text.lightMode.standard",
    },
    dark: {
      color: "text.darkMode.standard",
    },
  },
  light: {
    light: {
      color: "text.lightMode.light",
    },
    dark: {
      color: "text.darkMode.light",
    },
  },
  primary: {
    light: {
      color: "primary.lightMode.default",
    },
    dark: {
      color: "primary.lightMode.default",
    },
  },
  neutral: {
    light: {
      color: "neutral.lightMode.neutral",
    },
    dark: {
      color: "neutral.darkMode.neutral",
    },
  },
  inactive: {
    light: {
      color: "text.lightMode.inactive",
    },
    dark: {
      color: "text.darkMode.inactive",
    },
  },
};

// helps with theming using _dark prop, can access properties for light and dark colormodes
export const text = {
  h1: {
    bold: {
      standard: {
        light: {
          ...size.h1,
          ...weights.bold,
          ...colors.standard.light,
        },
        dark: {
          ...size.h1,
          ...weights.bold,
          ...colors.standard.dark,
        },
      },
    },
  },
  h2: {
    bold: {
      standard: {
        light: {
          ...size.h2,
          ...weights.bold,
          ...colors.standard.light,
        },
        dark: {
          ...size.h2,
          ...weights.bold,
          ...colors.standard.dark,
        },
      },
    },
  },
  h3: {
    bold: {
      standard: {
        light: {
          ...size.h3,
          ...weights.bold,
          ...colors.standard.light,
        },
        dark: {
          ...size.h3,
          ...weights.bold,
          ...colors.standard.dark,
        },
      },
      light: {
        light: {
          ...size.h3,
          ...weights.bold,
          ...colors.light.light,
        },
        dark: {
          ...size.h3,
          ...weights.bold,
          ...colors.light.dark,
        },
      },
    },
  },
  h4: {
    medium: {
      standard: {
        light: {
          ...size.h4,
          ...weights.medium,
          ...colors.standard.light,
        },
        dark: {
          ...size.h4,
          ...weights.medium,
          ...colors.standard.dark,
        },
      },
    },
    bold: {
      standard: {
        light: {
          ...size.h4,
          ...weights.bold,
          ...colors.standard.light,
        },
        dark: {
          ...size.h4,
          ...weights.bold,
          ...colors.standard.dark,
        },
      },
      light: {
        light: {
          ...size.h4,
          ...weights.bold,
          ...colors.light.light,
        },
        dark: {
          ...size.h4,
          ...weights.bold,
          ...colors.light.dark,
        },
      },
      neutral: {
        light: {
          ...size.h4,
          ...weights.bold,
          ...colors.neutral.light,
        },
        dark: {
          ...size.h4,
          ...weights.bold,
          ...colors.neutral.dark,
        },
      },
      white: {
        light: {
          ...size.h4,
          ...weights.bold,
          ...colors.neutral.light,
        },
        dark: {
          ...size.h4,
          ...weights.bold,
          ...colors.neutral.light,
        },
      },
    },
  },
  body: {
    medium: {
      standard: {
        light: {
          ...size.body,
          ...weights.medium,
          ...colors.standard.light,
        },
        dark: {
          ...size.body,
          ...weights.medium,
          ...colors.standard.dark,
        },
      },
      light: {
        light: {
          ...size.body,
          ...weights.medium,
          ...colors.light.light,
        },
        dark: {
          ...size.body,
          ...weights.medium,
          ...colors.light.dark,
        },
      },
      primary: {
        light: {
          ...size.body,
          ...weights.medium,
          ...colors.primary.light
        },
        dark: {
          ...size.body,
          ...weights.medium,
          ...colors.primary.dark,
        },
      },
      neutral: {
        light: {
          ...size.body,
          ...weights.medium,
          ...colors.neutral.light,
        },
        dark: {
          ...size.body,
          ...weights.medium,
          ...colors.neutral.dark,
        },
      },
      white: {
        light: {
          ...size.body,
          ...weights.medium,
          ...colors.neutral.light,
        },
        dark: {
          ...size.body,
          ...weights.medium,
          ...colors.neutral.light,
        },
      },
      inactive: {
        light: {
          ...size.body,
          ...weights.medium,
          ...colors.inactive.light,
        },
        dark: {
          ...size.body,
          ...weights.medium,
          ...colors.inactive.dark,
        },
      },
    },
    regular: {
      neutral: {
        light: {
          ...size.body,
          ...weights.regular,
          ...colors.neutral.light,
        },
        dark: {
          ...size.body,
          ...weights.regular,
          ...colors.neutral.dark,
        },
      },
    },
    bold: {
      standard: {
        light: {
          ...size.body,
          ...weights.bold,
          ...colors.standard.light,
        },
        dark: {
          ...size.body,
          ...weights.bold,
          ...colors.standard.dark,
        },
      },
      light: {
        light: {
          ...size.body,
          ...weights.bold,
          ...colors.light.light,
        },
        dark: {
          ...size.body,
          ...weights.bold,
          ...colors.light.dark,
        },
      },
    },
  },
  small: {
    bold: {
      standard: {
        light: {
          ...size.small,
          ...weights.bold,
          ...colors.standard.light,
        },
        dark: {
          ...size.small,
          ...weights.bold,
          ...colors.standard.dark,
        },
      },
    },
    medium: {
      standard: {
        light: {
          ...size.small,
          ...weights.medium,
          ...colors.standard.light,
        },
        dark: {
          ...size.small,
          ...weights.medium,
          ...colors.standard.dark,
        },
      },
      light: {
        light: {
          ...size.small,
          ...weights.medium,
          ...colors.light.light,
        },
        dark: {
          ...size.small,
          ...weights.medium,
          ...colors.light.dark,
        },
      },
    },
  },
};
