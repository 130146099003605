import { Box, Flex } from "@chakra-ui/react";
import { SvgLogoIcon } from "components/logo/LogoNew";
import { textStyle } from "theme/names";
import { StyledText } from "../utils";

interface Props {
  content: string;
  contentFormatted?: string;
}

export function AssistantMessage({ content, contentFormatted }: Props) {
  return (
    <Flex
      className="chat-container"
      alignItems="self-start"
      my={2}
      px="2"
      pt="4"
      pb="2"
      rounded="lg"
    >
      <Box {...{ w: 8, h: 8 }}>
        <SvgLogoIcon ai />
      </Box>
      <Flex ml="3" rounded="lg" minH="100%" alignItems="center">
        <Flex
          flexDir="column"
          textStyle={textStyle["body.medium.standard"]}
          fontSize="md"
          lineHeight="1.4"
          fontWeight="400"
        >
          <StyledText
            whiteSpace="pre-line"
            dangerouslySetInnerHTML={{
              __html: contentFormatted ?? content,
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
