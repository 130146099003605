export function getFormattedContacts(contacts) {
  return contacts.map((p) => ({
    id_str: p.id_str,
    twUserName: p.screen_name,
    image_400x400: p?.profile_image_url_https.replace("normal", "400x400"),
    image: p.profile_image_url_https,
    name: p.name,
    description: p.description,
    location: p.location,
    url: p.url,
    friends_count: p.friends_count,
    followers_count: p.followers_count,
    statuses_count: p.statuses_count,
    listed_count: p.listed_count,
    entities: p.entities,
    notes: [],
    lists: [],
    created_at: new Date(),
  }));
}
