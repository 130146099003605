import { memo, useState, useRef, useEffect } from "react";
import {
    useMediaQuery,
    Flex,
    useColorModeValue,
    Divider,
    useColorMode,
    Box,
    Text,
    Textarea,
    IconButton,
    Button,
    Stack,
} from "@chakra-ui/react";
import { useWindowWidth } from '@react-hook/window-size';
import { useSession } from "next-auth/client";
import { useOnClickOutside } from "utils/helpers";
import { getAccount, updateUser } from "../../utils/sessionHelper";
import {
    AddIcon,
    ChevronDownIcon,
    EditIcon,
} from "@chakra-ui/icons";
import shortid from 'shortid';
import {
    AiOutlineSetting,
    AiOutlineEye,
    AiOutlineDownload,
    AiOutlineDelete,
} from "react-icons/ai";
import { layerStyle } from "theme/names";
import { SaveIcon } from "components/icons/SaveIcon";
import { DeleteIcon } from "components/icons/DeleteIcon";

export function Snippet({ snippet, pushText, refresher, setIsEditing, setSelectedSnippet, setMode, setText }) {

    const [session, loading] = useSession();

    const updateSnippet = async () => {
        console.log("update snippet: " + snippet.id);
        setIsEditing(true);
        setSelectedSnippet(snippet);
        setMode("edit");
        setText(snippet.text);
        refresher({});
    }

    const deleteSnippet = async () => {
        console.log("delete snippet: " + snippet.id);
        let snippets = getAccount(session)?.snippets || [];
        snippets = snippets.filter((s) => s.id != snippet.id);
        console.log('snippets:', snippets)
        await updateUser(session, { snippets });
        refresher({});
    }

    return (
        <Box w="100%"
            p={3}
            bg={useColorModeValue("gray.100", "gray.700")}
            rounded="lg"
            cursor="pointer"
            onClick={(e) => {
                pushText("\n" + snippet.text);
            }}
            position="relative"
        >
            <Flex position="absolute" top="5px" right="5px">
                <IconButton
                    aria-label="Edit plug"
                    icon={<EditIcon />}
                    size="sm"
                    variant="action"
                    // mx="2"
                    onClick={(e) => {
                        e.stopPropagation();
                        updateSnippet();
                    }}
                />
                <IconButton
                    aria-label="Delete plug"
                    icon={DeleteIcon}
                    // icon={<AiOutlineDelete />}
                    size="sm"
                    variant="action"
                    // mx="2"
                    onClick={(e) => {
                        e.stopPropagation();
                        deleteSnippet();
                    }}
                />
            </Flex>
            <Text whiteSpace="pre-line" w="80%">{snippet.text}</Text>
        </Box>
    )
}

export function Snippets({ isSnippetsOpen, setIsSnippetsOpen, pushText }) {

    const [session, loading] = useSession();
    const [isEditing, setIsEditing] = useState(false);
    const [mode, setMode] = useState("create");
    const [selectedSnippet, setSelectedSnippet] = useState<any>({});

    const [text, setText] = useState("");
    const [refresh, refresher] = useState({});

    const ref = useRef<any>();
    useOnClickOutside(ref, async () => { await new Promise(resolve => setTimeout(resolve, 200)); setIsSnippetsOpen(false); });

    const saveSnippet = async () => {
        if (mode == "edit") {
            let snippets = getAccount(session)?.snippets || [];
            let snippet = snippets.find((s) => s.id == selectedSnippet?.id);
            if (snippet?.text) {
                snippet.text = text;
                await updateUser(session, { snippets });
            }
        }
        else {
            let newSnippet = {
                text,
                id: shortid.generate(),
            };
            let snippets = getAccount(session)?.snippets || [];
            snippets.push(newSnippet);
            await updateUser(session, { snippets });
        }

        setIsEditing(false);
        setText("");
        refresher({});
    }

    return (
        <Box
            ref={ref}
            position="absolute"
            top="35px"
            left="-115px"
            w="300px"
            // rounded="lg" 
            // bg="white"
            zIndex={20}
            // border="1px"
            // borderColor={useColorModeValue("gray.100", "whiteAlpha.300")}
            // borderRadius="xl"
            // boxShadow="rgba(100, 100, 111, 0.25) 0px 2px 19px 0px"
            p={5}
            layerStyle={layerStyle["bg.border.rounded"]}

        >
            <Flex alignItems={"center"} mb={2}>
                <Text textStyle="h4.bold.standard">Text Snippets</Text>
                <IconButton
                    aria-label="add snippet"
                    ml={5}
                    icon={<AddIcon />}
                    size="sm"
                    variant="tertiary"
                    onClick={() => {
                        setIsEditing(true);
                        setMode("create");
                    }}
                />
            </Flex>

            {
                isEditing && (
                    <Box>
                        <Textarea
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                        <Button variant={"secondary"} my={2} w="100%" onClick={saveSnippet}
                            leftIcon={SaveIcon}
                        >Save Snippet</Button>
                    </Box>
                )
            }

            <Stack spacing={2} maxH="400px" overflowY="auto">
                {
                    getAccount(session)?.snippets?.map((snippet, index) => (
                        <Snippet
                            key={index}
                            snippet={snippet}
                            pushText={pushText}
                            setIsEditing={setIsEditing}
                            setSelectedSnippet={setSelectedSnippet}
                            setMode={setMode}
                            setText={setText}
                            refresher={refresher}
                        />
                    ))
                }
            </Stack>

        </Box>
    );
}


