import { useState, useContext, useEffect } from "react";
import NextLink from "next/link";
import { Box } from "@chakra-ui/layout";
import { Text, Progress, List, Button, Flex, Link, HStack } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useSession } from "next-auth/client";
import { CustomListItem, Task } from "./custom-list-item";
import { getAccount } from "../../utils/sessionHelper";
import { TweetContext } from "../../context/tweetContext";
import { useOnboardingSteps } from "./useOnboardingSteps";

/**
 * Note: don't forget to add "id" while adding a new task.
 * It's literally the most important thing to save task progress.
 */
export const TASKLIST: Task[] = [
  {
    id: "aiInpiration",
    title: "Get AI-selected inspiration",
    link: "/auto",
    thTokens: 10,
    helperText:
      "Our AI automatically finds tweet it believes to be relevant for you. You can edit the settings on the page if you feel like it's a bit off.",
  },
  {
    id: "searchInspiration",
    title: "Search for inspirations",
    link: "/search",
    thTokens: 20,
    helperText:
      "Search for keywords or @handles and find relevant tweets with high engagement.",
  },
  {
    id: "aiWriting",
    title: "Try the AI-writing assistant",
    thTokens: 20,
    helperText:
      "Click the lightning icon at the top-right of any tweet in Tweet Hunter. Our AI will attempt to re-write that tweet and it will prefill the writing/scheduling module on the right of the screen.",
  },
  {
    id: "schedule",
    title: "Schedule a tweet",
    link: "compose",
    thTokens: 50,
    helperText:
      "Schedule or publish your first tweet with Tweet Hunter. Write a tweet in the text box, and choose an option: tweet now, schedule, or add to queue.",
  },
  {
    id: "evergreen",
    title: "Set up Evergreen tweets",
    link: "/collections?tab=evergreen",
    thTokens: 10,
    isSkipable: true,
    helperText:
      "Evergreen tweets are tweets from your account that you regularly retweet automatically. This way, you're still active even when you're not.",
  },
  {
    id: "engage",
    title: "Start engaging with people",
    link: "/engage?tab=people",
    thTokens: 20,
    helperText:
      "Tweet Hunter helps you follow people you want to reply to, and gets rid of their RTs and replies so you can just focus on their tweets. You can also just search for keywords instead of adding people.",
  },
];

export function OnBoardPanel() {
  const [session] = useSession();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const tweetContext: any = useContext(TweetContext);
  const { hidePanel, skipStep } = useOnboardingSteps();
  const onboarding = getAccount(session)?.onboarding;
  const completedSteps: string[] = onboarding?.completedSteps;
  const indexOfCurrentStep = TASKLIST.findIndex(
    (t) => t.id === onboarding?.currentStep
  );
  const stepsToShow = TASKLIST.slice(0, indexOfCurrentStep + 1);

  useEffect(() => {
    const onAllTasksComplete = async () => {
      if (completedSteps?.length === TASKLIST?.length) {
        hidePanel();
      }
    };
    onAllTasksComplete();
  }, [completedSteps]);

  const handleClose = async () => {
    setIsLoading(true);
    await hidePanel();
    setIsLoading(false);
    tweetContext.refresher({});
  };

  const onboardingProgress = (completedSteps?.length * 100) / TASKLIST?.length;
  const thTokens = getAccount(session)?.thTokens || 0;

  return (
    onboarding?.showPanel &&
    !tweetContext?.isFullModeOpen && (
      <Box maxW={1200} margin="auto" p={4} pt={{ base: "16", md: "8" }}>
        <Box
          borderWidth="thin"
          borderColor="gray.200"
          borderRadius="lg"
          px={6}
          py={1}
        >
          <Flex direction="row" justifyContent="space-between" mt="1">
            <Text fontWeight="medium" mt="1">
              Hello 👋 &nbsp; Let's take your Twitter game to the next level!
              Follow these key steps ({indexOfCurrentStep + 1}/{TASKLIST?.length}
              )
            </Text>
            <HStack spacing="6">
              {/* <NextLink href="/tokens" passHref>
                <Link
                  ml="2"
                  fontSize="sm"
                  fontWeight="medium"
                  display={{ base: "none", md: "inline" }}
                  textDecoration="underline"
                >
                  {thTokens} TH token{thTokens > 1 ? "s" : ""}
                </Link>
              </NextLink> */}
              <Button
                size="sm"
                mr="-4"
                onClick={handleClose}
                isLoading={isLoading}
              >
                <CloseIcon />
              </Button>
            </HStack>
          </Flex>

          <Progress
            value={onboardingProgress}
            size="xs"
            variant={"primary"}
            mx="-6"
            my="3"
          />
          <List spacing={3} mb="3">
            {stepsToShow.map(
              ({ id, title, helperText, link, thTokens, isSkipable }) => (
                <CustomListItem
                  title={title}
                  helperText={helperText}
                  isComplete={completedSteps?.includes(id)}
                  link={link}
                  thTokens={thTokens}
                  id={id}
                  isSkipable={isSkipable && id === onboarding?.currentStep}
                  handleSkip={() => skipStep("engage")}
                  key={id}
                />
              )
            )}
          </List>
        </Box>
      </Box>
    )
  );
}
