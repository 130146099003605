// as recommended in https://chakra-ui.com/docs/styled-system/customize-theme#scaling-out-your-project
// # all my border overrides

export const border = {
  default: {
    light: {
      border: "1px solid",
      borderColor: "border.lightMode.light",
    },
    dark: {
      border: "1px solid",
      borderColor: "border.darkMode.light",
    },
    hover: {
      light: {
        border: "1px solid",
        borderColor: "border.lightMode.hover",
      },
      dark: {
        border: "1px solid",
        borderColor: "border.darkMode.hover",
      },
    },
  },
};
