import { tableAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { textStyles } from "theme/textStyles/textStyles";
import { mode } from "@chakra-ui/theme-tools"
import { border } from "theme/foundations/border";
import { text } from "theme/foundations/text";
import { radius } from "theme/foundations/radius";
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

// define the base component styles
  // define the part you're going to style
const simple = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    table: {
      // p:4,
      // border: "1px solid",
      // borderColor: border.default.light.borderColor,
      borderRadius: radius.lg,
      bg: "white",
      _dark: {
        bg: "#1E1E1E",
        // borderColor: border.default.dark.borderColor,
      },
    },
    thead: {
      pb: 4,
      bg: "white",
      _dark: {
        bg: "#1E1E1E",
      },
    },
    th: {
      py: 4,
      borderBottom: "1px solid",
      borderColor: border.default.light.borderColor,
      ...text.small.bold.standard.light,
      bg: "white",
      _dark: {
        borderColor: border.default.dark.borderColor,
        ...text.small.bold.standard.dark,
        bg: "#1E1E1E",
      },
    },
    tr: {
      // my: "2",
      ...text.body.medium.light.light,
      bg: "white",
      _dark: {
        ...text.body.medium.light.dark,
        bg: "#1E1E1E",
      },
    },
    td: {
      // my: 1,
      ...text.body.medium.light.light,
      bg: "white",
      _dark: {
        ...text.body.medium.light.dark,
        bg: "#1E1E1E",
      },
    },
    caption: {},
    tfoot: {
      bg: "white",
      _dark: {
        bg: "#1E1E1E",
      },
    },
  };
});
const simpleNoBg = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    table: {
      // p:4,
      // border: "1px solid",
      // borderColor: border.default.light.borderColor,
      borderRadius: radius.lg,
      // bg: "white",
      _dark: {
        // bg: "#1E1E1E",
        // borderColor: border.default.dark.borderColor,
      },
    },
    thead: {
      // pb: 4,
      // py: 4,
      // borderBottom: "1px solid",
      // borderColor: border.default.light.borderColor,
      // bg: "white",
      _dark: {
        // bg: "#1E1E1E",
      },
    },
    th: {
      py: 4,
      borderBottom: "1px solid",
      borderColor: border.default.light.borderColor,
      ...text.small.bold.standard.light,
      // bg: "white",
      _dark: {
        borderColor: border.default.dark.borderColor,
        ...text.small.bold.standard.dark,
        // bg: "#1E1E1E",
      },
    },
    tr: {
      // my: "2",
      ...text.body.medium.light.light,
      // bg: "white",
      _dark: {
        ...text.body.medium.light.dark,
        // bg: "#1E1E1E",
      },
    },
    td: {
      // my: 1,
      ...text.body.medium.light.light,
      // bg: "white",
      _dark: {
        ...text.body.medium.light.dark,
        // bg: "#1E1E1E",
      },
    },
    caption: {},
    tfoot: {
      // bg: "white",
      _dark: {
        // bg: "#1E1E1E",
      },
    },
  };
});

// export the component theme
export const Table = defineMultiStyleConfig({
  variants: { simple, simpleNoBg },
  defaultProps: {},
});
