import firebaseClient from "firebase/app";
import "firebase/auth";
import 'firebase/firestore';
import * as Sentry from "@sentry/nextjs";

/*

Copy/paste your *client-side* Firebase credentials below. 

To get these, go to the Firebase Console > open your project > Gear Icon >
Project Settings > General > Your apps. If you haven't created a web app
already, click the "</>" icon, name your app, and copy/paste the snippet.
Otherwise, go to Firebase SDK Snippet > click the "Config" radio button >
copy/paste.

*/
const CLIENT_CONFIG = {
  apiKey: "AIzaSyAydmNY5RzExHxpYYpoPGrY1OplhIDynrY",
  authDomain: "discovr-3175b.firebaseapp.com",
  projectId: "discovr-3175b",
  storageBucket: "discovr-3175b.appspot.com",
  messagingSenderId: "179832528694",
  appId: "1:179832528694:web:ca4949da64b0540c765d97",
  measurementId: "G-G35PJGPBV2"
};

let isForceServer = false; 
// let i=3;
// i="3"; // to prevent deploy 

// if (typeof window !== "undefined" && !firebaseClient.apps?.length) {
if (!firebaseClient.apps?.length) {
  // console.log("init Firebase app");
  firebaseClient.initializeApp(CLIENT_CONFIG);
  firebaseClient.firestore().settings({ignoreUndefinedProperties: true});

  let isLocal = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && !isForceServer;
  // console.log("isLocal: " + isLocal);
  
  // if (location.hostname === "localhost") {
  if (isLocal) {
    // var db = firebaseClient.firestore(); 
    // db.useEmulator("localhost", 8080); 
  }

  // firebaseClient
  //   .auth()
  //   .setPersistence(firebaseClient.auth.Auth.Persistence.SESSION);
  // (window as any).firebase = firebaseClient;

}

const firebaseSignOut = async function () {

  try {
    await firebaseClient.auth().signOut();
  }
  catch (e) {
    console.log("Error in firebaseSignOut: " + e.message);
  }
}

const firebaseAuth = async function (session, source="tweetContext") {

  try {
    var user = firebaseClient.auth().currentUser;
    
    if (!user) {
      await firebaseSignIn(session);
    } 
    else {
      if (user.uid !== session?.user?.uid) {
        console.log("error, wrong authentification");
        await firebaseClient.auth().signOut();
        await firebaseSignIn(session);
      }
    }
  }
  catch (e) {
    console.error("Error in firebaseAuth: " + e.message, {e, session});
  }
}

const firebaseSignIn = async (session) => {
  
  let user;

  try {
    if (session?.user?.firebaseToken) {
      firebaseClient.auth().setPersistence(firebaseClient.auth.Auth.Persistence.NONE);
      let userCredential = await firebaseClient.auth().signInWithCustomToken(session?.user?.firebaseToken as string);
      // Signed in
      user = userCredential.user;
      // console.log("firebaseAuth - user authenticated: " + JSON.stringify(user));
    }
    else {
      console.log("firebaseSignIn - no firebaseToken in session");
    }
  } 
  catch (error)  {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("firebaseAuth error: " + error.message, JSON.stringify({error, session}));
      Sentry.captureException(error);
  };

  return user;
}

const getToken = async (session, source="unknown") => {
  if (firebaseClient?.auth()?.currentUser?.getIdToken) {
    //@ts-ignore
    let token = await firebaseClient.auth().currentUser.getIdToken();
    return token;
  }
  else {
    console.log("getToken error: impossible to access to auth user, trying to sign firebase - source: " + source);
    let user = await firebaseSignIn(session);
    if (user) {
      let token = await user.getIdToken();
      return token;
    }
    else 
      return "";
  }
}

export { firebaseClient, firebaseAuth, getToken, firebaseSignOut };
