import { useSession } from "next-auth/client";
import { getAccount, updateUser } from "utils/sessionHelper";
import * as Sentry from "@sentry/nextjs";
import { useFirebaseUser } from "../utils/useFirebaseUser";
import * as analytics from "utils/analytics";

type StatAction =
  | "auto_dm"
  | "access_engage"
  | "search_via_search_page"
  | "search_via_auto_page"
  | "add_to_queue"
  | "schedule_tweet"
  | "tweet_now"
  | "access_analytics"
  | "visit_queue_ghostwriters";

export function useStats() {
  const [session] = useSession();
  // const isAuthenticated = useFirebaseUser();

  async function updateStatsCounter(action: StatAction) {
    try {
      // if (!isAuthenticated) {
      //   console.error("couldn't update stats counter: not authenticated - counter: " + action);
      // }

      const counterKey = `counter_${action}`;
      const stats = getAccount(session)?.stats ?? {};
      const newCounter = stats[counterKey] ?? 0;
      const newStats = { ...stats, [`counter_${action}`]: newCounter + 1 };

      await updateUser(session, {
        stats: newStats,
      });

      await analytics.addUserProperties(newStats, session?.user?.data);
    } catch (e) {
      console.log("error in updateStats for action " + action, e);
      Sentry.captureException(e);
    }
  }

  return { updateStatsCounter };
}
