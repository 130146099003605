import { memo, useMemo, useState, useEffect, useRef } from "react";
import { useSession } from "next-auth/client";
import {
  Text,
  Box,
  InputGroup,
  Input,
  InputLeftElement,
  Flex,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import moment from "moment";
import toast from "react-hot-toast";
import { Message } from "./messages";
import { getAccount } from "utils/sessionHelper";
import { firebaseClient } from "firebaseClient";
import { textStyle } from "theme/names";
import { BsChatSquareText } from "react-icons/bs";
import { GrEdit } from "react-icons/gr";
import { RiDeleteBin5Line } from "react-icons/ri";

interface Props {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  conversations: Conversation[];
  onSelect: (conversation: Conversation) => void;
  onUpdate: (
    conversations: Conversation[],
    options?: { type: "delete"; id: string }
  ) => void;
}

export const History = memo(
  ({ searchTerm, setSearchTerm, conversations, onSelect, onUpdate }: Props) => {
    const [filteredToday, setFilteredToday] = useState<Conversation[]>([]);
    const [filteredYesterday, setFilteredYesterday] = useState<Conversation[]>(
      []
    );
    const [filteredThisWeek, setFilteredThisWeek] = useState<Conversation[]>(
      []
    );
    const [filteredLast60Days, setFilteredLast60Days] = useState<
      Conversation[]
    >([]);
    const [session] = useSession();

    const [today, yesterday, thisWeek, last60Days] = useMemo(() => {
      const sorted = conversations.sort(
        (a, b) => b.updatedAt.valueOf() - a.updatedAt.valueOf()
      );
      let today: Conversation[] = [];
      let yesterday: Conversation[] = [];
      let thisWeek: Conversation[] = [];
      let last60Days: Conversation[] = [];

      sorted.forEach((s) => {
        let updatedAt = moment(s.updatedAt);

        if (updatedAt.isSame(new Date(), "day")) {
          today.push(s);
        } else if (
          updatedAt.isSame(moment().subtract(1, "days").startOf("d"), "d")
        ) {
          yesterday.push(s);
        } else if (updatedAt.isoWeek() == moment().isoWeek()) {
          thisWeek.push(s);
        } else {
          last60Days.push(s);
        }
      });

      return [today, yesterday, thisWeek, last60Days];
    }, [conversations]);

    useEffect(() => {
      if (searchTerm) {
        const resultsInToday = getFilteredBySearchTerm(today, searchTerm);
        setFilteredToday(resultsInToday);

        const resultsInYesterday = getFilteredBySearchTerm(
          yesterday,
          searchTerm
        );
        setFilteredYesterday(resultsInYesterday);

        const resultsInThisWeek = getFilteredBySearchTerm(thisWeek, searchTerm);
        setFilteredThisWeek(resultsInThisWeek);

        const resultsInLast60Days = getFilteredBySearchTerm(
          last60Days,
          searchTerm
        );
        setFilteredLast60Days(resultsInLast60Days);
      } else {
        setFilteredToday(today);
        setFilteredYesterday(yesterday);
        setFilteredThisWeek(thisWeek);
        setFilteredLast60Days(last60Days);
      }
    }, [searchTerm, today]);

    const getFilteredBySearchTerm = (
      items: Conversation[],
      searchTerm: string
    ) => {
      return items.filter((t) => {
        return t.messages.some(
          (m) =>
            m.content?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            m.composerContent?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    };

    const handleDelete = async (id: string) => {
      const db = firebaseClient.firestore();
      await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("chatAssistConversations")
        .doc(id)
        .delete();
      const filteredConversations = conversations.filter((c) => c.id !== id);
      onUpdate(filteredConversations, { type: "delete", id });
    };

    const handleSave = async (id: string, newName: string) => {
      const updatedAt = new Date();
      const db = firebaseClient.firestore();
      await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("chatAssistConversations")
        .doc(id)
        .update({ name: newName, updatedAt });

      const index = conversations.findIndex((c) => c.id === id);
      let updatedConversations = [...conversations];
      updatedConversations[index].name = newName;
      updatedConversations[index].updatedAt = updatedAt;
      onUpdate(updatedConversations);
    };
    return (
      <>
        <Box p="4">
          <InputGroup>
            <Input
              placeholder="Search past conversations..."
              borderRadius="lg"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              textStyle={textStyle["body.medium.standard"]}
            />
            <InputLeftElement
              textStyle={textStyle["body.medium.light"]}
              children={
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.75 15.2188L11.5625 11.0312C12.4688 9.9375 12.9688 8.53125 12.9688 7C12.9688 3.4375 10.0312 0.5 6.46875 0.5C2.875 0.5 0 3.4375 0 7C0 10.5938 2.90625 13.5 6.46875 13.5C7.96875 13.5 9.375 13 10.5 12.0938L14.6875 16.2812C14.8438 16.4375 15.0312 16.5 15.25 16.5C15.4375 16.5 15.625 16.4375 15.75 16.2812C16.0625 16 16.0625 15.5312 15.75 15.2188ZM1.5 7C1.5 4.25 3.71875 2 6.5 2C9.25 2 11.5 4.25 11.5 7C11.5 9.78125 9.25 12 6.5 12C3.71875 12 1.5 9.78125 1.5 7Z"
                    fill="currentColor"
                  />
                </svg>
              }
            />
          </InputGroup>
        </Box>
        <Box flex="1 1 0%" overflow="auto" className="noScrollBar">
          {filteredToday.length > 0 ? (
            <Box mb="4">
              <Text
                fontWeight="semibold"
                ml="6"
                color="#0C67C2"
                mb="1"
                fontSize="sm"
              >
                Today
              </Text>
              {filteredToday.map((t) => (
                <HistoryItem
                  key={t.id}
                  conversation={t}
                  onSelect={onSelect}
                  onDelete={handleDelete}
                  onSave={(newName) => handleSave(t.id, newName)}
                />
              ))}
            </Box>
          ) : null}
          {filteredYesterday.length > 0 ? (
            <Box mb="4">
              <Text
                fontWeight="semibold"
                ml="6"
                color="#0C67C2"
                mb="1"
                fontSize="sm"
              >
                Yesterday
              </Text>
              {filteredYesterday.map((t) => (
                <HistoryItem
                  key={t.id}
                  conversation={t}
                  onSelect={onSelect}
                  onDelete={handleDelete}
                  onSave={(newName) => handleSave(t.id, newName)}
                />
              ))}
            </Box>
          ) : null}
          {filteredThisWeek.length > 0 ? (
            <Box mb="4">
              <Text
                fontWeight="semibold"
                ml="6"
                color="#0C67C2"
                mb="1"
                fontSize="sm"
              >
                Earlier this week
              </Text>
              {filteredThisWeek.map((t) => (
                <HistoryItem
                  key={t.id}
                  conversation={t}
                  onSelect={onSelect}
                  onDelete={handleDelete}
                  onSave={(newName) => handleSave(t.id, newName)}
                />
              ))}
            </Box>
          ) : null}
          {filteredLast60Days.length > 0 ? (
            <Box mb="4">
              <Text
                fontWeight="semibold"
                ml="6"
                color="#0C67C2"
                mb="1"
                fontSize="sm"
              >
                In the last 60 days
              </Text>
              {filteredLast60Days.map((t) => (
                <HistoryItem
                  key={t.id}
                  conversation={t}
                  onSelect={onSelect}
                  onDelete={handleDelete}
                  onSave={(newName) => handleSave(t.id, newName)}
                />
              ))}
            </Box>
          ) : null}
          {conversations.length === 0 ? (
            <Text textAlign="center">No conversations saved yet!</Text>
          ) : filteredToday.length === 0 &&
            filteredYesterday.length === 0 &&
            filteredThisWeek.length === 0 &&
            filteredLast60Days.length === 0 ? (
            <Text textAlign="center">No results found!</Text>
          ) : null}
        </Box>
        <Box>
          <Text
            fontSize="10.5px"
            px="6"
            py="5"
            textAlign="center"
            lineHeight="1.2"
          >
            Conversation history is only saved for 60 days. If you particularly
            like an action you wrote, consider saving it as a favorite!
          </Text>
        </Box>
      </>
    );
  }
);

export interface Conversation {
  id: string;
  name: string;
  messages: Message[];
  createdAt: Date;
  updatedAt: Date;
}

interface HistoryItemProps {
  conversation: Conversation;
  onSelect: (conversation: Conversation) => void;
  onDelete: (id: string) => Promise<void>;
  onSave: (newName: string) => Promise<void>;
}

function HistoryItem({
  conversation,
  onSelect,
  onDelete,
  onSave,
}: HistoryItemProps) {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [name, setName] = useState<string>(conversation.name);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const conversationItemBg = useColorModeValue("gray.100", "whiteAlpha.200");

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
      setName(conversation.name);
    } else {
      setName(conversation.name);
    }
  }, [isEditMode, conversation]);

  const handleDelete = async (e) => {
    try {
      e.stopPropagation();
      setIsDeleting(true);
      await onDelete(conversation.id);
    } catch (e) {
      console.error("Error in deleting conversation: ", e);
      toast.error("Error in deleting conversation: " + e.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSave = async (e) => {
    try {
      e.stopPropagation();
      if (!name) {
        toast.error("Name cannot be left blank");
        return;
      }

      setIsSaving(true);
      await onSave(name);
      setIsEditMode(false);
    } catch (e) {
      console.error("Error in updating conversation name: ", e);
      toast.error("Error in updating conversation name: " + e.message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Flex
      _hover={{ bg: conversationItemBg }}
      p="2"
      pl="6"
      cursor="pointer"
      minH="50px"
      alignItems="center"
      onClick={() => {
        if (isEditMode) {
          return;
        }

        onSelect(conversation);
      }}
    >
      <BsChatSquareText style={{ marginTop: "2px" }} />
      {isEditMode ? (
        <Flex>
          <Input
            variant="unstyled"
            size="sm"
            ml="3"
            ref={inputRef}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <IconButton
            aria-label="confirm"
            variant="secondaryAI"
            borderColor="transparent"
            _hover={{
              borderColor: "border.lightMode.hover",
            }}
            icon={<CheckIcon w="3" h="3" />}
            color="#D792FF !important"
            size="sm"
            ml="2"
            isLoading={isSaving}
            onClick={handleSave}
          />
          <IconButton
            aria-label="cancel"
            variant="secondaryAI"
            borderColor="transparent"
            _hover={{
              borderColor: "border.lightMode.hover",
            }}
            icon={<CloseIcon w="3" h="3" />}
            color="#D792FF !important"
            size="sm"
            ml="1"
            isDisabled={isSaving}
            onClick={(e) => {
              e.stopPropagation();
              setIsEditMode(false);
            }}
          />
        </Flex>
      ) : (
        <>
          <Text fontWeight="normal" fontSize="sm" ml="2.5">
            {name}
          </Text>
          <IconButton
            aria-label="edit conversation name"
            variant="secondaryAI"
            borderColor="transparent"
            _hover={{
              borderColor: "border.lightMode.hover",
            }}
            icon={<GrEdit size={12} strokeOpacity="0.5" />}
            color="#D792FF !important"
            size="sm"
            ml="2"
            onClick={(e) => {
              e.stopPropagation();
              setIsEditMode(true);
              inputRef.current?.focus();
            }}
          />
          <IconButton
            aria-label="Delete conversation"
            variant="secondaryAI"
            borderColor="transparent"
            _hover={{
              borderColor: "border.lightMode.hover",
            }}
            icon={<RiDeleteBin5Line size={13} color="gray" />}
            color="#D792FF !important"
            size="sm"
            ml="1"
            isLoading={isDeleting}
            onClick={handleDelete}
          />
        </>
      )}
    </Flex>
  );
}
