import {
    Box,
    Text,
} from "@chakra-ui/layout";
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    ModalCloseButton,
    ModalFooter,
    Button,
} from "@chakra-ui/react";
import React from "react";
import { useSession } from "next-auth/client";

export const ConfirmPopup = ({ isOpen, onClose, title, body, callback,
    cta = "Confirm",
    ctaProps = {},
    allowClickBackground = false,
    size = "md",
    customFooter = undefined,
    forceIsLoading = false,
}) => {

    const initialRef = React.useRef<HTMLInputElement>(null);
    const [session, loading] = useSession();
    const [isLoading, setIsLoading] = React.useState(false);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={allowClickBackground}
            //@ts-ignore
            initialFocusRef={initialRef}
            size={size}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Box display="flex" justifyContent="space-between">
                        {typeof title === "string" ? <Box>{title}</Box> : title}
                    </Box>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody >
                    {
                        Array.isArray(body) ? (
                            body.map((item, index) => (
                                <Text key={index} mb={4}>
                                    {item}
                                </Text>
                            ))
                        ) : (
                            body
                        )
                    }
                </ModalBody>

                <ModalFooter p={0}>
                    {/* <Button onClick={skipPopup} mr={3}>Skip</Button> */}
                    {customFooter}
                    {!customFooter && (
                        <>
                            <Button variant="tertiary" onClick={onClose}>Cancel</Button>
                            <Button
                                isLoading={isLoading || forceIsLoading}
                                onClick={async () => {
                                    setIsLoading(true);
                                    callback && (await callback());
                                    onClose();
                                    setIsLoading(false);
                                }}
                                // variant={"secondary"} 
                                variant="primary"
                                {...ctaProps}
                            >
                                {cta}
                            </Button>
                        </>)}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
