



export function removeDuplicates (arr, key): any[] {
    
  return [...new Map(arr.map(item => [item[key], item])).values()];
}

export function removeDuplicatesSimpleArray (arr) {
  
  return [...new Set(arr)];
}

export function getRandomInList (list: any, nbToKeep) {

  let selected = [] as any;
  list = [...list];

  for(let i=0; i<nbToKeep; i++) {
      if (list?.length > 0) {
          let randomIndex = Math.floor(Math.random() * list?.length);
          selected.push(list[randomIndex]);
          list.splice(randomIndex, 1);
      }
  }

  return selected;
}

export function hasAdvancedOptions(tweet: any): [boolean, any?] {
  const threadTweets = tweet.text.split("\n\n\n");

  let nbReminders = 0;
  let isPreventLinkExpand = false;
  let isAutoRetweet = false;
  let isAutoPlug = false;
  let isDelayBetweenTweets = false;
  let isAutoDm = false;
  let dmString = "";
  let isAutoRtWithOtherAccounts = false;
  let isLiveTweet = false;

  if (tweet?.isPreventLinkExpand && tweet.text.includes("http")) {
    isPreventLinkExpand = true;
    nbReminders++;
  }
  if (tweet?.isAutoRetweet) {
    isAutoRetweet = true;
    nbReminders++;
  }
  if (tweet?.isAutoPlug) {
    isAutoPlug = true;
    nbReminders++;
  }
  if (tweet?.isDelayBetweenTweets && threadTweets?.length > 1) {
    isDelayBetweenTweets = true;
    nbReminders++;
  }
  if (tweet?.isAutoDm) {
    isAutoDm = true;
    nbReminders++;
    let triggers = [] as any;
    if (tweet?.autoDmTrigger?.retweet) triggers.push("retweet");
    if (tweet?.autoDmTrigger?.reply) triggers.push("reply");
    if (tweet?.autoDmTrigger?.like) triggers.push("like");
    dmString = triggers.join(" and ");
  }
  if (tweet?.autoRtWithOtherAccounts) {
    isAutoRtWithOtherAccounts = true;
    nbReminders++;
  }
  if (tweet?.isLiveTweet) {
    isLiveTweet = true;
    nbReminders++;
  }

  if (nbReminders > 0) {
    return [
      true,
      {
        isPreventLinkExpand,
        isAutoRetweet,
        isAutoPlug,
        isDelayBetweenTweets,
        isAutoDm,
        isAutoRtWithOtherAccounts,
        isLiveTweet,
        dmString
      },
    ];
  }

  return [false];
}

export function formatDescription(who: string, topics: string): string {

  if (!who || typeof who !== "string")
    who = "";

  if (!topics || typeof topics !== "string")
    topics = "";

  who = who.trim();
  topics = topics.trim();

  if (who?.length && ![".", ","].includes(who[who?.length-1]))
    who += ".";

  if (topics?.length && ![".", ","].includes(topics[topics?.length-1]))
    topics += ".";

  let description = who + " " + topics;
  // console.log("description: " + description);

  return description;
}
