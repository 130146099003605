import { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

export function AiButtonSVGIcon({ style = {} }: Props) {
  return (
    <svg width="14" height="14" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.03125 0.03125C9.625 0.03125 10.0625 0.59375 9.90625 1.15625L8.21875 7H10.9062C11.5 7 12 7.5 12 8.09375C12 8.4375 11.8438 8.71875 11.5938 8.9375L3.5 15.8125C3.34375 15.9375 3.15625 16 2.9375 16C2.34375 16 1.90625 15.4375 2.0625 14.875L3.75 9H1.03125C0.46875 9 0 8.53125 0 7.96875C0 7.65625 0.125 7.34375 0.34375 7.15625L8.46875 0.21875C8.625 0.09375 8.8125 0 9.03125 0V0.03125ZM7.90625 2.65625L2.25 7.5H4.75C4.96875 7.5 5.1875 7.625 5.34375 7.8125C5.46875 8 5.53125 8.25 5.46875 8.46875L4.0625 13.375L9.78125 8.5H7.25C7 8.5 6.78125 8.40625 6.625 8.21875C6.5 8.03125 6.4375 7.78125 6.5 7.5625L7.90625 2.65625Z" fill="url(#g)" />
      <linearGradient
        id="g"
        x1="-5"
        y1="-5"
        x2="25"
        y2="25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EC6181" />
        <stop offset="1" stopColor="#5C69E3" />
      </linearGradient>
    </svg>
  )
  return (
    <svg
      width="11"
      height="17"
      viewBox="0 0 11 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M3.63303 18.7286C3.88137 18.8347 4.17011 18.7461 4.31517 18.517L10.9151 8.03041C11.022 7.86068 11.0284 7.64637 10.9317 7.47129C10.835 7.29566 10.6503 7.18661 10.4499 7.18661H6.04515L7.68333 0.685356C7.7494 0.422716 7.61564 0.151459 7.36697 0.0445765C7.11991 -0.0617556 6.82933 0.02738 6.68483 0.256177L0.0849081 10.7427C-0.021974 10.9125 -0.0284274 11.1268 0.0682615 11.3018C0.16495 11.4775 0.349712 11.5865 0.550056 11.5865H4.95485L3.31667 18.0878C3.2506 18.3505 3.38432 18.6217 3.63303 18.7286Z"
        fill="url(#paint0_linear_0_40)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_40"
          x1="5.5"
          y1="0"
          x2="5.5"
          y2="18.7731"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
