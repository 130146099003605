export function LibraryIcon2() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9375 13.625C16.1562 14.4062 15.6875 15.2188 14.875 15.4375L12.9375 15.9688C12.8125 16 12.6875 16 12.5625 16C11.875 16 11.2812 15.5625 11.0938 14.9062L8.5 5.1875V14.5C8.5 15.3438 7.8125 16 7 16H5C4.71875 16 4.46875 15.9375 4.25 15.8125C4 15.9375 3.75 16 3.5 16H1.5C0.65625 16 0 15.3438 0 14.5V1.5C0 0.6875 0.65625 0 1.5 0H3.5C3.75 0 4 0.09375 4.25 0.21875C4.46875 0.09375 4.71875 0 5 0H7C7.5625 0 8.0625 0.34375 8.3125 0.8125C8.4375 0.71875 8.625 0.625 8.8125 0.59375L10.7188 0.0625C10.875 0.03125 11 0 11.125 0C11.7812 0 12.375 0.46875 12.5625 1.125L15.9375 13.625ZM7 1.5H5V3H7V1.5ZM5 4.5V11.5H7V4.5H5ZM3.5 11.5V4.5H1.5V11.5H3.5ZM3.5 1.5H1.5V3H3.5V1.5ZM1.5 14.5H3.5V13H1.5V14.5ZM5 14.5H7V13L5 13.0312V14.5ZM9.1875 2.03125L9.5625 3.46875L11.5 2.9375L11.125 1.53125L9.1875 2.03125ZM9.96875 4.90625L11.7812 11.625L13.7188 11.125L11.9062 4.40625L9.96875 4.90625ZM12.5625 14.5312L14.5 14L14.0938 12.5625L12.1562 13.0938L12.5625 14.5312Z"
        fill="url(#paint0_linear_486_30534)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_486_30534"
          x1="0.4"
          y1="-0.2"
          x2="16.6"
          y2="16.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#316BFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
