export function BulbIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.875 17.7734V16.25H13.125L13.0859 17.7734C13.0859 17.9688 13.0078 18.2812 12.8906 18.4375L12.2266 19.4531C12.0312 19.7656 11.5625 20 11.1719 20H8.78906C8.39844 20 7.92969 19.7656 7.73438 19.4531L7.07031 18.4375C6.91406 18.2422 6.875 18.0078 6.875 17.7734ZM10 0C13.7891 0.0390625 16.875 3.08594 16.875 6.875C16.875 8.63281 16.2109 10.1953 15.1562 11.3672C14.4922 12.1094 13.5156 13.6719 13.125 14.9609C13.125 14.9609 13.125 14.9609 13.125 15H6.83594C6.83594 14.9609 6.83594 14.9609 6.83594 14.9609C6.44531 13.6719 5.46875 12.1094 4.80469 11.3672C3.75 10.1562 3.125 8.59375 3.125 6.875C3.125 3.24219 6.01562 0.0390625 10 0ZM13.75 10.1953C14.5312 9.25781 15 8.08594 15 6.875C15 4.14062 12.7344 1.875 9.96094 1.875C6.875 1.91406 5 4.45312 5 6.875C5 8.08594 5.42969 9.25781 6.21094 10.1953C6.83594 10.8594 7.57812 11.9922 8.125 13.125H11.8359C12.3828 11.9922 13.125 10.8594 13.75 10.1953ZM9.375 3.125C9.6875 3.125 10 3.4375 10 3.78906C10 4.10156 9.6875 4.375 9.375 4.375C8.32031 4.375 7.5 5.23438 7.5 6.25C7.5 6.60156 7.1875 6.875 6.875 6.875C6.52344 6.875 6.25 6.60156 6.25 6.25C6.25 4.53125 7.61719 3.125 9.375 3.125Z"
        fill="url(#paint0_linear_77_28227)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_77_28227"
          x1="0.5"
          y1="0.2625"
          x2="19.7001"
          y2="20.7227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#316BFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
