export function Casual() {
  return (
    <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5 6C14.4062 6 16 7.59375 16 9.5C16 11.4375 14.4062 13 12.5 13H12C12 14.6562 10.6562 16 9 16H3C1.34375 16 0 14.6562 0 13V7C0 6.46875 0.4375 6 1 6H12.5ZM10.5 13V7.5H1.5V13C1.5 13.8438 2.15625 14.5 3 14.5H9C9.8125 14.5 10.5 13.8438 10.5 13ZM12.5 11.5C13.5938 11.5 14.5 10.625 14.5 9.5C14.5 8.40625 13.5938 7.5 12.5 7.5H12V11.5H12.5ZM3.34375 3.15625C2.5 2.65625 2 1.75 2 0.78125C2 0.375 2.3125 0.03125 2.75 0.03125C3.15625 0.03125 3.5 0.375 3.5 0.78125C3.5 1.21875 3.71875 1.65625 4.125 1.875C4.96875 2.375 5.5 3.28125 5.5 4.25C5.5 4.65625 5.15625 5 4.75 5C4.3125 5 4 4.6875 4 4.25C4 3.8125 3.75 3.375 3.34375 3.15625ZM6.84375 3.15625C6 2.65625 5.5 1.75 5.5 0.78125C5.5 0.375 5.8125 0.03125 6.25 0.03125C6.65625 0.03125 7 0.375 7 0.78125C7 1.21875 7.21875 1.65625 7.625 1.875C8.46875 2.375 9 3.28125 9 4.25C9 4.65625 8.65625 5 8.25 5C7.8125 5 7.5 4.6875 7.5 4.25C7.5 3.8125 7.25 3.375 6.84375 3.15625Z" fill="url(#g)"/>

<linearGradient
          id="g"
          x1="-5"
          y1="-5"
          x2="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
      </linearGradient>
    </svg>

  )
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9818 4.03527C13.8257 4.03527 13.678 3.94183 13.6164 3.78808C13.2352 2.83605 12.625 1.97261 11.8517 1.29121C11.6888 1.14761 11.6732 0.899021 11.8167 0.736208C11.9603 0.57324 12.2089 0.557771 12.3718 0.701208C13.2369 1.46355 13.9199 2.42996 14.3466 3.49574C14.4274 3.6973 14.3293 3.92636 14.1277 4.00699C14.0799 4.02621 14.0305 4.03527 13.9818 4.03527Z"
        fill="url(#paint0_linear_0_75)"
      />
      <path
        d="M1.12346 7.6673C0.936116 7.6673 0.770178 7.53292 0.736584 7.34198C0.510803 6.05698 0.629084 4.73448 1.07846 3.5173C1.15377 3.31339 1.37987 3.20933 1.58362 3.28464C1.78737 3.3598 1.89158 3.58605 1.81627 3.7898C1.41502 4.87651 1.30955 6.05777 1.51127 7.20589C1.54893 7.4198 1.40596 7.6237 1.19205 7.66136C1.16893 7.66526 1.14612 7.6673 1.12346 7.6673Z"
        fill="url(#paint1_linear_0_75)"
      />
      <path
        d="M3.77268 9.02885C3.77752 9.03245 4.36065 9.5376 4.59471 9.82792C5.29362 10.6949 7.42096 13.3332 10.1861 12.0518C11.2346 11.566 11.8855 10.9618 12.1761 10.2045C12.528 9.28776 12.5074 8.30182 12.118 7.42838L9.95893 2.65041C9.89658 2.51057 9.78346 2.40338 9.64034 2.34854C9.49737 2.2937 9.34143 2.29791 9.20158 2.36026C9.06158 2.4226 8.9544 2.53573 8.89955 2.6787C8.84471 2.82182 8.84893 2.97745 8.91127 3.11745L10.0597 5.77229C10.1133 5.89635 10.0567 6.04057 9.93299 6.09495C9.80908 6.14932 9.66471 6.09354 9.60971 5.96995L7.83627 1.99245C7.71784 1.72682 7.40534 1.60713 7.13955 1.72557L7.0558 1.76291C6.92705 1.82026 6.82846 1.92432 6.77799 2.05588C6.73377 2.17104 6.73127 2.29541 6.76987 2.41104L8.41112 6.09245C8.46643 6.21635 8.4108 6.36166 8.28674 6.41698C8.16284 6.47213 8.01752 6.41666 7.96221 6.29276L5.93971 1.75557C5.88237 1.62698 5.7783 1.52838 5.64674 1.47791C5.51518 1.42745 5.3719 1.43135 5.24315 1.4887L5.1594 1.52604C5.03065 1.58338 4.93205 1.68745 4.88159 1.81901C4.83112 1.95057 4.83502 2.09385 4.89237 2.2226L6.84971 6.58276C6.90502 6.70666 6.8494 6.85198 6.72534 6.90729C6.60143 6.9626 6.45596 6.90698 6.4008 6.78292L4.93893 3.50416C4.88158 3.37557 4.77752 3.27682 4.64596 3.22635C4.5144 3.17588 4.37112 3.17979 4.24221 3.23713L4.15862 3.27448C4.02971 3.33198 3.93112 3.43604 3.88065 3.5676C3.83018 3.69916 3.83409 3.84245 3.89143 3.9712L5.66799 7.95588L6.87784 8.80448C6.98893 8.88229 7.0158 9.03542 6.93815 9.14651C6.86034 9.25776 6.70721 9.28479 6.59612 9.20729L4.66018 7.84932C4.52659 7.74979 4.37252 7.6987 4.21455 7.6987C4.17877 7.6987 4.14284 7.70135 4.1069 7.70651C3.9119 7.73495 3.73955 7.8376 3.62174 7.99573C3.50393 8.1537 3.45471 8.34823 3.48315 8.54323C3.5119 8.73885 3.61455 8.9112 3.77268 9.02885Z"
        fill="url(#paint2_linear_0_75)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_75"
          x1="13.0467"
          y1="0.603027"
          x2="13.0467"
          y2="4.03527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_75"
          x1="1.23288"
          y1="3.26013"
          x2="1.23288"
          y2="7.6673"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_75"
          x1="7.95054"
          y1="1.44269"
          x2="7.95054"
          y2="12.3968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#5C69E3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
