import ReactGiphySearchbox from "react-giphy-searchbox";

interface Props {
  onSelect: (item: any) => void;
}

export function GifBox({ onSelect }: Props) {
  return (
    <ReactGiphySearchbox
      apiKey={process.env.NEXT_PUBLIC_GIPHY_API_KEY}
      onSelect={onSelect}
      masonryConfig={[{ columns: 3, imageWidth: 110, gutter: 5 }]}
      autoFocus={true}
    />
  );
}
