export function WriteIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0078 2.22656C20.9844 3.20312 20.9844 4.76562 20.0078 5.74219L7.46875 18.2812C7.11719 18.6328 6.72656 18.8672 6.25781 18.9453C4.77344 19.2969 1.49219 20 1.49219 20C1.45312 20 1.375 20 1.33594 20C0.984375 20 0.671875 19.6484 0.75 19.2578L1.80469 14.4922C1.92188 14.0234 2.15625 13.5938 2.50781 13.2422L15.0078 0.742188C15.4766 0.273438 16.1406 0 16.7656 0C17.3906 0 18.0547 0.273438 18.5234 0.742188L20.0078 2.22656ZM6.14062 16.9531L15.6328 7.5L13.25 5.11719L3.83594 14.5703C3.71875 14.6484 3.67969 14.7656 3.64062 14.8828L3.01562 17.7734C3.99219 17.5391 5.125 17.3047 5.82812 17.1484C5.94531 17.1094 6.0625 17.0703 6.14062 16.9531ZM22.3125 18.125C22.8203 18.125 23.25 18.5547 23.25 19.0625C23.25 19.6094 22.8203 20 22.3125 20H10.4375C9.89062 20 9.5 19.6094 9.5 19.0625C9.5 18.5547 9.89062 18.125 10.4375 18.125H22.3125Z"
        fill="url(#paint0_linear_77_28232)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_77_28232"
          x1="2.5"
          y1="0.2625"
          x2="21.7001"
          y2="20.7227"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC6181" />
          <stop offset="1" stopColor="#316BFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
