import { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  Button,
  Flex,
  IconButton,
  Textarea,
} from "@chakra-ui/react";
import moment from "moment";
import { AddIcon } from "@chakra-ui/icons";
import { AiOutlineDelete } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import toast from "react-hot-toast";
import { useSession } from "next-auth/client";
import { v4 as uuid } from "uuid";
import { getAccount } from "utils/sessionHelper";
import { firebaseClient } from "firebaseClient";
import { getFormattedContacts } from "components/add-people/utils";
import { DeleteIcon } from "components/icons/DeleteIcon";
import { PlusIcon } from "components/icons/PlusIcon";
import { textStyle } from "theme/names";
import { EditIconSVG } from "components/icons/EditIconSVG";
import { EditIconComponent } from "components/icons/EditIconComponent";
import { DeleteIconComponent } from "components/icons/DeleteIconComponent";

interface Props {
  contact: any;
  setContact: (notes: any) => void;
}

export function Notes({ contact, setContact }: Props) {
  const [showNoteComposer, setShowNoteComposer] = useState<boolean>(false);;
  const [note, setNote] = useState<string>("");
  const [editNoteId, setEditNoteId] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [deleteNoteId, setDeleteNoteId] = useState<string>("");
  const [savedContact, setSavedContact] = useState<any>(null);
  const [session] = useSession();

  useEffect(() => {
    setShowNoteComposer(false);
    setNote("");
    fetchNotes();
  }, [contact]);

  const fetchNotes = async () => {
    if (!contact) {
      return;
    }

    const savedContact = await getSavedContact(contact.id_str);

    if (savedContact) {
      setSavedContact(savedContact);
      return;
    }

    setSavedContact({ ...contact });
  };

  const handleClick = async () => {
    try {
      if (!note) {
        toast.error("Please enter some text");
        return;
      }
      setIsSaving(true);

      let updatedNoteList: any[] = [];
      const notes = savedContact.notes || [];

      if (editNoteId) {
        const newList = notes.map((n) => {
          if (n.id === editNoteId) {
            return { ...n, text: note };
          } else {
            return n;
          }
        });

        updatedNoteList = newList;
      } else {
        let data: any = {
          id: uuid(),
          text: note,
          createdAt: new Date(),
        };
        updatedNoteList = [...notes, data];
      }

      let contactToUpdate = { ...contact };
      const db = firebaseClient.firestore();

      if (!savedContact?.image_400x400) {
        let formattedContact = getFormattedContacts([contact])[0];

        await db
          .collection("users")
          .doc(getAccount(session)?.id)
          .collection("contacts")
          .doc(formattedContact.id_str)
          .set(formattedContact);

        contactToUpdate = formattedContact;
      }

      await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("contacts")
        .doc(contactToUpdate.id_str)
        .update({ notes: updatedNoteList });

      toast.success("Successfully added");
      setContact({ ...contactToUpdate, notes: updatedNoteList });
      setNote("");
      setShowNoteComposer(false);
    } catch (err) {
      console.log("Error in saving note: ", err);
      toast.error("Error in saving note: ", err.message);
    } finally {
      setIsSaving(false);
    }
  };

  const getSavedContact = async (id: string) => {
    const db = firebaseClient.firestore();
    const contactDoc = await db
      .collection("users")
      .doc(getAccount(session)?.id)
      .collection("contacts")
      .doc(id)
      .get();

    return contactDoc.data();
  };

  const handleDelete = async (id: string) => {
    try {
      setDeleteNoteId(id);
      const db = firebaseClient.firestore();
      const filteredNotes = [...savedContact.notes].filter((n) => n.id !== id);

      await db
        .collection("users")
        .doc(getAccount(session)?.id)
        .collection("contacts")
        .doc(savedContact.id_str)
        .update({ notes: filteredNotes });

      setContact({ ...savedContact, notes: filteredNotes });
    } catch (err) {
      console.log("Error in deleting note: ", err);
      toast.error("Error in deleting note: ", err.message);
    } finally {
      setDeleteNoteId("");
    }
  };

  const compare = (ad, bd) => {
    const a = ad?.createdAt?.toDate ? ad?.createdAt?.toDate() : ad?.createdAt;
    const b = bd?.createdAt?.toDate ? bd?.createdAt?.toDate() : bd?.createdAt;
    return new Date(b).getTime() - new Date(a).getTime();
  };

  return (
    // <Box textAlign="left" mt="6" px="4" w="330px">
    //   <Text fontWeight="bold" fontSize="sm" color="gray.500">
    //     Personal notes:{" "}
    //   </Text>
    <Box
    textAlign="left"
    mt="8"
  >
    <Text textStyle={textStyle["body.medium.standard"]} >
      Personal notes:{" "}
    </Text>

      {savedContact?.notes?.length > 0 && (
        <VStack mt="4" spacing="4" alignItems="end">
          {savedContact?.notes?.sort(compare).map((n) => (
            <Button
              key={n.id}
              py={3}
              height="100%"
              overflow="hidden"
              width="100%"
              as="div"
              alignItems="left"
              justifyContent="flex-start"
              flexDirection="column"
              whiteSpace="normal"
            >
              <Flex
                fontSize="xs"
                alignItems="center"
                justifyContent="space-between"
                h="3"
                w="100%"
              >
                <Text textStyle={textStyle["body.medium.light"]}
                  // as="i"
                >
                  {moment(
                    n?.createdAt?.toDate ? n?.createdAt?.toDate() : n?.createdAt
                  ).format("MMMM Do YYYY")}
                </Text>
                <Box>
                  <IconButton
                    variant="action"
                    aria-label="edit note"
                    size="xs"
                    background="none"
                    icon={<EditIconComponent width={14}/>}
                    onClick={() => {
                      setEditNoteId(n.id);
                      setNote(n.text);
                      setShowNoteComposer(true);
                    }}
                  />
                  <IconButton
                    variant="action"
                    aria-label="delete note"
                    size="xs"
                    ml="2"
                    background="none"
                    isLoading={n.id === deleteNoteId}
                    icon={<DeleteIconComponent width={12}/>}
                    onClick={() => handleDelete(n.id)}
                  />
                </Box>
              </Flex>
              <Text textStyle={textStyle["body.medium.standard"]} fontSize="sm" mt="3">
                {n.text}
              </Text>
            </Button>
          ))}
          <Button
            variant="secondary"
            // fontWeight="400"
            // color="gray.500"
            // leftIcon={<AddIcon h="3" w="3" />}
            leftIcon={PlusIcon}
            onClick={() => setShowNoteComposer(true)}
          >
            Add note
          </Button>
        </VStack>
      )}

      {!savedContact?.notes?.length && !showNoteComposer && (
        <Box textAlign="start" mt="6">
          <Text textStyle={textStyle["body.medium.light"]}>You have not added any notes</Text>
          {/* <Text>You have not added any notes</Text> */}
          <Button
            variant={"secondary"}
            // size="sm"
            mt="4"
            onClick={() => setShowNoteComposer(true)}
          >
            Create my first note
          </Button>
        </Box>
      )}
      {showNoteComposer && (
        <Box textAlign="end" mt="4">
          <Textarea
            placeholder="Write your note..."
            h="160px"
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
          <Button
            variant="secondaryDestructive"
            // variant={"secondary"}
            // size="sm"
            colorScheme="red"
            mt="3"
            px="4"
            onClick={() => {
              setShowNoteComposer(false);
              setNote("");
            }}
            isDisabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            // size="sm"
            // variant="secondary"
            variant={"primary"}
            mt="3"
            px="4"
            ml="3"
            onClick={handleClick}
            isLoading={isSaving}
          >
            Create
          </Button>
        </Box>
      )}
    </Box>
  );
}
