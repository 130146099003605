import { Box, Icon } from "@chakra-ui/react";

export function AiIcon2({ width = "16px", height = "16px", glow = false, dark = false }) {
  return (
    <Icon viewBox="0 0 13 16"
      {...{
        width,
        height,
        filter: glow ? dark ? "drop-shadow(0px 0px 1px #000) drop-shadow(0px 0px 4px #000a)" : "drop-shadow(0px 0px 1px #fff) drop-shadow(0px 0px 4px #fffa)" : undefined
      }}
    >
      <defs>
        <linearGradient id="Gradient" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor={"#e86d8a"} />
          <stop offset="100%" stopColor={"#527ff0"} />
        </linearGradient>
      </defs>
      <path
        d="M9.69791 0.03125C10.2917 0.03125 10.7292 0.59375 10.5729 1.15625L8.88541 7H11.5729C12.1667 7 12.6667 7.5 12.6667 8.09375C12.6667 8.4375 12.5104 8.71875 12.2604 8.9375L4.16666 15.8125C4.01041 15.9375 3.82291 16 3.60416 16C3.01041 16 2.57291 15.4375 2.72916 14.875L4.41666 9H1.69791C1.13541 9 0.666656 8.53125 0.666656 7.96875C0.666656 7.65625 0.791656 7.34375 1.01041 7.15625L9.13541 0.21875C9.29166 0.09375 9.47916 0 9.69791 0V0.03125ZM8.57291 2.65625L2.91666 7.5H5.41666C5.63541 7.5 5.85416 7.625 6.01041 7.8125C6.13541 8 6.19791 8.25 6.13541 8.46875L4.72916 13.375L10.4479 8.5H7.91666C7.66666 8.5 7.44791 8.40625 7.29166 8.21875C7.16666 8.03125 7.10416 7.78125 7.16666 7.5625L8.57291 2.65625Z"
        fill="url(#Gradient)"
      />
    </Icon>
  );
}

export function AiSpinner({ width = "16px", height = "16px", glow = false, dark = false }) {
  return (
    <Icon viewBox="0 0 16 16"
      {...{
        width,
        height,
        // filter: glow ? dark ? "drop-shadow(0px 0px 1px #000) drop-shadow(0px 0px 4px #000a)" : "drop-shadow(0px 0px 1px #fff) drop-shadow(0px 0px 4px #fffa)" : undefined
      }}
    >
      <defs>
        <linearGradient id="Gradient" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor={"#e86d8a"} />
          <stop offset="100%" stopColor={"#527ff0"} />
        </linearGradient>
      </defs>
      {/* <path
        d="M9.69791 0.03125C10.2917 0.03125 10.7292 0.59375 10.5729 1.15625L8.88541 7H11.5729C12.1667 7 12.6667 7.5 12.6667 8.09375C12.6667 8.4375 12.5104 8.71875 12.2604 8.9375L4.16666 15.8125C4.01041 15.9375 3.82291 16 3.60416 16C3.01041 16 2.57291 15.4375 2.72916 14.875L4.41666 9H1.69791C1.13541 9 0.666656 8.53125 0.666656 7.96875C0.666656 7.65625 0.791656 7.34375 1.01041 7.15625L9.13541 0.21875C9.29166 0.09375 9.47916 0 9.69791 0V0.03125ZM8.57291 2.65625L2.91666 7.5H5.41666C5.63541 7.5 5.85416 7.625 6.01041 7.8125C6.13541 8 6.19791 8.25 6.13541 8.46875L4.72916 13.375L10.4479 8.5H7.91666C7.66666 8.5 7.44791 8.40625 7.29166 8.21875C7.16666 8.03125 7.10416 7.78125 7.16666 7.5625L8.57291 2.65625Z"
        fill="url(#Gradient)"
      /> */}
      <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill="url(#Gradient)" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" />
        <path fill="url(#Gradient)" d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z">
          <animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite" />
        </path>
      </svg>
    </Icon>
  );
}