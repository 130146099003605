import { memo, useState } from "react";
import { useSession } from "next-auth/client";
import {
  HStack,
  Card,
  Button,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import { BsThreeDots } from "react-icons/bs";
import { isAllowed } from "controllers/subscription";
import { EnterpriseDiscount } from "components/popups/enterprise-discount";
import { getToken } from "firebaseClient";
import { getAccount } from "utils/sessionHelper";

const bigButtons = [
  {
    title: "👍 Agree",
    mood: "agree",
  },
  {
    title: "👏 Congrats",
    mood: "congrats",
  },
  {
    title: "🤔 Question",
    mood: "question",
  },
];

const menuButtons = [
  {
    title: "☝️ One-liner",
    mood: "oneliner",
  },
  {
    title: "👎 Disagree",
    mood: "disagree",
  },
  {
    title: "😂 Funny",
    mood: "funny",
  },
  {
    title: "😎 Quote",
    mood: "quote",
  },
  {
    title: "🤓 Answer",
    mood: "answer",
  },
  {
    title: "🙏 Thanks",
    mood: "thanks",
  },
];

interface Props {
  tweetText: string;
  onNewText?: (newText: string) => void;
}

export function AIReplyButtons({ tweetText, onNewText = () => { } }: Props) {
  const [isLoading, setIsLoading] = useState<string>("");
  const [session] = useSession();
  const {
    isOpen: isOpenEnterprise,
    onOpen: onOpenEnterprise,
    onClose: onCloseEnterprise,
  } = useDisclosure();
  const variant = useColorModeValue("none", "secondary");

  const handleClick = async (type: string) => {
    try {
      if (!isAllowed(session?.user, "replyGeneration")) {
        onOpenEnterprise();
        return;
      }

      setIsLoading(type);
      const dataToSend = {
        idUser: getAccount(session)?.id,
        text: tweetText,
        mood: type,
        author: getAccount(session)?.twUserName,
      };

      const response = await fetch(
        "https://us-central1-ez4cast.cloudfunctions.net/tweetReplyBear-generate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getToken(session, "generateReply")}`,
            tokenuserid: session?.user?.uid ?? "",
          },
          body: JSON.stringify(dataToSend),
        }
      );
      const data = await response.json();

      if (data.success && data.tweet) {
        onNewText(data.tweet);
      } else {
        toast.error("Failed to generate response: " + data.error);
      }
    } catch (e) {
      console.error("Failed to generate AI reponse: ", e);
      toast.error("Failed to generate AI response: " + e.message);
    } finally {
      setIsLoading("");
    }
  };

  return (
    <HStack justifyContent="center">
      {bigButtons.map((btn, index) => (
        <Card
          borderRadius={"7px"}
          size="md"
          padding="1px"
          background="linear-gradient(310deg, rgba(49,107,255,1) 0%, rgba(236,97,129,1) 100%)"
          key={btn.mood}
        >
          <Button
            fontSize="xs"
            size="xs"
            background="linear-gradient(290deg, rgba(239,240,252,1) 0%, rgba(248,239,246,1) 100%)"
            border="0px transparent"
            color="#5f7695"
            isDisabled={!!isLoading}
            isLoading={isLoading === btn.mood}
            _loading={{
              background:
                "linear-gradient(290deg, rgba(239,240,252,1) 0%, rgba(248,239,246,1) 100%)",
              opacity: 1,
            }}
            _disabled={{
              background:
                "linear-gradient(290deg, rgba(239,240,252,1) 0%, rgba(248,239,246,1) 100%)",
              opacity: 1,
              cursor: "not-allowed",
              filter: "brightness(1.2)",
            }}
            _hover={{
              background:
                "linear-gradient(290deg, rgba(239,240,252,1) 0%, rgba(248,239,246,1) 100%)",
            }}
            onClick={() => handleClick(btn.mood)}
          >
            {btn.title}
          </Button>
        </Card>
      ))}
      <Menu isLazy>
        <Card
          borderRadius={"7px"}
          size="md"
          padding="1px"
          background="linear-gradient(310deg, rgba(49,107,255,1) 0%, rgba(236,97,129,1) 100%)"
        >
          <MenuButton
            as={IconButton}
            size="xs"
            aria-label="Open more options"
            background="linear-gradient(290deg, rgba(239,240,252,1) 0%, rgba(248,239,246,1) 100%)"
            border="0px transparent"
            isDisabled={!!isLoading}
            isLoading={menuButtons.some((i) => i.mood === isLoading)}
            _loading={{
              background:
                "linear-gradient(290deg, rgba(239,240,252,1) 0%, rgba(248,239,246,1) 100%)",
              opacity: 1,
              color: "#5f7695",
            }}
            _disabled={{
              background:
                "linear-gradient(290deg, rgba(239,240,252,1) 0%, rgba(248,239,246,1) 100%)",
              opacity: 1,
              cursor: "not-allowed",
              filter: "brightness(1.2)",
            }}
            _hover={{
              background:
                "linear-gradient(290deg, rgba(239,240,252,1) 0%, rgba(248,239,246,1) 100%)",
            }}
          >
            <BsThreeDots style={{ margin: "auto" }} color="#5f7695" />
          </MenuButton>
        </Card>
        <MenuList minW="120px">
          {menuButtons.map((btn) => (
            <MenuItem key={btn.mood} fontSize="xs" onClick={() => handleClick(btn.mood)}>
              {btn.title}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <EnterpriseDiscount
        isOpen={isOpenEnterprise}
        onClose={onCloseEnterprise}
        title="Unlock AI replies and more"
      />
    </HStack>
  );
}

export const AIReplyButtonsMemoed = memo(AIReplyButtons);
