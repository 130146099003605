import React, { memo, useEffect, useState } from 'react';
import {
    Button,
    Stack,
    Box,
    Text,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    IconButton,
    Link,
    useDisclosure,
    useMediaQuery,
    FormLabel,
    ModalFooter,
    VStack,
    useColorModeValue,
    Flex,
} from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/tooltip";
import * as analytics from "../../utils/analytics";
import { Profile } from "components/person-sidebar/profile";
import { TweetContext } from "../../context/tweetContext";
import { pushToCreator, incognitoSignIn } from "controllers/incognito";
import { useFirebaseUser } from "utils/useFirebaseUser";
import { useSession } from "next-auth/client";
import { useRouter } from "next/dist/client/router";
import toast from "react-hot-toast";
import { firebaseClient, getToken } from "firebaseClient";
import { PlusIcon } from 'components/icons/PlusIcon';



export const IncognitoSidebar = ({ }) => {

    const tweetContext: any = React.useContext(TweetContext);
    const isAuthenticated = useFirebaseUser();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUpdatingShare, setIsUpdatingShare] = useState<boolean>(false);
    const [isAddedInClientList, setIsAddedInClientList] = useState<boolean>(false);
    const [refresh, refresher] = useState({});
    const [session, loading] = useSession();
    const router = useRouter();

    // useEffect(() => {
    //     if (isAuthenticated && !window?.location?.href?.includes("app.tweethunter.io") && !window?.location?.href?.includes("localhost")) {
    //         router.push("https://app.tweethunter.io/write" + window?.location?.search);
    //     }
    // }, [isAuthenticated]);

    return (
        <Flex p={2} pb={10} minH="90vh" display="flex" flexDir="column" alignItems="center" justifyContent="space-between">
            <Flex display="flex" flexDir="column" alignItems="center" >
                <Flex>
                    {
                        !!tweetContext.incognitoCreator?.screen_name && (
                            <Box textAlign="center">
                                <Profile contact={tweetContext.incognitoCreator} disableSendDM={true} />
                            </Box>
                        )
                    }
                </Flex>
                <Flex mt={10}>
                    {
                        !isAuthenticated && (
                            <Button
                                variant={"primary"}
                                isLoading={isLoading}
                                mr={3}
                                onClick={async () => {
                                    incognitoSignIn(tweetContext, router);
                                }}
                            >
                                Sign in
                            </Button>
                        )
                    }
                    <Tooltip shouldWrapChildren placement='top' label={"This tweet will be pushed to the creator"}>
                        <Button variant={"secondary"}
                            isLoading={isLoading}
                            onClick={async () => {
                                setIsLoading(true);
                                if (isAuthenticated) {
                                    await pushToCreator(session, isAuthenticated, tweetContext);
                                }
                                else {
                                    incognitoSignIn(tweetContext, router);
                                }
                                setIsLoading(false);
                            }}
                        >
                            Push to client
                        </Button>
                    </Tooltip>
                </Flex>
            </Flex>
            {
                !loading && !isAddedInClientList && !session?.user?.data?.linkAccounts?.some(x => x.id == tweetContext.incognitoCreator?.id) && session?.user?.uid && (
                    <Tooltip shouldWrapChildren placement='top' label={"This will add the creator to your client's list so you can easily push content to this creator the next time you log in Tweet Hunter"}>
                        <Button variant={"secondary"}
                            mt={20}
                            isLoading={isUpdatingShare}
                            onClick={async () => {
                                setIsUpdatingShare(true);
                                let linkAccounts = session?.user?.data?.linkAccounts ?? [];
                                linkAccounts.push({ id: tweetContext.incognitoCreator.id_str, type: "editor", twUserName: tweetContext.incognitoCreator.screen_name, image: tweetContext.incognitoCreator?.profile_image_url_https?.replace("normal", "400x400") });
                                const db = firebaseClient.firestore();
                                await db.collection("users").doc(session?.user?.uid).update({ linkAccounts });
                                toast.success("Added to client list");
                                setIsUpdatingShare(false);
                                setIsAddedInClientList(true);
                            }}
                            leftIcon={PlusIcon}
                        >
                            Add in your client's list
                        </Button>
                    </Tooltip>
                )
            }
        </Flex>
    )
}