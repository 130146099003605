import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
  Flex,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel, Grid,
} from "@chakra-ui/react";
import { useEnterpriseCompaign } from "hooks/useEnterpriseCampaign";
import { useCountdown } from "hooks/useCountdown";
import { ImRocket } from "react-icons/im";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { IconType } from "react-icons/lib";
import { HiMail, HiOutlineLightningBolt } from "react-icons/hi";
import { FaRobot } from "react-icons/fa";
import { IoMdLocate } from "react-icons/io";
import { useSubscription } from "hooks/useSubscription";
import { TimeCounter } from "components/countdown/time-counter";
import { primary } from "theme/components/button/primary";
import { Bolt } from "components/icons/Bolt";
import { BoltIconComponent } from "components/icons/BoltIconComponent";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  onCloseClick?: () => void;
}

export function EnterpriseDiscount({ isOpen, onClose, onCloseClick = () => { }, title = "" }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="upgrade" isCentered>
      <ModalOverlay />
      <EnterpriseModalContent onFinishUpgrade={onClose} title={title} onCloseClick={onCloseClick} />
    </Modal>
  );
}

interface EnterpriseModalContentProps {
  onFinishUpgrade?: () => void;
  title?: string;
  onCloseClick?: () => void;
}

function EnterpriseModalContent({
  onFinishUpgrade = () => { },
  title,
  onCloseClick = () => { }
}: EnterpriseModalContentProps) {
  const [isUpgrading, setIsUpgrading] = useState<boolean>(false);
  const { activeCampaign, updateUserAcquisition } = useEnterpriseCompaign();
  const { updatePlan } = useSubscription();
  const { days, hours, minutes, seconds } = useCountdown(
    // @ts-ignore
    activeCampaign?.endDate.toDate
      ? // @ts-ignore
      activeCampaign.endDate.toDate()
      : activeCampaign?.endDate
  );

  const handleUpgrade = async (isCampaignActive = false) => {
    try {
      setIsUpgrading(true);
      const isSuccess = await updatePlan(
        "enterpriseMonthly",
        isCampaignActive ? "ENTERPRISE99" : ""
      );

      if (isSuccess) {
        updateUserAcquisition();
        onFinishUpgrade();
      }
    } finally {
      setIsUpgrading(false);
    }
  };

  const isCampaignActive =
    !!activeCampaign && (seconds > 0 || minutes > 0 || hours > 0 || days > 0);

  return (
    <ModalContent >
      {isCampaignActive && (
        <Text
          background="#FAD459"
          px="5"
          py="1"
          color="#202020"
          borderRadius="md"
          fontWeight="800"
          fontSize="xs"
        >
          Limited-time offer
        </Text>
      )}
      {title && (
        <ModalHeader
        >
          <Flex
            {...{
              flex: 1,
              color: "white",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            {title}
            <Text {...{ fontSize: "sm", fontWeight: 400, mt: 4, mb: 2 }}>Get the Enterprise plan.</Text>
          </Flex>
        </ModalHeader>
      )}
      {/* <ModalCloseButton onClick={onCloseClick} /> */}
      <ModalBody textAlign="center" color="white">
        <Text
          as={isCampaignActive ? "s" : "p"}
          fontWeight="bold"
          fontSize={isCampaignActive ? "xl" : "3xl"}
          mt="3"
          display="block"
          color="white"
        >
          $199 / mo
        </Text>
        {isCampaignActive && (
          <>
            <Text fontWeight="bold" fontSize="3xl">
              $99/mo
            </Text>
            <Box mt="3">
              <Text fontSize="xs">Time remaining</Text>
              <Flex justifyContent="center">
                {days > 0 && <TimeCounter type="Days" value={days} />}
                {(hours > 0 || days > 0) && (
                  <TimeCounter type="Hours" value={hours} />
                )}
                {(minutes > 0 || hours > 0 || days > 0) && (
                  <TimeCounter type="Minutes" value={minutes} />
                )}
                <TimeCounter
                  type="Seconds"
                  value={seconds}
                  endsWithColon={false}
                />
              </Flex>
            </Box>
          </>
        )}

        <Button
          variant={"primary"}
          leftIcon={<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4688 3.25C13.1562 3.25 13.7188 3.8125 13.7188 4.5C13.7188 5.21875 13.1562 5.75 12.4688 5.75C11.75 5.75 11.2188 5.21875 11.2188 4.5C11.2188 3.8125 11.75 3.25 12.4688 3.25ZM16.7812 0.625C17 1.625 17 2.4375 16.9375 3.21875C16.9375 6.46875 15.1875 8.40625 12.9375 9.875V13.0625C12.9375 13.5625 12.5625 14.1562 12.0938 14.4062L9 15.9375C8.90625 15.9688 8.78125 16 8.6875 16C8.28125 16 7.96875 15.6875 7.96875 15.25V12.375L7.28125 12.6875C7.1875 12.75 6.625 13 6.15625 12.5L4.46875 10.8438C4.40625 10.7812 3.96875 10.2812 4.28125 9.6875L4.59375 9H1.71875C1.3125 9 0.96875 8.6875 0.96875 8.25C1 8.15625 1 8.03125 1.0625 7.9375L2.59375 4.84375C2.84375 4.375 3.4375 4 3.9375 4H7.125C8.59375 1.75 10.5312 0 13.7812 0C14.5625 0 15.375 0 16.375 0.21875C16.5625 0.28125 16.7188 0.4375 16.7812 0.625ZM12.1875 8.59375C14.5 7.125 15.4688 5.5 15.4688 3.21875C15.4688 2.6875 15.5 2.15625 15.4062 1.59375C14.8438 1.5 14.3438 1.5 13.7812 1.5C11.4688 1.5 9.875 2.5 8.375 4.8125C7.8125 5.96875 6.5 8.59375 5.8125 10.0312L6.96875 11.1875C8.40625 10.5 11.0312 9.1875 12.1875 8.59375Z" fill="white" />
          </svg>
          }
          size="lg"
          mt="6"
          // px="6"
          onClick={(e) => handleUpgrade(isCampaignActive)}
          isLoading={isUpgrading}
        >
          Upgrade now!
        </Button>
        <Accordion allowToggle mt="1">
          <AccordionItem border="0" display="flex" justifyContent="center">
            {({ isExpanded }) => (
              <Box w="100%" mt="4">
                <AccordionButton
                  as={Button}
                  variant="tertiaryNoBackground"
                  color="white"
                  display="flex"
                  alignItems="center"
                  mx="auto"
                  _focus={{ boxShadow: "none", background: "none" }}
                  _hover={{ boxShadow: "none", background: "none" }}
                  justifyContent="center"
                >
                  <Text fontSize="xs">Enterprise plan features</Text>
                  <Icon
                    as={isExpanded ? AiFillCaretDown : AiFillCaretRight}
                    mt="1"
                    ml="2"
                    h="3"
                    w="3"
                    strokeWidth="1"
                    fontSize="md"
                  />
                </AccordionButton>
                <AccordionPanel
                  pt="8"
                  pl="2"
                  pb="0"
                  pr="0"
                  maxW="80%"
                  mx="auto"
                >
                  <AccordianItem
                    icon={<BoltIconComponent {...{ width: 24, height: 24 }} />}
                    title="AI replies"
                    description="Engage with people using AI-generated replies"
                  />
                  {/* <AccordianItem
                    icon={HiMail}
                    title="Get emails"
                    description="Find the emails of over 100 Twitter accounts every month"
                  /> */}
                  <AccordianItem
                    icon={<svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.5 18.75C16.5 19.1719 16.125 19.5 15.75 19.5H14.25C13.8281 19.5 13.5 19.1719 13.5 18.75C13.5 18.375 13.8281 18 14.25 18H15.75C16.125 18 16.5 18.375 16.5 18.75ZM11.25 18H9C8.57812 18 8.25 18.375 8.25 18.75C8.25 19.1719 8.57812 19.5 9 19.5H11.25C11.625 19.5 12 19.1719 12 18.75C12 18.375 11.625 18 11.25 18ZM8.0625 12.1875C8.0625 10.4531 9.46875 9 11.25 9C12.9844 9 14.4375 10.4531 14.4375 12.1875C14.4375 13.9688 12.9844 15.375 11.25 15.375C9.46875 15.375 8.0625 13.9688 8.0625 12.1875ZM10.3125 12.1875C10.3125 12.75 10.6875 13.125 11.25 13.125C11.7656 13.125 12.1875 12.75 12.1875 12.1875C12.1875 11.6719 11.7656 11.25 11.25 11.25C10.6875 11.25 10.3125 11.6719 10.3125 12.1875ZM21.9375 12.1875C21.9375 13.9688 20.4844 15.375 18.7031 15.375C16.9688 15.375 15.5156 13.9219 15.5156 12.1875C15.5156 10.4531 16.9688 9.04688 18.7031 9.04688C20.4844 9 21.9375 10.4531 21.9375 12.1875ZM19.6406 12.1875C19.6406 11.6719 19.2188 11.2969 18.7031 11.2969C18.1875 11.2969 17.8125 11.6719 17.8125 12.1875C17.8125 12.75 18.1875 13.125 18.7031 13.125C19.2188 13.125 19.6406 12.7031 19.6406 12.1875ZM21 18H18.75C18.3281 18 18 18.375 18 18.75C18 19.1719 18.3281 19.5 18.75 19.5H21C21.375 19.5 21.75 19.1719 21.75 18.75C21.75 18.375 21.375 18 21 18ZM25.5 8.25V20.25C25.5 22.3594 23.8125 24 21.75 24H8.25C6.14062 24 4.5 22.3594 4.5 20.25V8.25C4.5 6.1875 6.14062 4.5 8.25 4.5H13.875V1.125C13.875 0.515625 14.3438 0 15 0C15.6094 0 16.125 0.515625 16.125 1.125V4.5H21.75C23.8125 4.5 25.5 6.1875 25.5 8.25ZM23.25 8.25C23.25 7.45312 22.5469 6.75 21.75 6.75H8.25C7.40625 6.75 6.75 7.45312 6.75 8.25V20.25C6.75 21.0938 7.40625 21.75 8.25 21.75H21.75C22.5469 21.75 23.25 21.0938 23.25 20.25V8.25ZM27.75 9H27V18H27.75C28.5469 18 29.25 17.3438 29.25 16.5V10.5C29.25 9.70312 28.5469 9 27.75 9ZM0.75 10.5V16.5C0.75 17.3438 1.40625 18 2.20312 18H3V9H2.25C1.40625 9 0.75 9.70312 0.75 10.5Z" fill="white" />
                    </svg>
                    }
                    title="Custom AI model"
                    description="Train a custom model of our AI engine so that it's tailored to your needs and industry."
                  />
                  <AccordianItem
                    icon={<svg width="24" height="24" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 8C11.5 9.65625 10.1562 11 8.5 11C6.8125 11 5.5 9.65625 5.5 8C5.5 6.34375 6.8125 5 8.5 5C10.1562 5 11.5 6.34375 11.5 8ZM8.5 6.5C7.65625 6.5 7 7.1875 7 8C7 8.84375 7.65625 9.5 8.5 9.5C9.3125 9.5 10 8.84375 10 8C10 7.1875 9.3125 6.5 8.5 6.5ZM8.5 0C8.90625 0 9.25 0.34375 9.25 0.75V2.0625C11.9375 2.40625 14.0938 4.5625 14.4375 7.25H15.75C16.1562 7.25 16.5 7.59375 16.5 8C16.5 8.4375 16.1562 8.75 15.75 8.75H14.4375C14.0938 11.4688 11.9375 13.625 9.25 13.9688V15.25C9.25 15.6875 8.90625 16 8.5 16C8.0625 16 7.75 15.6875 7.75 15.25V13.9688C5.03125 13.625 2.875 11.4688 2.53125 8.75H1.25C0.8125 8.75 0.5 8.4375 0.5 8C0.5 7.59375 0.8125 7.25 1.25 7.25H2.53125C2.875 4.5625 5.03125 2.40625 7.75 2.0625V0.75C7.75 0.34375 8.0625 0 8.5 0ZM4 8C4 10.5 6 12.5 8.5 12.5C10.9688 12.5 13 10.5 13 8C13 5.53125 10.9688 3.5 8.5 3.5C6 3.5 4 5.53125 4 8Z" fill="currentColor"></path></svg>}
                    title="Lead Finder"
                    description="Automatically identify new leads and prospects on Twitter."
                  />
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        </Accordion>
      </ModalBody>
    </ModalContent>
  );
}

interface AccordianItemProps {
  icon: any;
  title: string;
  description: string;
}

function AccordianItem({ icon, title, description }: AccordianItemProps) {
  return (
    <Grid textAlign="start" fontSize="xs" mb="6" color="white" gridTemplateColumns="24px 1fr" gap="3">
      <Box placeSelf="center">
        {/* <Icon as={icon} h="12" w="12" fontSize="lg" /> */}
        {icon}
      </Box>
      <Box ml="1.5">
        <Text fontWeight="medium">{title}</Text>
        <Text {...{ opacity: .8 }}>{description}</Text>
      </Box>
    </Grid>
  );
}
