import { Box, Grid } from "@chakra-ui/react";
import { useState } from "react";
import { colors } from "theme/colors/colors";
import { radius } from "theme/foundations/radius";

function AnimatedCircle({
  positionX = "0%",
  positionY = "0%",
  width = "100%",
  animateX = "",
  animateY = "",
  animateDuration = "10s",
  rotateFrom = "",
  rotateTo = "",
  rotateDuration = "15s",
  gradient = "GradientRed",
  stroke = "none",
  ...props
}) {
  return (
    <rect
      x={positionX}
      y={positionY}
      width={width}
      height={width}
      fill={`url(#${gradient})`}
      stroke={stroke}
    >
      {animateX && (
        <animate
          attributeName="x"
          dur={animateDuration}
          values={animateX}
          repeatCount="indefinite"
        ></animate>
      )}
      {animateY && (
        <animate
          attributeName="y"
          dur={animateDuration}
          values={animateY}
          repeatCount="indefinite"
        ></animate>
      )}
      {rotateFrom && rotateTo && (
        <animateTransform
          attributeName="transform"
          type="rotate"
          from={rotateFrom}
          to={rotateTo}
          dur={rotateDuration}
          repeatCount="indefinite"
        />
      )}
    </rect>
  );
}

function GradientsDefinitions() {
  return (
    <defs>
      <radialGradient
        id="GradientRed"
        cx="50%"
        cy="50%"
        fx="100%"
        fy="50%"
        r=".5"
      >
        <animate
          attributeName="fx"
          dur="34s"
          values="0%;3%;0%"
          repeatCount="indefinite"
        ></animate>
        <stop offset="0%" stopColor="#EC618111"></stop>
        <stop offset="100%" stopColor="#316BFF00"></stop>
      </radialGradient>

      <radialGradient
        id="GradientBlue"
        cx="50%"
        cy="50%"
        fx="2.68147%"
        fy="50%"
        r=".5"
      >
        <animate
          attributeName="fx"
          dur="23.5s"
          values="0%;3%;0%"
          repeatCount="indefinite"
        ></animate>
        <stop offset="0%" stopColor="#316BFF11"></stop>
        <stop offset="100%" stopColor="#EC618100"></stop>
      </radialGradient>
    </defs>
  );
}

const values = [
  {
    width: 120,
    positionX: -60, // top edge of rectangle
    positionY: -60, // left edge of rectangle
    animateX: 120, // X axis movement, back and forth
    animateY: 0, // Y axis movement, back and forth
    rotateFromX: 60, // center of rotation
    rotateFromY: 0, // center of rotation
    rotateToX: 120, // center of rotation
    rotateToY: 60, // center of rotation
    rotateClockwise: true,
    gradient: "GradientRed",
    strokeColor: "#ff35",
    rotateDuration: "17s",
  },
  {
    width: 120,
    positionX: -40, // top edge of rectangle
    positionY: -30, // left edge of rectangle
    animateX: 0, // X axis movement, back and forth
    animateY: 0, // Y axis movement, back and forth
    rotateFromX: -80,
    rotateFromY: 20,
    rotateToX: 80,
    rotateToY: -20,
    rotateClockwise: true,
    gradient: "GradientBlue",
    strokeColor: "#f0f5",
    rotateDuration: "14s",
  },
  {
    width: 120,
    positionX: -60, // top edge of rectangle
    positionY: -50, // left edge of rectangle
    animateX: 40, // X axis movement, back and forth
    animateY: 60, // Y axis movement, back and forth
    rotateFromX: 0,
    rotateFromY: -40,
    rotateToX: 60,
    rotateToY: 60,
    rotateClockwise: false,
    gradient: "GradientBlue",
    strokeColor: "#00f5",
    rotateDuration: "12s",
  },
  {
    width: 90,
    positionX: 50, // top edge of rectangle
    positionY: -45, // left edge of rectangle
    animateX: -10, // X axis movement, back and forth
    animateY: 5, // Y axis movement, back and forth
    rotateFromX: 0,
    rotateFromY: 0,
    rotateToX: 10,
    rotateToY: 30,
    rotateClockwise: true,
    gradient: "GradientBlue",
    strokeColor: "#f735",
    rotateDuration: "15s",
  },
];
// const values = [
//   {
//     width: 120,
//     positionX: 0, // top edge of rectangle
//     positionY: -25, // left edge of rectangle
//     animateX: 100, // X axis movement, back and forth
//     animateY: 5, // Y axis movement, back and forth
//     rotateFromX: -50, // center of rotation
//     rotateFromY: -30, // center of rotation
//     rotateToX: 50, // center of rotation
//     rotateToY: 10, // center of rotation
//     rotateClockwise: true,
//     gradient: "GradientRed",
//     strokeColor: "#ff35",
//     rotateDuration: "19s",
//   },
//   {
//     width: 70,
//     positionX: 35, // top edge of rectangle
//     positionY: -0, // left edge of rectangle
//     animateX: 10, // X axis movement, back and forth
//     animateY: 5, // Y axis movement, back and forth
//     rotateFromX: -0,
//     rotateFromY: 0,
//     rotateToX: -40,
//     rotateToY: 0,
//     rotateClockwise: true,
//     gradient: "GradientBlue",
//     strokeColor: "#f0f5",
//     rotateDuration: "17s",
//   },
//   {
//     width: 120,
//     positionX: -60, // top edge of rectangle
//     positionY: -50, // left edge of rectangle
//     animateX: 40, // X axis movement, back and forth
//     animateY: 60, // Y axis movement, back and forth
//     rotateFromX: 0,
//     rotateFromY: -40,
//     rotateToX: 60,
//     rotateToY: 60,
//     rotateClockwise: false,
//     gradient: "GradientBlue",
//     strokeColor: "#00f5",
//     rotateDuration: "12s",
//   },
//   {
//     width: 90,
//     positionX: 50, // top edge of rectangle
//     positionY: -45, // left edge of rectangle
//     animateX: -10, // X axis movement, back and forth
//     animateY: 5, // Y axis movement, back and forth
//     rotateFromX: 0,
//     rotateFromY: 0,
//     rotateToX: 10,
//     rotateToY: 30,
//     rotateClockwise: true,
//     gradient: "GradientBlue",
//     strokeColor: "#f735",
//     rotateDuration: "15s",
//   },
// ];

export const AnimatedBg3 = ({
  children,
  square = false,
  withStroke = false, // for visual debugging
  overflow = false, // for visual debugging
  // withStroke = true,
  // overflow = true,
  ...props
}) => {

  // const [hover, hoverSet] = useState(false)
  return (
    <Box
      {...{
        borderRadius: "10px",
        overflow: overflow ? undefined : "hidden",
        bg: colors.neutral.lightMode.neutral,

        _dark: {
          bg: colors.neutral.darkMode.neutral,
        },
        // onMouseEnter: () => hoverSet(true),
        // onMouseLeave: () => hoverSet(false),

        ...props,
      }}
    >
      <Grid
        {...{
          maxH: "100%",
          borderRadius: "10px",
          _hover: {
            bg: "#A376BB07",
            transition: "background-color .5s ease",
          },
          gridTemplate: `"center"`,
        }}
      >
        <Grid {...{ gridArea: "center" }}>
          <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
            <GradientsDefinitions />

            {values.map(
              (
                {
                  width,
                  positionX,
                  positionY,
                  animateX,
                  animateY,
                  rotateFromX,
                  rotateFromY,
                  rotateToX,
                  rotateToY,
                  rotateClockwise,
                  gradient,
                  strokeColor,
                  rotateDuration,
                },
                mapIndex
              ) => {
                return (
                  <AnimatedCircle
                    {...{
                      key: mapIndex,
                      width: square ? `${width * 4}%` : `${width}%`,
                      gradient,
                      positionX: square ? `${positionX * 2}%` : `${positionX}%`,
                      positionY: square ? `${positionY * 2}%` : `${positionY}%`,
                      animateX: animateX
                        ? square
                          ? `
                            ${positionX * 1}%;
                            ${positionX * 1 + animateX * 2}%;
                            ${positionX * 1}%;
                          `
                          : `
                            ${positionX}%;
                            ${positionX + animateX}%;
                            ${positionX}%
                          `
                        : undefined,
                      animateY: animateY
                        ? square
                          ? `
                            ${positionY * 1}%;
                            ${positionY * 1 + animateY * 2}%;
                            ${positionY * 1}%;
                          `
                          : `
                            ${positionY}%;
                            ${positionY + animateY}%;
                            ${positionY}%;
                          `
                        : undefined,
                      rotateFrom: square
                        ? `
                            ${rotateClockwise ? 0 : 360}  
                            ${positionX * 4 +
                        (width * 4) / 2 +
                        (rotateFromX ?? 0) * 4
                        } 
                            ${positionY * 4 +
                        (width * 4) / 2 +
                        (rotateFromY ?? 0) * 2
                        } 
                          `
                        : `
                            ${rotateClockwise ? 0 : 360}  
                            ${positionX + width / 2 + (rotateFromX ?? 0)} 
                            ${positionY + width / 2 + (rotateFromY ?? 0)} 
                          `,
                      rotateTo: square
                        ? `
                            ${rotateClockwise ? 360 : 0}  
                            ${positionX * 4 +
                        (width * 4) / 2 +
                        (rotateToX ?? 0) * 4
                        }
                            ${positionY * 4 +
                        (width * 4) / 2 +
                        (rotateToY ?? 0) * 4
                        }
                            `
                        : `
                            ${rotateClockwise ? 360 : 0}  
                            ${positionX + width / 2 + (rotateToX ?? 0)}
                            ${positionY + width / 2 + (rotateToY ?? 0)}
                            `,
                      rotateDuration,
                      stroke: withStroke ? strokeColor : "",
                    }}
                  />
                );
              }
            )}
          </svg>
        </Grid>
        <Grid
          {...{
            gridArea: "center",
            border: "1px solid #A376BB22",
            borderRadius: "10px",
            h: props?.h ?? "100%",
          }}
        ></Grid>
        <Grid {...{ gridArea: "center" }}>{children}</Grid>
      </Grid>
    </Box>
  );
};
