import { useState, useEffect } from "react";
import { useSession } from "next-auth/client";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Input,
  Wrap,
  Box,
  Button,
  WrapItem
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import { colors, Label } from "components/tweet-labels";
import { getAccount, updateUser } from "utils/sessionHelper";
import { useMiscellaneousContext } from "context/miscellaneousContext";
import { SaveIcon } from "components/icons/SaveIcon";

interface Props {
  label: Label;
  isOpen: boolean;
  onClose: () => void;
  onSave?: (label: Label) => void;
}

export function EditLabel({ label, isOpen, onClose, onSave = () => {} }: Props) {
  const [title, setTitle] = useState<string>("");
  const [color, setColor] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [session] = useSession();
  const miscellaneousContext: any = useMiscellaneousContext();

  useEffect(() => {
    setTitle(label.title);
    setColor(label.color);
  }, [label])

  const handleSave = async () => {
    try {
      setIsSaving(true);
      let labels = getAccount(session)?.tweetLabels;
      const index = labels?.findIndex((l) => l.id === label.id);
      labels[index].title = title;
      labels[index].color = color;
      await updateUser(session, { tweetLabels: labels });
      miscellaneousContext.labelsRefresher({});
      onSave(labels[index]);
    } catch (e) {
      console.log("Error in updating label: ", e);
      toast.error("Error in updating label: " + e.message);
    } finally {
      setIsSaving(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb="0">Edit tweet label</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt="0">
          <Text fontSize="sm">
            Edit your label settings for more precision.
          </Text>
          <Box mt="4">
            <Text fontWeight="medium">Name</Text>
            <Input
              size="sm"
              borderRadius="md"
              width="250px"
              mt="2"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>
          <Box mt="4">
            <Text fontWeight="medium">Color code</Text>
            <Wrap mt="2">
              {colors.map((c) => (
                <WrapItem key={c}>
                  <Box
                    bg={c + ".500"}
                    h="8"
                    w="8"
                    borderRadius="full"
                    cursor="pointer"
                    onClick={() => setColor(c)}
                    border={c === color ? "2px solid black" : "none"}
                  />
                </WrapItem>
              ))}
            </Wrap>
          </Box>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <Button variant="tertiary" onClick={onClose}>Cancel</Button>
          <Button
            // variant={"primary"}
            variant="primary"
            mr={3}
            // size="sm"
            onClick={handleSave}
            isLoading={isSaving}
            leftIcon={SaveIcon}
          >
            Save Label
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
