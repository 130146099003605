import React, { useEffect } from "react";
import moment from "moment-timezone";
import { firebaseClient, getToken } from "../firebaseClient";
import { getAccount } from "./sessionHelper";
import numbro from "numbro"
import * as Sentry from "@sentry/nextjs";
import isAbsoluteUrl from "is-absolute-url";

const localUrl = "http://localhost:5002/discovr-3175b/us-central1/";
const prodUrl = "https://us-central1-discovr-3175b.cloudfunctions.net/";
const forceProd = true;

export let baseUrl =
  (!process.env.NODE_ENV || process.env.NODE_ENV === "development") && !forceProd
    ? localUrl
    : prodUrl;

export const showThTokens = (session) => {
  return session?.user?.data?.thTokensMoneyDue > 0 && session?.user?.data?.dateSignUp?._seconds && session?.user?.data?.dateSignUp?._seconds * 1000 < new Date("2023-01-16").getTime();
};

export const getURL = () => {
  const url =
    process?.env?.URL && process.env.URL !== ""
      ? process.env.URL
      : process?.env?.VERCEL_URL && process.env.VERCEL_URL !== ""
      ? process.env.VERCEL_URL
      : "http://localhost:3000";
  return url.includes("http") ? url : `https://${url}`;
};

export const isLocal = () => {
  return (!process.env.NODE_ENV || process.env.NODE_ENV === "development");
};

export const postData = async ({ url, token, data = {} as any, session = undefined as any}) => {
  if (session) {
    data.tokenUserId = session?.user?.uid,
    data.token = await getToken(session, "postData-" + url);
  }

  const res = await fetch(baseUrl + url, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json", token }),
    credentials: "same-origin",
    body: JSON.stringify(data),
  });

  return res.json();
};

export const getData = async ({ url, token}) => {
  const res = await fetch(baseUrl + url, {
    method: "GET",
    headers: new Headers({ "Content-Type": "application/json", token }),
    credentials: "same-origin",
  });

  return res.json();
};

export const toDateTime = (secs) => {
  var t = new Date("1970-01-01T00:30:00Z"); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};

export const useOnClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export const shuffleArray = (a) => {
  var j, x, i;
  for (i = a?.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
  }
  return a;
}

export const convertToAccountTimezone = (date, timezone) => {
  
  if (!timezone)
    return date;

  var here = moment().tz(moment.tz.guess());
  // console.log("here: " + here.format());
  // console.log("tz: " + session?.user?.data?.timezone);
  //@ts-ignore
  let diff = moment.tz.zone(timezone).utcOffset(here);
  //@ts-ignore
  let diff2 = moment.tz.zone(moment.tz.guess()).utcOffset(here);
  // let diff = (nowM - nowMT).format();
  // console.log("diff: " + diff);
  // console.log("diff2: " + diff2);
  let timezoneDiff = diff-diff2;

  let dateTimezoned = new Date(date);
  dateTimezoned.setTime(dateTimezoned.getTime() - (1000*60*timezoneDiff));

  return {date: dateTimezoned, timezoneDiff};
}

export const convertToAccountTimezoneReversed = (date, timezone) => {
  
  if (!timezone)
    return date;

  var here = moment().tz(moment.tz.guess());
  // console.log("here: " + here.format());
  // console.log("tz: " + session?.user?.data?.timezone);
  //@ts-ignore
  let diff = moment.tz.zone(timezone).utcOffset(here);
  //@ts-ignore
  let diff2 = moment.tz.zone(moment.tz.guess()).utcOffset(here);
  // let diff = (nowM - nowMT).format();
  // console.log("diff: " + diff);
  // console.log("diff2: " + diff2);
  let timezoneDiff = diff-diff2;

  let dateTimezoned = new Date(date);
  dateTimezoned.setTime(dateTimezoned.getTime() + (1000*60*timezoneDiff));

  return {date: dateTimezoned, timezoneDiff};
}

export const extractImages = (text) => {
  let images: any = [];
  let matchs = text.match(/(?:\[img:)(.*?)(?=\])/g);
  if (matchs && matchs?.length > 0) {
    matchs.forEach(match => {
      // console.log("match: " + match);
      match = match.replace("[img:", "");
      images.push(match);
    });
  }

  return images;
}

export const setInLocalStorage = (keyName, value) => {
  try {
    localStorage.setItem(keyName, value);
  } catch (error) {
    console.error('Error in local storage', error);
    Sentry.captureException(error);

    try {
      localStorage.clear();
      localStorage.setItem(keyName, value);
    }
    catch (error) {
      console.error('Error in local storage sub catch', error);
      Sentry.captureException(error);
    }
  }
};

export const roundUp = (value, mantissa = 1) => {
  if (value < 1000) {
    return value;
  }

  try {
    return numbro(value)?.format({
      average: true,
      mantissa: mantissa,
    });
  }
  catch (e) {
    console.log("Error in roundUp: " + e.message, e);
    Sentry.captureException(e);
    return value;
  }
};

export const getRandom = (arr, n) =>  {
  let result = new Array(n),
      len = arr?.length,
      taken = new Array(len);
  if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

export function isNumeric(str) {
  if (typeof str != "string") return false
  //@ts-ignore
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export const cleanTweetForCountChar = (text) => {

  let matchs = text.match(/(?:\[img:)(.*?)(?=\])/g);
  if (matchs) {
      matchs.forEach(match => {
          match = match + "]";
          text = text.replace(match, "");
      });
  }
  // let matchesQuote = text.match(/\bhttps:\/\/twitter\.com\/(\w+)\/status\/(\d+)\b/g);
  // if (matchesQuote) {
  //     matchesQuote.forEach(match => {
  //         text = text.replace(match, "");
  //     });
  // }

  return text;
}

export const formatUrl = (url: string) => {
  try {
    if (!isAbsoluteUrl(url)) {
      return url;
    }
    const urlObject = new URL(url)

    let formattedUrl = urlObject.href.replace(`${urlObject.protocol}//`, "")

    // removed this because it was sometimes confusing for users
    // if (formattedUrl?.length > 35) {
    //   formattedUrl = formattedUrl.substring(0, 35) + "..."
    // }
    return formattedUrl
  } catch (err) {
    console.log("Error in formatUrl with url: ", url);
    console.log("Error in formatUrl with error: ", err);
    Sentry.captureException(new Error("Error in formatUrl with url: " + url));
    return url;
  }
}

export const hslToHsla = (hsl: string, alpha: number) => {
  const hsla = hsl?.replace("hsl", "hsla");
  return hsla?.replace(")", `, ${alpha})`);
}

export const hexToHexa = (hex: string, alpha: number) => {
  // @ts-ignore
  return `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;
}