import { useState, useRef } from "react";
import { useSession } from "next-auth/client";
import getCaretCoordinates from "textarea-caret";
import { Suggestion } from ".";
import { getHandleSuggestions } from "controllers/search";
import {
  getBeforeSelectionText,
  getLastMeasureIndex,
  validateSearch,
  replaceWithMeasure,
  setInputSelection,
} from "../../utils/suggestion";

interface Position {
  top: number;
  left: number;
}

export function useSuggestions(inputRef?: any) {
  const [session] = useSession();
  const [isSuggestionsOpen, setIsSuggestionOpen] = useState<boolean>(false);
  const [measureText, setMeasureText] = useState<string>("");
  const [measuring, setMeasuring] = useState<boolean>(false);
  const [measurePrefix, setMeasurePrefix] = useState<string>("");
  const [measureLocation, setMeasureLocation] = useState<number>(0);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [position, setPosition] = useState<Position>({ top: 0, left: 0 });
  const suggestionApiCallRef = useRef<AbortController | null>();

  const checkForSuggestions = (event, prefix = "@") => {
    setTopLeft(event);
    const { key } = event;
    const prevMeasureText = measureText;
    const target = event.target as HTMLTextAreaElement;
    const selectionStartText = getBeforeSelectionText(target);
    const { location: measureIndex, prefix: measurePrefix } =
      getLastMeasureIndex(selectionStartText, prefix);
    const { selectionStart } = target;
    const isCursorAtWordEnd = target.value[selectionStart]
      ? /(\s)/.test(target.value[selectionStart])
      : true; // target.value[selectionStart] would be undefined when its the end of line
    if (measureIndex !== -1) {
      const measureText = selectionStartText.slice(
        measureIndex + measurePrefix?.length
      );
      const validateMeasure: boolean = validateSearch(measureText);

      if (validateMeasure && measureText) {
        if (
          key === measurePrefix ||
          key === "Shift" ||
          measuring ||
          measureText !== prevMeasureText
        ) {
          setMeasuring(true);
          setMeasureText(measureText);
          setMeasurePrefix(measurePrefix);
          setMeasureLocation(measureIndex);
        }
      } else if (measuring) {
        stopMeasure();
      }

      const hasHttps = measureText.slice(0, 5) === "https";
      if (validateMeasure && measureText && isCursorAtWordEnd && !hasHttps) {
        setIsSuggestionOpen(true);
        searchSuggestions(measurePrefix + measureText);
      }
    } else if (measuring) {
      stopMeasure();
    }
  };

  const setTopLeft = (event: any) => {
    const textarea = event.target || inputRef.current;
    const { selectionEnd } = textarea;
    const { top: newTop, left: newLeft } = getCaretCoordinates(
      textarea,
      selectionEnd
    );

    setPosition({
      top: newTop - inputRef.current.scrollTop || 0,
      left: newLeft
    })
  };


  const stopMeasure = (callback = () => {}) => {
    setMeasuring(false);
    setMeasureLocation(0);
    setMeasureText("");
    setIsSuggestionOpen(false);
    setSuggestions([]);
    callback();
  };

  const searchSuggestions = async (handle: string) => {
    if (suggestionApiCallRef.current) {
      suggestionApiCallRef.current?.abort();
    }

    const suggestionController = new AbortController();
    suggestionApiCallRef.current = suggestionController;

    let searchTerm = handle.replace("@", "");
    const data = await getHandleSuggestions(session, { search: searchTerm }, suggestionController.signal);

    if (data?.success === 1) {
      const formattedData: Suggestion[] = data.results.map((d) => ({
        name: d.name,
        handle: d.screen_name,
        followersCount: d.followers_count,
        image: d.profile_image_url_https,
      }));
      setSuggestions(formattedData);
    } else if (!suggestionController?.signal?.aborted) {
      setIsSuggestionOpen(false);
    }
  };

  const updateText = async (
    { currentText, handle, endWithSpace = true },
    callback = (updatedText: string) => {}
  ) => {
    const { text, selectionLocation } = replaceWithMeasure(currentText, {
      measureLocation,
      targetText: handle,
      prefix: measurePrefix,
      // @ts-ignore
      selectionStart: inputRef.current.selectionStart,
      split: endWithSpace ? " " : "",
    });

    await callback(text);
    stopMeasure(() => {
      // @ts-ignore
      setInputSelection(inputRef.current, selectionLocation);
      setSuggestions([]);
      setIsSuggestionOpen(false);
    });
  };

  const disableArrowKeys = (event) => {
    if (isSuggestionsOpen && suggestions?.length > 0) {
      // disable arrow keys when suggestions are open
      if (
        event.keyCode === 40 ||
        event.keyCode === 38 ||
        event.keyCode === 39 ||
        event.keyCode === 37
      ) {
        event.preventDefault();
      }
    }
  };

  return {
    isSuggestionsOpen,
    setIsSuggestionOpen,
    checkForSuggestions,
    suggestions,
    updateText,
    searchSuggestions,
    setSuggestions,
    disableArrowKeys,
    position
  };
}
