import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  List,
  ListItem,
  ListIcon,
  Text,
  Box
} from "@chakra-ui/react";
import { ImCross } from "react-icons/im";
import { MdCheckBox } from "react-icons/md";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

export function AutoDmEducation({ isOpen, onClose }: Props) {
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="bold" fontSize="md">
          Tips on how to use Auto DM responsibly:
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <List spacing={1}>
            <ListItem>
              <ListIcon as={MdCheckBox} color="green.500" />
              Be upfront about sending a DM if people perform an action on your
              tweet.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckBox} color="green.500" />
              Doing a giveaway? Specify how many winners there will be.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckBox} color="green.500" />
              Money or digital currency cannot be given away in exchange for a
              like, retweet or reply.
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckBox} color="green.500" />
              Giveaways shouldn't require your readers to @mention other people
              to participate.
            </ListItem>
          </List>
          <List spacing="1" mt="6">
            <ListItem>
              <ListIcon as={ImCross} color="red.500" />
              Don't send unsolicited DMs people do not expect to receive.
            </ListItem>
            <ListItem>
              <ListIcon as={ImCross} color="red.500" />
              Don't send, encourage or facilitate unsolicited @mentions.
            </ListItem>
          </List>
          <Text mt="6" fontWeight="bold" fontSize="md">
            Respecting those guidelines means you're likely to comply with
            Twitter's Terms of Use and Policy, making your account safe from
            being restricted or suspended!
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Box/>
          <Button variant={"secondary"} mr={3} onClick={onClose} size="sm">
            Got it
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
