import React, { memo } from 'react';
import {
    Button,
    Stack,
    Box,
    Center,
    Text
} from "@chakra-ui/react";

import {TweetCard} from "./tweetCard";
import PuffLoader from "react-spinners/PuffLoader";




export function TweetList({ status, setList, list, getTweetsFromStatus, removeFromList, isLoading, setHasMore, hasMore }) {

    // const [hasMore, setHasMore] = React.useState(true);

    React.useEffect(() => {
        // console.log("add event listener");

        // window.addEventListener("scroll", loadMore, { passive: true });
        // return () => window.removeEventListener("scroll", loadMore);
        
        window.addEventListener("touchend", loadMore, { passive: true });
        return () => window.removeEventListener("touchend", loadMore);

    }, []);

    const loadMore = async (e) => {
        // console.log("loadMore");
        var node = e.target;
        // console.log("node.scrollHeight", node.scrollHeight);
        // console.log("node.scrollTop", node.scrollTop);
        // console.log("node.scrollHeight - node.scrollTop: " + (node.scrollHeight - node.scrollTop));
        // console.log("node.clientHeight: " + node.clientHeight);
        const bottom = (node.scrollHeight - node.scrollTop) < (node.clientHeight + 100);
        if (bottom) {      
            // console.log("BOTTOM REACHED:", bottom, hasMore); 
            if (!isLoading && hasMore[status]) {
                hasMore[status] = await getTweetsFromStatus(status, setList, list, true);
                setHasMore({...hasMore});
            }
        }    
    }

    if (!isLoading && list?.length === 0) {
      return <Text mt="12" textAlign="center">No {status} tweets found</Text>
    }

    return (
        <Box p={4} onScroll={loadMore} maxH="calc(100vh - 60px)" overflow="auto"  w="100%" className="noScrollBar">
            <Stack spacing={5} w="100%"> 
                {
                    list.map((tweet: any) => (
                        <TweetCard
                            key={tweet.id}
                            tweet={tweet}
                            // getTweetsFromStatus={getTweetsFromStatus}
                            // setDrafts={setDrafts}
                            // setScheduleds={setScheduleds}
                            removeFromList={removeFromList}
                            list={list}
                            setList={setList}
                        />
                    ))
                }
                {
                    isLoading && (
                        <Center pt={10}>
                            <PuffLoader color={"hsl(203, 100%, 36%)"} loading={true} css="display: block; margin: 0 auto; border-color: red;"  size={50} />
                        </Center>
                    ) 
                }
            </Stack>
        </Box>
    );
}