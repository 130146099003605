import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  Button,
  Box,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Center,
  useDisclosure,
  useMediaQuery,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  Checkbox,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  useColorModeValue,
  Flex,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  Collapse,
  Grid,
  ModalFooter,
  Icon
} from "@chakra-ui/react";
import { useSession } from "next-auth/client";
import { useFirebaseUser } from "../utils/useFirebaseUser";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import { firebaseClient, firebaseAuth } from "../firebaseClient";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { buildSchedule, findNextSlot, saveSchedule, isTweetFromAccount } from "../controllers/schedule";
import * as analytics from "../utils/analytics";
import { SmallAddIcon, QuestionOutlineIcon, ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { postData } from "../utils/helpers";
import { useRouter } from "next/dist/client/router";
import { isAllowed, getPlan } from "../controllers/subscription";
import { getAccount, getAuthUrl } from "../utils/sessionHelper";
import { AiVariationPopup } from "../components/popups/aiVariationPopup";
import * as Sentry from "@sentry/nextjs";
import { XYPlot, XAxis, VerticalBarSeries } from 'react-vis';
import { useOnboardingSteps } from "components/onboard-panel/useOnboardingSteps";
// import Commandine from "commandine-palette";
import { TweetPopup } from "components/popups/tweetPopup";
import { TweetLabels } from "components/tweet-labels";
import { Schedule } from "components/schedule"
import { layerStyle, textStyle } from 'theme/names';
import { colors } from 'theme/colors/colors';
import { SaveIcon } from 'components/icons/SaveIcon';

export const TweetContext = createContext({});

export function TweetWrapper({ children }) {

  const firstField = React.useRef()
  // const [text, setText] = React.useState("");

  const [tabIndex, setTabIndex] = React.useState(0);
  const [selectedTweet, setSelectedTweet] = React.useState(undefined);
  const [hasInit, setHasInit] = React.useState(false);
  const [session, loading] = useSession();
  const isAuthenticated = useFirebaseUser();
  const refComposer = React.createRef();
  const router = useRouter();

  // const { isOpen: isOpenRebuild, onOpen: onOpenRebuild, onClose: onCloseRebuild } = useDisclosure();

  const { isOpen: isOpenVariation, onOpen: onOpenVariation, onClose: onCloseVariation } = useDisclosure();
  const { isOpen: isOpenVariationAuto, onOpen: onOpenVariationAuto, onClose: onCloseVariationAuto } = useDisclosure();
  const focusRefVariation = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [isTooSmallForTweet] = useMediaQuery("(max-width: 1000px)");

  const { isOpen: isOpenEditSchedule, onOpen: onOpenEditSchedule, onClose: onCloseEditScheduleInternal } = useDisclosure();
  const [schedule, setSchedule] = React.useState<any>([]);
  const [addTime, setAddTime] = React.useState(new Date(2020, 0, 1, 18, 0, 0));
  const [isEvergreen, setIsEvergreen] = React.useState(false);

  const [isOpen, setIsOpen] = React.useState(false);
  const [isFullModeOpen, setIsFullModeOpen] = React.useState(false);

  const [isIncognitoMode, setIsIncognitoMode] = React.useState(false);
  const [incognitoCreator, setIncognitoCreator] = React.useState({});

  const initialTextState = {
    text: "",
    typingTimeout: undefined,
    isSaved: false,
    scheduledTime: new Date(),
    files: [],
    threadTweets: [],
  };
  const [textState, setTextState] = useState<any>(initialTextState);
  const [textStates, setTextStates] = React.useState([initialTextState]);
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);
  const [id, setId] = React.useState("");
  const [refresh, refresher] = React.useState<any>({});
  const [nextSlot, setNextSlot] = React.useState<Date>();
  const [isTweetTextChanged, setIsTweetTextChanged] = useState<boolean>(false);
  const [isOpenCredentials, setIsOpenCredentials] = React.useState(false);
  const [selectedLabelsIds, setSelectedLabelsIds] = useState<string[]>([])
  const onCloseCredentials = () => setIsOpenCredentials(false);
  const onOpenCredentials = () => setIsOpenCredentials(true);
  const extraAuthRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const { updateOnboardSteps } = useOnboardingSteps();
  const [forceScheduledTime, setForceScheduledTime] = React.useState<boolean>(false);

  const evergreenScheduleBg = useColorModeValue("green.100", "green.800");
  const evergreenCheckboxColor = useColorModeValue("black", "white");
  const addSlotColor = useColorModeValue("gray.500", "gray.100");
  const xAxisFill = useColorModeValue("black", "white");

  //states for advanced options
  const [isPreventLinkExpand, setIsPreventLinkExpand] = React.useState<boolean>(false);
  const [isPostTwitter, setIsPostTwitter] = React.useState<boolean>(true);

  const [selectedTweetPreview, setSelectedTweetPreview] = useState({ text: "", id: "" });
  const { isOpen: isOpenTweetPreview, onOpen: onOpenTweetPreview, onClose: onCloseTweetPreview } = useDisclosure();

  const setFiles = (files) => {
    if (!files.length)
      return
    // @ts-ignore
    const oldFileList = refComposer?.current?.textState().files || []
    const newFilesList = Array.from(new Set([...oldFileList, ...files].filter(Boolean)));
    const textToAppend = newFilesList.map(f => {
      // @ts-ignore
      if (refComposer?.current?.textState().text.includes(f))
        return
      return "[img:" + f + "]"
    }).join(" ")
    // @ts-ignore
    const oldText = refComposer?.current?.textState().text
    const newText = oldText.length ? oldText + " " + textToAppend : textToAppend
    setTextState({ ...textState, files: newFilesList, text: newText });
  }

  const getFiles = () => {
    // @ts-ignore
    return refComposer?.current?.textState().files || []
  }

  const onCloseEditSchedule = () => {
    onCloseEditScheduleInternal();
  }

  const onClose = () => {
    //@ts-ignore
    setTextState(refComposer?.current?.textState());
    setIsOpen(false);
  };

  const onOpen = () => {
    // setInitText(textState.text ? textState.text : "");
    setTabIndex(0);
    setIsOpen(true);
  };

  useEffect(() => {
    if (isTweetTextChanged) {
      setIsTweetTextChanged(false);
    }
  }, [isTweetTextChanged])

  // console.log("render TweetWrapper " + refComposer.current + " / ");

  // TODO - refactor below function into newTweet and loadTweet

  const newTweet = async (tweet, forceId = "", animate = false, scheduledTime = undefined as any, callback = () => { }) => {
    setSelectedTweet(undefined);
    const { text: defaultText = "", files = [] } = tweet
    // console.log(defaultText?.length);
    // setTextState({text: defaultText, typingTimeout: undefined, isSaved: (defaultText?.length ? true : false)});

    // console.log("newTweet ref: " + refComposer.current);

    if (scheduledTime) {
      // console.log("newTweet scheduledTime: " + scheduledTime);
      setNextSlot(new Date(scheduledTime));
    }
    setForceScheduledTime(false);

    if (refComposer.current) {
      // console.log("setTextState: " + defaultText);
      // console.log(files);

      let newState = {
        ...tweet,
        text: defaultText,
        threadTweets: [],
        typingTimeout: undefined,
        isSaved: (defaultText?.length ? true : false),
        scheduledTime: scheduledTime ? new Date(scheduledTime) : new Date(),
        files: files,
      };

      // console.log('newState:', newState);

      // console.log("refComposer.current.setTextState", newState);
      //@ts-ignore
      refComposer.current.setTextState(newState);

      //@ts-ignore
      refComposer.current.resetUploader(files);
    }

    const isNewTweet = Object.keys(tweet)?.length === 1 && Object.keys(tweet)[0] === "text";
    let updatedTextState = isNewTweet ? {} : { ...textState };
    if (animate) {
      let count = 0;
      while (count < defaultText?.length) {
        setTextState({
          ...updatedTextState,
          text: defaultText.substring(0, count),
        });
        count += 5;
        await new Promise(resolve => setTimeout(resolve, 1));
      }
      setTextState({
        ...updatedTextState,
        text: defaultText,
      });
      callback();
    } else {
      // console.log("refComposer null: " + defaultText);
      // setInitText(defaultText);
      let newState = {
        ...updatedTextState,
        statusGhost: "",
        ...tweet,
        text: defaultText,
        threadTweets: [],
        files: files,
        scheduledTime: scheduledTime ? new Date(scheduledTime) : new Date(),
      };

      // if (scheduledTime)
      //     newState.scheduledTime = scheduledTime;

      // console.log("setTextState", newState);
      setTextState(newState);
    }

    if (defaultText?.length > 0 && !forceId) {
      let newId = uuidv4();
      setId(newId);
      createDraft({ text: defaultText }, newId);
    }
    else if (forceId) {
      setId(forceId);
    }
    else {
      let newId = uuidv4();
      setId(newId);
    }

    //@ts-ignore
    if (refComposer?.current?.simulateInput) {
      //@ts-ignore
      refComposer.current.simulateInput();
    }
  }

  const createDraft = async (data, newId) => {

    try {
      if (!isAuthenticated) {
        // toast.error("App not fully loaded yet, please retry later");
        // Sentry.captureException(new Error("createDraft - App not fully loaded yet, please retry later"));
        return;
      }

      if (!newId) {
        console.error("createDraft - ID is undefined");
        Sentry.captureException(new Error("createDraft - ID is undefined"));
      }

      // let newId = uuidv4();
      // setId(newId); 

      if (getAccount(session)?.id) {
        let newTweet = {
          id: newId,
          idAccount: getAccount(session).idAccount ?? getAccount(session).id,
          text: data.text ?? "",
          idUser: getAccount(session).id,
          threadTweets: [],
          status: "draft",
          dateCreated: new Date(),
          profilePic: getAccount(session).image,
          name: getAccount(session).name,
          userId: getAccount(session).id,
          twUserName: getAccount(session).twUserName,
          ...data
        }
        // console.log({newTweet});
        const db = firebaseClient.firestore();
        await db.collection("users").doc(getAccount(session).id).collection("tweets").doc(newId).set(newTweet);
      }
    }
    catch (e) {
      console.error(e);
      Sentry.captureException(e);
      // toast.error("An error happened while creating a draft");
      return false;
    }

    return true;
  }

  React.useEffect(() => {
    if (session?.user?.firebaseToken) {
      firebaseAuth(session);
    }
  }, [session]);

  const requestVariationAuto = (tw) => {

    setSelectedTweet(tw);
    onOpenVariationAuto();
  }

  const refreshNextSlot = async (scheduleTweets) => {

    if (!scheduleTweets) {
      const db = firebaseClient.firestore();
      let docs = await db.collection("users").doc(getAccount(session).id).collection("tweets").where("status", "==", "scheduled").orderBy("scheduledTime", "asc").get();
      scheduleTweets = [];
      docs.forEach(doc => {
        let data = doc.data();
        if (isTweetFromAccount(data, session)) {
          scheduleTweets.push(data);
        }
      })
    }

    let date = findNextSlot(session, scheduleTweets);
    if (date)
      setNextSlot(date);
  }

  const requestVariation = (tw) => {

    // console.log("requestVariation: " + session?.user?.data?.credits);
    if (session?.user?.data?.credits == 0) {
      console.log("no more credits");
      analytics.log("requestVariation_noMoreCredits", { ...session?.user?.data });
    }

    setSelectedTweet(tw);
    onOpenVariation();
  }

  const getRandomIntFromInterval = (min: number = -5, max: number = 5) => Math.floor(Math.random() * (max - min + 1) + min);

  const naturalizeSchedule = () => {
    // console.log("naturalizeSchedule");

    if (!schedule) {
      toast.error("No Schedule found!")
      return;
    }

    const newSchedule = schedule.map(s => {
      let newMinute = s.minute;
      let newHour = s.hour;

      // console.log("naturalizeSchedule " + s.minute);
      // console.log(s);

      if (!s.minute)
        s.minute = 0;

      if (s.minute < 55 && s.minute > 4) {
        newMinute = s.minute + (getRandomIntFromInterval());
      }
      if (s.minute <= 4) {
        const randomNumber = getRandomIntFromInterval();
        //   console.log("naturalizeSchedule randomNumber: " + randomNumber);

        if ((randomNumber + s.minute) < 0) {
          if (s.hour !== 0) {
            newMinute = 60 + randomNumber;
            newHour = s.hour - 1;
          } else {
            newMinute = s.minute + getRandomIntFromInterval(1, 5);
          }
        } else {
          newMinute = s.minute + randomNumber;
        }
      }
      if (s.minute >= 55) {
        const randomNumber = getRandomIntFromInterval();

        if ((randomNumber + s.minute) > 59) {
          if (s.hour !== 23) {
            newMinute = (randomNumber + s.minute) - 60;
            newHour = s.hour + 1;
          } else {
            newMinute = s.minute + getRandomIntFromInterval(-5, -1);
          }
        } else {
          newMinute = s.minute + randomNumber;
        }
      }
      return { ...s, minute: newMinute, hour: newHour };
    })

    setSchedule([...newSchedule]);
    saveSchedule(session, newSchedule, refresher);
  }

  const open = () => {
    onOpen();
    //@ts-ignore
    refComposer?.current?.focus();
  }

  const handleAddSlot = () => {

    if (schedule?.length && schedule.some(x => x.hour === addTime.getHours() && x.minute === addTime.getMinutes())) {
      toast.error("Can't add a 2nd slot at the exact same time as another slot.");
      return;
    }

    let hasAlreadyEvergreen = schedule?.length ? schedule?.filter(x => x.isEvergreen)?.length > 0 : false;

    let time = {
      hour: addTime.getHours(),
      minute: addTime.getMinutes() ?? 0,
      days: isEvergreen && hasAlreadyEvergreen ? [false, false, false, false, false, false, false] : [true, true, true, true, true, true, true],
      isEvergreen: isEvergreen,
      labels: selectedLabelsIds
    }

    if (time.hour < 0 || time.hour > 24 || time.minute < 0 || time.minute > 60) {
      toast.error("Invalid slot configuration");
      return;
    }

    let newSchedule = schedule;

    if (!newSchedule) {
      newSchedule = [];
    }

    //@ts-ignore
    newSchedule.push(time);
    //@ts-ignore
    newSchedule.sort((a, b) => parseFloat("" + a.hour + "." + (a.minute < 10 ? "0" + a.minute : a.minute)) - parseFloat("" + b.hour + "." + (b.minute < 10 ? "0" + b.minute : b.minute)));
    setSchedule([...newSchedule]);
    setSelectedLabelsIds([]);
    saveSchedule(session, newSchedule, refresher);

    if (isEvergreen) {
      updateOnboardSteps("evergreen", "engage");
    }
  }

  const advancedOptions = {
    isPreventLinkExpand,
    setIsPreventLinkExpand,
    isPostTwitter,
    setIsPostTwitter
  }

  let sharedState = {
    isOpen: isOpen,
    setIsOpen: setIsOpen,
    isFullModeOpen,
    setIsFullModeOpen,
    isIncognitoMode,
    setIsIncognitoMode,
    incognitoCreator,
    setIncognitoCreator,
    isPreviewOpen,
    setIsPreviewOpen,
    setFiles,
    getFiles,
    open: open,
    close: onClose,
    newTweet: newTweet,
    setSelectedTweet: setSelectedTweet,
    selectedTweet: selectedTweet,
    requestVariation: requestVariation,
    requestVariationAuto: requestVariationAuto,
    createDraft: createDraft,
    textState: textState,
    setTextState: setTextState,
    textStates: textStates,
    setTextStates: setTextStates,
    refComposer,
    id: id,
    onOpenEditSchedule: onOpenEditSchedule,
    tabIndex: tabIndex,
    setTabIndex: setTabIndex,
    setSchedule: setSchedule,
    schedule: schedule,
    setNextSlot: setNextSlot,
    nextSlot: nextSlot,
    refreshNextSlot: refreshNextSlot,
    refresher: refresher,
    refresh: refresh,
    onOpenCredentials: onOpenCredentials,
    onCloseCredentials: onCloseCredentials,
    isTweetTextChanged,
    setIsTweetTextChanged,
    advancedOptions,
    setSelectedTweetPreview,
    selectedTweetPreview,
    isOpenTweetPreview,
    onOpenTweetPreview,
    onCloseTweetPreview,
    setForceScheduledTime,
    forceScheduledTime,
  }

  const [showNewSlot, showNewSlotSet] = useState(false)
  const verticalBarSeriesColor = useColorModeValue(colors.primary.lightMode[200], colors.primary.darkMode[200])

  return (
    <TweetContext.Provider value={sharedState}>
      {children}

      {/* {
                !session?.user?.firebaseToken && session && (
                    <Box
                        position="fixed"
                        left={0}
                        top={0}
                        w="100%"
                        h="100%"
                        backgroundColor="white"
                        zIndex={2000}
                    >
                        <Center position="absolute" top="50%" left="50%" transform="translateX(-50%)" >
                            <PuffLoader color={"hsl(203, 100%, 36%)"} loading={true} css="display: block; margin: 0 auto; border-color: red;"  size={50} />
                        </Center>
                    </Box>
                )
            } */}

      <AiVariationPopup isOpen={isOpenVariationAuto} onClose={onCloseVariationAuto} selectedTweet={selectedTweet} currentTempLevel={session?.user?.data?.tempLevel} newTweet={newTweet} onOpenScheduler={onOpen} />
      <AlertDialog
        isOpen={isOpenVariation}
        //@ts-ignore
        leastDestructiveRef={focusRefVariation}
        onClose={onCloseVariation}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Request tweet variations
            </AlertDialogHeader>

            <AlertDialogBody>
              {
                //@ts-ignore
                session?.user?.data?.credits && session?.user?.data?.credits > 0 ? (
                  `This action will cost you 1 credit. You will have ${session.user.data.credits - 1} left.`
                )
                  : (
                    isAllowed(session?.user, "ghostwriting") ? "You can't perform this action, not enough credits. Credits will be reseted on the 1st of each month." : "You can't perform this action, not enough credits."
                  )
              }
              <Text mt={4} fontSize="sm">
                Variations will be written manually by our team of ghostwriters. The process can take up to 48 hours.
              </Text>
              <Text mt={4} fontSize="sm">
                Once ready, you can find them in the section "My Variations".
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="tertiary" onClick={onCloseVariation}>
                Cancel
              </Button>
              {
                session?.user?.data?.credits && session?.user?.data?.credits > 0 ? (
                  <Button ref={focusRefVariation} variant={"primary"} onClick={async () => {
                    //@ts-ignore
                    session.user.data.credits--;
                    if (session?.user?.uid && selectedTweet) {
                      postData({
                        url: "user-requestVariation",
                        token: "",
                        data: {
                          idUser: session.user.uid,
                          //@ts-ignore
                          twUserName: session.user.data.twUserName,
                          tweet: selectedTweet,
                        },
                      });
                      toast.success("Variation requested successfully", { style: { background: "gray.600", color: "#222" } });
                      //@ts-ignore
                      analytics.log("hit_request_variations", { ...analytics.formatTweet(selectedTweet) });
                    }
                    else {
                      toast.error("An error occurred", { style: { background: "gray.600", color: "#222" } });
                    }
                    onCloseVariation();
                  }} ml={3}>
                    Confirm
                  </Button>
                ) : (
                  ["start", "grow"].includes(getPlan(session?.user)) ? (
                    <Button ref={focusRefVariation} ml={3} variant={"primary"} onClick={() => {
                      router.push("/pricing");
                      onCloseVariation();
                    }}>
                      Upgrade
                    </Button>
                  ) : (
                    <Box>

                    </Box>
                  )
                )
              }
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        isOpen={isOpenEditSchedule}
        onClose={onCloseEditSchedule}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit your tweet schedule</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box overflow="scroll" className="noScrollBar">
              <Table size="sm" variant="simple">
                <Thead>
                  <Tr>
                    <Th {...{ textStyles: "small.bold.standard" }}>Time</Th>
                    {
                      ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day, index) => (
                        <Th key={day}>{day}</Th>
                      ))
                    }
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    schedule && schedule.map((time: any, index) => (
                      <Schedule
                        time={time}
                        index={index}
                        key={"time-" + time.hour + "-" + time.minute}
                        onDelete={() => {
                          schedule.splice(index, 1);
                          setSchedule([...schedule]);
                          saveSchedule(session, schedule, refresher);
                        }}
                        onChange={(indexDay, isChecked) => {
                          if (time.isEvergreen && isChecked) {
                            if (
                              schedule
                                .filter((x) => x.isEvergreen)
                                ?.some((x) => x.days[indexDay] === true)
                            ) {
                              toast.error(
                                "You may only have one active Evergreen slot per day to prevent spam"
                              );
                              return;
                            }
                          }
                          time.days[indexDay] = isChecked;
                          setSchedule([...schedule]);
                          saveSchedule(session, schedule, refresher);
                        }}
                      />
                    ))
                  }
                </Tbody>
              </Table>
            </Box>
            <Box textAlign="center" mb="5">
              <Tooltip label="This will randomly add or subtract up to 5 minutes to your posting time to make them more natural and avoid peak posting hours.">
                <Button variant="tertiary" size="sm" onClick={naturalizeSchedule}>Make my schedule more natural</Button>
              </Tooltip>
            </Box>
            <Grid {...{ gap: 4, }}>
              <Button
                placeSelf="center"
                variant="secondary"
                onClick={() => { showNewSlotSet(!showNewSlot) }}
                leftIcon={<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 6.5C13 6.9375 12.6562 7.25 12.25 7.25H7.25V12.25C7.25 12.6875 6.90625 13.0312 6.5 13.0312C6.0625 13.0312 5.75 12.6875 5.75 12.25V7.25H0.75C0.3125 7.25 0 6.9375 0 6.53125C0 6.09375 0.3125 5.75 0.75 5.75H5.75V0.75C5.75 0.34375 6.0625 0.03125 6.5 0.03125C6.90625 0.03125 7.25 0.34375 7.25 0.75V5.75H12.25C12.6562 5.75 13 6.09375 13 6.5Z" fill="currentColor" />
                </svg>
                }
                rightIcon={showNewSlot ? <ChevronUpIcon /> : <ChevronDownIcon />}
              >
                Add a new slot
              </Button>
              <Collapse in={showNewSlot}>
                <Box
                  p="3"
                  layerStyle={layerStyle['bg.border.rounded']}
                  textStyle="body.medium.standard"
                >
                  <HStack
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button bg="white" p={0} w="150px" mr="2" color="black">
                      <Datetime initialValue={addTime}
                        dateFormat={false}
                        timeFormat="hh:mm A"
                        inputProps={{ className: "customPicker" }}
                        onChange={(d) => {
                          //@ts-ignore
                          d?.toDate && setAddTime(d.toDate());
                        }}
                      />
                    </Button>
                    {
                      // true &&(
                      session?.user?.data?.hasEvergreen && (
                        <Checkbox
                          // variant={"secondary"}
                          // color={evergreenCheckboxColor}
                          defaultChecked={false}
                          onChange={async (e) => {
                            setIsEvergreen(e.target.checked);
                          }}
                        >
                          Evergreen slot
                        </Checkbox>
                      )
                    }
                  </HStack>
                  <Accordion allowToggle mt="2">
                    <AccordionItem border="none">
                      <AccordionButton
                        width="auto"
                        marginX="auto"
                        _focus={{
                          boxShadow: "none"
                        }}
                        _hover={{
                          bg: "none"
                        }}
                      >
                        <Box flex='1' textAlign='left' textStyle={textStyle['body.medium.standard']}>
                          Advanced Options
                        </Box>
                        <AccordionIcon ml="1" />
                      </AccordionButton>
                      <AccordionPanel textAlign="center">
                        <Box layerStyle={layerStyle.bgBorderGrayRounded} py={4}>
                          <Flex justifyContent="center" alignItems="center">
                            <Text fontWeight="semibold" textStyle={textStyle['body.medium.standard']}>Assign a label to your new slot</Text>
                            <Tooltip
                              label={
                                <>
                                  <Text>You can use labels to categorize your tweets (topics, format, etc.).</Text>
                                  <br />
                                  <Text>
                                    By assigning a label to a slot, you can organize your weekly queue in a way that diversifies the type of content you post (promotional, entertaining, high-value, etc.).
                                  </Text>
                                  <br />
                                  <Text>
                                    Create a slot with a label assigned to it, and then assign the label to your tweet before adding it to your queue using the drop down menu in the composer. It will automatically be added to the next available slot that has the proper label assigned to it.
                                  </Text>
                                </>
                              }
                            >
                              <QuestionOutlineIcon ml="3" />
                            </Tooltip>
                          </Flex>
                          <Box mt="4">
                            <TweetLabels
                              selectedLabelIds={selectedLabelsIds}
                              onChange={(labels, callback) => {
                                if (labels?.length > 1) {
                                  toast.error("You can only assign 1 label to a queue slot")
                                  callback && callback(true);
                                  return;
                                }

                                setSelectedLabelsIds(labels);
                                callback && callback();
                              }}
                            />
                          </Box>
                        </Box>

                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                  <Flex justifyContent="center" mt="4">
                    <Button
                      mb={2}
                      variant="primary"
                      onClick={handleAddSlot}
                      leftIcon={SaveIcon}
                    >
                      Save Slot
                    </Button>
                  </Flex>
                </Box>
              </Collapse>
            </Grid>
            {/* <Center mt={5}>
                            <Button variant="unstyled" textDecoration="underline" fontWeight="400" fontSize="sm" onClick={() => {
                                router.push("/settings");
                                onCloseEditSchedule();
                                onClose();
                            }}>
                                Timezone: {getAccount(session)?.timezone}
                            </Button>
                        </Center> */}
            {
              getAccount(session)?.engagementDistribution ? (
                <Center display="Flex" flexDirection="column">
                  <Flex w="100%" justifyContent="flex-end">
                    <Button
                      variant="secondary"
                      isDisabled={!isAuthenticated}
                      onClick={async () => {
                        // toast.info("Refreshing, this can take up to 1 minute");
                        toast.promise(
                          buildSchedule(session, true, refresher),
                          {
                            loading: 'Refreshing ... can take 1 minute',
                            success: 'Engagement refreshed',
                            error: 'An error happened',
                          }
                        );
                        // await buildSchedule(session, true);
                      }}
                      leftIcon={<svg width="14" height="14" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.6875 9.03125C15.0938 9.15625 15.3125 9.5625 15.1875 9.96875C14.3125 12.9375 11.5625 15 8.46875 15C6 15 3.71875 13.6562 2.46875 11.5625V14.75C2.46875 15.1875 2.15625 15.5 1.71875 15.5C1.3125 15.5 0.96875 15.1875 0.96875 14.75V9.75C0.96875 9.34375 1.3125 9 1.71875 9H6.71875C7.15625 9 7.46875 9.34375 7.46875 9.75C7.46875 10.1875 7.125 10.5 6.71875 10.5H3.5625C4.5 12.3125 6.375 13.5 8.46875 13.5C10.9062 13.5 13.0938 11.875 13.75 9.53125C13.875 9.125 14.2812 8.90625 14.6875 9.03125ZM15.25 0.5C15.6562 0.5 16 0.84375 16 1.25V6.25C16 6.6875 15.6562 7 15.25 7H10.25C9.8125 7 9.5 6.6875 9.5 6.25C9.5 5.84375 9.8125 5.5 10.25 5.5H13.375C12.4375 3.71875 10.5625 2.5 8.5 2.5C6.0625 2.5 3.875 4.15625 3.1875 6.46875C3.09375 6.875 2.6875 7.09375 2.28125 6.96875C1.875 6.875 1.65625 6.4375 1.75 6.0625C2.625 3.09375 5.40625 1 8.5 1C10.9688 1 13.25 2.375 14.5 4.46875V1.25C14.5 0.84375 14.8125 0.5 15.25 0.5Z" fill="currentColor" />
                      </svg>}
                    >
                      Refresh
                    </Button>
                  </Flex>
                  <Text m={5} textStyle={textStyle["body.bold.standard"]}>Times at which your followers are most active</Text>
                  <Box ml={-7}>
                    <XYPlot height={200} width={600}>
                      <VerticalBarSeries color={verticalBarSeriesColor} data={getAccount(session).engagementDistribution.map((x, index) => {
                        return {
                          // x: index > 12 ? (index/2) + "pm" : index + "am", 
                          // x: "am", 
                          x: index,
                          y: x
                        }
                      })} />
                      <XAxis style={{ text: { fill: xAxisFill, fontWeight: 400 } }} />
                    </XYPlot>
                  </Box>
                  <Text mb={2} textStyle={textStyle["body.bold.light"]}>Time (h)</Text>
                </Center>
              ) : (
                <Center m={10}>
                  <Button
                    isDisabled={!isAuthenticated}
                    variant="secondary"
                    onClick={async () => {
                      // toast.info("Refreshing, this can take up to 1 minute");
                      toast.promise(
                        buildSchedule(session, true, refresher),
                        {
                          loading: 'Analyzing ... can take 1 minute',
                          success: 'Engagement refreshed',
                          error: 'An error happened',
                        }
                      );
                      // await buildSchedule(session, true);
                    }}
                  >
                    Analyze your followers activity
                  </Button>
                </Center>
              )
            }
          </ModalBody>
          <ModalFooter>
            <Center w="full">
              <Button
                variant="tertiary"
                textDecoration="underline" fontWeight="400" fontSize="sm" onClick={() => {
                  router.push("/settings");
                  onCloseEditSchedule();
                  onClose();
                }}
                leftIcon={<Icon viewBox="0 0 16 16" >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0ZM8 14.5C8.21875 14.5 8.8125 14.2812 9.46875 13C9.75 12.4375 9.96875 11.7812 10.1562 11H5.8125C6 11.7812 6.21875 12.4375 6.5 13C7.15625 14.2812 7.75 14.5 8 14.5ZM5.5625 9.5H10.4062C10.4688 9.03125 10.5 8.53125 10.5 8C10.5 7.5 10.4688 7 10.4062 6.5H5.5625C5.5 7 5.5 7.5 5.5 8C5.5 8.53125 5.5 9.03125 5.5625 9.5ZM10.1562 5C9.96875 4.25 9.75 3.59375 9.46875 3.03125C8.8125 1.75 8.21875 1.5 8 1.5C7.75 1.5 7.15625 1.75 6.5 3.03125C6.21875 3.59375 6 4.25 5.8125 5H10.1562ZM11.9062 6.5C11.9688 7 12 7.5 12 8C12 8.53125 11.9688 9.03125 11.9062 9.5H14.3125C14.4375 9.03125 14.5 8.53125 14.5 8C14.5 7.5 14.4375 7 14.3125 6.5H11.9062ZM10.6875 2.09375C11.125 2.90625 11.4688 3.90625 11.6875 5H13.75C13.0938 3.71875 12 2.6875 10.6875 2.09375ZM5.28125 2.09375C3.96875 2.6875 2.875 3.71875 2.21875 5H4.28125C4.5 3.90625 4.84375 2.90625 5.28125 2.09375ZM1.5 8C1.5 8.53125 1.53125 9.03125 1.65625 9.5H4.0625C4 9.03125 4 8.53125 4 8C4 7.5 4 7 4.0625 6.5H1.65625C1.53125 7 1.5 7.5 1.5 8ZM13.75 11H11.6875C11.4688 12.125 11.125 13.125 10.6875 13.9375C12 13.3438 13.0938 12.3125 13.75 11ZM4.28125 11H2.21875C2.875 12.3125 3.96875 13.3438 5.28125 13.9375C4.84375 13.125 4.5 12.125 4.28125 11Z"
                      fill="currentColor"
                    />
                  </svg>
                </Icon>}
              >
                Timezone: {getAccount(session)?.timezone}
              </Button>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={isOpenCredentials}
        onClose={onCloseCredentials}
        //@ts-ignore
        leastDestructiveRef={extraAuthRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {"One more step"}
            </AlertDialogHeader>
            <AlertDialogBody >
              <Flex p={5} pt={0} flexDirection="column" alignItems="center">
                <Text>{"To publish tweets, we need some extra authorization from you."}</Text>
                <Text mt={5}>{"Hit the button and let our scheduler work wonders for you."}</Text>
                <Button mt={10} maxW="200px" variant={"primary"}
                  //@ts-ignore
                  ref={extraAuthRef}
                  as="a"
                  href={getAuthUrl(session)}
                  target="_blank"
                  onClick={() => {
                    analytics.log("hit_getExtraAuth");
                    onCloseCredentials();
                  }}
                >
                  Allow us to send tweet
                </Button>
              </Flex>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {/* <ConfirmPopup isOpen={isOpenRebuild} onClose={onCloseRebuild} 
                title="Re-Queue" 
                body={"Are you sure you want to re-queue your tweets? This will reschedule all your tweets and threads to fill the gaps in the queue."}
                callback={async () => {
                    analytics.log("hit_rebuildQueue");
                    await rebuildQueue(session, undefined, refresher);
                }}
            /> */}
      {/* Project not maintained */}
      {/* <Commandine 
                projectId="FBfg5RI803gpbNWl1AFPvQpwUTB2"
                versionId="fdadf783-a78f-44eb-b462-5ced3d3ec5fd"
                mode="floating"
            /> */}
      <TweetPopup isOpen={isOpenTweetPreview} onClose={onCloseTweetPreview} text={selectedTweetPreview?.text} id={selectedTweetPreview?.id} tweet={selectedTweetPreview} />
    </TweetContext.Provider>
  );
}

export function useTweetContext() {
  return useContext(TweetContext);
}
