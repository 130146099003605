export const feather = {
  color: "white",
  bg: "primary.lightMode.default",
  borderRadius: "200px",
  border: "3px solid",
  borderColor: "primary.lightMode.100",
  _hover: {
    bg: "primary.lightMode.400",
    borderColor: "primary.lightMode.200",
  },
  _active: {
    bg: "primary.lightMode.400",
    borderColor: "primary.lightMode.200",
  },
  _dark: {
    bg: "primary.darkMode.default",
    borderColor: "primary.darkMode.100",
  },
};
