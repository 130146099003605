import { useState, useEffect } from "react";
import { useSession } from "next-auth/client";
import {
  Box,
  HStack,
  Menu,
  MenuButton,
  Text,
  MenuList,
  MenuItem,
  Button,
  Tooltip,
  IconButton,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure
} from "@chakra-ui/react";
import { ChevronDownIcon, AddIcon, EditIcon } from "@chakra-ui/icons";
import toast from "react-hot-toast";
import { v4 as uuid } from "uuid";

import { TweetComposer } from "components/tweet-composer";
import { getAccount, updateUser } from "utils/sessionHelper";
import { SaveIcon } from "components/icons/SaveIcon";
import { DeleteIcon } from "components/icons/DeleteIcon";
import { ConfirmPopup } from "components/popups/confirmPopup";
import { useTweetContext } from "context/tweetContext";

interface Plug {
  id: string;
  name: string;
  text: string;
}

interface Props {
  text: string;
  onChange: (text: string) => void;
}

export function AutoPlug({ text, onChange }: Props) {
  const [plugs, setPlugs] = useState<Plug[]>([]);
  const [selectedPlug, setSelectedPlug] = useState<Plug>();
  const [plugName, setPlugName] = useState<string>("");
  const [isSavingPlug, setIsSavingPlug] = useState<boolean>(false);
  const [mode, setMode] = useState<"create" | "edit">("create");
  const [isSavingPlugText, setIsSavingPlugText] = useState<boolean>(false);
  const [refresh, refresher] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
  const [session] = useSession();
  const tweetContext: any = useTweetContext();

  // set plugs
  useEffect(() => {
    if (session) {
      const plugList = getAccount(session)?.autoPlugList;

      if (plugList && plugList?.length > 0) {
        setPlugs(plugList.slice());
      } else {
        setPlugs([]);
      }
    }
  }, [session])

  useEffect(() => {
    if (plugs.length === 0) {
      setSelectedPlug(undefined);
      return;
    }

    // when no plug is selected, select the first option.
    if (!selectedPlug) {
      setSelectedPlug(plugs[0]);
      if (!text) {
        onChange(plugs[0].text);
      }
    }
  }, [plugs])

  // useEffect part 1 & useEffect part 2 are together playing a role here to efficiently select the plug,
  // when you edit a "scheduled tweet" or a tweet from "drafts"
  // part 1 - trigger a rerender when a scheduled / drafts tweets are edited
  useEffect(() => {
    refresher({});
  }, [tweetContext.refresh])

  // part 2 - select the plug with the same auto plug text
  useEffect(() => {
    const matchingPlug = plugs.find(plug => plug.text === text);
    if (matchingPlug) {
      setSelectedPlug(matchingPlug);
    }
  }, [refresh])

  const handleCreatePlug = async () => {
    if (!plugName.trim()) {
      toast.error("Plug name cannot be empty");
      return;
    }

    if (plugs.find(plug => plug.name === plugName)) {
      toast.error("Plug with same name already exists!");
      return;
    }

    const newPlug: Plug = {
      id: uuid(),
      name: plugName,
      text: text
    }

    const plugsCopy = plugs.slice();
    plugsCopy.push(newPlug);

    setIsSavingPlug(true);
    await updateUser(session, { autoPlugList: plugsCopy });
    setSelectedPlug(newPlug);
    setPlugs(plugsCopy);
    setIsSavingPlug(false);
    onClose();

  }

  const handleUpdatePlug = async () => {
    if (!plugName.trim()) {
      toast.error("Plug name cannot be empty");
      return;
    }

    if (plugs.find(plug => plug.name === plugName)) {
      toast.error("Plug with same name already exists!");
      return;
    }

    // to avoid typescript error in the next line
    if (!selectedPlug) return;

    const updatedPlug: Plug = { ...selectedPlug, name: plugName };
    const index = plugs.findIndex(plug => plug.id === updatedPlug.id);
    const plugsCopy = plugs.slice();
    plugsCopy[index] = updatedPlug;

    setIsSavingPlug(true);
    await updateUser(session, { autoPlugList: plugsCopy });
    setSelectedPlug(updatedPlug);
    setPlugs(plugsCopy);
    setIsSavingPlug(false);
    onClose();
  }

  const handleDeletePlug = async () => {
    const filteredPlugs = plugs.filter(plug => plug.id !== selectedPlug?.id);

    await updateUser(session, { autoPlugList: filteredPlugs });
    setSelectedPlug(undefined);
    setPlugs(filteredPlugs);
    onCloseDelete();
  }

  const handleSavePlugText = async () => {
    // to avoid typescript error in next line
    if (!selectedPlug) return;

    const updatedPlug: Plug = { ...selectedPlug, text: text };
    const index = plugs.findIndex(plug => plug.id === updatedPlug.id);
    const plugsCopy = plugs.slice();
    plugsCopy[index] = updatedPlug;

    setIsSavingPlugText(true);
    await updateUser(session, { autoPlugList: plugsCopy });
    setSelectedPlug(updatedPlug);
    setPlugs(plugsCopy);
    setIsSavingPlugText(false);
  }

  const handleSelectPlug = (plug: Plug) => {
    setSelectedPlug(plug);
    onChange(plug.text);
  }

  return (
    <Box my="4">
      <HStack mb="3" spacing="0" mt="3">

        {/* Plugs dropdown */}
        <Menu>
          <MenuButton
            as={Button}
            size="sm"
            variant={"secondary"}
            rightIcon={<ChevronDownIcon />}
            maxW="36"
            mr="2"
          >
            <Text noOfLines={1} wordBreak="break-all">{selectedPlug ? selectedPlug.name : "No plugs found"}</Text>
          </MenuButton>
          <MenuList maxH="215px" overflow="scroll">
            {plugs.length === 0 ? <MenuItem>No plugs found</MenuItem> : null}
            {plugs?.length > 0 ? (
              plugs.map(plug => (
                <MenuItem key={plug.id} onClick={() => handleSelectPlug(plug)}>{plug.name}</MenuItem>
              ))
            ) : null}
          </MenuList>
        </Menu>

        {/* Action buttons */}
        {selectedPlug ? (
          <>
            <Tooltip label="Save" >
              <IconButton
                aria-label="Save plug"
                size="sm"
                variant="action"
                icon={SaveIcon}
                onClick={handleSavePlugText}
                isLoading={isSavingPlugText}
              >
                Save
              </IconButton>
            </Tooltip>
            <Tooltip label="Edit">
              <IconButton
                aria-label="Edit plug"
                icon={<EditIcon />}
                size="sm"
                variant="action"
                onClick={() => {
                  setMode("edit");
                  setPlugName(selectedPlug.name);
                  onOpen();
                }}
              />
            </Tooltip>
            <Tooltip label="Delete">
              <IconButton
                aria-label="Delete plug"
                variant="action"
                icon={DeleteIcon}
                size="sm"
                onClick={onOpenDelete}
              />
            </Tooltip>
          </>
        ) : null}
        <Tooltip label="Create">
          <IconButton
            aria-label="add plug"
            icon={<AddIcon />}
            size="sm"
            variant="action"
            onClick={() => {
              setMode("create");
              setPlugName("");
              onOpen();
            }}
          />
        </Tooltip>

      </HStack>


      {/* Text Editor */}
      <TweetComposer
        initText={text}
        disableHeader={true}
        disableImage={false}
        disableThread={true}
        disableGif={true}
        mode="autoPlug"
        disableReWrite={true}
        customSave={(text) => {
          onChange(text);
        }}
      />

      {/* Create / Edit Plug Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{mode === "create" ? "Create" : "Update"} Plug</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              borderRadius="lg"
              placeholder="Plug name..."
              value={plugName}
              onChange={e => setPlugName(e.target.value)}
              onKeyUp={e => {
                if (e.key === "Enter") {
                  if (mode === "create") {
                    handleCreatePlug();
                  } else {
                    handleUpdatePlug();
                  }
                }
              }}
              isDisabled={isSavingPlug}
            />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="tertiary"
              mr={3}
              onClick={onClose}
              isDisabled={isSavingPlug}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              leftIcon={SaveIcon}
              isLoading={isSavingPlug}
              onClick={() => {
                if (mode === "create") {
                  handleCreatePlug();
                } else {
                  handleUpdatePlug();
                }
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Delete Plug Modal */}
      <ConfirmPopup
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        title="Delete plug"
        body={
          <Text>
            Are you sure? You are about to delete <b>{selectedPlug?.name}</b>{" "}
            plug.
          </Text>
        }
        callback={handleDeletePlug}
      />
    </Box>
  )
}