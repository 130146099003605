import React, { memo, useEffect, useState } from 'react';
import {
    Button,
    Stack,
    Box,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    IconButton,
    Link,
    useDisclosure,
    Text,
    VStack,
    useColorModeValue,
    Flex,
    Image,
    theme,
    Switch,
    FormControl,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    RadioGroup,
    Radio,
    NumberInput,
    NumberInputField,
    Alert,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Center,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Checkbox,
    Icon,
    ButtonGroup,
    Input,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Divider,
    AlertIcon,
    Progress,
} from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/tooltip";
import NextLink from "next/link";
import { useSession } from "next-auth/client";
import toast from "react-hot-toast";
import { firebaseClient } from "../../firebaseClient";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import Tweet from "../../components/tweets/masonry/tweet";
import { TweetComposer } from "../../components/tweet-composer";
import {
    pushTaplio,
    postTweet,
    scheduleTweet,
    buildSchedule,
    findNextSlot,
    pushTweet,
    isTweetFromAccount,
    saveTweet,
    checkTweetValid,
    shareNext,
} from "../../controllers/schedule";
import dateFormat from 'dateformat';
import { format, formatDistance } from "date-fns";
import { TiTickOutline } from "react-icons/ti";
import { colors } from 'theme/colors/colors';
import * as analytics from "../../utils/analytics";
import { BiGhost } from 'react-icons/bi';
import { AiTwotoneEdit, AiOutlineEye } from 'react-icons/ai';
import { FiShare2 } from 'react-icons/fi';
import { BiTimeFive } from 'react-icons/bi';
import { useRouter } from "next/dist/client/router";
import moment from "moment-timezone";
import { motion } from "framer-motion"
import { TweetContext } from "../../context/tweetContext";
import { ChevronDownIcon, ChevronUpIcon, SunIcon, MoonIcon } from '@chakra-ui/icons'
import { QuestionIcon } from '@chakra-ui/icons';
import Thread from "../../components/tweets/masonry/thread";
import { IncognitoSidebar } from "components/incognito/incognito-sidebar";
import { TweetList } from "./tweetList";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getAccount, getAuthUrl, updateUser } from "../../utils/sessionHelper";
import { useFirebaseUser } from "../../utils/useFirebaseUser";
import { MdOutlineNextPlan } from 'react-icons/md';
import { convertToAccountTimezone, convertToAccountTimezoneReversed } from "../../utils/helpers";
import { useOnboardingSteps } from "components/onboard-panel/useOnboardingSteps";
import { CreatorListItem } from "components/scheduler/creatorListItem";
import { MainContext } from "../../context/mainContext";
import { completeTokenTask } from "controllers/tokens"
import { AutoDmEducation } from "components/popups/auto-dm-education";
import { OptionItem } from "components/scheduler/optionItem";
import { useStats } from "hooks/useStats";
import * as Bowser from "bowser"
import { setInLocalStorage } from "utils/helpers";
import { useWindowWidth } from '@react-hook/window-size';
import { TweetLabels } from "components/tweet-labels";
import { pushToCreator } from "controllers/incognito";
import { BsChevronDown, BsChevronRight } from "react-icons/bs"
import { layerStyle, textStyle, variant } from 'theme/names';
import { layerStyles } from 'theme/layerStyles/layerStyles';
import { SettingsIconComponent } from 'components/icons/SettingsIconComponent';
import { ContactListDropdown } from "components/contact-list-dropdown";
import { AutoPlug } from './auto-plug';
const async = require('async');


function DotsLoader() {
    const [dots, setDots] = useState(0);
    const [dotsInterval, setDotsInterval] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        setDotsInterval(setInterval(() => {
            setDots(dots => dots === 3 ? 0 : dots + 1)
        }, 500));
        return () => {
            if (dotsInterval) clearInterval(dotsInterval);
        };
    }, []);

    return (
        <Text as="span">{'.'.repeat(dots)}</Text>
    )
}

function isEqual(prevProps, nextProps) {

    // console.log("isEqual tweets");
    // console.log("isDiff " + (prevProps.tweets != nextProps.tweets));
    // console.log("isDiff " + (prevProps.savedTweet != nextProps.savedTweet));
    // console.log("isDiff " + (prevProps.refresh != nextProps.refresh));
    if (prevProps.tweets != nextProps.tweets
        && prevProps.nextSlot != nextProps.nextSlot
        && prevProps.drafts != nextProps.drafts
        && prevProps.scheduleds != nextProps.scheduleds
        && prevProps.sent != nextProps.sent
        && prevProps.screenWidth != nextProps.screenWidth
        || true
    ) {
        // console.log("scheduler not equal");
        return false;
    }
    return true;
}


function Scheduler({ }) {

    const firstField = React.useRef()
    // const [text, setText] = React.useState("");
    // const [tabIndex, setTabIndex] = React.useState(0);
    const [isPosting, setIsPosting] = React.useState(false);
    const [isLoadingTweets, setIsLoadingTweets] = React.useState(false);
    const [isLoadingAddToQueue, setIsLoadingAddToQueue] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [hasInit, setHasInit] = React.useState(false);

    const [isScheduled, setIsScheduled] = React.useState(false);
    const [selectedTweet, setSelectedTweet] = React.useState(undefined);
    const [drafts, setDrafts] = React.useState([]);
    const [scheduleds, setScheduleds] = React.useState([]);
    const [sent, setSent] = React.useState([]);
    const [hasMore, setHasMore] = React.useState({ draft: true, scheduled: true, sent: true });
    const [session, loading] = useSession();
    const isAuthenticated = useFirebaseUser();
    const router = useRouter();
    const mainContext = React.useContext(MainContext);

    const { isOpen: isOpenVariation, onOpen: onOpenVariation, onClose: onCloseVariation } = useDisclosure();
    const focusRefVariation = React.useRef() as React.MutableRefObject<HTMLButtonElement>;



    const { isOpen: isOpenEditSchedule, onOpen: onOpenEditSchedule, onClose: onCloseEditScheduleInternal } = useDisclosure();
    // const [schedule, setSchedule] = React.useState([]);
    const [addTime, setAddTime] = React.useState(new Date(2020, 0, 1, 18, 0, 0));

    const scrollParentRef = React.useRef();
    const cancelRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
    const [isOpenConfirm, setIsOpenConfirm] = React.useState(false);
    const onCloseConfirm = () => setIsOpenConfirm(false);

    const tweetContext: any = React.useContext(TweetContext);

    const [hasVideoSignature, setHasVideoSignature] = React.useState<boolean>(false);
    const [isVideoSignatureDisabled, setIsVideoSignatureDisabled] = React.useState<boolean>(false);
    const [signatureProps, setSignatureProps] = React.useState<{
        theme: string,
        avatar: boolean,
        name: boolean,
        handle: boolean,
        followers: boolean,
        bio: boolean,
        showFollowButton: boolean,
        videoBg?: string
    }>({
        theme: "dark",
        avatar: true,
        name: true,
        handle: true,
        followers: true,
        bio: true,
        showFollowButton: true,
        videoBg: "Sunflare"
    });
    const [signatureVideo, setSignatureVideo] = React.useState<{
        status: string,
        progress?: number,
        url?: string,
        fileName?: string
    }>({
        status: "idle",
        progress: 0,
        url: ""
    });

    const [autoPlugText, setAutoPlugText] = React.useState<string>("");
    const [autoPlugTrigger, setAutoPlugTrigger] = React.useState<number>(20);
    const [autoRetweetHours, setAutoRetweetHours] = React.useState<number>(12);
    const [isAutoRetweet, setIsAutoRetweet] = React.useState<boolean>(false);
    // const [isPreventLinkExpand, setIsPreventLinkExpand] = React.useState<boolean>(false);
    const { isPreventLinkExpand, setIsPreventLinkExpand } = tweetContext.advancedOptions;
    const [isAutoPlug, setIsAutoPlug] = React.useState<boolean>(false);
    // const [isPostTwitter, setIsPostTwitter] = React.useState<boolean>(true);
    const { isPostTwitter, setIsPostTwitter } = tweetContext.advancedOptions;
    const [isPostLinkedin, setIsPostLinkedin] = React.useState<boolean>(false);
    const [autoRetweetTimes, setAutoRetweetTimes] = useState<number>(1);
    const [isDelayBetweenTweets, setIsDelayBetweenTweets] = useState<boolean>(false);
    const [delayBetweenTweetsMinutes, setDelayBetweenTweetsMinutes] = useState<number>(0);
    const [isLiveTweet, setIsLiveTweet] = useState<boolean>(false);

    const [isGenerateBlog, setIsGenerateBlog] = useState<boolean>(false);
    const [blogTitle, setBlogTitle] = useState("");
    const [isPlugBlogAsReply, setIsPlugBlogAsReply] = useState<boolean>(false);

    const [isAutoDm, setIsAutoDm] = useState<boolean>(false);
    const [isAutoDmRandom, setIsAutoDmRandom] = useState<boolean>(false);
    const [isAutoDmLeadMagnet, setIsAutoDmLeadMagnet] = useState<boolean>(false);
    const [autoDmLeadMagnetUrl, setAutoDmLeadMagnetUrl] = useState<string>("");
    const [autoDmLeadMagnetListId, setAutoDmLeadMagnetListId] = useState<number>(-1);
    const [autoDmLeadMagnetConstactList, setAutoDmLeadMagnetConstactList] = useState<any>({});
    const [autoDmText, setAutoDmText] = useState<string>("");
    const [autoDmMax, setAutoDmMax] = useState<number>(500);
    const [autoDmTrigger, setAutoDmTrigger] = useState<any>({});
    const [isFirstUpdate, setIsFirstUpdate] = useState<boolean>(true);
    const [autoRtWithOtherAccounts, setAutoRtWithOtherAccounts] = useState<boolean>(false);
    const [isFromEditMode, setIsFromEditMode] = useState<boolean>(false);
    const { updateOnboardSteps } = useOnboardingSteps();
    const { isOpen: isOpenEdu, onOpen: onOpenEdu, onClose: onCloseEdu } = useDisclosure();
    const { updateStatsCounter } = useStats();

    const [isPushTaplio, setIsPushTaplio] = useState<boolean>(false);
    const [isPublishSameTime, setIsPublishSameTime] = useState("1");
    const [isPushTaplioDiverged, setIsPushTaplioDiverged] = useState<boolean>(false);
    const [pushTaplioText, setPushTaplioText] = useState("");

    const [isSafari, setIsSafari] = useState(false);
    const [device, setDevice] = useState('other');
    const [isSavingPostingNext, setIsSavingPostingNext] = useState<boolean>(false)
    const [isNotifyFanList, setIsNotifyFanList] = useState(false);
    const [fanListToNotify, setFanListToNotify] = useState(-1);
    const [selectedLabelIds, setSelectedLabelIds] = useState<string[]>([])

    const [selectedCreator, setSelectedCreator] = useState<any>(undefined);
    const [hasSetUrlPreviewType, setHasSetUrlPreviewType] = useState<boolean>(false)
    const colorGray = useColorModeValue("gray.500", "gray.100");
    const borderLight = useColorModeValue("#EFEFEF", "#2A292E");
    const colorLightGray = useColorModeValue(theme.colors.gray[100], theme.colors.gray[600])
    const colorLightGray200 = useColorModeValue("border.lightMode.light", "border.darkMode.light");
    // const colorLightGray200 = useColorModeValue("border.lightMode.light", "border.darkMode.light");
    const colorWhite = useColorModeValue("white", "#1E1E1E");
    const {
        isOpen: isOpenSchedulePopup,
        onClose: onCloseSchedulePopup,
        onToggle: onToggleSchedulePopup
    } = useDisclosure();

    let isSchedulerOpen = tweetContext.isOpen;
    let nextSlot = tweetContext.nextSlot;
    const [buttonGroupDate, setButtonGroupDate] = useState();
    useEffect(() => {
        setButtonGroupDate(dateFormat(nextSlot, 'mmm dS, yyyy, h:MM TT'));
    }, [nextSlot])
    // let isSafari: boolean = false;
    // if (typeof window !== "undefined") {
    //   const browser = Bowser.getParser(window.navigator.userAgent);
    //   if (browser?.getBrowserName()?.includes("Safari")) {
    //     isSafari = true;
    //   }
    // }
    useEffect(() => {
        const browser = Bowser.getParser(window.navigator.userAgent);
        if (browser?.getBrowserName()?.includes("Safari")) {
            setIsSafari(true);
        }
    }, []);

    useEffect(() => {
        //@ts-ignore
        setDevice(window?.navigator?.userAgentData?.platform ?? "other");
    }, []);

    useEffect(() => {
        getTweetsFromStatus("scheduled", setScheduleds)
    }, [selectedLabelIds])

    const videoSignatureTag = signatureVideo?.fileName ? `[img:vid-${signatureVideo.fileName}]` : '';
    const handleVideoSignatureAddition = () => {
        const currentTextState = tweetContext?.refComposer?.current?.textState();
        if (!currentTextState || !videoSignatureTag) return;

        // Check if the signature is already in the text
        if (currentTextState.text.includes(videoSignatureTag)) return;

        const updatedText = currentTextState.text + videoSignatureTag;
        const updatedFiles = [...currentTextState.files, signatureVideo.url];
        tweetContext.refComposer.current.setTextState({ ...tweetContext.refComposer.current.textState, text: updatedText, files: updatedFiles });
        tweetContext.setIsTweetTextChanged(true);
    }
    const handleVideoSignatureRemoval = () => {
        const currentTextState = tweetContext?.refComposer?.current?.textState();
        if (!currentTextState || !videoSignatureTag) return;

        const updatedText = currentTextState.text.replace(videoSignatureTag, "");
        const updatedFiles = currentTextState.files.filter(x => x !== signatureVideo.url);
        tweetContext.refComposer.current.setTextState({ ...tweetContext.refComposer.current.textState, text: updatedText, files: updatedFiles });
        tweetContext.setIsTweetTextChanged(true);
    }
    useEffect(() => {
        if (hasVideoSignature && !isVideoSignatureDisabled) {
            handleVideoSignatureAddition();
        } else {
            handleVideoSignatureRemoval();
        }
    }, [hasVideoSignature, tweetContext?.refComposer?.current]);

    useEffect(() => {
        const regex = new RegExp(/\[img:(.*?)\]/g);
        const matches = tweetContext.refComposer.current.textState().text.match(regex);
        if (matches) {
            const hasOnlyVideoSignature = matches.length === 1 && matches[0] === videoSignatureTag;
            const hasOtherMedia = matches.some(match => match !== videoSignatureTag);

            if (hasOnlyVideoSignature) {
                setIsVideoSignatureDisabled(false);
            } else if (hasOtherMedia && hasVideoSignature) {
                setHasVideoSignature(false);
                setIsVideoSignatureDisabled(true);
                handleVideoSignatureRemoval();
                toast.error("You can't add a video signature with other media");
            }
        } else {
            setIsVideoSignatureDisabled(false);
        }

    }, [tweetContext?.refComposer?.current]);



    const generateVideoSignature = async () => {
        setSignatureVideo({
            status: "rendering",
            progress: 0,
        });
        handleVideoSignatureRemoval();
        const { twUserName } = getAccount(session);
        if (!twUserName) {
            toast.error("Please connect your Twitter account first");
            return;
        }
        try {
            const signatureResponse = await fetch("/api/renderVideoSignature", {
                method: "post",
                body: JSON.stringify({ signatureProps, twUserName }),
                headers: {
                    "content-type": "application/json",
                },
            });
            const signatureData = await signatureResponse.json();
            if (signatureData.success === 0) {
                throw new Error(signatureData.message);
            }

            const { renderId, bucketName } = signatureData.data;
            let isRendering = true;

            let fakeProgress = 0;
            while (isRendering) {
                const progressResponse = await fetch("/api/renderVideoSignatureProgress", {
                    method: "post",
                    body: JSON.stringify({ id: renderId, bucketName, isCached: signatureData?.cached }),
                });
                const progressData = await progressResponse.json();

                if (progressData.success === 0) {
                    throw new Error(progressData.message);
                }

                const { type, url, progress, fileName } = progressData.data;
                switch (type) {
                    case "error":
                        setSignatureVideo({
                            status: "error",
                        });
                        isRendering = false;
                        break;

                    case "done":
                        setSignatureVideo({
                            url,
                            fileName,
                            status: "done",
                        });
                        await updateUser(session, { videoSignatureUrl: url, videoSignatureFileName: fileName });
                        handleVideoSignatureAddition();
                        isRendering = false;
                        break;

                    case "progress":
                        setSignatureVideo({
                            status: "rendering",
                            progress: Math.max(fakeProgress, progress * 100)
                        });
                        if (fakeProgress > 90) {
                            fakeProgress += 0.5;
                        } else if (fakeProgress > 50 && fakeProgress < 90) {
                            fakeProgress += 1;
                        } else if (fakeProgress > 97) {
                            fakeProgress += 0.1;
                        } else {
                            fakeProgress += 2;
                        }
                        await new Promise<void>((resolve) => {
                            setTimeout(() => {
                                resolve();
                            }, 1000);
                        });
                        break;
                }
            }
        } catch (error) {
            setSignatureVideo({
                status: "error",
            });
            toast.error("Error generating video signature");
        }
    };

    const getTweetsFromStatus = async (status, setter, getter = undefined as any, isLoadingMore = false) => {

        setHasMore({ draft: true, scheduled: true, sent: true });
        let loadBatch = 30;

        if (!isAuthenticated) {
            // toast.error("App not fully loaded yet, please retry later");
            // Sentry.captureException(new Error("getTweetsFromStatus - App not fully loaded yet, please retry later"));
            return;
        }

        setIsLoadingTweets(true);

        const db = firebaseClient.firestore();
        let docs;

        // if (status == "scheduled")
        //     docs = await db.collection("users").doc(getAccount(session).id).collection("tweets").where("status", "==", status).orderBy("scheduledTime", "asc").get();
        // else
        //     // removed the limit because if you're connected on a sub-account and your main account has a lot of tweets, the sub-accounts tweets will not be shown
        //     docs = await db.collection("users").doc(getAccount(session).id).collection("tweets").where("status", "==", status).orderBy("dateCreated", "desc").limit(loadBatch).get();
        //     // docs = await db.collection("users").doc(getAccount(session).id).collection("tweets").where("status", "==", status).orderBy("dateCreated", "desc").get();

        let tempDrafts: any = [];

        let hasDeletedDrafts = true;
        while (hasDeletedDrafts) {
            hasDeletedDrafts = false;
            tempDrafts = [];
            // to properly calculate the next slot
            if (status == "scheduled") {
                docs = await db.collection("users").doc(getAccount(session).id).collection("tweets").where("status", "==", status).where("idAccount", "==", getAccount(session).idAccount).orderBy("scheduledTime", "asc").get();
            }
            else if (isLoadingMore && getter?.length > 0) {
                tempDrafts = getter;
                docs = await db.collection("users").doc(getAccount(session).id).collection("tweets").where("status", "==", status).where("idAccount", "==", getAccount(session).idAccount).orderBy("dateCreated", "desc").startAfter(getter[getter?.length - 1].dateCreated).limit(loadBatch).get();
            }
            else {
                docs = await db.collection("users").doc(getAccount(session).id).collection("tweets").where("status", "==", status).where("idAccount", "==", getAccount(session).idAccount).orderBy("dateCreated", "desc").limit(loadBatch).get();
            }

            // console.log("nb results found: " + docs?.docs?.length);

            docs.forEach(doc => {
                let data = doc.data();

                // make sure the 
                if (isTweetFromAccount(data, session)) {
                    if (status == "draft" && data.text === "" && doc.id !== tweetContext.id) {
                        db.collection("users").doc(getAccount(session).id).collection("tweets").doc(doc.id).delete();
                        hasDeletedDrafts = true;
                    }
                    else if (status == "draft" && data.text === "") {
                    }
                    else {
                        tempDrafts.push(data);
                    }
                }
            })
        }

        // console.log("new list size: " + tempDrafts?.length);
        // console.log(tempDrafts);

        setter(tempDrafts);
        setIsLoadingTweets(false);

        // console.log("getTweetsFromStatus - forceScheduledTime: " + tweetContext.forceScheduledTime);
        if (status == "scheduled" && !tweetContext.forceScheduledTime) {
            let date = findNextSlot(session, tempDrafts, { labels: selectedLabelIds });

            // date will be null when there is no scheduled slot without a label on startup
            // so under those conditions, select a first label automatically and find a slot again
            // const labels = getAccount(session)?.tweetLabels;
            // if (!date && labels?.length > 0) {
            //     const labelId = labels[0].id;
            //     setSelectedLabelIds([labelId]);
            //     date = findNextSlot(session, tempDrafts, { labels: [labelId] });
            // }

            if (date) {
                tweetContext.setNextSlot(date);
            }
        }

        let hasMore = (docs?.docs?.length == loadBatch) && (status != "scheduled");

        return hasMore;
    }

    const refreshNextSlot = () => {
        getTweetsFromStatus("scheduled", setScheduleds);
    }

    const removeFromList = (list, item, setList) => {
        let index = list.indexOf(item);
        list.splice(index, 1);
        setList([...list]);
        // refresher({});
    }

    const prepareTaplioPost = (dataToSend) => {
        let textTaplio = pushTaplioText;
        if (!isPushTaplioDiverged) {
            textTaplio = tweetContext.refComposer.current.textState().text;
        }
        // console.log("textTaplio: " + textTaplio);

        var dataToSendTaplio = { ...dataToSend, text: textTaplio };

        return dataToSendTaplio;
    }

    const prepareTweet = (isScheduled = false, scheduledTime = undefined) => {

        let textState = tweetContext?.refComposer?.current?.textState();
        let text = textState ? textState.text : tweetContext.textState.text;

        // test if text is a valid url with a regex, the url can be http or https and have params
        const regexUrl = new RegExp(/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g);
        let isRtUrl = regexUrl.test(text.trim()) && (text.includes("twitter.com") || text.includes("x.com") || text.includes("tweethunter.io")) && !text.trim()?.includes(" ");
        // console.log("isRtUrl: " + isRtUrl);

        let overrideIsAutoPlug = isAutoPlug && text && !isRtUrl;
        // console.log("text: " + tweetContext?.refComposer?.current?.textState()?.text);
        // console.log("autoPlugText: " + autoPlugText);

        let dataToSend: any = {
            idUser: getAccount(session)?.id,
            idAccount: getAccount(session)?.idAccount,
            twUserName: getAccount(session)?.twUserName,
            profilePic: getAccount(session)?.image,
            name: getAccount(session)?.name,
            firstId: "",
            previousId: "",
            id_str: "",
            statusGhost: "done",
            countTweetsPublished: 0,
            text: text,
            id: tweetContext.id,
            hasVideoSignature: hasVideoSignature,
            isVideoSignatureDisabled: isVideoSignatureDisabled,
            signatureProps: signatureProps,
            signatureVideo: signatureVideo,
            isAutoPlug: overrideIsAutoPlug,
            autoPlugTrigger: autoPlugTrigger,
            isAutoRetweet: isAutoRetweet,
            isPreventLinkExpand: isPreventLinkExpand,
            isPreventLinkExpandAutoPlug: isPreventLinkExpand,
            autoRetweetHours: autoRetweetHours,
            autoRetweetTimes: autoRetweetTimes,
            autoPlugText: autoPlugText,
            isRetweeted: false,
            isPlugged: false,
            isPostTwitter: isPostTwitter,
            isPostLinkedin: isPostLinkedin,
            isDelayBetweenTweets: isDelayBetweenTweets && text?.includes("\n\n\n"),
            delayBetweenTweetsMinutes: delayBetweenTweetsMinutes,
            autoRtWithOtherAccounts: autoRtWithOtherAccounts,
            isPushTaplio,
            // isNotifyFanList: isNotifyFanList && text?.includes("\n\n\n"),
            labels: selectedLabelIds,
            labelsName: selectedLabelIds?.map(x => getAccount(session)?.tweetLabels?.find(y => y.id == x)?.title ?? "")?.filter(x => !!x) ?? [],
            // fanListToNotify,
        }

        if (isScheduled) {
            dataToSend.isScheduled = isScheduled;
        }
        if (scheduledTime) dataToSend.scheduledTime = new Date(scheduledTime);

        if (isGenerateBlog) {
            dataToSend.isGenerateBlog = isGenerateBlog;
            dataToSend.blogTitle = blogTitle;
            dataToSend.isPlugBlogAsReply = isPlugBlogAsReply;
            dataToSend.isPlugBlogAsReplyPlugged = false;
            dataToSend.isNotifyFanList = isGenerateBlog;
        }

        dataToSend.isAutoDm = isAutoDm;
        if (isAutoDm) {
            dataToSend.autoDmStatus = "running";
            dataToSend.receivers = [];
            dataToSend.autoDmTrigger = autoDmTrigger;
            dataToSend.autoDmText = autoDmText;
            dataToSend.autoDmMax = autoDmMax;
            dataToSend.isAutoDmRandom = isAutoDmRandom;
            dataToSend.isAutoDmLeadMagnet = isAutoDmLeadMagnet;
            dataToSend.autoDmLeadMagnetUrl = autoDmLeadMagnetUrl;
            dataToSend.autoDmLeadMagnetListId = autoDmLeadMagnetListId;
        }

        dataToSend.isLiveTweet = isLiveTweet;
        if (isLiveTweet) {
            dataToSend.liveTweetStatus = "running";
        }

        // console.log("isAutoDm: " + isAutoDm);

        return dataToSend;
    }

    React.useEffect(() => {

        if (isAuthenticated && !hasInit) {
            setHasInit(true);
            let isTweetInit = false;

            if (tweetContext.isIncognitoMode) {
                let currentTweetContent = localStorage.getItem("currentTweetContent");
                if (currentTweetContent) {
                    console.log("found tweet:" + currentTweetContent);
                    tweetContext.newTweet({ text: currentTweetContent });
                    tweetContext.setIsTweetTextChanged(true);
                    isTweetInit = true;
                }
            }

            if (!isTweetInit)
                tweetContext.newTweet({ text: "" });

            // getTweetsFromStatus("draft", setDrafts);
            // getTweetsFromStatus("scheduled", setScheduleds); // needed for the slot
            // getTweetsFromStatus("sent", setSent);
            let index = tweetContext.tabIndex;
            if (index == 1) {
                getTweetsFromStatus("draft", setDrafts);
            }
            else if (index == 2) {
                getTweetsFromStatus("scheduled", setScheduleds);
            }
            else if (index == 3) {
                getTweetsFromStatus("sent", setSent);
            }
            else {
                getTweetsFromStatus("scheduled", setDrafts);
            }

            tweetContext.setSchedule(getAccount(session).schedule);

            if (session?.user?.data?.subscription?.isSubscribed && !getAccount(session)?.schedule || !getAccount(session)?.engagementDistribution) {
                buildSchedule(session);
            }

            setIsAutoRetweet(getAccount(session).isAutoRetweet == true);
            // setIsAutoRetweet(false);
            setHasVideoSignature(getAccount(session).hasVideoSignature == true);
            setSignatureProps((current) => getAccount(session).signatureProps ? getAccount(session).signatureProps : current);
            setSignatureVideo((current) => ({ ...current, url: getAccount(session).videoSignatureUrl || current.url, fileName: getAccount(session).videoSignatureFileName || current.fileName }));
            setIsAutoPlug(getAccount(session).isAutoPlug == true);
            setIsPushTaplio(getAccount(session).isPushTaplio == true && getAccount(session).keyTaplio);
            setIsPublishSameTime(getAccount(session).isPublishSameTime);
            setFanListToNotify(getAccount(session).fanListToNotify ?? -1);
            setAutoPlugTrigger(getAccount(session).autoPlugTrigger ? getAccount(session).autoPlugTrigger : 20);
            setAutoDmText(getAccount(session).autoDmText ? getAccount(session).autoDmText : "");
            setAutoDmMax(getAccount(session).autoDmMax ? getAccount(session).autoDmMax : 500);
            setAutoDmTrigger(getAccount(session).autoDmTrigger ? getAccount(session).autoDmTrigger : { retweet: false, reply: false, like: false })
            const isPreventLinkValue = getAccount(session).preventLinkExpand ? getAccount(session).preventLinkExpand : false;
            setIsPreventLinkExpand(isPreventLinkValue);
            // setIsPreventLinkExpand(false);
            setAutoRetweetHours(getAccount(session).autoRetweetHours ? getAccount(session).autoRetweetHours : 6);
            setAutoRetweetTimes(getAccount(session).autoRetweetTimes ? getAccount(session).autoRetweetTimes : 1);
            setAutoRtWithOtherAccounts(getAccount(session).autoRtWithOtherAccounts == true);
            setDelayBetweenTweetsMinutes(getAccount(session).delayBetweenTweetsMinutes ? getAccount(session).delayBetweenTweetsMinutes : 0);
            setIsDelayBetweenTweets(getAccount(session).isDelayBetweenTweets ? getAccount(session).isDelayBetweenTweets : false);
            setIsPostTwitter(getAccount(session).isPostTwitter ? getAccount(session).isPostTwitter : true);
            setIsPostLinkedin(getAccount(session).isPostLinkedin ?
                getAccount(session).isPostLinkedin :
                (session?.user?.data?.accounts.filter(x => x.provider == "linkedin")?.length > 0 ? true : false));
        }
        else if (session?.user?.uid) {
            refreshNextSlot();
        }

        // onOpenEditSchedule();
    }, [session, isAuthenticated, tweetContext.refresh]);

    useEffect(() => {
        setPushTaplioText(tweetContext.textState?.text ?? "");
        // onOpenEditSchedule();
    }, [tweetContext.refresh]);

    useEffect(() => {
        if (isAuthenticated && tweetContext.textState?.text) {
            setIsFromEditMode(true);
            if (tweetContext.textState?.autoPlugText && tweetContext.textState?.isAutoPlug) {
                setIsAutoPlug(true);
                setAutoPlugText(tweetContext.textState?.autoPlugText);
                setAutoPlugTrigger(tweetContext.textState?.autoPlugTrigger);
            }
            if (tweetContext.textState?.isPreventLinkExpand) {
                setIsPreventLinkExpand(true);
                // setIsPreventLinkExpand(false);
            }
            if (tweetContext.textState?.autoRtWithOtherAccounts) {
                setAutoRtWithOtherAccounts(true)
            }
            if (tweetContext.textState?.hasVideoSignature) {
                setHasVideoSignature(true);
            }
            if (tweetContext.textState?.isVideoSignatureDisabled) {
                setIsVideoSignatureDisabled(true);
            }
            if (tweetContext.textState?.signatureProps) {
                setSignatureProps(tweetContext.textState?.signatureProps);
            }
            if (tweetContext.textState?.signatureVideo) {
                setSignatureVideo(tweetContext.textState?.signatureVideo);
            }
            if (tweetContext.textState?.isAutoRetweet) {
                setIsAutoRetweet(true);
                //   setIsAutoRetweet(false);
                setAutoRetweetHours(tweetContext.textState?.autoRetweetHours);
                setAutoRetweetTimes(tweetContext.textState?.autoRetweetTimes);
            }
            if (tweetContext.textState?.isAutoDm) {
                setIsAutoDm(true);
                setAutoDmTrigger({
                    like: tweetContext.textState?.autoDmTrigger?.like,
                    reply: tweetContext.textState?.autoDmTrigger?.reply,
                    retweet: tweetContext.textState?.autoDmTrigger?.retweet
                });
                setAutoDmText(tweetContext.textState?.autoDmText);
                setIsAutoDmRandom(tweetContext.textState?.isAutoDmRandom || false);
                setIsAutoDmLeadMagnet(tweetContext.textState?.isAutoDmLeadMagnet || false);
                setAutoDmLeadMagnetUrl(tweetContext.textState?.autoDmLeadMagnetUrl || "");
                setAutoDmLeadMagnetListId(tweetContext.textState?.autoDmLeadMagnetListId || -1);
                setAutoDmLeadMagnetConstactList(tweetContext.textState?.autoDmLeadMagnetListId ? getAccount(session)?.contactLists?.find((c) => c.id === tweetContext.textState?.autoDmLeadMagnetListId) : {});
                setAutoDmMax(tweetContext.textState?.autoDmMax);
                if (session) {
                    getAccount(session).autoDmText = tweetContext.textState?.autoDmText;
                }
            }
            if (tweetContext.textState?.isPushTaplio && getAccount(session).keyTaplio)
                setIsPushTaplio(true);
            if (tweetContext.textState?.isPublishSameTime)
                setIsPublishSameTime(tweetContext.textState?.isPublishSameTime);
            // if (tweetContext.textState?.isNotifyFanList) {
            //     setIsNotifyFanList(true);
            //     setFanListToNotify(tweetContext.textState?.fanListToNotify ?? -1);
            // }
            if (tweetContext.textState?.isLiveTweet) {
                setIsLiveTweet(true);
            }
            // if (tweetContext.textState?.isGenerateBlog) {
            //     setIsGenerateBlog(true);
            //     setBlogTitle(tweetContext.textState?.blogTitle);
            // }
            if (tweetContext.textState?.isDelayBetweenTweets) {
                setIsDelayBetweenTweets(true);
                setDelayBetweenTweetsMinutes(tweetContext.textState?.delayBetweenTweetsMinutes);
            }
            setSelectedLabelIds(tweetContext.textState?.labels ?? []);
        }
    }, [tweetContext.refresh])

    useEffect(() => {
        if (isFromEditMode) {
            setIsFromEditMode(false);
        }
    }, [isFromEditMode])



    const buildAdvancedOptionString = () => {
        let s = "";

        (isAutoRetweet || isAutoPlug ? "enabled: " + (isAutoRetweet ? "auto-retweet" : "") + (isAutoPlug ? (isAutoRetweet ? ", " : "") + "auto-plug" : "") : "")

        if (!hasVideoSignature && !isAutoRetweet && !isAutoPlug && !isAutoDm && !isDelayBetweenTweets && !autoRtWithOtherAccounts)
            return s;

        s += "enabled: ";

        if (hasVideoSignature)
            s += "video-signature, ";

        if (isAutoRetweet)
            s += "auto-retweet, ";

        if (isAutoPlug)
            s += "auto-plug, ";

        if (isAutoDm)
            s += "auto-dm, "

        if (isDelayBetweenTweets)
            s += "tweet delay, ";

        if (autoRtWithOtherAccounts) {
            s += "auto-rt other accounts, "
        }

        s = s.substring(0, s?.length - 2);

        return s;
    }

    const preparePushTweet = async () => {

        //@ts-ignore
        if (!tweetContext.refComposer.current.textState().text) {
            toast.error("You need to write something", { style: { background: "gray.600", color: "#222" } });
            return;
        }

        let dataToSend = {
            id: tweetContext.id,
            idUser: getAccount(session)?.id,
            idGhost: session?.user?.uid,
            text: tweetContext.refComposer.current.textState().text,
        }
        await pushTweet(setIsPosting, dataToSend);
        //@ts-ignore
        tweetContext.newTweet({ text: "" });
        resetAutomation();
    }

    const addToQueue = async (conf = {} as any) => {

        setIsLoadingAddToQueue(true);

        //@ts-ignore
        if (!tweetContext?.refComposer?.current?.textState || !tweetContext?.refComposer?.current?.textState()?.text) {
            toast.error("You need to write something");
            return;
        }

        let textState = tweetContext.refComposer.current.textState();

        console.log("go prepareTweet");
        let dataToSend: any = prepareTweet(true, nextSlot);

        if (!(await checkTweetValid(textState?.threadTweets, session, tweetContext, dataToSend, mainContext))) {
            setIsLoadingAddToQueue(false);
            return;
        }
        else if (isPushTaplio) {
            var dataToSendTaplio = prepareTaplioPost(dataToSend);
            if (!(await checkTweetValid([], session, tweetContext, dataToSendTaplio, mainContext, { disableCheckTwitter: true, disableCheckImage: true, checkLinkedin: true }))) {
                setIsLoadingAddToQueue(false);
                return;
            }
        }

        console.log("checked");

        if (conf?.isShareNext) {
            await shareNext(session, dataToSend, tweetContext);
        }

        //@ts-ignore
        analytics.log("hit_tweet_addInQueue", { ...textState });
        updateOnboardSteps("schedule", "evergreen");

        tweetContext.newTweet({ text: "" });
        tweetContext.setIsTweetTextChanged(true);
        resetAutomation();
        console.log("scheduled reset");

        await scheduleTweet(session, undefined, setIsOpenConfirm, dataToSend, getAccount(session)?.timezone, tweetContext, false);
        console.log("tweet scheduled");

        tweetContext.refresher({});
        setSelectedLabelIds([]);
        updateStatsCounter("add_to_queue");
        validateTokenTask();

        if (isPushTaplio) {
            if (isPublishSameTime == "2")
                pushTaplio(session, dataToSendTaplio, true, nextSlot);
            else
                pushTaplio(session, dataToSendTaplio, true);
        }

        setIsLoadingAddToQueue(false);
        // tweetContext.newTweet({ text: "" });
        // tweetContext.setIsTweetTextChanged(true);
        // resetAutomation();

    }

    const validateTokenTask = async () => {
        if (isPreventLinkExpand || isAutoRetweet || isAutoPlug || isAutoDm || isDelayBetweenTweets) {
            await completeTokenTask("advanced setting", session);
        }
    }

    const resetAutomation = () => {
        setIsAutoDm(false);
        setIsAutoDmRandom(false);
        setIsAutoDmLeadMagnet(false);
        setIsLiveTweet(false);
        setBlogTitle("");
        setIsDelayBetweenTweets(false);
        setIsPushTaplioDiverged(false);
        // setIsNotifyFanList(false);
        setPushTaplioText("");
        setHasVideoSignature(false);
        setIsVideoSignatureDisabled(false);
        setSignatureProps({
            theme: "dark",
            avatar: true,
            name: true,
            handle: true,
            followers: true,
            bio: true,
            showFollowButton: true,
            videoBg: "Sunflare"
        });
        setSignatureVideo({
            status: "idle",
            progress: 0,
            url: ""
        });
    }

    const handleDmTriggerChange = async (key: string, value: boolean) => {
        const updatedTrigger = { ...autoDmTrigger, [key]: value }
        setAutoDmTrigger(updatedTrigger);
        const db = firebaseClient.firestore();
        await db.collection("users").doc(getAccount(session).id).update({
            autoDmTrigger: updatedTrigger
        });
    }

    const displayTimeDiff = () => {
        // console.log("scheduledTime: " + tweetContext.textState.scheduledTime);
        // console.log("now: " + new Date());

        // convert it twice
        let convertedTime = convertToAccountTimezoneReversed(tweetContext.textState?.scheduledTime ?? new Date(), getAccount(session)?.timezone).date;
        // convertedTime = convertToAccountTimezoneReversed(convertedTime, getAccount(session)?.timezone).date;
        // console.log("convertedTime: " + convertedTime);

        let s =
            // dateFormat(scheduledTime, 'mmmm dS, yyyy, h:MM TT')}</boolean>
            convertedTime < new Date() ? " now " : " in " +
                // false ? " now " : " in " +
                // formatDistance(convertToAccountTimezone(tweetContext.textState.scheduledTime, getAccount(session)?.timezone).date, new Date(), { addSuffix: false })}</b>
                formatDistance(convertedTime, new Date(), { addSuffix: false });
        // formatDistance(scheduledTime, convertToAccountTimezone(new Date(), getAccount(session)?.timezone), { addSuffix: false });

        // console.log("timeDiff string: " + s);

        return s;
    }

    const handleSchedule = async () => {
        //@ts-ignore
        if (!tweetContext.refComposer.current.textState().text) {
            toast.error("You need to write something", { style: { background: "gray.600", color: "#222" } });
            return;
        }

        setIsScheduled(true);
        setIsOpenConfirm(true);

        tweetContext.setTextState({
            //@ts-ignore
            ...tweetContext.refComposer.current.textState(),
            scheduledTime: tweetContext.textState?.scheduledTime ? tweetContext.textState.scheduledTime : (tweetContext.nextSlot ?? new Date()),
        });
        //@ts-ignore
        analytics.log("hit_tweet_schedule", { ...tweetContext.refComposer.current.textState() });
    }

    const handleLabelChange = async (labels: string[], callback) => {
        setSelectedLabelIds(labels);
        callback && callback();
    }

    useEffect(() => {
        saveTweet(session, tweetContext.id, { labels: selectedLabelIds }, tweetContext.createDraft, undefined);
    }, [selectedLabelIds])

    const renderPushToCreator = () => {

        let textState = tweetContext?.refComposer?.current?.textState() ?? tweetContext?.textState;
        // console.log("statusGhost: " + textState?.statusGhost);
        // console.log('textState:', textState);

        // if (!tweetContext?.refComposer?.current || tweetContext?.refComposer?.current?.textState()?.statusGhost == "rejected")
        if (["rejected", "pending"].includes(textState?.statusGhost))
            return (
                <Flex mt={0} mb={4} justifyContent="flex-end">
                    <Button variant={"secondary"}
                        isLoading={isLoadingAddToQueue}
                        // isDisabled={!selectedCreator?.id}
                        onClick={async () => {
                            setIsLoadingAddToQueue(true);
                            await pushToCreator(session, isAuthenticated, tweetContext, textState?.idUser, textState?.id);
                            tweetContext.refresher({});
                            setIsLoadingAddToQueue(false);
                        }}
                    >
                        Push Revision
                    </Button>
                </Flex>
            )
        else
            return (
                <Flex mt={0} mb={4} justifyContent="flex-end">
                    <Menu isLazy
                    // offset={[0, -263]}
                    >
                        <MenuButton
                            as={Button}
                            mr={3}
                            variant={"secondary"}
                            rightIcon={<ChevronDownIcon />}
                        >
                            <Flex alignItems="center">
                                {selectedCreator?.twUserName && <Image src={selectedCreator.image ?? "/assets/resources/emptyProfile.png"} w="6" h="6" rounded="full" />}
                                <Text noOfLines={1} wordBreak="break-all" w="120px" ml={selectedCreator?.twUserName ? 2 : 0}>{selectedCreator?.twUserName ? "@" + selectedCreator?.twUserName : "pick a client"}</Text>
                            </Flex>
                        </MenuButton>
                        <MenuList maxH="215px" overflow="scroll" zIndex={999999999}>
                            {session?.user?.data?.linkAccounts?.map((creator, index) => (
                                <MenuItem
                                    as={Box}
                                    key={"creator-" + index}
                                    onClick={() => {
                                        setSelectedCreator(creator);
                                    }}
                                >
                                    <CreatorListItem creator={creator} index={index} />
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                    <Button variant={"secondary"}
                        isLoading={isLoadingAddToQueue}
                        isDisabled={!selectedCreator?.id}
                        onClick={async () => {
                            setIsLoadingAddToQueue(true);
                            await pushToCreator(session, isAuthenticated, tweetContext, selectedCreator.id);
                            setIsLoadingAddToQueue(false);
                        }}
                    >
                        Push to client
                    </Button>
                </Flex>
            )
    }

    const renderTweetNowAddToQueue = () => {
        return (
            <Box>
                <Flex display="flex" justifyContent="space-between">
                    <Tooltip shouldWrapChildren placement='top' label={isDelayBetweenTweets && delayBetweenTweetsMinutes < 1 ? "You can't tweet now with this thread delay" : "A confirmation popup will open"}>
                        <Button variant={"secondary"}
                            mr={3}
                            isDisabled={isDelayBetweenTweets && delayBetweenTweetsMinutes < 1}
                            onClick={async () => {

                                //@ts-ignore
                                if (!tweetContext.refComposer.current.textState().text) {
                                    toast.error("You need to write something", { style: { background: "gray.600", color: "#222" } });
                                    return;
                                }

                                setIsScheduled(false);
                                setIsOpenConfirm(true);
                                //@ts-ignore
                                tweetContext.setTextState(tweetContext.refComposer.current.textState());
                                //@ts-ignore
                                analytics.log("hit_tweet_now", { ...tweetContext.refComposer.current.textState() });
                            }}
                        >
                            Tweet now
                        </Button>
                    </Tooltip>
                    <Box display="flex" justifyContent={'flex-end'}>
                        <Box>
                            <ButtonGroup variant="light" size='md' isAttached >
                                <Tooltip hasArrow label={device?.includes("mac") ? "Cmd-Enter" : "Ctrl-Enter"}>
                                    <Button
                                        // bg="gray.600"
                                        variant={"primaryLeft"}
                                        // color="white"
                                        mr='-px'
                                        // borderRight="solid 2px white"
                                        // mr={0}
                                        display="flex"
                                        flexDirection="column"
                                        py={1}
                                        isLoading={isLoadingAddToQueue}
                                        onClick={async () => {
                                            if (isLoadingAddToQueue) {
                                                return
                                            }

                                            await addToQueue();
                                        }}
                                    >
                                        <Text mb={-2} >
                                            Add to Queue
                                        </Text>
                                        <Text
                                            fontSize="9px"
                                            mt={0.5}
                                        >
                                            {buttonGroupDate}
                                        </Text>
                                    </Button>
                                </Tooltip>
                                <Popover isOpen={isOpenSchedulePopup} onClose={onCloseSchedulePopup}
                                // variant="secondary"
                                >
                                    <PopoverTrigger>
                                        <Button
                                            variant={"primaryRight"}
                                            //   bg="gray.600"
                                            ml="2px"
                                            //   color="white"
                                            aria-label='More scheduling options'
                                            as={IconButton}
                                            icon={<ChevronDownIcon />}
                                            onClick={onToggleSchedulePopup}
                                        />
                                    </PopoverTrigger>
                                    <PopoverContent
                                        maxWidth="250px"
                                        // _focus={{ boxShadow: "inherit" }}
                                        _focusVisible={{ outline: "none" }}
                                        {...layerStyles.bgBorderRounded}
                                        shadow="lg"
                                    >
                                        <PopoverBody px="0" py="1"
                                        // border="none"
                                        // layerStyle={layerStyle['bg.border.rounded']}
                                        >
                                            <VStack align="stretch" spacing="0">
                                                <Button
                                                    variant="action"
                                                    leftIcon={
                                                        // <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        //     <path d="M4.75 2H9.25V0.75C9.25 0.34375 9.5625 0 10 0C10.4062 0 10.75 0.34375 10.75 0.75V2H12C13.0938 2 14 2.90625 14 4V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V4C0 2.90625 0.875 2 2 2H3.25V0.75C3.25 0.34375 3.5625 0 4 0C4.40625 0 4.75 0.34375 4.75 0.75V2ZM1.5 14C1.5 14.2812 1.71875 14.5 2 14.5H12C12.25 14.5 12.5 14.2812 12.5 14V6H1.5V14Z" fill="currentColor"/>
                                                        // </svg>

                                                        <Icon as={BiTimeFive} fontSize="lg" mr="0" />
                                                    }
                                                    justifyContent="flex-start"
                                                    borderRadius="none"
                                                    fontWeight="md"
                                                    _focus={{ boxShadow: "none" }}
                                                    onClick={handleSchedule}
                                                >
                                                    Schedule
                                                </Button>
                                                <Button
                                                    variant="action"
                                                    leftIcon={<Icon as={MdOutlineNextPlan} fontSize="lg" mr="0" />}
                                                    justifyContent="flex-start"
                                                    borderRadius="none"
                                                    fontWeight="md"
                                                    _focus={{ boxShadow: "none" }}
                                                    isLoading={isSavingPostingNext}
                                                    onClick={async () => {
                                                        setIsSavingPostingNext(true)
                                                        await addToQueue({ isShareNext: true });
                                                        setIsSavingPostingNext(false)
                                                    }}
                                                >
                                                    Post next
                                                </Button>
                                                <Divider my="2" />
                                                <Box p="2" px="3">
                                                    <Text textStyle={textStyle['small.medium.light']} fontSize="xs">Assign one or more labels to your tweet</Text>
                                                    <TweetLabels
                                                        size="xs"
                                                        mainContainerProps={{
                                                            justify: "start",
                                                            mt: "3"
                                                        }}
                                                        selectedLabelIds={selectedLabelIds}
                                                        onChange={handleLabelChange}
                                                        onEnter={onCloseSchedulePopup}
                                                    />
                                                </Box>
                                            </VStack>
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            </ButtonGroup>
                            <Box display="flex" justifyContent="flex-end" mt={0} m={0}>
                                <Button
                                    p={0}
                                    m={0}
                                    variant="tertiaryNoBackground"
                                    // color={colorGray}
                                    onClick={tweetContext.onOpenEditSchedule}
                                >
                                    Edit queue
                                </Button>
                            </Box>
                        </Box>

                    </Box>
                </Flex>
                {
                    selectedLabelIds?.length > 0 && (
                        <Box mb="6">
                            <TweetLabels
                                size="xs"
                                mainContainerProps={{
                                    justify: "start",
                                }}
                                showAddLabelButton={false}
                                showOnlySelectedLabels={true}
                                selectedLabelIds={selectedLabelIds}
                                onChange={handleLabelChange}
                            />
                        </Box>
                    )
                }
            </Box>
        )
    }

    // switching off "Remove URL preview" setting cause twitter V2 api doesn't support it anymore (checkbox hidden from UI)
    useEffect(() => {
        if (session && isAuthenticated && !hasSetUrlPreviewType) {
            setHasSetUrlPreviewType(true)

            if (getAccount(session)?.preventLinkExpand || getAccount(session)?.preventLinkExpandAutoPlug) {
                setIsPreventLinkExpand(false)
                updateUser(session, { preventLinkExpand: false, preventLinkExpandAutoPlug: false })
            }
        }
    }, [session, isAuthenticated])

    const renderAdvancedOptions = () => {
        // return (<></>);
        return (
            <Box pb={6} >
                <FormControl mt={3}>
                    <Box display="flex" alignItems="center" gap="2" mx="2" h="10" >
                        <Tooltip
                            hasArrow
                            label={
                                <>
                                    <Text>A looping video containing your profile information will be added to your post. </Text>
                                    <Text>Posts with videos are better for X’s (Twitter’s) algorithm and will encourage others to follow you.</Text>
                                    <Text>The video format is optimized for X (Twitter).</Text>
                                </>
                            }
                        // label="Due tu recent changes in the Twitter API, we disabled this feature temporarily."
                        >
                            <QuestionIcon color="gray.400" />
                        </Tooltip>
                        <Text textStyle={textStyle['body.bold.standard']}  >Video Signature</Text>
                        <Box flex="1" />
                        <Switch
                            isChecked={!isVideoSignatureDisabled && hasVideoSignature}
                            onChange={async () => {
                                setHasVideoSignature((current) => !current);
                                await updateUser(session, { hasVideoSignature: !hasVideoSignature });

                            }}
                            disabled={isVideoSignatureDisabled}
                        />
                    </Box>
                    {
                        hasVideoSignature && (
                            <Box mb="4" ml="8">
                                <Text textStyle="small.medium.light" mb="6">Add a looping video signature to your post to encourage new follows and favor the algorithm.</Text>
                                <Flex mb="6" align="center" justify="space-between">
                                    <Box>
                                        <Text textStyle={useColorModeValue("body.medium.primary", "body.medium.standard")} mb="3">Video Background</Text>
                                        <Menu isLazy >
                                            <MenuButton as={Button} size="sm" variant={"secondary"} rightIcon={<ChevronDownIcon />} textTransform="capitalize" isDisabled={signatureVideo?.status === "rendering"}>
                                                {signatureProps.videoBg ? signatureProps.videoBg.replace("-", " ") : "Select a video background"}
                                            </MenuButton>
                                            <MenuList maxH="215px" overflow="scroll"
                                            >
                                                {["Sunflare",
                                                    "Blue Smoke",
                                                    "White Piano",
                                                    "Glare",
                                                    "City Night",
                                                    "Jelly",
                                                    "White Smoke",
                                                    "Technology",
                                                    "Dark Earth",
                                                    "Coding",
                                                    "Crypto",
                                                    "Blue Sky",
                                                    "Dark Water",
                                                    "Beach",
                                                    "Forest"
                                                ].map(video => (
                                                    <MenuItem
                                                        key={video}
                                                        onClick={async (e) => {
                                                            setSignatureProps((current) => ({ ...current, videoBg: video.toLocaleLowerCase().replace(" ", "-") }));
                                                            await updateUser(session, { signatureProps: { ...signatureProps, videoBg: video.toLocaleLowerCase().replace(" ", "-") } });
                                                        }}
                                                    >
                                                        {video}
                                                    </MenuItem>
                                                ))
                                                }
                                            </MenuList>
                                        </Menu>
                                    </Box>
                                    <Box width={16} height={16} overflow="hidden" borderRadius="lg" border={"2px solid"}
                                        borderColor={useColorModeValue("border.lightMode.light", "border.darkMode.light")} bgColor="#0E0D16">
                                        <Box as="video" key={signatureProps.videoBg} width="100%" height="100%" objectFit="cover" autoPlay loop opacity={0.4}>
                                            <source src={`https://remotionlambda-useast1-ktyb94jea4.s3.amazonaws.com/assets/${signatureProps.videoBg?.toLocaleLowerCase().replace(" ", "-")}.mp4`} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </Box>
                                    </Box>
                                </Flex>
                                <Box >
                                    <Flex justify="space-between" align="center" mb="3">
                                        <Text textStyle={useColorModeValue("body.medium.primary", "body.medium.standard")} >Video Assets</Text>
                                        {/* <Flex gap={2} align="center">
                                            <Icon as={SunIcon} color="gray.400" />
                                            <Switch
                                                isChecked={signatureProps.theme == "dark"}
                                                onChange={async (e) => {
                                                    console.log("e.target.checked: " + e.target.checked);
                                                    const selectedTheme = e.target.checked ? "dark" : "light";
                                                    setSignatureProps((current) => ({ ...current, theme: selectedTheme }));
                                                    await updateUser(session, { signatureProps: { ...signatureProps, theme: selectedTheme } });

                                                }}
                                                colorScheme='gray'
                                            />
                                            <Icon as={MoonIcon} color="gray.400" />
                                        </Flex> */}

                                    </Flex>
                                    <Text textStyle="small.medium.light" mb="4">Select which assets to include and choose between dark and light mode.</Text>
                                    {[
                                        { lable: "Profile picture", value: "avatar" },
                                        { lable: "Handle", value: "handle" },
                                        { lable: "Display Name", value: "name" },
                                        { lable: "Number of Followers", value: "followers" },
                                        { lable: "Bio", value: "bio" },
                                        { lable: "“Follow for more” CTA", value: "showFollowButton" },
                                    ].map(({ lable, value }) => <Checkbox
                                        key={value}
                                        isChecked={signatureProps[value]}
                                        onChange={async (e) => {
                                            setSignatureProps((current) => ({ ...current, [value]: e.target.checked }));
                                            await updateUser(session, { signatureProps: { ...signatureProps, [value]: e.target.checked } });
                                        }}
                                        display="flex"
                                        mb="3"
                                        disabled={signatureVideo?.status === "rendering"}
                                    >
                                        <Box textStyle="body.bold.light" >
                                            {lable}
                                        </Box>
                                    </Checkbox>)}
                                </Box>
                                <Box>
                                    {signatureVideo?.status === "rendering" && signatureVideo.progress !== undefined && signatureVideo.progress >= 0 ?
                                        <Box mb={4}>
                                            <Text textStyle={useColorModeValue("body.medium.primary", "body.medium.standard")} mb="3" textAlign="center" fontWeight="bold">Generating Video <DotsLoader /></Text>
                                            <Progress size='xs' hasStripe isAnimated value={signatureVideo.progress} />
                                            <Text textStyle="small.medium.light" mt="3" textAlign="center">This might take up to 3 minutes</Text>
                                        </Box>
                                        : <Button mb="4" w="full" onClick={generateVideoSignature}>Generate</Button>}
                                    {signatureVideo?.url && <Box borderRadius="lg" overflow="hidden"><video width="320" height="320" autoPlay loop>
                                        <source src={signatureVideo.url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video></Box>}

                                </Box>
                            </Box>
                        )
                    }
                </FormControl>
                <Divider />
                {/* <Box mb={0} display="flex" alignItems="center" gap="2" mx="2" h="10" >
                    <Tooltip hasArrow label="When enabled, links in tweets and auto-plug won’t show the URL preview"> */}
                {/* <Tooltip hasArrow label="This feature has been disabled due to the recent Twitter API changes"> */}
                {/* <QuestionIcon color="gray.400" mt={0} mr={2} />
                    </Tooltip>
                    <Checkbox ml={-1}
                        isChecked={isPreventLinkExpand}
                        // isDisabled={true}
                        onChange={async (e) => {
                            setIsPreventLinkExpand(e.target.checked);
                            // const db = firebaseClient.firestore();
                            // await db.collection("users").doc(getAccount(session).id).update({
                            //     preventLinkExpand: e.target.checked,
                            //     preventLinkExpandAutoPlug: e.target.checked,
                            // });
                            await updateUser(session, { preventLinkExpand: e.target.checked, preventLinkExpandAutoPlug: e.target.checked });
                        }}
                    >
                        <Box textStyle="body.bold.standard" >
                            Remove URL Previews
                        </Box>
                    </Checkbox>
                </Box>
                <Divider /> */}

                <FormControl mt={1}>
                    <Box display="flex" alignItems="center" gap="2" mx="2" h="10" >
                        <Tooltip
                            hasArrow
                            label={
                                <>
                                    <Text>When you enable Auto Retweet, the tweet you wrote will be retweeted by your own account after a certain time.</Text><br />
                                    <Text>Choose the right interval and # of times.</Text><br />
                                    <Text>Example</Text>
                                    <Text>Interval = 6 hours</Text>
                                    <Text># of times = 2</Text><br />
                                    <Text>Tweet will be retweeted 6 hours after being posted, and then a second time 6 hours later.</Text>
                                </>
                            }
                        // label="Due tu recent changes in the Twitter API, we disabled this feature temporarily."
                        >
                            <QuestionIcon color="gray.400" />
                        </Tooltip>
                        <Text textStyle={textStyle['body.bold.standard']}  >Auto retweet</Text>
                        <Box flex="1" />
                        <Switch
                            isChecked={isAutoRetweet}
                            // isDisabled={true}
                            // m={2}
                            // mr={2}
                            onChange={async (e) => {
                                setIsAutoRetweet(!isAutoRetweet);
                                // const db = firebaseClient.firestore();
                                // await db.collection("users").doc(getAccount(session).id).update({isAutoRetweet: !isAutoRetweet})
                                await updateUser(session, { isAutoRetweet: !isAutoRetweet });
                            }}
                        />
                    </Box>
                    {
                        isAutoRetweet && (
                            <Box my="4">
                                <Flex justifyContent="space-between" alignItems="center" maxW="48" marginLeft="6">
                                    <Text textStyle="body.medium.standard">Interval</Text>
                                    <Menu isLazy>
                                        <MenuButton as={Button} size="sm" variant={"secondary"} rightIcon={<ChevronDownIcon />}>
                                            {autoRetweetHours + (autoRetweetHours == 1 ? " hour" : " hours")}
                                        </MenuButton>
                                        <MenuList maxH="215px" overflow="scroll">
                                            {
                                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(x => (
                                                    <MenuItem
                                                        key={x}
                                                        onClick={async (e) => {
                                                            // console.log(e);
                                                            setAutoRetweetHours(x);
                                                            //   const db = firebaseClient.firestore();
                                                            //   await db.collection("users").doc(getAccount(session).id).update({autoRetweetHours: x})
                                                            await updateUser(session, { autoRetweetHours: x });
                                                        }}
                                                    >
                                                        {x + (x == 1 ? " hour" : " hours")}
                                                    </MenuItem>
                                                ))
                                            }
                                        </MenuList>
                                    </Menu>
                                </Flex>
                                <Flex justifyContent="space-between" alignItems="center" maxW="48" marginLeft="6" marginTop="3">
                                    <Text textStyle="body.medium.standard"># of times</Text>
                                    <Menu isLazy>
                                        <MenuButton as={Button} size="sm" variant={"secondary"} rightIcon={<ChevronDownIcon />}>
                                            {autoRetweetTimes + (autoRetweetTimes == 1 ? " time" : " times")}
                                        </MenuButton>
                                        <MenuList maxH="215px" overflow="scroll">
                                            {
                                                // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(x => (
                                                [1, 2, 3].map(x => (
                                                    <MenuItem
                                                        key={x}
                                                        onClick={async (e) => {
                                                            // console.log(e);
                                                            setAutoRetweetTimes(x);
                                                            // const db = firebaseClient.firestore();
                                                            // await db.collection("users").doc(getAccount(session).id).update({ autoRetweetTimes: x })
                                                            await updateUser(session, { autoRetweetTimes: x });
                                                        }}
                                                    >
                                                        {x + (x == 1 ? " time" : " times")}
                                                    </MenuItem>
                                                ))
                                            }
                                        </MenuList>
                                    </Menu>
                                </Flex>
                            </Box>
                        )
                    }
                </FormControl>
                <Divider />

                <FormControl mt={1} display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" gap="2" mx="2" h="10">
                        <Tooltip hasArrow label="If activated, a new tweet will be posted as reply if the tweet reach a certain number of likes">
                            <QuestionIcon color="gray.400" />
                        </Tooltip>
                        <Text textStyle="body.bold.standard"  >Auto plug</Text>
                        <Box flex="1" />
                        <Switch
                            isChecked={isAutoPlug}
                            // m={4}
                            // ml={8}
                            onChange={async (e) => {
                                setIsAutoPlug(!isAutoPlug);
                                // const db = firebaseClient.firestore();
                                // await db.collection("users").doc(getAccount(session).id).update({isAutoPlug: !isAutoPlug})
                                await updateUser(session, { isAutoPlug: !isAutoPlug });
                            }}
                        />
                        {
                            isAutoPlug && (
                                <>
                                    <NumberInput value={autoPlugTrigger}
                                        onChange={async (value) => {
                                            let valueInt = parseInt(value) || 0;
                                            setAutoPlugTrigger(valueInt);
                                            // const db = firebaseClient.firestore();
                                            // await db.collection("users").doc(getAccount(session).id).update({autoPlugTrigger: valueInt})
                                            await updateUser(session, { autoPlugTrigger: valueInt });
                                        }} size="sm" width="80px" defaultValue={20} min={0}
                                    >
                                        <NumberInputField
                                            pl={2}
                                            pr={1}
                                            borderRadius="6px" />
                                    </NumberInput>
                                    <Text ml={2} mt={2}>likes</Text>
                                </>
                            )
                        }
                    </Box>

                    {/* Auto Plug Setting */}
                    {isAutoPlug ? <AutoPlug text={autoPlugText} onChange={(text) => setAutoPlugText(text)} /> : null}

                </FormControl>
                <Divider />
                <FormControl mt={1} display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" gap="2" mx="2" h="10">
                        <Tooltip hasArrow label={
                            <>
                                <Text>When you enable Auto DM on a tweet, users who perform at least one of the trigger(s) will receive a DM from you within 5 minutes.</Text><br />
                                <Text>Useful to send free resources, discount codes and more.</Text><br />
                                <Text>Use variables to personalize your DM:</Text>
                                <Text>[tweet] : the link to your original tweet</Text>
                                <Text>[name] : the Twitter name of the recipient</Text>
                                <Text>[first] : the first word of recipient's name</Text>
                                <Text>[handle] : the Twitter handle of the recipient</Text><br />
                                <Text>Note: will not work if user DMs are closed and will send a maximum of 500 per 24 hour period.</Text><br />
                                <Text>Note 2: Auto DM will stop working 72 hours after your tweet has been posted.</Text>
                            </>
                        }>
                            <QuestionIcon color="gray.400" />
                        </Tooltip>
                        <Text textStyle="body.bold.standard" mt={0}>Auto DM</Text>
                        <Box flex="1" />
                        <Switch
                            isChecked={isAutoDm}
                            // m={4}
                            // ml={8}
                            onChange={async (e) => {
                                setIsAutoDm(!isAutoDm);
                                if (!isAutoDm) {
                                    const counter_auto_dm = getAccount(session)?.stats?.counter_auto_dm ?? 0;
                                    if (counter_auto_dm === 0) {
                                        setTimeout(() => {
                                            onOpenEdu();
                                        }, 500)
                                    }
                                    updateStatsCounter("auto_dm")
                                }
                            }}
                        />
                    </Box>
                    {
                        isAutoDm && (
                            <Box my="4">
                                <Box marginLeft="6" mb="4">
                                    <Text textStyle="body.bold.standard">Conditions</Text>
                                    <Text textStyle="body.medium.light">To receive DM, user must...</Text>
                                    <Stack my="4">
                                        <Checkbox
                                            fontWeight="medium"
                                            isChecked={autoDmTrigger.retweet}
                                            onChange={async (e) => {
                                                await handleDmTriggerChange("retweet", e.target.checked);
                                            }}
                                        >
                                            retweet
                                        </Checkbox>
                                        <Checkbox
                                            fontWeight="medium"
                                            isChecked={autoDmTrigger.reply}
                                            onChange={async (e) => {
                                                await handleDmTriggerChange("reply", e.target.checked);
                                            }}
                                        >
                                            reply
                                        </Checkbox>
                                        <Checkbox
                                            fontWeight="medium"
                                            isChecked={autoDmTrigger.like}
                                            onChange={async (e) => {
                                                await handleDmTriggerChange("like", e.target.checked);
                                            }}
                                        >
                                            like
                                        </Checkbox>
                                        <Flex alignItems="center">
                                            <Text textStyle="body.bold.standard">Max DMs to send: </Text>
                                            <NumberInput ml={2} size="sm" w="80px" value={autoDmMax} onChange={(valueString) => setAutoDmMax(parseInt(valueString) || 0)} >
                                                <NumberInputField borderRadius="6px" pr="2" pl="2" />
                                            </NumberInput>
                                        </Flex>
                                        <Checkbox
                                            isChecked={isAutoDmRandom}
                                            onChange={async (e) => {
                                                setIsAutoDmRandom(!isAutoDmRandom);
                                            }}
                                        >
                                            Send randomly to {autoDmMax} people after 24h
                                        </Checkbox>
                                        {/* {
                                        session?.user?.data?.isAdmin && ( */}
                                        <Checkbox
                                            isChecked={isAutoDmLeadMagnet}
                                            onChange={async (e) => {
                                                setIsAutoDmLeadMagnet(!isAutoDmLeadMagnet);
                                            }}
                                        >
                                            Plug Lead Magnet and capture emails
                                        </Checkbox>
                                        {/* )
                                    } */}
                                        {
                                            isAutoDmLeadMagnet && (
                                                <Flex flexDir="column" >
                                                    <Text mt={4} fontSize="sm">Your Lead Magnet resource:</Text>
                                                    <Input mt={2} value={autoDmLeadMagnetUrl} onChange={(e) => setAutoDmLeadMagnetUrl(e.target.value)} placeholder="https://www.notion.so/amazing-resources..." />
                                                    <Text mt={4} fontSize="sm">List where emails will be pushed:</Text>
                                                    <ContactListDropdown
                                                        value={autoDmLeadMagnetConstactList}
                                                        placeholder="Pick from contact list"
                                                        onSelectList={(list) => {
                                                            setAutoDmLeadMagnetConstactList(list);
                                                            setAutoDmLeadMagnetListId(list.id);
                                                        }}
                                                        menuButtonProps={{ mt: 2, ml: 0 }}
                                                    />
                                                    <Text mt={4} fontSize="sm">The following link will be added at the end of the DM:</Text>
                                                    <Link mt={0} fontSize="xs" textDecoration="underline"
                                                        noOfLines={1}
                                                        isExternal
                                                        href={"https://tweethunter.io/value?id=" + tweetContext?.id + "&idUser=" + getAccount(session).id + "&idList=" + autoDmLeadMagnetListId + "&url=" + encodeURIComponent(autoDmLeadMagnetUrl) + "&twUserName=" + getAccount(session).twUserName + "&idList=" + autoDmLeadMagnetListId}
                                                    >
                                                        {"https://tweethunter.io/value?id=" + tweetContext?.id + "&idUser=" + getAccount(session).id + "&idList=" + autoDmLeadMagnetListId + "&url=" + encodeURIComponent(autoDmLeadMagnetUrl) + "&twUserName=" + getAccount(session).twUserName + "&idList=" + autoDmLeadMagnetListId}
                                                    </Link>
                                                </Flex>
                                            )
                                        }
                                    </Stack>
                                    <Text textStyle="body.bold.standard">DM content</Text>
                                    <Text textStyle="body.medium.light">When condition is filled, user gets the following DM</Text>
                                    <Text mt={2} textStyle="small.medium.light">When using this  feature, you need to make people aware they will get a DM from you by adding words like "dm", "message" or "send" in the original tweet</Text>
                                    <Text mt="2" textStyle="small.medium.light" >Note: You can send a maximum of 500 per 24 hour period.</Text>
                                </Box>


                                <TweetComposer
                                    initText={getAccount(session)?.autoDmText}
                                    disableHeader={true}
                                    //   disableImage={true}
                                    disableThread={true}
                                    disableGif={true}
                                    disableReWrite={true}
                                    mode="autoDm"
                                    onUpdate={async (state) => {
                                        getAccount(session).autoDmText = state.text;
                                    }}
                                    customSave={async (text) => {
                                        setAutoDmText(text);
                                        // const db = firebaseClient.firestore();
                                        // await db.collection("users").doc(getAccount(session).id).update({autoDmText: text});
                                        await updateUser(session, { autoDmText: text }, false, false, { disableUpdateUser: true });
                                    }}
                                />
                            </Box>
                        )
                    }
                </FormControl>
                <Divider />
                <FormControl mt={1} >
                    <Box display="flex" alignItems="center" gap="2" mx="2" h="10">
                        <Tooltip
                            hasArrow
                            label={
                                <>
                                    <Text>Only works if you are scheduling a thread.</Text><br />
                                    <Text>When you enable Thread Delay, every individual tweet that composes your thread will be published a certain time after the previous tweet.</Text><br />
                                    <Text>Example: there are 4 tweets in your thread, with a delay of 1 minute, You schedule the thread for 4:00pm. </Text><br />
                                    <Text>The first tweet will be published at 4:00pm.</Text>
                                    <Text>The second tweet at 4:01pm.</Text>
                                    <Text>The third tweet at 4:02pm.</Text>
                                    <Text>Your thread will be done publishing at 4:03pm.</Text>
                                </>
                            }
                        >
                            <QuestionIcon color="gray.400" />
                        </Tooltip>
                        <Text textStyle="body.bold.standard"  >Thread Delay </Text>
                        <Box flex="1" />
                        <Switch
                            isChecked={isDelayBetweenTweets}
                            // m={2}
                            // mr={4}
                            onChange={async (e) => {
                                setIsDelayBetweenTweets(!isDelayBetweenTweets);
                                // only save when turning to false
                                if (isDelayBetweenTweets)
                                    await updateUser(session, { isDelayBetweenTweets: !isDelayBetweenTweets });
                            }}
                        />
                    </Box>
                    {
                        isDelayBetweenTweets && (
                            <Box my="4">
                                <Flex justifyContent="space-between" alignItems="center" maxW="48" marginLeft="6">
                                    <Text textStyle="body.medium.standard">Delay</Text>
                                    <Menu isLazy>
                                        <MenuButton as={Button} size="sm" variant={"secondary"} rightIcon={<ChevronDownIcon />}>
                                            {(delayBetweenTweetsMinutes < 1 ? Math.round(delayBetweenTweetsMinutes * 60) : delayBetweenTweetsMinutes) + (delayBetweenTweetsMinutes < 1 ? " seconds" : (delayBetweenTweetsMinutes == 1 ? " minute" : " minutes"))}
                                        </MenuButton>
                                        <MenuList maxH="215px" overflow="scroll" zIndex="9">
                                            {
                                                [0.08, 0.17, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 30].map(x => (
                                                    <MenuItem
                                                        key={x}
                                                        onClick={async (e) => {
                                                            // console.log(e);
                                                            setDelayBetweenTweetsMinutes(x);
                                                            //   const db = firebaseClient.firestore();
                                                            //   await db.collection("users").doc(getAccount(session).id).update({delayBetweenTweetsMinutes: x})
                                                            await updateUser(session, { delayBetweenTweetsMinutes: x });
                                                        }}
                                                    >
                                                        {/* {x + (x == 1 ? " minute" : " minutes")} */}
                                                        {(x < 1 ? Math.round(x * 60) : x) + (x < 1 ? " seconds" : (x == 1 ? " minute" : " minutes"))}
                                                    </MenuItem>
                                                ))
                                            }
                                        </MenuList>
                                    </Menu>
                                </Flex>
                            </Box>
                        )
                    }
                    <Divider />
                </FormControl>
                {
                    session?.user?.data?.isAdmin && (
                        <FormControl mt={1} >
                            <Box display="flex" alignItems="center" gap="2" mx="2" h="10">
                                <Tooltip
                                    hasArrow
                                    label="If activated, Tweet Hunter will make your other Twitter accounts RT this tweet at a random time, from 0 to 10 minutes after it's published."
                                >
                                    <QuestionIcon color="gray.400" />
                                </Tooltip>
                                <Text textStyle="body.bold.standard"  >Auto RT with other accounts</Text>
                                <Box flex="1" />
                                <Switch
                                    isChecked={autoRtWithOtherAccounts}
                                    // m={2}
                                    // mr={4}
                                    onChange={async (e) => {
                                        setAutoRtWithOtherAccounts(!autoRtWithOtherAccounts);
                                        await updateUser(session, { autoRtWithOtherAccounts: !autoRtWithOtherAccounts });
                                    }}
                                />
                            </Box>
                        </FormControl>
                    )
                }
                {/* {
                    session?.user?.data?.isAdmin && (
                        <FormControl mt={2}>
                            <Box display="flex" alignItems="center">
                                <Tooltip
                                    hasArrow
                                    label="If activated, Tweet Hunter will add a 'live' leaderboard to your tweet by checking all mentions to your tweet"
                                >
                                    <QuestionIcon color="gray.400" mt={1} />
                                </Tooltip>
                                <Text mt={1}>Add a live leaderboard reply</Text>
                                <Switch
                                    isChecked={isLiveTweet}
                                    m={2}
                                    mr={4}
                                    onChange={async (e) => {
                                        setIsLiveTweet(!isLiveTweet);
                                    }}
                                />
                            </Box>
                        </FormControl>
                    )
                } */}
                <Divider />
                <FormControl mt={1} display="flex" flexDirection="column" >
                    <Box display="flex" alignItems="center" gap="2" mx="2" h="10">
                        <Tooltip hasArrow label={
                            <>
                                <Text>Add this content in your Taplio queue with your default Taplio's settings.</Text>
                                <Text>Go in Settings / Integrations to set your Taplio's key</Text>
                            </>
                        }>
                            <QuestionIcon color="gray.400" />
                        </Tooltip>
                        <Text textStyle="body.bold.standard"  >Also publish to Linkedin</Text>
                        <Box flex="1" />
                        <IconButton aria-label='show TH preview' m={0} icon={<AiOutlineEye fontSize="20px" />} variant="action" size="xs"
                            onClick={(e) => {
                                tweetContext.setSelectedTweetPreview({ id: "", text: pushTaplioText, type: "linkedin" });
                                tweetContext.onOpenTweetPreview();
                            }}
                        />
                        <Switch
                            isChecked={isPushTaplio && getAccount(session)?.keyTaplio}
                            isDisabled={!getAccount(session)?.keyTaplio}
                            // m={2}
                            // ml={8}
                            onChange={async (e) => {
                                setIsPushTaplio(!isPushTaplio);
                                await updateUser(session, { isPushTaplio: !isPushTaplio });
                            }}
                        />

                    </Box>
                    {
                        isPushTaplio && (
                            <Box my="4">

                                <RadioGroup
                                    onChange={(val) => {
                                        setIsPublishSameTime(val);
                                        updateUser(session, { isPublishSameTime: val });
                                    }}
                                    value={isPublishSameTime} defaultValue={"1"}
                                >
                                    <Stack ml={6} mt={1} mb={3} >
                                        <Radio size='sm' value="1"><Box textStyle="body.medium.light">Add to next available slot in Taplio</Box></Radio>
                                        <Radio size='sm' value="2"><Box textStyle="body.medium.light">Publish at same time as on Tweet Hunter</Box></Radio>
                                    </Stack>
                                </RadioGroup>
                                <Accordion allowMultiple
                                    onClick={(e) => {
                                        tweetContext.setIsTweetTextChanged(true);
                                    }}
                                >
                                    <AccordionItem border="none">
                                        <AccordionButton as={Button} p={0} justifyContent="flex-start" variant="invisible" size="sm">
                                            <Text fontSize="sm" pl={6} mr={1}>
                                                {"Modify the post for Linkedin "}
                                            </Text>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel p={0}>
                                            <Box mt={2}>
                                                <TweetComposer
                                                    initText={pushTaplioText}
                                                    disableHeader={true}
                                                    disableImage={true}
                                                    disableThread={true}
                                                    disableGif={true}
                                                    disableSuggestion={true}
                                                    mode="taplio-push"
                                                    height="100px"
                                                    nbCharMax={2800}
                                                    customSave={async (text) => {
                                                        setPushTaplioText(text);
                                                        if (text != pushTaplioText) {
                                                            // console.log("pushTaplio diverged");
                                                            setIsPushTaplioDiverged(true);
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </Box>
                        )
                    }
                    {
                        !getAccount(session)?.keyTaplio && (
                            <NextLink href="/settings?tab=integrations" passHref legacyBehavior>
                                <Button variant="tertiary" size="xs" fontSize={"xs"} mb={4} >
                                    <Link
                                    // textDecoration="underline" pl={6} fontSize="xs"
                                    >Connect a Taplio account</Link>
                                </Button>
                            </NextLink>
                        )
                    }
                </FormControl>
                <Divider />
                <OptionItem
                    saveKey={"isGenerateBlog"}
                    isBeta={false}
                    isEnable={isGenerateBlog}
                    setIsEnable={setIsGenerateBlog}
                    title="Generate Blog Post"
                    tooltip="If activated, Tweet Hunter will generate a blog post out of your tweet/thread which can be shared and indexed on search engines"
                    content={
                        <Flex justifyContent="flex-start" marginLeft="6" flexDir="column">
                            <Flex alignItems="center">
                                <Text textStyle="body.medium.standard">Title</Text>
                                <Input ml={5} placeholder="A cool blog title" value={blogTitle} onChange={(e) => { setBlogTitle(e.target.value) }} />
                            </Flex>
                            <CopyToClipboard
                                text={"https://tweethunter.io/thread-finder/" + getAccount(session)?.twUserName + "/" + tweetContext.id}
                                onCopy={() => {
                                    // analytics.log("hit_copy_clipboard", analytics.formatTweet(tweet.data));
                                    toast.success("Public url copied to clipboard");
                                }}
                            >
                                <Button mt={2} variant="primary">copy public link</Button>
                            </CopyToClipboard>
                        </Flex>
                    }
                    extraOptions={
                        <Flex>
                            <Text textStyle="body.bold.standard" mt={1}>{"Plug blog post url as reply"}</Text>
                            <Box flex="1" />
                            <Switch
                                isChecked={isPlugBlogAsReply}
                                // m={2}
                                // mr={4}
                                onChange={async (e) => {
                                    setIsPlugBlogAsReply(!isPlugBlogAsReply);
                                    await updateUser(session, { isPlugBlogAsReply: !isPlugBlogAsReply });
                                }}
                            />
                        </Flex>
                    }
                    extraRefreshKeys={[{ updateFunction: setBlogTitle, name: "blogTitle" }]}
                />
                {/* {
                    session?.user?.data?.isBeta && (
                        <FormControl mt={2}>
                            <Box display="flex" alignItems="center">
                                <Tooltip
                                    hasArrow
                                    label="If activated, all your supporters will will receive a notification when your tweet/thread is published."
                                >
                                    <QuestionIcon color="gray.400" mt={1} />
                                </Tooltip>
                                <Text mt={1}><Link href="/home-settings">Notify fan list <Text as="span" fontSize="xs">(beta)</Text></Link></Text>
                                <Switch
                                    isChecked={isNotifyFanList}
                                    m={2}
                                    mr={4}
                                    onChange={async (e) => {
                                        setIsNotifyFanList(!isNotifyFanList);
                                    }}
                                />
                            </Box>
                        </FormControl>
                    )
                } */}
            </Box>
        );
    }

    // console.log("tweetContext.textState.text: " + tweetContext.textState.text);
    // console.log("linkAccounts", getAccount(session)?.linkAccounts);

    const renderTabs = (isFullMode = false) => {
        return (
            <Flex
                {...{
                    // overflow: "hidden auto",
                    // overscrollBehavior: "contain",
                    w: "100%",
                    h: "100%",
                    className: "noScrollBar",
                    mb: "100px",
                    flexDir: "column",
                    placeItems: "flex-start",
                }}
            >
                {/* <Flex w="100%"> */}
                <Tabs
                    onChange={(index) => {
                        tweetContext.setTabIndex(index);
                        if (index == 1) {
                            getTweetsFromStatus("draft", setDrafts);
                        }
                        else if (index == 2) {
                            getTweetsFromStatus("scheduled", setScheduleds);
                        }
                        else if (index == 3) {
                            getTweetsFromStatus("sent", setSent);
                        }
                    }}
                    index={tweetContext.tabIndex}
                    w="100%"
                >
                    <TabList mt={3} justifyContent="center" border={0}>
                        <Tab key="Compose" >Compose</Tab>
                        <Tab key="Drafts">Drafts</Tab>
                        <Tab key="Scheduled">Scheduled</Tab>
                        <Tab key="Sent">Sent</Tab>
                    </TabList>

                    <TabPanels
                        // w="100%" maxW="100%"
                        // overflowX="hidden"
                        {...{ minHeight: "fit-content", height: "100%" }}
                    >
                        <TabPanel {...{ minHeight: "fit-content", height: "100%" }}>
                            {
                                tweetContext.isIncognitoMode ? (
                                    <Flex w="100%" flexDirection={'column'} justifyContent='space-between' >
                                        <IncognitoSidebar />
                                    </Flex>
                                ) : (
                                    <Stack spacing="24px">
                                        <FormControl>
                                            {renderMainTweetComposer()}
                                            {
                                                // session?.user?.data?.thWriteAccessToken ? (
                                                true ? (
                                                    <Box pt={5}>
                                                        {renderTweetNowAddToQueue()}
                                                        {
                                                            getAccount(session)?.linkAccounts?.some(x => x.type == "editor") && (
                                                                renderPushToCreator()
                                                            )
                                                        }
                                                        <Accordion allowMultiple>
                                                            <AccordionItem border="none">
                                                                {({ isExpanded }) => (
                                                                    <>
                                                                        <h2>
                                                                            <AccordionButton p={0} justifyContent="flex-start"
                                                                                {...{ _hover: { bg: "none" } }}
                                                                            >
                                                                                <Flex alignItems="flex-start" flexDirection="column" mr={5} mt={1}>
                                                                                    <Text mr={1} textStyle="body.bold.standard">
                                                                                        {"Advanced Options "}
                                                                                    </Text>
                                                                                    <Text mt="1px" fontSize="11px" textAlign="start" textStyle="body.medium.light" lineHeight={1.2}>
                                                                                        {buildAdvancedOptionString()}
                                                                                    </Text>
                                                                                </Flex>
                                                                                {/* <Box flex="1" textAlign="left">
                                                                                {"Advanced Options " + (isAutoRetweet || isAutoPlug ? "(" + (isAutoRetweet ? "auto-retweet" : "") + (isAutoPlug ? (isAutoRetweet ? ", " : "") + "auto-plug " : "") + ")" : "")}
                                                                            </Box> */}
                                                                                <Icon
                                                                                    as={isExpanded ? BsChevronDown : BsChevronRight}
                                                                                    mt="0.5"
                                                                                    ml="2"
                                                                                    h="3"
                                                                                    w="3"
                                                                                    strokeWidth="1"
                                                                                />
                                                                            </AccordionButton>
                                                                        </h2>
                                                                        <AccordionPanel p={0}>
                                                                            <Alert status="info" mt="4" size="sm">
                                                                                <AlertIcon />
                                                                                {/* <Text  textStyle="body.bold.standard" > */}
                                                                                These settings will affect this tweet only
                                                                                {/* </Text> */}
                                                                            </Alert>
                                                                            {renderAdvancedOptions()}

                                                                        </AccordionPanel>
                                                                    </>
                                                                )}
                                                            </AccordionItem>
                                                        </Accordion>

                                                    </Box>
                                                ) : (
                                                    <Box display="flex" flexDirection="column" justifyContent="flex-end">
                                                        <Text>To post tweet, we need some extra authorization. Hit the button below to authorize Tweet Hunter to post tweets.</Text>
                                                        <Button mt={5} variant={"primary"}
                                                            as="a"
                                                            href={getAuthUrl(session)}
                                                            target="_blank"
                                                            onClick={() => {
                                                                analytics.log("hit_getExtraAuth");
                                                            }}
                                                        >
                                                            Allow us to send tweet
                                                        </Button>
                                                    </Box>
                                                )
                                            }
                                        </FormControl>
                                    </Stack>
                                )
                            }
                        </TabPanel>
                        {
                            [
                                { list: drafts, setList: setDrafts, status: "draft" },
                                { list: scheduleds, setList: setScheduleds, status: "scheduled" },
                                { list: sent, setList: setSent, status: "sent" },
                            ].map((object, index) => (
                                <TabPanel key={"list" + index} p={0} pt={2} w="100%">
                                    {
                                        <TweetList
                                            list={object.list}
                                            setList={object.setList}
                                            removeFromList={removeFromList}
                                            getTweetsFromStatus={getTweetsFromStatus}
                                            isLoading={isLoadingTweets}
                                            status={object.status}
                                            hasMore={hasMore}
                                            setHasMore={setHasMore}
                                        />
                                    }
                                </TabPanel>
                            ))
                        }
                    </TabPanels>
                </Tabs>
                {/* </Flex> */}
            </Flex>
        )
    }

    const renderMainTweetComposer = (isFullMode = false) => {
        const placeHolder = isFullMode ? `Write here.\n\n\nCreate a thread by skipping 3 lines between each tweet.\n\n\nYou can drag and drop images, videos and GIFs, or use the menu at the bottom of this section.` : `Write here. \n\n\nSkip 3 lines to start a thread.`
        return (
            <TweetComposer
                submit={async (e) => {
                    await addToQueue();
                }}
                customSave={async (text) => {
                    // console.log("customSave: " + text);
                    if (!isPushTaplioDiverged) {
                        setPushTaplioText(text);
                    }
                    if (tweetContext.isIncognitoMode && text) {
                        setInLocalStorage("currentTweetContent", text);
                    }
                }}
                isFullMode={isFullMode}
                ref={tweetContext.refComposer}
                initText={tweetContext.textState.text}
                initialTextStates={tweetContext.textStates}
                initThreadTweets={tweetContext.textState.threadTweets}
                id={tweetContext.id}
                createDraft={tweetContext.createDraft}
                newTweet={tweetContext.newTweet}
                initFiles={tweetContext.textState.files}
                isPostTwitter={isPostTwitter}
                selectedTweet={tweetContext?.selectedTweet}
                disablePreview={isPreventLinkExpand}
                placeHolder={placeHolder}
                imageEditor={isSafari ? false : true}
                onNewDraft={() => setSelectedLabelIds([])}
            />
        )
    }

    const RHS_SCHEDULER_WIDTH = 400;
    const THREADER_PREVIEW_WIDTH = 370;
    const WIDTH_TO_SUBTRACT_FULL_MODE = RHS_SCHEDULER_WIDTH + THREADER_PREVIEW_WIDTH + 62;

    const screenWidth = useWindowWidth();
    const [isTooSmall, isTooSmallSet] = useState(false);
    const [isTooSmallForTweet, isTooSmallForTweetSet] = useState(false);
    const [isTooSmallFullScreen, isTooSmallFullScreenSet] = useState(false);

    useEffect(() => {
        isTooSmallSet(screenWidth < 800)
        isTooSmallForTweetSet(screenWidth < 1000)
        isTooSmallFullScreenSet(screenWidth < 1300)
    }, [screenWidth])

    return (
        <motion.div
            animate={{
                width: !isSchedulerOpen ? "0px" : (isTooSmall ? "100%" : "400px"),
            }}
            transition={{
                duration: 0.1,
                ease: "easeOut", // "linear" "easeIn", "easeOut", "easeInOut" "circIn", "circOut", "circInOut" "backIn", "backOut", "backInOut" "anticipate"
            }}
            className="noScrollBar"
            style={{
                height: "100%",
                minHeight: "100%",
                width: "0px",
                right: "0px",
                top: "0px",
                position: "fixed",
                overflow: "hidden scroll",
                overscrollBehavior: "contain",
                // overflow:"scroll",
                // "::-webkit-scrollbar": {
                //     width: 0
                // },
                // boxShadow: "rgba(100, 100, 111, 0.05) 0px 2px 19px 0px",
                //@ts-ignore
                zIndex: "100",
                backgroundColor: colorWhite,
                borderLeft: isSchedulerOpen ? "solid 1px " + borderLight : "none",
            }}
        >
            {
                tweetContext?.isOpen && tweetContext?.isFullModeOpen &&
                <Flex
                    p={6}
                    pl={10}
                    position="fixed"
                    left="62px"
                    top="0"
                    width={`calc(100% - ${WIDTH_TO_SUBTRACT_FULL_MODE}px)`}
                    h={'99vh'}
                    flexDir={'column'}
                >
                    {renderMainTweetComposer(true)}
                </Flex>
            }
            <Flex
                w="100%"
                h="100%"
                // maxH="100%"
                p={2} pl={2}
                flexDirection="column"
                // justifyContent="space-between" 
                alignItems="center"
            >
                {/* top row with collapse sidebar icon + full mode icon */}
                <Box {...{
                    w: "full", p: 4, pb: [0], alignSelf: "start",
                    // overflow: "hidden",
                    // h: "55px"
                }}>
                    {!isTooSmall && (
                        <>
                            <IconButton
                                aria-label="collapse sidebar"
                                variant="action"
                                size="table"
                                mr={2}
                                onClick={() => {
                                    tweetContext.setIsOpen(!tweetContext.isOpen);
                                    tweetContext.setIsFullModeOpen(false);
                                }}
                                icon={
                                    <Icon viewBox="0 0 14 11">
                                        <svg
                                            width="14"
                                            height="11"
                                            viewBox="0 0 14 11"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8.12109 2.87891L10.1172 4.875H5.25C4.75781 4.875 4.375 5.28516 4.375 5.75C4.375 6.24219 4.75781 6.625 5.25 6.625H10.1172L8.12109 8.64844C7.95703 8.8125 7.875 9.03125 7.875 9.25C7.875 9.49609 7.95703 9.71484 8.12109 9.87891C8.44922 10.2344 9.02344 10.2344 9.35156 9.87891L12.8516 6.37891C13.207 6.05078 13.207 5.47656 12.8516 5.14844L9.35156 1.64844C9.02344 1.29297 8.44922 1.29297 8.12109 1.64844C7.76562 1.97656 7.76562 2.55078 8.12109 2.87891ZM2.625 10.125V1.375C2.625 0.910156 2.21484 0.5 1.75 0.5C1.25781 0.5 0.875 0.910156 0.875 1.375V10.125C0.875 10.6172 1.25781 11 1.75 11C2.21484 11 2.625 10.6172 2.625 10.125Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </Icon>
                                }
                            />
                        </>
                    )}
                    {!isTooSmallFullScreen && !tweetContext.isFullModeOpen && (
                        <IconButton
                            aria-label="Full screen tweet composer"
                            variant="action"
                            size="table"
                            onClick={() => {
                                tweetContext.setTextState(tweetContext.refComposer.current.textState());
                                tweetContext.setTextStates(tweetContext.refComposer.current.textStates());
                                tweetContext.setIsFullModeOpen(!tweetContext.isFullModeOpen);
                            }}
                            icon={<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.25 1C4.65625 1 5 1.34375 5 1.75C5 2.1875 4.65625 2.5 4.25 2.5H1.5V5.25C1.5 5.6875 1.15625 6 0.75 6C0.3125 6 0 5.6875 0 5.25V1.75C0 1.34375 0.3125 1 0.75 1H4.25ZM13.25 1C13.6562 1 14 1.34375 14 1.75V5.25C14 5.6875 13.6562 6 13.25 6C12.8125 6 12.5 5.6875 12.5 5.25V2.5H9.75C9.3125 2.5 9 2.1875 9 1.75C9 1.34375 9.3125 1 9.75 1H13.25ZM4.25 13.5C4.65625 13.5 5 13.8438 5 14.25C5 14.6875 4.65625 15 4.25 15H0.75C0.3125 15 0 14.6875 0 14.25V10.75C0 10.3438 0.3125 10 0.75 10C1.15625 10 1.5 10.3438 1.5 10.75V13.5H4.25ZM13.25 10C13.6562 10 14 10.3438 14 10.75V14.25C14 14.6875 13.6562 15 13.25 15H9.75C9.3125 15 9 14.6875 9 14.25C9 13.8438 9.3125 13.5 9.75 13.5H12.5V10.75C12.5 10.3438 12.8125 10 13.25 10Z" fill="currentColor" />
                            </svg>
                            }
                        />
                    )}
                    {!isTooSmallFullScreen && tweetContext.isFullModeOpen && (
                        <Tooltip aria-label='tooltip' label='Back to panel composer' placement='right'>
                            <IconButton
                                aria-label="Back to panel composer"
                                variant="action"
                                size="table"
                                onClick={() => {
                                    tweetContext.setTextState(tweetContext.refComposer.current.textState());
                                    tweetContext.setTextStates(tweetContext.refComposer.current.textStates());
                                    tweetContext.setIsFullModeOpen(!tweetContext.isFullModeOpen);
                                }}
                                icon={
                                    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.25 10C4.65625 10 5 10.3438 5 10.75V14.25C5 14.6875 4.65625 15 4.25 15C3.8125 15 3.5 14.6875 3.5 14.25V11.5H0.75C0.3125 11.5 0 11.1875 0 10.75C0 10.3438 0.3125 10 0.75 10H4.25ZM9.75 6C9.3125 6 9 5.6875 9 5.25V1.75C9 1.34375 9.3125 1 9.75 1C10.1562 1 10.5 1.34375 10.5 1.75V4.5H13.25C13.6562 4.5 14 4.84375 14 5.25C14 5.6875 13.6562 6 13.25 6H9.75ZM4.25 1C4.65625 1 5 1.34375 5 1.75V5.25C5 5.6875 4.65625 6 4.25 6H0.75C0.3125 6 0 5.6875 0 5.25C0 4.84375 0.3125 4.5 0.75 4.5H3.5V1.75C3.5 1.34375 3.8125 1 4.25 1ZM13.25 10C13.6562 10 14 10.3438 14 10.75C14 11.1875 13.6562 11.5 13.25 11.5H10.5V14.25C10.5 14.6875 10.1562 15 9.75 15C9.3125 15 9 14.6875 9 14.25V10.75C9 10.3438 9.3125 10 9.75 10H13.25Z" fill="currentColor" />
                                    </svg>
                                }
                            />
                        </Tooltip>
                    )}
                    {
                        getAccount(session)?.isPremium && !isTooSmallFullScreen && tweetContext.isFullModeOpen &&
                        <Text fontWeight="700"
                            color={colors.primary.lightMode[300]}
                            bg={colors.primary.lightMode[80]}
                            as={"span"}
                            // textStyle={textStyle["body.bold.standard"]}
                            fontSize={"xs"}
                            ml={1}

                        > <TiTickOutline style={{ display: "inline-block", position: "relative", top: "2px" }} /> Long form post enabled</Text>
                    }
                    <Divider {...{ mt: [10, 10, 4] }}></Divider>
                </Box>
                {
                    session?.user?.uid && selectedTweet && !isTooSmallForTweet && (
                        <VStack
                            position="fixed"
                            left="calc(-44vw)" //transform="translateX(-30%)"
                            top="50%"
                            transform="translateY(-50%)"
                            // top={100} 
                            // zIndex="1400"
                            maxW="400px"
                            spacing={5}
                        >
                            <Tweet
                                //@ts-ignore
                                tweet={{ data: selectedTweet }}
                                disableBookmarkButton={true}
                                disableRequest={false}
                                disableHideButton={false}
                                disableName={false}
                                disableEditTweet={true}
                                disableSubMenu={true}
                                //@ts-ignore
                                isVariation={selectedTweet.isVariation}
                                //@ts-ignore
                                disableFooter={selectedTweet.isVariation}
                            />
                            {
                                // session?.user?.data?.isBeta && (
                                //@ts-ignore
                                session?.user?.uid && !selectedTweet.isVariation && (
                                    <Button
                                        bg="white"
                                        color="gray.800"
                                        leftIcon={<BiGhost />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // requestVariation(selectedTweet);
                                        }}
                                    >
                                        Request variation
                                    </Button>
                                )
                            }
                        </VStack>
                    )
                }

                <Flex w="100%"
                // h="100%"
                >
                    {
                        tweetContext.isFullModeOpen ?
                            tweetContext.isIncognitoMode ? (
                                <Flex w="100%"
                                    // h={'100vh'}
                                    flexDirection={'column'} justifyContent='space-between' >
                                    {/* <IncognitoSidebar  /> */}
                                    {renderTabs()}
                                </Flex>
                            ) : (
                                <Flex
                                    pl={4}
                                    w="100%"
                                    h={'calc(100%)'}
                                    // h={'calc(100% - 58px)'}
                                    // maxH={'100%'}
                                    flexDirection={'column'}
                                    justifyContent='space-between'
                                >

                                    <Flex
                                        overflow="hidden auto"
                                        direction='column'
                                        pr={4}
                                        maxH="calc(100vh - 200px)"
                                    >
                                        {/* title */}
                                        <Flex mt={6} mb={6} alignItems={'flex-start'} justifyContent='space-between' >
                                            <Flex direction={'column'} alignItems={'flex-start'}>
                                                <Flex alignItems={' flex-start'}>
                                                    <SettingsIconComponent />
                                                    <Text
                                                        textStyle={textStyle['h4.bold.standard']}
                                                        ml={2}>Advanced options</Text>
                                                </Flex>
                                                <Text fontSize={'sm'}
                                                    mt={2}
                                                    textStyle={textStyle['body.medium.light']}
                                                >Will only affect the tweet or thread you are composing</Text>
                                            </Flex>
                                        </Flex>
                                        {/* <Box {...{
                                            // maxH: "100%"
                                            maxH: "62vh"
                                        }}> */}
                                        {renderAdvancedOptions()}
                                        {/* </Box> */}
                                    </Flex>
                                    <Box p={4} pb={2} >
                                        {
                                            getAccount(session)?.shareRole == "editor" ? (
                                                renderPushToCreator()
                                            ) : (
                                                renderTweetNowAddToQueue()
                                            )
                                        }
                                    </Box>
                                </Flex>
                            )
                            :
                            renderTabs()
                    }

                </Flex>
                <Flex flexDirection="column" w="100%" alignItems="flex-end">
                    {/* <Text fontSize="13px" bg="red" color="white" p={2}>
                        ⚠️ We are having issues with the scheduler. Your tweets may not be sent. 
                    </Text> */}
                </Flex>
            </Flex>
            <AutoDmEducation isOpen={isOpenEdu} onClose={onCloseEdu} />
            <AlertDialog
                isOpen={isOpenConfirm}
                //@ts-ignore
                leastDestructiveRef={cancelRef}
                onClose={onCloseConfirm}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {isScheduled ? "Schedule this tweet" : "Confirm your tweet"}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Stack>
                                {
                                    tweetContext.textState?.text && (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <Thread
                                                tweet={prepareTweet(isScheduled, convertToAccountTimezone(tweetContext.textState.scheduledTime, getAccount(session)?.timezone).date)}
                                                threadTweets={tweetContext.textState.threadTweets}
                                                disableNbChar={true}
                                                showOutline={true}
                                                disablePreview={isPreventLinkExpand}
                                                showGenerateImage={false}
                                                showSeeMoreDivider={true}
                                                dividerCss={{ width: "100%" }}
                                            />
                                        </Box>
                                    )
                                }
                                {
                                    isScheduled ? (
                                        <Box>
                                            <Text textAlign="center" fontWeight="700">
                                                Pick a time slot
                                            </Text>
                                            <Center m={5}>
                                                <Button variant={"secondary"} p={0} width="200px" >
                                                    <Datetime
                                                        isValidDate={current => {
                                                            const yesterday = moment().subtract(1, 'day');
                                                            return current.isAfter(yesterday);
                                                        }}
                                                        initialValue={tweetContext.textState.scheduledTime}
                                                        className="customDateTime"
                                                        onChange={(d) => {
                                                            //@ts-ignore
                                                            // console.log("selected date: " + d.toDate());

                                                            if (d?.toDate) {
                                                                //@ts-ignore
                                                                // setScheduledTime(d.toDate());
                                                                //@ts-ignore
                                                                tweetContext.setTextState({ ...tweetContext.textState, scheduledTime: d.toDate() });
                                                            }
                                                        }}
                                                    />
                                                </Button>
                                            </Center>
                                            <Text textAlign="center" textStyle="body.medium.standard">
                                                Will be published<b>{displayTimeDiff()}</b>
                                            </Text>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Text>
                                                Your tweet will be sent immediately
                                            </Text>
                                        </Box>
                                    )
                                }
                            </Stack>

                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <CopyToClipboard
                                text={"https://tweethunter.io/public?id=" + tweetContext.id}
                                onCopy={() => {
                                    // analytics.log("hit_copy_clipboard", analytics.formatTweet(tweet.data));
                                    toast.success("Public url copied to clipboard", {
                                        style: {
                                            background: "gray.600",
                                            color: "#222",
                                        },
                                    });
                                }}
                            >
                                <IconButton
                                    aria-label="GitHub"
                                    variant="secondary"
                                    icon={<FiShare2 fontSize="20px" />}
                                />
                            </CopyToClipboard>
                            <Button ml={3} ref={cancelRef} onClick={onCloseConfirm} variant="secondary">
                                Cancel
                            </Button>
                            <Button
                                isLoading={isPosting}
                                variant={"primary"}
                                onClick={async () => {

                                    console.log("scheduledTime: " + tweetContext.textState.scheduledTime);
                                    let textState = tweetContext.refComposer.current.textState();
                                    let dateTimezoned = convertToAccountTimezoneReversed(tweetContext.textState.scheduledTime, getAccount(session)?.timezone).date;
                                    // let dateTimezoned = tweetContext.textState.scheduledTime;
                                    console.log("dateTimezoned: " + dateTimezoned);

                                    let dataToSend: any = prepareTweet(isScheduled, dateTimezoned);
                                    // dataToSend.files = tweetContext.textState.files;
                                    setIsPosting(true);

                                    if (!(await checkTweetValid(textState.threadTweets, session, tweetContext, dataToSend, mainContext))) {
                                        setIsPosting(false);
                                        return;
                                    }
                                    else if (isPushTaplio) {
                                        var dataToSendTaplio = prepareTaplioPost(dataToSend);
                                        if (!(await checkTweetValid([], session, tweetContext, dataToSendTaplio, mainContext, { disableCheckTwitter: true, disableCheckImage: true, checkLinkedin: true }))) {
                                            setIsPosting(false);
                                            return;
                                        }
                                    }

                                    updateOnboardSteps("schedule", "evergreen");
                                    if (isScheduled) {
                                        await scheduleTweet(session, setIsPosting, setIsOpenConfirm, dataToSend, getAccount(session)?.timezone, tweetContext, true);
                                        updateStatsCounter("schedule_tweet");
                                    }
                                    else {
                                        dataToSend.scheduledTime = new Date();
                                        await saveTweet(session, dataToSend.id, dataToSend, tweetContext.createDraft, undefined);
                                        const isThreadContainsManyTweets = dataToSend?.text?.split("\n\n\n")?.length > 3;
                                        const isThreadContainVideo = dataToSend?.text?.includes("img:vid-")
                                        const isThreadHasMoreThan4Media = dataToSend?.text?.match(/\[img:/g)?.length > 4
                                        // if thread with many tweets & media, make publish async
                                        if (isThreadContainsManyTweets && (isThreadContainVideo || isThreadHasMoreThan4Media)) {
                                            await scheduleTweet(session, setIsPosting, setIsOpenConfirm, dataToSend, getAccount(session)?.timezone, tweetContext, true, { customSuccessMessage: "Your thread is being published - because it contains many tweets & media, it can take up to a few minutes" });
                                        } else {
                                            await postTweet(setIsPosting, setIsOpenConfirm, dataToSend, session);
                                        }
                                        updateStatsCounter("tweet_now");
                                    }

                                    if (isPushTaplio) {
                                        if (isPublishSameTime == "2" || !isScheduled)
                                            pushTaplio(session, dataToSendTaplio, isScheduled, dateTimezoned);
                                        else
                                            pushTaplio(session, dataToSendTaplio, true);
                                    }

                                    tweetContext.newTweet({ text: "" });
                                    tweetContext.setIsTweetTextChanged(true);
                                    setSelectedLabelIds([]);
                                    resetAutomation();
                                    validateTokenTask();
                                    analytics.log(isScheduled ? "hit_tweet_schedule_confirm" : "hit_tweet_now_confirm", { ...dataToSend });
                                    tweetContext.refresher({});

                                }} ml={3}>
                                {isScheduled ? "Schedule" : "Tweet"}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </motion.div>
    );
}

// export default memo(Scheduler, isEqual);
export default memo(Scheduler);
