import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import numbro from "numbro";
import scrollIntoView from "scroll-into-view-if-needed";
import { useKeyPress } from "utils/useKeyPress";

export interface Suggestion {
  handle: string;
  name: string;
  followersCount: number;
  image: string;
}

interface Props {
  suggestions: Suggestion[];
  onSelect: (handle: string) => void;
}

export function Suggestions({ suggestions, onSelect }: Props) {
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState<any>(undefined);

  const highlightColor = useColorModeValue("gray.100", "whiteAlpha.100");
  const backgroundColor = useColorModeValue("white", "#1E1E1E");

  useEffect(() => {
    if (suggestions?.length && downPress) {
      setCursor((prevState) => {
        const newState =
          prevState < suggestions?.length - 1 ? prevState + 1 : prevState;
        const el = document.getElementById(suggestions[newState].handle);
        if (el) {
          scrollIntoView(el, { scrollMode: "if-needed", behavior: "smooth" });
        }
        return newState;
      });
    }
  }, [downPress]);
  useEffect(() => {
    if (suggestions?.length && upPress) {
      setCursor((prevState) => {
        const newState = prevState > 0 ? prevState - 1 : prevState;
        const el = document.getElementById(suggestions[newState].handle);
        if (el) {
          scrollIntoView(el, { scrollMode: "if-needed", behavior: "smooth" });
        }
        return newState;
      });
    }
  }, [upPress]);
  useEffect(() => {
    if (suggestions?.length && enterPress) {
      onSelect(suggestions[cursor].handle);
    }
  }, [cursor, enterPress]);
  useEffect(() => {
    if (suggestions?.length && hovered) {
      setCursor(suggestions.findIndex((s) => s.handle === hovered));
    }
  }, [hovered]);

  return (
    <List spacing="2" role="listbox">
      {suggestions.map(({ handle, name, followersCount, image }, index) => {
        return (
          <ListItem
            zIndex={99999999}
            role="option"
            id={handle}
            p="1"
            display="flex"
            key={handle}
            cursor="pointer"
            background={index === cursor ? highlightColor : backgroundColor}
            onClick={() => onSelect(handle)}
            onMouseEnter={() => setHovered(handle)}
            onMouseDown={() => setHovered(undefined)}
          >
            <Image src={image} h="10" w="10" borderRadius="full" />
            <Box ml="2">
              <Text
                width="full"
                maxWidth="44"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                fontSize="sm"
                textAlign="left"
              >
                {name}
              </Text>
              <Text
                fontSize="sm"
                maxWidth="44"
                color="gray.500"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                @{handle} -{" "}
                {numbro(followersCount)
                  .format({
                    mantissa: 1,
                    totalLength: 3,
                  })
                  .replace(".0", "")}{" "}
                followers
              </Text>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};
