
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Divider,
  GridItem,
  Icon,
  Image,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import {
  useMasonry,
  usePositioner,
  useContainerPosition,
  useResizeObserver,
  useScroller
} from "masonic";
import React, { Ref, useEffect } from 'react';
import { MdRemove, MdAdd, MdOutlineEdit } from "react-icons/md";
import { filterUniqueByKey } from 'utils/arrayUtils';
import { colors } from "theme/colors/colors";
import { AiOutlineDelete } from 'react-icons/ai';
import { useWindowSize } from "@react-hook/window-size";
import toast from 'react-hot-toast';

export type MediaObject = {
  createdAt: {
    _seconds: number;
    _nanoseconds: number;
  };
  deleted: boolean;
  fileName: string;
  size: number;
  id: string;
  type: string;
  userId: string;
  url: string;
  updatedAt: {
    _seconds: number;
    _nanoseconds: number;
  };
};

interface MediaMasonryProps {
  medias: MediaObject[];
  tweetContext: any;
  handleDelete?: (id: string) => Promise<void>;
  openDeleteModal?: (id: string) => void;
  openEditModal?: (id: string) => void;
  isDeleting?: boolean;
  colorMode: string;
  modalMode?: boolean;
  setSelectedMedias?: (medias: string[]) => void;
  selectedMedias?: string[];
  ref?: Ref<any>; // extra element to interact with and impact the width of the container
  isSchedulerOpen?: boolean;
}

const MediaMasonry = ({ medias, tweetContext, handleDelete, openDeleteModal, openEditModal, colorMode, modalMode, setSelectedMedias, isSchedulerOpen, selectedMedias, ref: extraRef, isDeleting }: MediaMasonryProps) => {
  const getUniqMedias = () => filterUniqueByKey(medias, "url").filter(media => !media.deleted);
  const [selectedList, setSelectedList] = React.useState<string[]>([]);
  const [windowWidth, height] = useWindowSize();
  const [cleanedList, setCleanedList] = React.useState<MediaObject[]>([]);
  const containerRef = React.useRef(null);
  const { offset, width } = useContainerPosition(containerRef, [
    windowWidth,
    height,
  ]);
  const positioner = usePositioner({ width: width, columnGutter: 16 }, [cleanedList]);
  const { scrollTop, isScrolling } = useScroller(offset);
  const resizeObserver = useResizeObserver(positioner);
  const memoizedPositioner = React.useMemo(() => positioner, [positioner]);

  useEffect(() => {
    setCleanedList(getUniqMedias());
  }, [medias])

  useEffect(() => {
    (selectedMedias?.length && selectedMedias !== selectedList) && setSelectedList(selectedMedias);
  }, [selectedMedias])

  const handleSelect = (id) => {
    if (selectedList.includes(id)) {
      setSelectedList(selectedList.filter(x => x !== id));
      setSelectedMedias && setSelectedMedias(selectedList.filter(x => x !== id));
    } else {
      setSelectedList([...selectedList, id]);
      setSelectedMedias && setSelectedMedias([...selectedList, id]);
    }
  }
  const handleUse = async (id) => {
    if (modalMode) {
      handleSelect(id);
      return;
    }
    handleSelectionValidation([id]);
  }
  const handleSelectionValidation = (files: string[]) => {
    if (files.length === 0) {
      toast.error("Please select at least one media");
      return;
    }
    tweetContext.setFiles(files);
    if (tweetContext?.refComposer) {
      tweetContext.refComposer.current?.editText("[img:" + files[0] + "]");
    }
    if (!tweetContext.isOpen) {
      tweetContext.setIsOpen(true);
    }
  }


  useEffect(() => {
    return () => {
      // @ts-ignore
      containerRef.current?.destroy && containerRef.current?.destroy();
    }
  }, [])

  const mediaIsSelected = (id) => selectedList.includes(id) || (!modalMode && tweetContext.getFiles && tweetContext.getFiles().some(file => file === id));
  const renderGridItem = ({ data: item }) => {
    const isImage = item.type.split("/")[0] === "image";
    return (
      <>
        {!item.deleted
          && <Card
            maxW='xs'
            borderRadius={"xl"}
            _hover={{ boxShadow: "lg", ...modalMode && { cursor: "pointer" } }}
            onClick={() => modalMode && handleSelect(item.id)}
            border={mediaIsSelected(item.id) && `2px solid ${colors.success.lightMode[400]}`}
          >
            <CardBody p={0}>
              <Image
                src={item.thumbnail ?? item.url}
                borderBottomRadius={"none"}
                borderTopRadius={"lg"}
              />
              <Box px={2} py={1} position={"absolute"} backgroundColor={"#1e1e1e"} opacity={0.7} top={3} right={3} color={"#FFFFFF"} fontSize={"xs"} borderRadius={"lg"} fontWeight={600}>
                {item.type.split("/")[0]}
              </Box>
            </CardBody>
            <Divider />
            <CardFooter
              borderBottomRadius={"lg"}
              p={2}
              _dark={{
                background: "#1E1E1E"
              }}
            >
              <SimpleGrid columns={2} gap="2" w="100%" >
                <GridItem
                  colSpan={isImage ? 2 : 1}
                  as={Button}
                  size="sm"
                  w="full"
                  fontSize="xs"
                  variant={"secondary"}
                  leftIcon={modalMode && selectedList.includes(item.id) ? <Icon as={MdRemove} fontSize="sm" /> : <Icon as={MdAdd} fontSize="sm" />}
                  onClick={async () => {
                    await handleUse(item.id)
                  }}
                >
                  {modalMode && selectedList.includes(item.id) ? "Remove from selection" : modalMode ? "Select" : "Add to post"}
                </GridItem>
                {(isImage && openEditModal) && <Button
                  variant={"secondary"}
                  w="full"
                  size="sm"
                  fontSize="xs"
                  leftIcon={<Icon as={MdOutlineEdit} fontSize="sm" />}
                  onClick={async () => {
                    openEditModal && openEditModal(item.id)
                  }}
                >
                  {"Edit"}
                </Button>}
                {handleDelete && <Button
                  variant={"secondary"}
                  w="full"
                  size="sm"
                  fontSize="xs"
                  _dark={{
                    color: "text.darkMode.standard",
                    bg: "#1E1E1E",
                    borderColor: "border.darkMode.light",
                    _hover: {
                      color: colors.alert.darkMode.default,
                      borderColor: colors.alert.darkMode.default,
                    }
                  }}
                  sx={{
                    _hover: {
                      "svg ": {
                        color: colorMode === "dark" ? colors.alert.lightMode.default : colors.alert.darkMode.default
                      },
                      color: colorMode === "dark" ? colors.alert.lightMode.default : colors.alert.darkMode.default,
                      borderColor: colorMode === "dark" ? colors.alert.lightMode.default : colors.alert.darkMode.default,
                    }
                  }}
                  leftIcon={<Icon as={AiOutlineDelete} fontSize="sm" />}
                  onClick={async () => {
                    openDeleteModal && openDeleteModal(item.id)
                  }}
                >
                  {"Delete"}
                </Button>}
              </SimpleGrid>
            </CardFooter>
          </Card>}
      </>
    )
  }

  return (
    <Box ref={containerRef}>
      {
        useMasonry({
          resizeObserver,
          positioner: memoizedPositioner,
          scrollTop,
          isScrolling,
          containerRef,
          height,
          items: cleanedList,
          overscanBy: 3,
          render: renderGridItem,
        })
      }
    </Box>
  );
}

export default MediaMasonry;