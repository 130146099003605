import { radius } from "theme/names";
import { secondary } from "./secondary";

export const ai = (props) => {
  const { theme, fromcolor, tocolor } = props;
  const lgFrom = "#EC6181";
  const lgTo = "#316BFF";

  return {
    ...secondary,
    px: 3,
    border: "1px solid",
    borderColor: "transparent",
    borderRadius: radius.lg,
    // background: `linear-gradient(white, white) padding-box, 
    //   linear-gradient(135deg, ${lgFrom}, ${lgTo}) border-box`,
    bgGradient: `linear-gradient(135deg, ${lgFrom}, ${lgTo})`,
    bgClip: "text",
    
    "> *": {
      background: `linear-gradient(135deg, ${lgFrom}, ${lgTo})`,
      backgroundClip: "text",
      // textFillColor: 'transparent',
    },
    // _loading: {
    //   background: `linear-gradient(white, white) padding-box, 
    //   linear-gradient(135deg, ${lgFrom}, ${lgTo}) border-box`,
    // "> *": {
    //   background: `linear-gradient(135deg, ${lgFrom}, ${lgTo})`,
    //   backgroundClip: "text",
    //   // textFillColor: 'transparent',
    // },
    // backgroundClip: "none",
    // },
    _hover: {
        background: `linear-gradient(white, white) padding-box, 
        linear-gradient(180deg, ${lgFrom}, ${lgTo}) border-box`,
      "> *": {
        background: `linear-gradient(315deg, ${lgFrom}, ${lgTo})`,
        backgroundClip: "text",
      },
    },
    _dark: {
      background: `linear-gradient(#1E1E1E, #1E1E1E) padding-box, 
              linear-gradient(135deg, ${lgFrom}, ${lgTo}) border-box`,
      _hover: {
        background: `linear-gradient(#1E1E1E, #1E1E1E) padding-box, 
        linear-gradient(180deg, ${lgFrom}, ${lgTo}) border-box`,
        "> *": {
          background: `linear-gradient(315deg, ${lgFrom}, ${lgTo})`,
          backgroundClip: "text",
        },
      },
    },
  };
};
