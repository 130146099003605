import { Text, Box, useColorModeValue, Flex, Image } from "@chakra-ui/react";
import { AiOutlineRetweet } from "react-icons/ai";
import { MetaCard } from "./metaCard";

interface Props {
  type: "tweet" | "metacard" | "";
  data: any;
  isRetweet?: boolean;
}

export function TwitterCard({ type, data, isRetweet }: Props) {
  if (type === "tweet" && Object.keys(data)?.length > 0) {
    return (
      <Box>
        <Box
          border="1px"
          borderColor={useColorModeValue("border.lightMode.light", "border.darkMode.light")}
          rounded="lg"
          mt="-2"
          p="2"
        >
          <Flex alignItems="center">
            <Image
              src={data.user.profile_image_url_https}
              fallbackSrc="/assets/resources/emptyProfile.png"
              alt="profile image"
              borderRadius="50%"
              h="7"
              w="7"
            />
            <Text fontWeight="700" fontSize="sm" ml="1" noOfLines={1} wordBreak="break-all">
              {data.user.name}
            </Text>
            <Text
              fontWeight="500"
              fontSize="sm"
              color={useColorModeValue("gray.500", "gray.400")}
              ml="1"
              noOfLines={1} wordBreak="break-all"
            >
              @{data.user.screen_name}
            </Text>
          </Flex>
          <Text fontSize="sm">{data.full_text}</Text>
        </Box>
        {isRetweet && (
          <Flex alignItems="center" justifyContent="center" fontSize="sm">
            <AiOutlineRetweet />
            <Text ml="1">This tweet will be a retweeted</Text>
          </Flex>
        )}
      </Box>
    );
  } else if (type === "tweet") {
    return (
      <Box
        border="1px"
        borderColor={useColorModeValue("border.lightMode.light", "border.darkMode.light")}
        rounded="lg"
        mt="1"
        p="2"
      >
        <Text pl="3">This Tweet is unavailable.</Text>
      </Box>
    );
  }
  return <MetaCard metadata={data} />;
}
