import { defineStyleConfig } from "@chakra-ui/react";
import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { color } from "theme/names";

// https://chakra-ui.com/docs/components/input/theming
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    bg: "white",
    width: "100%",
    minWidth: 0,
    outline: 0,
    position: "relative",
    appearance: "none",
    transitionProperty: "common",
    transitionDuration: "normal",
    fontWeight: "400",
    _disabled: {
      // opacity: 0.4,
      cursor: "not-allowed",
      color: "text.lightMode.inactive",
    },
    _dark: {
      bg: "#1E1E1E"
    },
  },
});

const variantOutline = definePartsStyle((props) => {
  return {
    addon: {
      color: color.primary.lightMode.default,
      bg: "white",
      _dark: {
        bg: "#1E1E1E",
        color: color.primary.darkMode.default
      }
    },
    field: {
      bg: "white",
      borderRadius: "10px",
      borderColor: "border.lightMode.light",
      color: "text.lightMode.standard",
      _placeholder: {
        color: "text.lightMode.inactive",
      },
      _disabled: {
        boxShadow: "none",
        color: "text.lightMode.light",
        opacity: 1,
        // color: "gray.500",

        background: "background.lightMode.disabled",
      },
      _invalid: {
        boxShadow: "none",
        borderColor: "alert.lightMode.default",
      },
      _hover: {
        boxShadow: "none",
        borderColor: "primary.lightMode.default",
        _disabled: {
          borderColor: "border.lightMode.light",
        },
      },
      _focus: {
        boxShadow: "0px 0px 0px 3px rgba(10, 102, 194, 0.15)",
        borderColor: "primary.lightMode.default",
      },
      _dark: {
        bg: "#1E1E1E",
        color: "gray.200",
        background: "transparent",
        borderColor: "gray.600",
        _placeholder: {
          color: "gray.500",
        },
        _disabled: {
          boxShadow: "none",
          color: "gray.500",
          background: "transparent",
          _disabled: {
            borderColor: "gray.600",
          },
        },
        _invalid: {
          boxShadow: "none",
          borderColor: "alert.lightMode.default",
        },
        _hover: {
          boxShadow: "none",
          borderColor: "gray.500",
        },
        _focus: {
          boxShadow: "0px 0px 0px 3px rgba(10, 102, 194, 0.15)",
          borderColor: "primary.lightMode.default",
        },
      },
    },
  };
});

export const Input = defineMultiStyleConfig({
  baseStyle,
  variants: { outline: variantOutline },
  //   sizes,
  defaultProps: {
    variant: "outline",
    size: "md",
    colorScheme: "primaryChakraColorScheme",
    // @ts-ignore
    // focusBorderColor: "primary.lightMode.default",
  },
});