import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
    theme,
    Button,
    Box,
    Textarea,
    Text,
    Spinner,
    VStack,
    useColorModeValue,
    Flex,
    Checkbox,
    useMediaQuery
} from "@chakra-ui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { GrView } from 'react-icons/gr'
import { FiShare2 } from 'react-icons/fi'
import Thread from "../tweets/masonry/thread";
import { TweetContext } from "../../context/tweetContext";
import { getAccount, updateUser } from "../../utils/sessionHelper";
import { useFirebaseUser } from "../../utils/useFirebaseUser";
import { ViewIcon } from 'components/icons/ViewIconComponent';
import { textStyle } from 'theme/names';
import { colors } from 'theme/colors/colors';

export function ThreadTweetPreviewSidebar({
    textState,
    alwaysShowSidebar,
    handleSetShowSidebar,
    disablePreview,
    disableThread,
    selectTweetInThread = undefined,
    id,
}) {

    const tweetContext: any = React.useContext(TweetContext);
    const threadBorderColor = useColorModeValue(colors.border.lightMode.light, colors.border.darkMode.light)
    // const threadBorderColor = useColorModeValue(theme.colors.gray[100], theme.colors.gray[600])
    const threadBg = useColorModeValue("white", "#1E1E1E");
    const { isFullModeOpen } = tweetContext;
    const isThread = textState?.threadTweets?.length > 1;
    const [isMobileScreen] = useMediaQuery("(max-width: 800px)");

    console.log('textState:', textState)
    console.log('id:', id)
    // console.log('tweetContext textState:', tweetContext.textState)

    const idTweet = id || textState?.id;
    console.log('idTweet:', idTweet)

    return (
        <Flex
            position="fixed"
            right={isMobileScreen ? "0px" : "400px"} //transform="translateX(-30%)"
            top={isMobileScreen ? "20px" : "0px"}
            w={isMobileScreen ? "95%" : "370px"}
            h="100%"
            borderLeft={isMobileScreen ? "none" : "solid 1px " + threadBorderColor}
            bg={threadBg}
            // overflow="hidden scroll"
            flexDir="column"
            justifyContent="space-between"
            zIndex={20}
        >
            <Flex
                flexDir="column"
                overflow="hidden scroll"
                height='100%'
                pt={6}
                className="noScrollBar"
                textStyle={textStyle['body.medium.standard']}
            >
                <Flex
                    py={0}
                    px={4}
                    justifyContent={'space-between'}
                    alignItems='center'
                >
                    <Flex
                        justifyContent='flex-start'
                        alignItems='center'
                    >
                        <ViewIcon width={18} height={18} />
                        <Text
                            textStyle={textStyle['h4.bold.standard']}
                            ml={2}>Preview</Text>
                    </Flex>
                    <CopyToClipboard
                        text={"https://tweethunter.io/public?id=" + idTweet}
                        onCopy={() => {
                            if (idTweet)
                                toast.success("Link copied to clipboard");
                            else
                                toast.error("You need to be signed to share preview");
                        }}
                    >
                        <Button
                            variant="invisible" rightIcon={<FiShare2 fontSize="16px" style={{ marginTop: "2px" }} />}>
                            Share
                        </Button>
                    </CopyToClipboard>
                </Flex>

                <Thread
                    selectTweetInThread={selectTweetInThread}
                    threadTweets={textState?.threadTweets}
                    disablePreview={disablePreview}
                    displayImageOptions={true}
                    showGenerateImage={true}
                    showSeeMoreDivider={true}
                    dividerCss={{ width: "100%" }}
                />
            </Flex>

            <Flex flexDir="column" p={5} pb={5} pr={8} fontSize="xs">
                {
                    isThread && !isMobileScreen &&
                    <>
                        <Text fontSize="md">Thread Tip</Text>
                        <Text mt={2}>Add [tweet] to insert the first tweet of your thread into the last tweet and ask readers for a retweet!</Text>
                    </>
                }
                {
                    !isFullModeOpen && !isMobileScreen &&
                    <Checkbox
                        mt={2}
                        size="sm"
                        isChecked={alwaysShowSidebar}
                        onChange={(e) => handleSetShowSidebar()}
                    >
                        Always show preview
                    </Checkbox>
                }
            </Flex>
        </Flex>

    )
}
