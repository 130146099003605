export const filterUniqueByKey = <T>(array: T[], key: keyof T): T[] => {
  const uniqueValues: any[] = [];
  return array.filter(obj => {
    const value = obj[key];
    if (!uniqueValues.includes(value)) {
      uniqueValues.push(value);
      return true;
    }
    return false;
  });
}