import { useState, useEffect, useMemo } from "react";
import {
  Text,
  Box,
  Flex,
  IconButton,
  Icon,
  Divider,
  InputGroup,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useColorModeValue,
  InputLeftElement,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { GRADIENT_COLOR, prompts, SavedAction } from "./utils";
import { CloseLibrary } from "./icons/close-library";
import { BsFillCaretDownFill } from "react-icons/bs";
import { textStyle } from "theme/names";
import { colors } from "theme/colors/colors";
import { ActionCaretRight } from "./icons/ActionCaretRight";
import { hexToHexa } from "utils/helpers";
import { SavedActions } from "./saved-actions";

interface Props {
  onSelect?: (prompt: any) => void;
  searchTerm: string;
  setSearchTerm: (value: any) => void;
  activeIndex: any;
  setActiveIndex: (value: any) => void;
  savedActions: SavedAction[];
  onUpdateSavedActions: (updatedSavedActions: SavedAction[]) => void;
  editorInstance: any;
  imperativeHandleInstance: any;
}

export function ActionLibrary({
  onSelect = () => {},
  searchTerm,
  setSearchTerm,
  activeIndex,
  setActiveIndex,
  savedActions,
  onUpdateSavedActions,
  editorInstance,
  imperativeHandleInstance,
}: Props) {
  const [filteredPrompts, setFilteredPrompts] = useState(prompts);
  const [filteredSavedActions, setFilteredSavedActions] =
    useState<SavedAction[]>(savedActions);

  const promptBg = useColorModeValue("gray.100", "whiteAlpha.200");
  const accordionExpandedBg = useColorModeValue(
    "linear-gradient(91deg, #FFEFF2 0%, #EEF0FF 100%)",
    "#1a202c"
  );

  useEffect(() => {
    if (searchTerm) {
      const filteredList = prompts.filter(
        (p) =>
          (typeof p.label === "string" &&
            p.label.includes(searchTerm.trim())) ||
          p.prompt.includes(searchTerm.trim())
      );
      setFilteredPrompts(filteredList);

      const filteredSavedList = savedActions.filter(
        (a) =>
          a.title.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
          a.prompt.toLowerCase().includes(searchTerm.toLowerCase().trim())
      );
      setFilteredSavedActions(filteredSavedList);
    } else {
      setFilteredPrompts(prompts);
      setFilteredSavedActions(savedActions);
    }
  }, [searchTerm]);

  const categories = useMemo(() => {
    let categoryList = filteredPrompts
      .map((prompt) => prompt.category)
      .filter((x) => !!x);
    return [...new Set(categoryList)];
  }, [filteredPrompts]);

  return (
    <>
      <Box p="4">
        <InputGroup
        // size="sm"
        >
          <Input
            placeholder="Search actions..."
            borderRadius="lg"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            textStyle={textStyle["body.medium.standard"]}
          />
          <InputLeftElement
            textStyle={textStyle["body.medium.light"]}
            children={
              // <SearchIcon />
              <svg
                width="14"
                height="14"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.75 15.2188L11.5625 11.0312C12.4688 9.9375 12.9688 8.53125 12.9688 7C12.9688 3.4375 10.0312 0.5 6.46875 0.5C2.875 0.5 0 3.4375 0 7C0 10.5938 2.90625 13.5 6.46875 13.5C7.96875 13.5 9.375 13 10.5 12.0938L14.6875 16.2812C14.8438 16.4375 15.0312 16.5 15.25 16.5C15.4375 16.5 15.625 16.4375 15.75 16.2812C16.0625 16 16.0625 15.5312 15.75 15.2188ZM1.5 7C1.5 4.25 3.71875 2 6.5 2C9.25 2 11.5 4.25 11.5 7C11.5 9.78125 9.25 12 6.5 12C3.71875 12 1.5 9.78125 1.5 7Z"
                  fill="currentColor"
                />
              </svg>
            }
          />
        </InputGroup>
      </Box>
      {searchTerm ? (
        <Box flex="1 1 0%" overflow="auto" className="noScrollBar">
          {filteredSavedActions.map(({ id, title, prompt }) => (
            <Flex
              key={id}
              _hover={{ bg: promptBg }}
              p="2"
              pl="6"
              cursor="pointer"
              minH="50px"
              alignItems="center"
              onClick={() => onSelect({ prompt })}
            >
              <Text fontWeight="normal" fontSize="sm">
                {title}
              </Text>
            </Flex>
          ))}
          {filteredPrompts.map((prompt, index) => (
            <Flex
              key={typeof prompt.label === "string" ? prompt.label : index}
              _hover={{ bg: promptBg }}
              p="2"
              pl="6"
              cursor="pointer"
              minH="50px"
              alignItems="center"
              onClick={() => onSelect(prompt)}
            >
              <Text fontWeight="normal" fontSize="sm">
                {prompt.label}
              </Text>
            </Flex>
          ))}
        </Box>
      ) : (
        <Accordion
          index={[activeIndex]}
          onChange={(index) => setActiveIndex(index)}
          flex="1 1 0%"
          overflow="auto"
          className="noScrollBar"
          pb="10"
          allowToggle
        >
          <SavedActions
            actions={savedActions}
            onUpdate={onUpdateSavedActions}
            onSelect={(prompt) => onSelect({ prompt })}
            editorInstance={editorInstance}
            imperativeHandleInstance={imperativeHandleInstance}
          />
          {categories.map((category) => (
            <AccordionItem key={category} border="none">
              {({ isExpanded }) => (
                <Box key={category}>
                  <Box bg={isExpanded ? accordionExpandedBg : "inherit"}>
                    <AccordionButton
                      alignItems="center"
                      fontSize="sm"
                      fontWeight="semibold"
                      _expanded={{ bgGradient: GRADIENT_COLOR, bgClip: "text" }}
                      minH="50px"
                      textStyle={textStyle["body.medium.light"]}
                      {...{
                        pl: 6,
                        pr: 6,
                        // color: colors.ai[600],
                        color: "text.lightMode.light",
                        _hover: {
                          color: isExpanded ? undefined : colors.ai[700],
                          bg: isExpanded ? undefined : colors.ai[50],
                        },
                        _dark: {
                          color: "text.darkMode.light",
                          // color: colors.ai[400],
                          _hover: {
                            color: isExpanded ? undefined : colors.ai[200],
                            // bg: isExpanded ? undefined : colors.ai[900],
                            // color: isExpanded ? undefined : colors.ai[100],
                            bg: isExpanded
                              ? undefined
                              : hexToHexa(colors.ai[900], 0.2),
                          },
                        },
                      }}
                    >
                      <Box as="span" flex="1" textAlign="left">
                        {category}
                      </Box>
                      <motion.div
                        animate={{
                          rotate: isExpanded ? 180 : 0,
                        }}
                        transition={{
                          type: "spring",
                          duration: 0.5,
                          bounce: 0,
                        }}
                        style={{ height: "14px" }}
                      >
                        <Icon
                          as={BsFillCaretDownFill}
                          h="3.5"
                          w="3.5"
                          color={isExpanded ? `#606AE2` : "inherit"}
                          opacity={".65"}
                        />
                      </motion.div>
                    </AccordionButton>
                  </Box>

                  <AccordionPanel p="0">
                    {filteredPrompts
                      .filter((x) => x.category === category)
                      .map((prompt, index) => (
                        <Flex
                          key={
                            typeof prompt.label === "string"
                              ? prompt.label
                              : index
                          }
                          fontSize="sm"
                          p="2"
                          pl="8"
                          cursor="pointer"
                          minH="50px"
                          alignItems="center"
                          onClick={() => onSelect(prompt)}
                          textStyle={textStyle["body.medium.light"]}
                          {...{
                            gap: 3,
                            // color: colors.ai[600],
                            // bg: colors.ai[50],
                            color: "text.lightMode.light",
                            _hover: {
                              color: colors.ai[700],
                              bg: colors.ai[50],
                              // bg: colors.ai[200],
                            },
                            _dark: {
                              color: "text.darkMode.light",
                              // color: colors.ai[400],
                              // bg: "transparent",
                              // fontWeight: "500",
                              _hover: {
                                color: colors.ai[200],
                                // bg: colors.ai[900],
                                // color: colors.ai[200],
                                bg: hexToHexa(colors.ai[900], 0.2),
                              },
                            },
                          }}
                        >
                          <Box width="6px" color={colors.ai[300]}>
                            <ActionCaretRight />
                          </Box>
                          <Text
                          // ml={4}
                          >
                            {prompt.label}
                          </Text>
                        </Flex>
                      ))}
                  </AccordionPanel>
                </Box>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      )}
      {filteredPrompts?.length === 0 && filteredSavedActions?.length === 0 ? (
        <Text textAlign="center">No results found!</Text>
      ) : null}
    </>
  );
}
