import { mode } from "@chakra-ui/theme-tools";

export const colors = {
  "th-dark": "linear-gradient(96.86deg, #000000 3.49%, #144462 94.63%)",
  neutral: {
    lightMode: {
      neutral: "#FFFFFF",
    },
    darkMode: {
      neutral: "#1E1E1E",
    },
  },
  border: {
    lightMode: {
      inactive: "#B7BEC6",
      light: "#EFEFEF",
      hover: "#B7BEC6",
      active: "#838990",
    },
    darkMode: {
      inactive: "#4F4F4F",
      light: "#2A292E",
      hover: "#838990",
      active: "#838990",
    },
  },
  background: {
    lightMode: {
      light: "#FBFBFB",
      medium: "#F6F6F6",
      disabled: "#F3F6F9",
    },
    darkMode: {
      light: "#1E2023",
      medium: "#1B1B1B",
      disabled: "#23262B",
    },
  },
  text: {
    lightMode: {
      standard: "#213856",
      light: "#566F8F",
      inactive: "#9E9E9E",
      white: "#FFFFFF",
    },
    darkMode: {
      standard: "#CBCCCD",
      light: "#818181",
      inactive: "#797979",
      white: "#FFFFFF",
    },
  },
  state: {
    lightMode: {
      inactive: "#B7BEC6",
    },
    darkMode: {
      inactive: "#777777",
    },
  },
  primary: {
    lightMode: {
      // https://colorkit.io/shades-tints
      80: "hsl(203, 100%, 98%)",
      90: "hsl(203, 100%, 96%)",
      100: "hsl(203, 100%, 95%)",
      110: "hsl(203, 100%, 95%)",
      120: "hsl(203, 100%, 90%)",
      // TAPLIO 100: "#E8F3FF",
      180: "hsl(203, 100%, 66%)",
      190: "hsl(203, 100%, 60%)",
      200: "hsl(203, 100%, 56%)",
      // TAPLIO 200: "#54A9FF",
      250: "hsl(203, 100%, 45%)",
      260: "hsl(203, 100%, 47.5%)",
      300: "hsl(203, 100%, 36%)",
      default: "hsl(203, 100%, 36%)",
      // TAPLIO  default: "#0A66C2",
      350: "hsl(203, 100%, 30%)",
      400: "hsl(203, 100%, 24%)",
      470: "hsl(203, 100%, 18%)",
      // TAPLIO  400: "#05488B",
      500: "hsl(203, 100%, 15%)",
      // TAPLIO 500: "#002E5B",
      600: "hsl(203, 100%, 12%)",
      700: "hsl(203, 100%, 9%)",
      800: "hsl(203, 100%, 6%)",
      // OLD VALUES
      // 10: "hsl(203, 100%, 98%)",
      // 20: "hsl(203, 100%, 96%)",
      // 30: "hsl(203, 100%, 94%)",
      // 40: "hsl(203, 100%, 92%)",
      // 50: "hsl(203, 100%, 90%)",
      // 100: "hsl(203, 100%, 75%)",
      // 150: "hsl(203, 100%, 60%)",
      // 190: "hsl(203, 100%, 47.5%)",
      // 200: "hsl(203, 100%, 45%)",
      // 250: "hsl(203, 100%, 30%)",
      // default: "hsl(203, 100%, 18%)",
      // 300: "hsl(203, 100%, 18%)",
      // 350: "hsl(203, 100%, 15%)",
      // 400: "hsl(203, 100%, 12%)",
      // 450: "hsl(203, 100%, 9%)",
      // 500: "hsl(203, 100%, 6%)",
    },
    darkMode: {
      100: "hsl(203, 100%, 9%)",
      105: "hsl(203, 100%, 10%)",
      //   100: "#1A2C3F",
      200: "hsl(203, 100%, 23%)",
      210: "hsl(203, 100%, 25%)",
      //   200: "#05488B",
      250: "hsl(203, 100%, 40%)",
      300: "hsl(203, 100%, 48%)",
      default: "hsl(203, 100%, 48%)",
      //   default: "#316BFF",
      390: "hsl(203, 100%, 60%)",
      400: "hsl(203, 100%, 60%)",
      //   400: "#54A9FF",
      450: "hsl(203, 100%, 82%)",
      480: "hsl(203, 100%, 88%)",
      500: "hsl(203, 100%, 96%)",
      //   500: "#E8F3FF",
      // OLD VALUES
      // 10: "hsl(203, 100%, 2%)",
      // 20: "hsl(203, 100%, 4%)",
      // 30: "hsl(203, 100%, 6%)",
      // 40: "hsl(203, 100%, 8%)",
      // 50: "hsl(203, 100%, 10%)",
      // 100: "hsl(203, 100%, 25%)",
      // 150: "hsl(203, 100%, 40%)",
      // 190: "hsl(203, 100%, 52.5%)",
      // 200: "hsl(203, 100%, 55%)",
      // 250: "hsl(203, 100%, 70%)",
      // 300: "hsl(203, 100%, 82%)",
      // default: "hsl(203, 100%, 82%)",
      // 350: "hsl(203, 100%, 85%)",
      // 400: "hsl(203, 100%, 88%)",
      // 450: "hsl(203, 100%, 91%)",
      // 500: "hsl(203, 100%, 94%)",
    },
  },
  ai: {
    pink: "#EC6181",
    blue: "#316BFF",
    50: "#faf7ff",
    100: "#F1EDF7",
    200: "#D8CDEA",
    300: "#BDACDC",
    400: "#A28BCE",
    500: "#876AC0",
    600: "#685396",
    700: "#493A6A",
    800: "#2A213E",
    900: "#0B0812",
    lightMode: {
      // https://colorkit.io/shades-tints
      10: "hsl(260, 100%, 98%)",
      20: "hsl(260, 100%, 96%)",
      30: "hsl(260, 100%, 94%)",
      40: "hsl(260, 100%, 92%)",
      50: "hsl(260, 100%, 90%)",
      100: "hsl(260, 100%, 75%)",
      150: "hsl(260, 100%, 60%)",
      190: "hsl(260, 100%, 47.5%)",
      200: "hsl(260, 100%, 45%)",
      250: "hsl(260, 100%, 30%)",
      default: "hsl(260, 100%, 18%)",
      300: "hsl(260, 100%, 18%)",
      350: "hsl(260, 100%, 15%)",
      400: "hsl(260, 100%, 12%)",
      450: "hsl(260, 100%, 9%)",
      500: "hsl(260, 100%, 6%)",
    },
    darkMode: {
      // https://colorkit.io/shades-tints
      10: "hsl(260, 100%, 2%)",
      20: "hsl(260, 100%, 4%)",
      30: "hsl(260, 100%, 6%)",
      40: "hsl(260, 100%, 8%)",
      50: "hsl(260, 100%, 10%)",
      100: "hsl(260, 100%, 25%)",
      150: "hsl(260, 100%, 40%)",
      190: "hsl(260, 100%, 52.5%)",
      200: "hsl(260, 100%, 55%)",
      250: "hsl(260, 100%, 70%)",
      default: "hsl(260, 100%, 82%)",
      300: "hsl(260, 100%, 82%)",
      350: "hsl(260, 100%, 85%)",
      400: "hsl(260, 100%, 88%)",
      450: "hsl(260, 100%, 91%)",
      500: "hsl(260, 100%, 94%)",
    },
  },
  crm: {
    lightMode: {
      // https://colorkit.io/shades-tints
      10: "hsl(133, 94%, 98%)",
      15: "hsl(133, 94%, 97%)",
      20: "hsl(133, 94%, 96%)",
      30: "hsl(133, 94%, 94%)",
      40: "hsl(133, 94%, 92%)",
      50: "hsl(133, 94%, 90%)",
      100: "hsl(133, 94%, 75%)",
      150: "hsl(133, 94%, 60%)",
      170: "hsl(133, 94%, 50%)",
      190: "hsl(133, 94%, 47.5%)",
      200: "hsl(133, 94%, 45%)",
      220: "hsl(133, 94%, 41%)",
      250: "hsl(133, 94%, 30%)",
      default: "hsl(133, 94%, 18%)",
      300: "hsl(133, 94%, 18%)",
      350: "hsl(133, 94%, 15%)",
      400: "hsl(133, 94%, 12%)",
      450: "hsl(133, 94%, 9%)",
      500: "hsl(133, 94%, 6%)",
    },
    darkMode: {
      // https://colorkit.io/shades-tints
      10: "hsl(133, 94%, 2%)",
      15: "hsl(133, 94%, 3%)",
      20: "hsl(133, 94%, 4%)",
      30: "hsl(133, 94%, 6%)",
      40: "hsl(133, 94%, 8%)",
      50: "hsl(133, 94%, 10%)",
      100: "hsl(133, 94%, 25%)",
      150: "hsl(133, 94%, 40%)",
      170: "hsl(133, 94%, 50%)",
      190: "hsl(133, 94%, 52.5%)",
      200: "hsl(133, 94%, 55%)",
      220: "hsl(133, 94%, 59%)",
      250: "hsl(133, 94%, 70%)",
      default: "hsl(133, 94%, 82%)",
      300: "hsl(133, 94%, 82%)",
      350: "hsl(133, 94%, 85%)",
      400: "hsl(133, 94%, 88%)",
      450: "hsl(133, 94%, 91%)",
      500: "hsl(133, 94%, 94%)",
    },
  },
  secondary: {
    lightMode: {
      100: "#DEFFF1",
      200: "#9DEBCA",
      default: "#7BC8A8",
      400: "#34AB7A",
      500: "#007645",
    },
    darkMode: {
      100: "#31403A",
      200: "##34AB7A",
      default: "#7BC8A8",
      400: "#9DEBCA",
      500: "#DEFFF1",
    },
  },
  info: {
    lightMode: {
      50: "#f4f9ff",
      100: "#E8F3FF",
      200: "#54A9FF",
      default: "#0A66C2",
      400: "#05488B",
      500: "#002E5B",
    },
    darkMode: {
      100: "#1A2C3F",
      200: "#05488B",
      default: "#316BFF",
      400: "#54A9FF",
      500: "#E8F3FF",
    },
  },
  success: {
    lightMode: {
      100: "#E5FCE5",
      200: "#9AE299",
      default: "#31C52E",
      400: "#009E00",
      500: "#007300",
    },
    darkMode: {
      100: "#203220",
      200: "#009E00",
      default: "#31C52E",
      400: "#9AE299",
      500: "#E5FCE5",
    },
  },
  warning: {
    lightMode: {
      100: "#FFF8D3",
      200: "#FFE172",
      default: "#DFB009",
      400: "#D9AA00",
      500: "#916D00",
    },
    darkMode: {
      100: "#453B1A",
      200: "#D9AA00",
      default: "#DFB009",
      400: "#FFE172",
      500: "#FFF8D3",
    },
  },
  alert: {
    lightMode: {
      100: "#FFE7E2",
      200: "#F8B1B1",
      default: "#EC4343",
      400: "#BE0723",
      500: "#8C0000",
    },
    darkMode: {
      100: "#3D2425",
      200: "#BE0723",
      default: "#EC4343",
      400: "#F8B1B1",
      500: "#FFE7E2",
    },
  },
  primaryChakraColorScheme: {
    // https://colorkit.io/shades-tints
    100: "#E7F2FE",
    200: "#AFCFEF",
    300: "#78ACE0",
    400: "#4189D1",
    500: "#0A66C2",
    600: "#074F99",
    700: "#05396E",
    800: "#032343",
    900: "#010D18",
  },
  green: {
    100: "#E5FCE5",
    400: "#31C52E",
  },
  taplio: {
    // https://colorkit.io/shades-tints
    0: "#F9F9FD",
    25: "#F3F3FB",
    50: "#ECEDF8",
    100: "#D5D9EE",
    150: "#C2C6E5",
    200: "#AFB3DC",
    250: "#9CA0D3",
    300: "#898DCA",
    350: "#767AC1",
    400: "#6367B8",
    450: "#5054AF",
    500: "#3D41A6",
    550: "#373793",
    600: "#313183",
    650: "#2B2B73",
    700: "#252563",
    750: "#1F1F53",
    800: "#191943",
    850: "#131333",
    900: "#0D0D23",
    950: "#070713",
  },
  taplioAccent: {
    // https://colorkit.io/shades-tints
    50: "#EAF5FB",
    100: "#D7E9F5",
    150: "#C3E0F1",
    200: "#AFD7ED",
    250: "#9BCEE9",
    300: "#87C5E5",
    350: "#73BCE1",
    400: "#5FB3DD",
    450: "#4BAAD9",
    500: "#37A1D5",
    550: "#3490BD",
    600: "#2E80A8",
    650: "#287093",
    700: "#22607E",
    750: "#1C5069",
    800: "#164054",
    850: "#10303F",
    900: "#0A202A",
    950: "#041015",
  },
  tweethunter: {
    // https://colorkit.io/shades-tints
    0: "#ffffff",
    25: "#eAF4FA",
    50: "#EAF4FA",
    100: "#D2DEE3",
    150: "#B9C6CC",
    200: "#A0AEB5",
    250: "#87969E",
    300: "#6E7E87",
    350: "#556670",
    400: "#3C4E59",
    450: "#233642",
    500: "#144462",
    550: "#123D57",
    600: "#10364C",
    650: "#0E2F41",
    700: "#0C2836",
    750: "#0A212B",
    800: "#081A20",
    850: "#061315",
    900: "#040C0A",
    950: "#010101",
  },
  zinc: {
    50: "#fafafa",
    100: "#f4f4f5",
    200: "#e4e4e7",
    300: "#d4d4d8",
    400: "#a1a1aa",
    500: "#71717a",
    600: "#52525b",
    700: "#3f3f46",
    725: "#39393F",
    750: "#333338",
    775: "#2D2D31",
    800: "#27272a",
    825: "#232326",
    850: "#1F1F22",
    875: "#1B1B1E",
    900: "#18181b",
  },
  zincAlpha: {
    50: "#71717a11",
    100: "#71717a22",
    200: "#71717a33",
    300: "#71717a44",
    400: "#71717a66",
    500: "#71717a88",
    600: "#71717aaa",
    700: "#71717abb",
    800: "#71717acc",
    900: "#71717add",
  },
};
