// import { postsDirectory } from "libs/posts";
import { firebaseClient } from "../firebaseClient";
import { signIn } from "next-auth/client";
import * as analytics from "utils/analytics";
import { setInLocalStorage } from "utils/helpers";
import toast from "react-hot-toast";
import { getAccount } from "utils/sessionHelper";
import { checkTweetValid } from "../controllers/schedule";


export async function pushToCreator(session, isAuthenticated, tweetContext, idUser = undefined, idTweet = undefined) {


    let tweet = tweetContext?.refComposer?.current?.textState();
    let threadTweets = tweet?.text?.split("\n\n\n");

    // if (!(await checkTweetValid(threadTweets, session, tweetContext, tweet, null))) {
    //     return;
    // }

    setInLocalStorage("currentTweetContent", tweet.text);

    let data = {
        tweet: {
            text: tweet.text,
            id: tweet.id,
        },
        twUserName: tweetContext?.incognitoCreator?.screen_name,
        ghostwriter: {
            id: session?.user?.uid,
            twUserName: session?.user?.data?.twUserName,
            image: session?.user?.data?.image,
        },
        org: session?.user?.data?.subscription?.org ?? "",
    } as any;

    if (idUser) data.idUser = idUser;
    if (idTweet) data.idTweet = idTweet;

    // console.log('idTweet:', idTweet)
    // return;

    // let response = await fetch('https://app.taplio.com/api/public/push', {
    let response = await fetch('/api/public/push-to-creator', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
    let json = await response.json();

    if (json?.success) {
        toast.success("Tweet pushed to creator");
        tweetContext.newTweet({ text: "" });
        tweetContext.refresher({});
        setInLocalStorage("currentTweetContent", "");
    }
    else {
        toast.error("An error happened: " + json.error);
    }
}

export async function incognitoSignIn(tweetContext, router) {

    // const url = window?.location?.href;
    // console.log("redict url: " + url);

    // signIn("twitter", {
    //     callbackUrl: url,
    //     // redirect: false,
    // }, {
    //     timezone: "Europe/Paris",
    // })
    analytics.log("incognitoSignIn");
    router.push("https://app.tweethunter.io/sign?redirect=" + encodeURIComponent("https://tweethunter.io/write" + window?.location?.search));
}
