import * as Sentry from "@sentry/nextjs";
import { getToken } from "../firebaseClient";

export const likeTweet = async (session, dataToSend) => {
  try {
    const url = "https://us-central1-ez4cast.cloudfunctions.net/twitterProxy-likeTweet";
    const token = await getToken(session, "likeTweet");
    let res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        tokenuserid: session?.user?.uid ?? "",
      },
      body: JSON.stringify(dataToSend),
    });
    let data = await res.json();
    return data;
  } catch (e) {
    console.log("Error: " + e.message, e);
    Sentry.captureException(e);
  }
};

export const searchUsers = async (session, dataToSend) => {
  try {
    let res = await fetch("https://us-central1-ez4cast.cloudfunctions.net/twitterProxy-searchUsers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    });
    let data = await res.json();
    return data;
  } catch (e) {
    console.log("Error: " + e.message, e);
    Sentry.captureException(e);
  }
};

export const verifyUserCountry = async (countryCode: string) => {
  try {
    const res = await fetch("https://api.ipregistry.co", {
      method: "GET",
      headers: {
        "Authorization": `ApiKey ${process.env.NEXT_PUBLIC_IPREGISTRY_API_KEY}`
      }
    })
    const data = await res.json();

    return data.location.country.code === countryCode;
  } catch (e) {
    console.log("Error: ", e.message, e);
    Sentry.captureException(e);
    return false;
  }
}