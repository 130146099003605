import { useRef, useState } from "react";
import { useSession } from "next-auth/client";
import {
  Flex,
  Image,
  Box,
  HStack,
  ButtonProps,
  Button,
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import { IconType } from "react-icons/lib";
import { VscRefresh } from "react-icons/vsc";
import { MdOutlineModeEdit } from "react-icons/md";
import { AiOutlineSave } from "react-icons/ai";
import { CloseIcon, CheckIcon } from "@chakra-ui/icons";
import { getAccount } from "utils/sessionHelper";
import { StyledText } from "../utils";
import { textStyle } from "theme/names";
import { colors } from "theme/colors/colors";
import { useChatContext } from "context/chatContext";
import { EditorRef } from "../imperative-handle";

interface Props {
  index: number;
  content: string;
  contentFormatted?: string;
  editorText?: string;
  composerContent?: string;
  editorInstance: any;
  imperativeHandleInstance: any;
}

export function UserMessage({
  index,
  content,
  contentFormatted,
  editorText,
  composerContent,
  editorInstance: Editor,
  imperativeHandleInstance: ImperativeHandle,
}: Props) {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [session] = useSession();
  const chatContext: any = useChatContext();
  const textRef: any = useRef(null);
  const editorRef: any = useRef<EditorRef | null>(null);

  const handleTryAgain = () => {
    if (chatContext?.isLoading) {
      toast.error("Wait till the current request finishes");
      return;
    }

    const composerTag = textRef.current?.getElementsByTagName("div")?.[0];
    const composerTagInString = composerTag?.outerHTML;
    const composerText = composerTag?.getAttribute("data-tooltip");

    chatContext?.chatAssistRef?.current?.controllerRef?.current?.abort();
    const messages = chatContext?.chatAssistRef?.current?.messages ?? [];
    const newMessages = [...messages].slice(0, index);
    chatContext?.chatAssistRef?.current?.sendMessage(
      contentFormatted?.replaceAll(composerTagInString, "[composer content]") ||
        content,
      undefined,
      newMessages,
      composerText
    );
  };

  const handleSubmit = () => {
    const newEditorText = editorRef.current?.getTextCustom();
    chatContext?.chatAssistRef?.current?.controllerRef?.current?.abort();
    const messages = chatContext?.chatAssistRef?.current?.messages ?? [];
    const newMessages = [...messages].slice(0, index);

    chatContext?.chatAssistRef?.current?.sendMessage(
      newEditorText,
      undefined,
      newMessages,
      newEditorText?.includes("[composer content]")
        ? composerContent
        : undefined // composerContent = composerContent when the call was made, not the current content in composer
    );
    setIsEditMode(false);
  };

  return (
    <Flex
      key={index}
      className="chat-container"
      alignItems="self-start"
      my={2}
      px="2"
      pt="4"
      pb="2"
      rounded="lg"
    >
      <Image src={getAccount(session)?.image} w="8" h="8" rounded="full" />
      <Flex direction="column" w="-webkit-fill-available">
        <Flex ml="3" rounded="lg" minH="100%" alignItems="center">
          <Flex
            flexDir="column"
            textStyle={textStyle["body.medium.standard"]}
            fontSize="md"
            lineHeight="1.4"
            fontWeight="400"
            w="100%"
          >
            {isEditMode ? (
              <Box w="100%">
                <Editor
                  initialValue={editorText || content}
                  classNames={["edit-sent-msg"]}
                  placeholder="Start typing..."
                >
                  <ImperativeHandle ref={editorRef} />
                </Editor>
              </Box>
            ) : (
              <StyledText
                ref={textRef}
                whiteSpace="pre-line"
                dangerouslySetInnerHTML={{
                  __html: contentFormatted ?? content,
                }}
              />
            )}
          </Flex>
        </Flex>
        <Box h="15px">
          <HStack
            justifyContent="flex-end"
            mt="1"
            pr="2"
            spacing="3"
            sx={{
              display: isEditMode ? "flex" : "none",
              ".chat-container:hover &": {
                display: "flex",
              },
            }}
          >
            {isEditMode ? (
              <>
                <UserActionButton
                  leftIcon={<CloseIcon h="3" w="3" mr="-0.5" mt="0.5" />}
                  color="gray.500"
                  onClick={() => setIsEditMode(false)}
                >
                  Cancel
                </UserActionButton>
                <UserActionButton
                  leftIcon={<CheckIcon h="3" w="3" mr="-0.5" mt="0.5" />}
                  onClick={handleSubmit}
                >
                  Submit
                </UserActionButton>
              </>
            ) : (
              <>
                <UserActionButton
                  icon={MdOutlineModeEdit}
                  onClick={() => setIsEditMode(true)}
                  isDisabled={isSaving}
                >
                  Edit
                </UserActionButton>
                <UserActionButton
                  icon={AiOutlineSave}
                  isDisabled={isSaving}
                  onClick={async () => {
                    setIsSaving(true);
                    await chatContext?.chatAssistRef?.current?.handleSaveAction(
                      editorText
                    );
                    setIsSaving(false);
                  }}
                >
                  Save
                </UserActionButton>
                <UserActionButton
                  icon={VscRefresh}
                  onClick={handleTryAgain}
                  isDisabled={isSaving}
                >
                  Try again
                </UserActionButton>
              </>
            )}
          </HStack>
        </Box>
      </Flex>
    </Flex>
  );
}

interface UserActionButtonProps extends ButtonProps {
  icon?: IconType;
}

function UserActionButton({
  children,
  icon: Icon,
  ...props
}: UserActionButtonProps) {
  return (
    <Button
      size="sm"
      variant="link"
      fontWeight="400"
      color={colors.ai[500]}
      leftIcon={
        Icon && <Icon style={{ marginTop: "3px", marginRight: "-4px" }} />
      }
      {...props}
    >
      {children}
    </Button>
  );
}
