import React, { memo, useEffect, useState } from "react";
import { Box, Divider, Flex, Stack, Text } from "@chakra-ui/layout";
import {
  useColorModeValue,
  Textarea,
  Spinner,
  Select,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import theme from "@chakra-ui/theme";
import { Button, IconButton } from "@chakra-ui/button";

import { FaRegHeart, FaRetweet, FaComment } from "react-icons/fa";
import {
  ExternalLinkIcon,
  Icon,
  ViewOffIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { format, formatDistance } from "date-fns";
import numbro from "numbro";
import styled from "@emotion/styled";
import twitter from "twitter-text";
import { Image } from "@chakra-ui/image";
import { hideTweet, refreshProfilePic } from "../../../controllers/bookmark";
import { generate } from "../../../controllers/variations";
import { FiMoreHorizontal, FiMoreVertical } from "react-icons/fi";
import { CgDanger } from "react-icons/cg";
import { RiCloseFill } from "react-icons/ri";
import { AiFillEye } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import { SiGoogletranslate } from "react-icons/si";
import {
  scheduleTweet,
  checkTweetValid,
  defaultAddToQueue,
  isTweetFromAccount,
  findNextSlot
} from "../../../controllers/schedule";
import * as Sentry from "@sentry/nextjs";
import { v4 as uuidv4 } from "uuid";

import { BiCopy } from "react-icons/bi";
import UserPopup from "../user-popup";
import { useClipboard } from "@chakra-ui/hooks";
import { useSession } from "next-auth/client";

import { motion } from "framer-motion";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import * as analytics from "utils/analytics";
import { postData, useOnClickOutside, cleanTweetForCountChar } from "../../../utils/helpers";
import { CollectionDropdown } from "../../collection-dropdown";
import { firebaseClient, getToken } from "../../../firebaseClient";
import { TweetContext } from "../../../context/tweetContext";
import { BiGhost } from "react-icons/bi";
import autosize from "autosize";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import { getAccount } from "../../../utils/sessionHelper";
import { isAllowed } from "controllers/subscription";
import { useOnboardingSteps } from "components/onboard-panel/useOnboardingSteps";
import { likeTweet } from "controllers/miscellaneous";
import { useFirebaseUser } from "../../../utils/useFirebaseUser";
import { useMiscellaneousContext } from "context/miscellaneousContext";
import { TweetPreview } from "components/tweet-preview";
import { useStats } from "hooks/useStats";
import { color, layerStyle, textStyle, variant } from "theme/names";
import { RetweetIcon } from "components/icons/RetweetIcon";
import { colors } from "theme/colors/colors";
import { HeartIcon } from "components/icons/HeartIcon";
import { SaveIcon } from "components/icons/SaveIcon";
import { MdOutlineBarChart } from "react-icons/md"

const StyledText = styled(Text)`
  a {
    color: ${() => theme.colors.twitter[400]};
  }
`;

const Tweet = ({
  tweet,
  savedTweet = [],
  disableBookmarkButton,
  disableHideButton,
  disableMainHideButton = true,
  refresher = undefined,
  removeTweet = undefined,
  mode = "copy",
  source = "unknown",
  requestVariation = undefined,
  setSelectedTweet = undefined,
  disableRequest,
  isVariation = false,
  disableHeader = false,
  disableFooter = false,
  isEditMode = false,
  disableEditTweet = false,
  disableSubMenu = false,
  disableName = true,
  forceDisplayName = false,
  disableCopy = false,
  disableRetweet = false,
  disableLike = false,
  disableImpressionCount = false,
  isSelectable = false,
  disableCheckmark = true,
  disableVotes = true,
  disableUserPopup = false,
  toggleSelection = undefined,
  disableAddToQueue = true,
  disableHideGeneration = true,
  disableTweetSelection = false,
  disableTranslate = false,
  disableReport = false,
  forceSelectedTweet = undefined as any,
  voteAction = undefined as any,
  overrideRootUrl = undefined as any,
  disablePostOnTwitter = true,
  disableRefreshProfilePic = false,
}) => {
  const { hasCopied, onCopy } = useClipboard(
    // tweet.data.full_text + `\n\nInspired by @${tweet.data.twUserName}`
    tweet.data.full_text
  );
  const [session, loading] = useSession();
  const isAuthenticated = useFirebaseUser();
  const [isSaved, setIsSaved] = React.useState(false);
  // const [isSelectedInternal, setIsSelectedInternal] = React.useState(false);
  const [isSelected, setIsSelected] = React.useState(false);
  const inLists = [
    ...new Set(
      ...savedTweet
        //@ts-ignore
        .filter((x) => x.id_str === tweet.data.id_str)
        // @ts-ignore
        .map((s) => s.lists),
      tweet.data.lists
    ),
  ];
  const initRef = React.useRef(null);

  const [editValue, setEditValue] = React.useState("");

  const [isOpenSubMenu, setIsOpenSubMenu] = React.useState(false);
  const refSubMenu = React.useRef<any>();
  useOnClickOutside(refSubMenu, async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    setIsOpenSubMenu(false);
  });

  const [isOpenBookmark, setIsOpenBookmark] = React.useState(false);
  const refBookmark = React.useRef<any>();
  useOnClickOutside(refBookmark, async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    setIsOpenBookmark(false);
  });

  const [isOpenUserProfile, setIsOpenUserProfile] = React.useState(false);
  const refUserProfile = React.useRef<any>();
  useOnClickOutside(refUserProfile, async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    setIsOpenUserProfile(false);
  });

  const [isOpenPopOver, setIsOpenPopOver] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLoadingVariation, setIsLoadingVariation] = React.useState(false);
  const [nbChar, setNbChar] = React.useState<number>(0);
  const [typingTimeout, setTypingTimeout] = React.useState<any>(undefined);

  const [languageTrans, setLanguageTrans] = React.useState<any>("fr");
  const [isLoadingTrans, setIsLoadingTrans] = React.useState<any>(false);
  const [hasInit, setHasInit] = React.useState(false);

  const [vote, setVote] = React.useState(0);

  const [addedToQueue, setAddedToQueue] = React.useState(false);
  const [isLoadingAddToQueue, setIsLoadingAddToQueue] = React.useState(false);

  const openPopOver = () => setIsOpenPopOver(true);
  const closePopOver = () => setIsOpenPopOver(false);
  const tweetContext = React.useContext<any>(TweetContext);
  const refTextArea = React.useRef<any>();
  const { updateOnboardSteps } = useOnboardingSteps();
  const miscellaneousContext: any = useMiscellaneousContext();
  const mainContainerBg = useColorModeValue("white", "#1E1E1E");
  const mainContainerBorderColor = useColorModeValue(
    "border.lightMode.light",
    "border.darkMode.light"
  );
  const closeIconColor = useColorModeValue("gray.600", "gray.100");
  const userProfileBg = useColorModeValue("white", "#1E1E1E");
  const variationIconColor = useColorModeValue("gray.500", "gray.300");
  const subMenuBg = useColorModeValue("white", "#1E1E1E");

  const { updateStatsCounter } = useStats();

  useEffect(() => {
    if (!hasInit && session?.user?.data) {
      setLanguageTrans(session.user.data.language ?? "fr");
      setHasInit(true);
    }
  }, [isOpenSubMenu]);

  useEffect(() => {
    const isSaved =
      //@ts-ignore
      savedTweet.map((tw) => tw.id_str).includes(tweet.data.id_str) ||
      tweet?.data?.lists?.length;
    setIsSaved(isSaved);
  }, [savedTweet]);

  useEffect(() => {
    if (isEditMode) {
      setEditValue(tweet.data.full_text);
    }
  }, []);

  React.useEffect(() => {
    if (refTextArea?.current) {
      autosize(refTextArea.current);
      return () => {
        autosize.destroy(refTextArea.current);
      };
    } else {
      return () => {
        if (refTextArea?.current) {
          autosize.destroy(refTextArea.current);
        }
      };
    }
  }, []);

  // console.log({inLists});
  // entities = JSON.parse(tweet.data.entities),
  // entities = JSON.parse(tweet.data.entities),
  // tweet.data.extended_entities
  // if (tweet.data.full_text.includes("my first billboard")) {
  //   // console.log(tweet.data);
  //   // console.log(typeof tweet.data.user.entities);
  //   console.log(tweet.data.extended_entities);
  //   console.log(tweet.data.entities);
  //   console.log(tweet.data?.entities?.url?.urls);
  // }

  // if (tweet?.data?.user?.entities && typeof tweet.data.user.entities == "string")
  //   tweet.data.user.entities = JSON.parse(tweet.data.user.entities);

  if (!tweet.data.fullText) {
    // console.log(tweet.data?.extended_entities?.urls ?? (tweet.data?.entities && typeof tweet.data?.entities == "string" ? JSON.parse(tweet.data.entities).urls : tweet?.data?.entities?.urls));
    tweet.data.fullText = twitter.autoLink(
      tweet.data.full_text ?? tweet.data.text,
      {
        //@ts-ignore
        // urlEntities: tweet.data?.extended_entities?.urls,
        // urlEntities: tweet.data?.extended_entities?.urls ?? tweet.data?.extended_entities?.media,
        // urlEntities: tweet.data?.extended_entities?.media,
        urlEntities:
          tweet.data?.extended_entities?.urls ??
          (tweet.data?.entities && typeof tweet.data?.entities == "string"
            ? JSON.parse(tweet.data.entities).urls
            : tweet?.data?.entities?.urls),
        //@ts-ignore
        mentionEntities: tweet.data?.user_mentions,
        usernameIncludeSymbol: true,
        targetBlank: true,
      }
    );

    // regex to remove urls at the end of text
    // tweet.data.fullText = tweet.data.fullText.replace(/\s+\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]/gi, "");
  }

  if (tweet?.data?.created_at?.toDate) {
    tweet.data.created_at = tweet.data.created_at.toDate();
    // Sentry.captureException(new Error("Invalid created_at"));
  }

  if (!tweet.data.fullTextAppended) {
    // tweet.data.fullTextAppended = isVariation ? tweet.data.full_text : tweet.data.full_text + "\n\nInspired by @" + tweet.data.user?.screen_name;
    tweet.data.fullTextAppended = tweet.data.full_text;
  }

  // console.log("render tweet");
  // let isSelected = isSelectedInternal && (!forceSelectedTweet || forceSelectedTweet.id_str === tweet.data.id_str) ;
  // console.log(forceSelectedTweet);

  if (mode === "preview") {
    const tweetData = tweet.data;

    return (
      <Box
        borderWidth={isSelected ? "3px" : "1px"}
        borderColor={isSelected ? "green.300" : mainContainerBorderColor}
        p="3"
        pt="4"
        rounded="lg"
      >
        <TweetPreview
          imageSrc={tweetData.user.profile_image_url_https}
          createdAt={tweetData.created_at}
          name={tweetData.user.name}
          isCreatorYou={false}
          text={tweetData.full_text}
          entities={
            (tweetData.entities && typeof tweetData.entities == "string"
              ? JSON.parse(tweetData.entities)
              : tweetData.entities) ?? { urls: [] }
          }
          tweet={tweetData}
          extended_entities={
            tweetData.extended_entities &&
            typeof tweetData.extended_entities == "string"
              ? JSON.parse(tweetData.extended_entities)
              : tweetData.extended_entities
          }
        />
      </Box>
    );
  }

  const userPhotoAndName = () => {
    // console.log('tweet:', tweet)
    let image = tweet.data.user.profile_image_url_https?.replace(
      "normal",
      "400x400"
    );
    return (
      <Box display="flex">
        <Image
          key={image}
          width={8}
          height={8}
          borderRadius={20}
          ml={1}
          //@ts-ignore
          src={image}
          fallbackSrc="/assets/resources/emptyProfile.png"
          onClick={() => {
            if (!disableUserPopup) setIsOpenUserProfile(!isOpenUserProfile);
          }}
          onError={async (e) => {
            console.log("onError on image loading: " + image, e);
            if (
              !disableRefreshProfilePic &&
              !window?.location?.href?.includes("localhost")
            ) {
              refreshProfilePic(tweet.data, image);
            }
          }}
        />
        {!disableName && (
          <Flex ml={3} flexDirection="column">
            <Text
              fontSize={12}
              color={theme.colors.gray[500]}
              fontWeight="600"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              width="90px"
            >
              {tweet.data.user.name}
            </Text>
            {tweet?.data?.user?.screen_name && (
              <Text
                as="a"
                href={
                  (overrideRootUrl ? overrideRootUrl : "https://twitter.com/") +
                  tweet.data.user.screen_name
                }
                target="_blank"
                fontSize={11}
                color={theme.colors.gray[400]}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                width="90px"
              >
                {"@" + tweet.data.user.screen_name}
              </Text>
            )}
          </Flex>
        )}
      </Box>
    );
  };

  const handleVariations = async () => {
    analytics.log("hit_request_variation", analytics.formatTweet(tweet.data));
    updateOnboardSteps("aiWriting", "schedule");
    // //@ts-ignore
    // tweetContext.requestVariation(tweet.data);

    if (
      session?.user?.data?.skipPopupVariationAuto &&
      (session?.user?.data?.creditsAuto ||
        isAllowed(session.user, "ai_variation"))
    ) {
      setIsLoadingVariation(true);
      let result = await generate(
        session,
        tweet.data,
        1,
        getAccount(session)?.tempLevel
      );
      setIsLoadingVariation(false);

      if (result?.success && result?.data?.variations?.length) {
        let newVariation = result?.data?.variations[0];
        tweetContext.newTweet({ text: newVariation });
        tweetContext.setIsTweetTextChanged(true);
        tweetContext.open();
        toast.success("Variation pasted in tweet composer");
      }
    } else {
      tweetContext.requestVariationAuto(tweet.data);
    }
  };

  const [hoverVariations, setHoverVariations] = useState(false);
  const [hoverCollections, setHoverCollections] = useState(false);
  const [collectionLoading, setCollectionLoading] = useState(false);

  return (
    <Box
      key={tweet.data.id}
      minW="250px"
      bg={mainContainerBg}
      borderWidth={isSelected ? "3px" : "1px"}
      borderColor={isSelected ? "green.300" : mainContainerBorderColor}
      // outline={isSelected ? "5px" : "1px"}
      rounded="lg"
      // p={2}
      // boxShadow="rgba(100, 100, 111, 0.05) 0px 2px 19px 0px;"
      onClick={() => {
        if (isSelectable || toggleSelection) {
          console.log("click tweet");
          // setIsSelectedInternal(!isSelectedInternal);
          if (isSelectable) setIsSelected(!isSelected);
          //@ts-ignore
          toggleSelection && toggleSelection(tweet.data);
        }
      }}
      _hover={
        isSelectable || toggleSelection
          ? // { backgroundColor: "gray.100" } : {}
            {
              backgroundColor: "gray.100",
              boxShadow:
                "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)",
              borderColor: "transparent",
            }
          : {
              boxShadow:
                "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)",
              borderColor: "transparent",
            }
      }
      cursor={isSelectable || toggleSelection ? "pointer" : "unset"}
    >
      <Box css={{ filter: tweet.data.isBlurred && "blur(3px)" }}>
        {!disableHeader && (
          <>
            <Flex p={2} alignItems="center" justifyContent="space-between">
              {isVariation ? (
                <Box display="flex" justifyContent="space-between" w="100%">
                  {userPhotoAndName()}
                  {!disableHideGeneration && (
                    <IconButton
                      aria-label="delete"
                      // color="twitter.600"
                      variant={"secondary"}
                      size="sm"
                      onClick={async () => {
                        //@ts-ignore
                        removeTweet && removeTweet(tweet.data);
                      }}
                      icon={<Icon color={closeIconColor} as={RiCloseFill} />}
                      mr={0}
                    />
                  )}
                  {/* {
                            ["good", "bad"].includes(tweet.data.estimatedPerf) && (
                              <>
                                <IconButton
                                  aria-label="checkmark"
                                  color={tweet.data.estimatedPerf == "good" ? "green.300" : "red.400"}
                                  variant="tertiary"
                                  size="lg"
                                  height="30px"
                                  colorScheme={isSaved ? "gray" : "gray"}
                                  icon={tweet.data.estimatedPerf == "good" ? <BsCheckCircle fontSize="24px" /> : <ImCross fontSize="20px" />}
                                />
                              </>
                            )
                          } */}
                </Box>
              ) : (
                <>
                  <Stack
                    isInline
                    alignItems="center"
                    cursor={mode === "answer" ? "pointer" : "default"}
                    onClick={() => {
                      if (mode !== "answer") {
                        return;
                      }
                      tweetContext.setIsOpen(false);
                      miscellaneousContext.setSidebarPerson(tweet.data.user);
                      miscellaneousContext.setIsPersonSidebarOpen(true);
                    }}
                  >
                    {!disableUserPopup ? (
                      <Box position="relative">
                        {userPhotoAndName()}
                        {isOpenUserProfile && (
                          <Box
                            position="absolute"
                            top="40px"
                            left="0"
                            w="280px"
                            bg={userProfileBg}
                            boxShadow="rgba(100, 100, 111, 0.15) 0px 2px 19px 0px;"
                            rounded="md"
                            zIndex="1"
                            ref={refUserProfile}
                          >
                            <UserPopup
                              user={tweet.data.user}
                              tweet={tweet.data}
                            />
                          </Box>
                        )}
                      </Box>
                    ) : (
                      userPhotoAndName()
                    )}
                    {mode == "answer" ? (
                      <Box>
                        <Text
                          fontSize="md"
                          maxW="180px"
                          noOfLines={1}
                          wordBreak="break-all"
                        >
                          {tweet.data.user.name}
                        </Text>
                        <Flex maxWidth="100px" alignItems="center" mt="-1">
                          <Text mr={1} fontSize="11px">
                            {numbro(tweet.data.user.followers_count)
                              .format({
                                // mantissa: 1,
                                average: true,
                                // totalLength: 3,
                              })
                              .replace(".0", "")}
                          </Text>
                          <Text fontSize="11px" color="gray.400">
                            followers
                          </Text>
                        </Flex>
                      </Box>
                    ) : (
                      <></>
                    )}
                    {forceDisplayName && (
                      <Flex ml={3} flexDirection="column">
                        <Text
                          fontSize={12}
                          color={theme.colors.gray[500]}
                          fontWeight="600"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          width="90px"
                        >
                          {tweet.data.user.name}
                        </Text>
                        {tweet?.data?.user?.screen_name && (
                          <Text
                            as="a"
                            href={
                              "https://twitter.com/" +
                              tweet.data.user.screen_name
                            }
                            target="_blank"
                            fontSize={11}
                            color={theme.colors.gray[400]}
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            width="90px"
                          >
                            {"@" + tweet.data.user.screen_name}
                          </Text>
                        )}
                      </Flex>
                    )}
                  </Stack>
                  <Stack isInline alignItems="center" justifyContent="center">
                    <Link
                      noOfLines={1}
                      wordBreak="break-all"
                      maxWidth="110px"
                      fontSize={12}
                      color={theme.colors.gray[400]}
                      mt={1}
                      mr={1}
                      href={`https://twitter.com/${tweet.data.twUserName}/status/${tweet.data.id_str}`}
                      target="_blank"
                    >
                      {
                        //@ts-ignore
                        // format(new Date(tweet.data.created_at), "Pp")
                        mode == "answer"
                          ? formatDistance(
                              new Date(tweet.data.created_at),
                              new Date(),
                              { addSuffix: true }
                            )
                          : format(
                              new Date(tweet.data.created_at),
                              "MMM dd, yyyy"
                            )
                      }
                    </Link>
                    {!disableRequest && isAuthenticated && (
                      <Box
                        {...{
                          onMouseEnter: () => {
                            setHoverVariations(true);
                          },
                          onMouseLeave: () => {
                            setHoverVariations(false);
                          },
                          onBlur: () => {
                            setHoverVariations(false);
                          },
                          borderRadius: "10px",
                        }}
                      >
                        <IconButton
                          aria-label="AI Variation"
                          // color={variationIconColor}
                          // variant={"solid"}
                          // size="sm"
                          // isLoading={isLoadingVariation}
                          variant={variant.Button.secondaryAI}
                          size="sm"
                          isLoading={isLoadingVariation}
                          onClick={handleVariations}
                          cursor="pointer"
                          icon={
                            <Flex
                              {...{
                                opacity: hoverVariations ? 1 : 0.65,
                              }}
                            >
                              <Icon viewBox="0 0 13 16">
                                <defs>
                                  <linearGradient
                                    id="Gradient"
                                    x1="0"
                                    x2="0"
                                    y1="0"
                                    y2="1"
                                  >
                                    <stop offset="20%" stopColor={"#e86d8a"} />
                                    <stop offset="80%" stopColor={"#527ff0"} />
                                  </linearGradient>
                                </defs>
                                <path
                                  d="M9.69791 0.03125C10.2917 0.03125 10.7292 0.59375 10.5729 1.15625L8.88541 7H11.5729C12.1667 7 12.6667 7.5 12.6667 8.09375C12.6667 8.4375 12.5104 8.71875 12.2604 8.9375L4.16666 15.8125C4.01041 15.9375 3.82291 16 3.60416 16C3.01041 16 2.57291 15.4375 2.72916 14.875L4.41666 9H1.69791C1.13541 9 0.666656 8.53125 0.666656 7.96875C0.666656 7.65625 0.791656 7.34375 1.01041 7.15625L9.13541 0.21875C9.29166 0.09375 9.47916 0 9.69791 0V0.03125ZM8.57291 2.65625L2.91666 7.5H5.41666C5.63541 7.5 5.85416 7.625 6.01041 7.8125C6.13541 8 6.19791 8.25 6.13541 8.46875L4.72916 13.375L10.4479 8.5H7.91666C7.66666 8.5 7.44791 8.40625 7.29166 8.21875C7.16666 8.03125 7.10416 7.78125 7.16666 7.5625L8.57291 2.65625Z"
                                  fill="url(#Gradient)"
                                />
                              </Icon>
                            </Flex>
                          }
                          // colorScheme={isSaved ? "gray" : "gray"}
                          // icon={<HiOutlineLightningBolt size={'1.2em'} />}
                          mr={0}
                        />
                      </Box>
                    )}
                    {/* <Tooltip label={isSaved ? "Add/remove this tweet from collections" : "Save tweet for later access."}> */}
                    <Box>
                      {!disableBookmarkButton && isAuthenticated && (
                        <CollectionDropdown
                          inLists={inLists}
                          refresher={refresher}
                          tweet={tweet.data}
                          isOpenPopOver={isOpenPopOver}
                          closePopOver={closePopOver}
                          setIsSaved={setIsSaved}
                          isOpenBookmark={isOpenBookmark}
                          refBookmark={refBookmark}
                          setCollectionLoading={setCollectionLoading}
                          trigger={
                            <Box
                              {...{
                                onMouseEnter: () => {
                                  setHoverCollections(true);
                                },
                                onMouseLeave: () => {
                                  setHoverCollections(false);
                                },
                                onBlur: () => {
                                  setHoverCollections(false);
                                },
                                borderRadius: "10px",
                              }}
                            >
                              <IconButton
                                aria-label="Bookmark"
                                variant={"secondaryBookmark"}
                                size="sm"
                                onClick={async () => {
                                  // openPopOver();
                                  setIsOpenBookmark(!isOpenBookmark);
                                }}
                                isLoading={collectionLoading}
                                cursor="pointer"
                                icon={
                                  <Flex
                                    {...{
                                      opacity: isSaved
                                        ? 1
                                        : hoverCollections
                                        ? 1
                                        : 0.65,
                                    }}
                                  >
                                    <Icon viewBox="0 0 17 17">
                                      {isSaved ? (
                                        <svg
                                          width="17"
                                          height="17"
                                          viewBox="0 0 17 17"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9.32289 0.4375C9.19789 0.1875 8.94789 0 8.63539 0C8.35414 0 8.10414 0.1875 7.97914 0.4375L5.82289 4.84375L1.04164 5.5625C0.760388 5.59375 0.541638 5.8125 0.447888 6.0625C0.354138 6.34375 0.416638 6.625 0.635388 6.84375L4.10414 10.2812L3.26039 15.125C3.22914 15.4062 3.35414 15.7188 3.57289 15.875C3.82289 16.0312 4.10414 16.0625 4.35414 15.9375L8.63539 13.625L12.9166 15.9375C13.1666 16.0625 13.4791 16.0312 13.7291 15.875C13.9479 15.7188 14.0729 15.4062 14.0104 15.125L13.1979 10.2812L16.6666 6.84375C16.8854 6.625 16.9479 6.34375 16.8541 6.0625C16.7604 5.8125 16.5416 5.59375 16.2604 5.5625L11.4791 4.84375L9.32289 0.4375Z"
                                            fill="#ED8936"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          width="17"
                                          height="17"
                                          viewBox="0 0 17 17"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M8.63541 0C8.94791 0 9.19791 0.1875 9.32291 0.4375L11.4792 4.84375L16.2604 5.5625C16.5417 5.59375 16.7604 5.8125 16.8542 6.0625C16.9479 6.34375 16.8854 6.625 16.6667 6.84375L13.1979 10.2812L14.0104 15.125C14.0729 15.4062 13.9479 15.7188 13.7292 15.875C13.4792 16.0312 13.1667 16.0625 12.9167 15.9375L8.63541 13.625L4.35416 15.9375C4.10416 16.0625 3.82291 16.0312 3.57291 15.875C3.35416 15.7188 3.22916 15.4062 3.26041 15.125L4.10416 10.2812L0.635406 6.84375C0.416656 6.625 0.354156 6.34375 0.447906 6.0625C0.541656 5.8125 0.760406 5.59375 1.04166 5.5625L5.82291 4.84375L7.97916 0.4375C8.10416 0.1875 8.35416 0 8.63541 0ZM8.63541 2.46875L7.01041 5.875C6.88541 6.09375 6.69791 6.25 6.44791 6.28125L2.72916 6.8125L5.41666 9.46875C5.60416 9.65625 5.66666 9.90625 5.63541 10.1562L5.01041 13.875L8.29166 12.125C8.51041 12 8.79166 12 9.01041 12.125L12.2917 13.875L11.6667 10.1562C11.6042 9.90625 11.6979 9.65625 11.8854 9.46875L14.5417 6.8125L10.8542 6.28125C10.6042 6.25 10.3854 6.09375 10.2917 5.875L8.63541 2.46875Z"
                                            fill="#ED8936"
                                          />
                                        </svg>
                                      )}
                                    </Icon>
                                  </Flex>
                                }
                                mr={0}
                              />
                            </Box>
                          }
                          // trigger={(
                          //   <IconButton
                          //     aria-label="Bookmark"
                          //     color="twitter.600"
                          //     // variant={"secondary"}
                          //     // variant="tertiary"
                          //     variant={isSaved ? "ghost" : "solid"}
                          //     size="sm"
                          //     onClick={async () => {
                          //       // openPopOver();
                          //       setIsOpenBookmark(!isOpenBookmark);
                          //     }}
                          //     cursor="pointer"
                          //     // as={isSaved ? BsBookmarksFill : BsBookmarks}
                          //     colorScheme={isSaved ? "gray" : "gray"}
                          //     icon={
                          //       <Icon fill={isSaved ? theme.colors.orange[300] : theme.colors.gray[300]}>
                          //         <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><defs></defs><title>star-flat</title><path d="M505.68,207,386.23,326.48,426,485.4a21.38,21.38,0,0,1-8.64,22.82A21.68,21.68,0,0,1,405.32,512,21.06,21.06,0,0,1,392.95,508L256,410.21,119.05,508a21.16,21.16,0,0,1-24.42.21A21.38,21.38,0,0,1,86,485.4l39.78-158.92L6.32,207a21.18,21.18,0,0,1-4.69-23.25,21.43,21.43,0,0,1,19.73-13.12H177.18L236.06,13.87a21.28,21.28,0,0,1,39.89,0l58.87,156.79H490.64a21.43,21.43,0,0,1,19.73,13.12A21.18,21.18,0,0,1,505.68,207Z"/><g><path d="M505.68,207,386.23,326.48,426,485.4a21.38,21.38,0,0,1-8.64,22.82A21.68,21.68,0,0,1,405.32,512,21.06,21.06,0,0,1,392.95,508L256,410.21V0a21.33,21.33,0,0,1,19.94,13.87l58.87,156.79H490.64a21.43,21.43,0,0,1,19.73,13.12A21.18,21.18,0,0,1,505.68,207Z"/></g></svg>
                          //       </Icon>
                          //     }
                          //     mr={0}
                          //   />
                          // )}
                        />
                      )}
                    </Box>
                    {/* </Tooltip> */}
                  </Stack>
                </>
              )}
            </Flex>
            <Divider mb={1.5} />
          </>
        )}
        {isEditMode ? (
          <>
            <Box p={2}>
              <Textarea
                ref={refTextArea}
                resize={"vertical"}
                minHeight="180px"
                minWidth="280px"
                placeholder="The tweet text"
                value={editValue}
                onChange={(e) => {
                  setIsSaving(true);
                  setEditValue(e.target.value);
                  setNbChar(twitter.parseTweet(e.target.value).weightedLength);

                  if (typingTimeout) {
                    clearTimeout(typingTimeout);
                  }

                  let newTimeout: any = setTimeout(async () => {
                    setIsSaving(true);
                    analytics.log(
                      "hit_save_edit_tweet",
                      analytics.formatTweet(tweet.data)
                    );
                    const db = firebaseClient.firestore();
                    tweet.data.variations[tweet.data.index] = e.target.value;
                    console.log(tweet.data.variations);
                    //@ts-ignore
                    await db
                      .collection("variations")
                      .doc(tweet.data.idVariation)
                      .update({ variations: tweet.data.variations });
                    setIsSaving(false);
                  }, 1000);

                  setTypingTimeout(newTimeout);
                }}
              />
              <Box display="flex" justifyContent="space-between" minHeight={10}>
                {isSaving ? (
                  <Box ml={1}>
                    <Spinner size="xs" color="gray.400" />
                  </Box>
                ) : (
                  <Text align="right" color="gray.400" fontSize="sm" as="i">
                    saved
                  </Text>
                )}
                <Text
                  align="right"
                  color={nbChar > 280 ? "red" : "gray"}
                  fontWeight={nbChar > 280 ? "bold" : "regular"}
                  fontSize="sm"
                >
                  {nbChar} / 280
                </Text>
              </Box>
            </Box>
          </>
        ) : !disableCopy ? (
          <CopyToClipboard
            text={tweet.data.fullTextAppended}
            onCopy={() => {
              // analytics.log("hit_copy_clipboard", analytics.formatTweet(tweet.data));
              toast.success("Tweet copied to clipboard", {
                style: {
                  background: "gray.600",
                  color: "#222",
                },
              });
            }}
          >
            <StyledText
              textStyle={textStyle["body.medium.light"]}
              p={1}
              pl={3}
              pr={2}
              pb={2}
              whiteSpace="pre-wrap"
              dangerouslySetInnerHTML={{
                __html: tweet.data.fullText,
              }}
            />
          </CopyToClipboard>
        ) : (
          <StyledText
            textStyle={textStyle["body.medium.light"]}
            p={1}
            pl={3}
            pr={2}
            pb={1.5}
            whiteSpace="pre-wrap"
            dangerouslySetInnerHTML={{
              __html: tweet.data.fullText,
            }}
          />
        )}
        {tweet.data?.extended_entities?.media &&
          tweet.data?.extended_entities?.media?.length > 0 && (
            <Box w="100%" display="flex">
              {tweet.data?.extended_entities?.media.map((media, index) => (
                <Box key={tweet.data.id_str + index}>
                  <Image
                    loading="lazy"
                    objectFit="cover"
                    // p={2}
                    src={media.media_url_https}
                  />
                </Box>
              ))}
            </Box>
          )}
        {!disableFooter && (
          <>
            <Divider mb={2} mt={0} />
            <Flex
              pt={0}
              pb={2}
              px={2}
              alignItems="center"
              justifyContent="space-between"
            >
              {!isVariation ? (
                <Box display="flex" flexDir="row">
                  {!disableLike && (
                    <Button
                      alignItems="center"
                      variant="action"
                      size="sm"
                      px={1}
                      fontSize="sm"
                      {...{
                        color: colors.text.lightMode.light,
                        _dark: { color: colors.text.darkMode.light },
                      }}
                      // mr={3}
                      // ml={1}
                      gap={1}
                      cursor="pointer"
                      onClick={async () => {
                        if (
                          getAccount(session)?.thWriteAccessToken &&
                          tweet?.data?.id_str
                        ) {
                          toast.promise(
                            new Promise(async (resolve, reject) => {
                              let dataToSend = {
                                id_str: tweet.data.id_str,
                                thCustomClientId:
                                  getAccount(session)?.thCustomClientId,
                                thCustomClientSecret:
                                  getAccount(session)?.thCustomClientSecret,
                                thWriteAccessToken:
                                  getAccount(session).thWriteAccessToken,
                                thWriteSecretToken:
                                  getAccount(session).thWriteSecretToken,
                                thApp: getAccount(session).thApp,
                                userId: getAccount(session).idAccount
                              };

                              let data = await likeTweet(session, dataToSend);
                              if (data?.success) resolve(data);
                              else {
                                console.log(
                                  "Error in tweet like: " + data.error
                                );
                                reject();
                              }
                            }),
                            {
                              loading: "liking ... ",
                              success: "Tweet liked",
                              error: "Couldn't like tweet",
                            }
                          );
                        } else if (tweet?.data?.id_str) {
                          toast.error(
                            "We need more authorization to like tweets"
                          );
                          //@ts-ignore
                          tweetContext.onOpenCredentials();
                        } else {
                          toast.error("Not possible to like this tweet");
                        }
                      }}
                    >
                      {/* <Box mr={2} as={FaRegHeart} color={theme.colors.red[300]} /> */}
                      <Box
                        {...{
                          color: colors.alert.lightMode.default,
                          opacity: 0.55,
                          _dark: { color: colors.alert.darkMode.default },
                        }}
                      >
                        <HeartIcon {...{ width: 12, height: 12 }} />
                      </Box>
                      {numbro(
                        tweet?.data?.public_metrics?.like_count ??
                          tweet.data.favorite_count
                      )
                        .format({
                          // mantissa: 1,
                          average: true,
                          totalLength: 1,
                        })
                        .replace(".0", "")}
                    </Button>
                  )}
                  {(tweet.data.retweet_count ||
                    tweet.data.retweet_count === 0) &&
                    !disableRetweet && (
                      <Button
                        alignItems="center"
                        cursor="pointer"
                        gap={1}
                        variant="action"
                        size="sm"
                        px={1}
                        fontSize="sm"
                        {...{
                          color: colors.text.lightMode.light,
                          _dark: { color: colors.text.darkMode.light },
                        }}
                        onClick={() => {
                          //@ts-ignore
                          tweetContext.newTweet({
                            text: `https://twitter.com/${
                              tweet.data.twUserName ??
                              tweet.data.user?.screen_name
                            }/status/${tweet.data.id_str}`,
                          });
                          //@ts-ignore
                          tweetContext.open();
                        }}
                      >
                        {/* <Box mr={2} as={FaRetweet} color={theme.colors.green[300]}/> */}
                        <Box
                          {...{
                            color: colors.crm.lightMode[220],
                            opacity: 0.65,
                            _dark: { color: colors.crm.darkMode[220] },
                          }}
                        >
                          <RetweetIcon {...{ width: 14, height: 14 }} />
                        </Box>
                        {numbro(
                          tweet?.data?.public_metrics
                            ? tweet.data.public_metrics.quote_count +
                                tweet.data.public_metrics.retweet_count
                            : tweet.data.retweet_count
                        )
                          .format({
                            // mantissa: 1,
                            average: true,
                            totalLength: 1,
                          })
                          .replace(".0", "")}
                      </Button>
                    )}
                  {tweet?.data?.impression_count && !disableImpressionCount && (
                    <Button
                        alignItems="center"
                        cursor="text"
                        gap={1}
                        variant="action"
                        size="sm"
                        px={1}
                        fontSize="sm"
                        {...{
                          color: colors.text.lightMode.light,
                          _dark: { color: colors.text.darkMode.light },
                        }}
                      >
                        <Box
                          {...{
                            color: colors.crm.lightMode[220],
                            opacity: 0.65,
                            _dark: { color: colors.crm.darkMode[220] },
                          }}
                        >
                          <MdOutlineBarChart {...{ width: 14, height: 14 }} />
                        </Box>
                        {numbro(
                          tweet?.data?.impression_count ?? tweet?.data?.public_metrics?.impression_count
                        )
                          .format({
                            average: true,
                            totalLength: 1,
                          })
                          .replace(".0", "")}
                      </Button>
                  )}
                  {(tweet.data.commentCount ||
                    tweet.data.commentCount === 0) && (
                    <Flex alignItems="center">
                      <Box
                        mr={2}
                        as={FaComment}
                        color={theme.colors.green[300]}
                      />
                      {numbro(tweet.data.commentCount)
                        .format({
                          // mantissa: 1,
                          average: true,
                          totalLength: 1,
                        })
                        .replace(".0", "")}
                    </Flex>
                  )}
                </Box>
              ) : (
                <Box></Box>
              )}
              <Stack isInline spacing={1} alignItems="center">
                {isEditMode && false && (
                  <Button
                    mr={2}
                    size="sm"
                    variant={"secondary"}
                    fontSize="xs"
                    onClick={async () => {
                      if (nbChar <= 280) {
                        setIsSaving(true);
                        analytics.log(
                          "hit_save_edit_tweet",
                          analytics.formatTweet(tweet.data)
                        );
                        const db = firebaseClient.firestore();
                        console.log(tweet.data.variations);
                        tweet.data.variations[tweet.data.index] = editValue;
                        //@ts-ignore
                        await db
                          .collection("variations")
                          .doc(tweet.data.idVariation)
                          .update({ variations: tweet.data.variations });
                        setIsSaving(false);
                        toast.success("Tweet saved", {
                          style: { background: "gray.600", color: "#222" },
                        });
                      } else {
                        toast.error("Tweet too long", {
                          style: { background: "gray.600", color: "#222" },
                        });
                      }
                    }}
                    isLoading={isSaving}
                    leftIcon={SaveIcon}
                  >
                    {"Save"}
                  </Button>
                )}
                {!disableMainHideButton && (
                  <Tooltip
                    label={
                      typeof window !== "undefined" &&
                      //@ts-ignore
                      window?.navigator?.userAgentData?.platform?.includes(
                        "mac"
                      )
                        ? "Cmd-i"
                        : "Ctrl-i"
                    }
                  >
                    <Button
                      variant={"secondary"}
                      size="sm"
                      fontSize="xs"
                      _hover={{
                        colorScheme: "red",
                        bg: "red.500",
                        color: "white",
                      }}
                      leftIcon={<Icon as={CloseIcon} fontSize="3xs" />}
                      onClick={async () => {
                        analytics.log(
                          "hit_hide_tweet",
                          analytics.formatTweet(tweet.data)
                        );
                        //@ts-ignore
                        removeTweet(tweet.data);
                        hideTweet(tweet.data, session?.user?.uid, -1);
                      }}
                    >
                      {"Hide"}
                    </Button>
                  </Tooltip>
                )}
                {mode == "answer" ? (
                  // <Button
                  //   variant={"secondary"}
                  //   size="sm"
                  //   _hover={{
                  //     colorScheme:"twitter",
                  //     bg:"#1A91DA",
                  //     color:"white",
                  //   }}
                  //   target="_blank"
                  //   as="a"
                  //   //@ts-ignore
                  //   href={`https://twitter.com/${tweet.data.twUserName}/status/${tweet.data.id_str}`}
                  //   onClick={() => {
                  //     analytics.log("hit_answer", analytics.formatTweet(tweet.data));
                  //   }}
                  // >
                  //   {"Answer"}
                  // </Button>
                  <></>
                ) : !disableEditTweet && isAuthenticated ? (
                  // <Tooltip label={session?.user?.uid ? "Open the scheduler and prefil with this tweet" : "You need to be signed up to tweet"}>
                  <Box>
                    <Button
                      size="sm"
                      fontSize="xs"
                      variant={"secondary"}
                      isDisabled={!session?.user?.uid}
                      // target="_blank"
                      // as="a"
                      //@ts-ignore
                      // href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweet.data.fullTextAppended)}`}
                      onClick={() => {
                        analytics.log(
                          "hit_edit_and_tweet",
                          analytics.formatTweet(tweet.data)
                        );
                        // console.log("hit_edit_and_tweet");
                        // console.log("tweet.data.fullTextAppended: " + tweet.data.fullTextAppended);

                        tweet.data.isVariation = isVariation;

                        tweetContext.newTweet({
                          text: tweet.data.fullTextAppended,
                        });
                        tweetContext.setIsTweetTextChanged(true);
                        if (!disableTweetSelection)
                          tweetContext.setSelectedTweet(tweet.data);
                        tweetContext.open();
                      }}
                    >
                      {"Edit & tweet"}
                    </Button>
                  </Box>
                ) : (
                  // </Tooltip>
                  <></>
                )}
                {!disableAddToQueue && (
                  <Box>
                    <Button
                      variant={addedToQueue ? "invisible" : "secondary"}
                      // w="120px"
                      isLoading={isLoadingAddToQueue}
                      ml={1}
                      size="sm"
                      fontSize="xs"
                      display="flex"
                      flexDirection="column"
                      onClick={async () => {
                        analytics.log("hit_tweet_addInQueue_generated", {});

                        const tweetText = cleanTweetForCountChar(tweet.data.full_text)
                        const nbChar = twitter.parseTweet(tweetText).weightedLength
                        if (nbChar > 280) {
                          toast.error("Tweet is too long. Use Edit & Tweet instead")
                          return
                        }

                        setIsLoadingAddToQueue(true);

                        // get the latest next slot date. Solves an issue a user had - https://app.gleap.io/projects/639038d764690426efd894a8/bugs/iCgctpa5QuBNup1g3gvw9hILEjSh5IaslcrC7jNavRyJp06foCjE4fT7qvMXyyY2Hk8RxG
                        let date;
                        {
                          const db = firebaseClient.firestore();
                          let docs = await db.collection("users").doc(getAccount(session).id).collection("tweets").where("status", "==", "scheduled").orderBy("scheduledTime", "asc").get();
                          let scheduleTweets: any[] = [];
                          docs.forEach(doc => {
                              let data = doc.data();
                              if (isTweetFromAccount(data, session)) {
                                  scheduleTweets.push(data);
                              }
                          })
                          date = findNextSlot(session, scheduleTweets);
                        }
                        await defaultAddToQueue(
                          session,
                          tweetContext,
                          tweet.data.full_text,
                          // @ts-ignore
                          date
                        );

                        setAddedToQueue(true);
                        setIsLoadingAddToQueue(false);

                        updateOnboardSteps("schedule", "evergreen");
                        updateStatsCounter("add_to_queue");
                      }}
                      leftIcon={
                        addedToQueue ? (
                          <Icon viewBox="0 0 15 11">
                            <svg
                              width="15"
                              height="11"
                              viewBox="0 0 15 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.9166 0.71875C14.2291 1.03125 14.2291 1.5 13.9166 1.78125L5.66663 10.0312C5.38538 10.3438 4.91663 10.3438 4.63538 10.0312L0.385376 5.78125C0.072876 5.5 0.072876 5.03125 0.385376 4.71875C0.666626 4.4375 1.13538 4.4375 1.41663 4.71875L5.16663 8.46875L12.8854 0.71875C13.1666 0.4375 13.6354 0.4375 13.9166 0.71875Z"
                                fill="#31C52E"
                              />
                            </svg>
                          </Icon>
                        ) : (
                          <></>
                        )
                      }
                    >
                      <Text
                        color={
                          addedToQueue
                            ? color["success.lightMode.default"]
                            : undefined
                        }
                        _dark={{
                          color: addedToQueue
                            ? color["success.darkMode.default"]
                            : undefined,
                        }}
                      >
                        {addedToQueue ? "Added" : "Add to Queue"}
                      </Text>
                    </Button>
                  </Box>
                )}
                {!disableCheckmark && (
                  <>
                    {/* toto */}
                    {/* <BsCheckCircle fontSize="14px" /> */}
                    <IconButton
                      aria-label="checkmark"
                      color={isSelected ? "green.300" : "gray.200"}
                      variant="tertiary"
                      size="lg"
                      height="30px"
                      colorScheme={isSaved ? "gray" : "gray"}
                      icon={<BsCheckCircle fontSize="24px" />}
                    />
                  </>
                )}
                {!disablePostOnTwitter && (
                  <Box>
                    <Button
                      size="sm"
                      variant="tertiary"
                      fontSize="xs"
                      mr={2}
                      as="a"
                      href={tweet.data.postOnTwitterLink}
                      target="_blank"
                      colorScheme={"twitter"}
                      // w="120px"
                    >
                      {/* <Text>  */}
                      Post on Twitter
                      {/* </Text> */}
                    </Button>
                  </Box>
                )}
                {!disableVotes && (
                  <Flex gap={2}>
                    <IconButton
                      aria-label="upvote"
                      color={vote == -1 ? "red.300" : "gray.400"}
                      variant="tertiary"
                      size="md"
                      // height="30px"
                      icon={
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 9C4 9.5625 3.53125 10 3 9.96875H1C0.4375 9.96875 0 9.53125 0 9V2.03125C0 1.5 0.4375 1.03125 1 1.03125H3C3.53125 1.03125 4 1.46875 4 2.03125V9ZM15.0312 7.1875C15.5938 7.53125 16 8.1875 16 8.90625C16 10.0625 15.0312 11 13.9062 11H10.7812C11.0625 11.75 11.25 12.4688 11.25 12.875C11.25 13.9062 10.4062 15 9.03125 15C6.59375 15 7.4375 12.5625 5.28125 10.8438C5.09375 10.7188 5 10.5 5 10.2812C5 9.875 5.3125 9.53125 5.75 9.53125C6.34375 9.53125 7.5 10.6875 8.28125 12.625C8.59375 13.5 8.65625 13.5 9.03125 13.5C9.5625 13.5 9.75 13.125 9.75 12.875C9.75 12.625 9.5 11.625 8.9375 10.5625C8.875 10.4688 8.84375 10.3438 8.84375 10.2188C8.84375 9.8125 9.1875 9.5 9.59375 9.5H13.9062C14.2188 9.5 14.5 9.21875 14.5 8.90625C14.5 8.625 14.25 8.34375 13.9375 8.34375C13.5312 8.3125 13.2188 7.96875 13.2188 7.59375C13.2188 7.09375 13.5938 7.09375 13.5938 6.6875C13.5938 6.40625 13.375 6.15625 13.0938 6.09375C12.8438 6.0625 12.5 5.8125 12.5 5.375C12.5 5 12.6875 4.96875 12.6875 4.6875C12.6875 3.96875 11.75 4.25 11.75 3.40625C11.75 3.25 11.8125 3.21875 11.8125 3.09375C11.8125 2.78125 11.5312 2.5 11.2188 2.5H9.5625C8.75 2.5 7.96875 2.78125 7.34375 3.25L6.1875 4.125C6.0625 4.21875 5.90625 4.28125 5.75 4.28125C5.3125 4.28125 5 3.90625 5 3.53125C5 3.28125 5.09375 3.0625 5.28125 2.90625L6.4375 2.0625C7.34375 1.375 8.4375 1.03125 9.5625 1.03125H11.2188C12.3125 1.03125 13.2188 1.875 13.2812 2.96875C13.8438 3.34375 14.1875 3.96875 14.1875 4.6875C14.1875 4.78125 14.1875 4.875 14.1875 4.96875C14.7188 5.34375 15.0938 5.96875 15.0938 6.6875C15.0938 6.84375 15.0625 7.03125 15.0312 7.1875Z"
                            fill="currentColor"
                          />
                        </svg>
                      }
                      // icon={<AiOutlineDislike fontSize="24px" />}
                      onClick={() => {
                        if (vote === -1) {
                          setVote(0);
                        } else {
                          setVote(-1);
                        }
                        voteAction(tweet.data, -1);
                      }}
                    />
                    <IconButton
                      aria-label="upvote"
                      color={vote == 1 ? "green.300" : "gray.400"}
                      variant="tertiary"
                      size="md"
                      // height="30px"
                      icon={
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 6C3.53125 6 4 6.46875 4 7V13.9375C4 14.4688 3.53125 14.9062 3 14.9062H1C0.4375 14.9062 0 14.4688 0 13.9375V6.96875C0 6.4375 0.4375 6 1 6H3ZM16 7.09375C16 7.84375 15.5938 8.46875 15.0312 8.84375C15.0625 9.03125 15.0938 9.1875 15.0938 9.34375C15.0938 10.0625 14.7188 10.6875 14.1875 11.0625C14.1875 11.1562 14.1875 11.25 14.1875 11.3438C14.1875 12.0625 13.8438 12.6875 13.2812 13.0625C13.2188 14.1562 12.3125 15 11.2188 15H9.5625C8.4375 15 7.34375 14.6562 6.4375 13.9688L5.28125 13.125C5.09375 12.9688 4.96875 12.75 4.96875 12.5C4.96875 12.125 5.3125 11.75 5.75 11.75C5.90625 11.75 6.0625 11.8125 6.1875 11.9062L7.34375 12.7812C7.96875 13.25 8.75 13.5312 9.5625 13.5312H11.2188C11.5312 13.5312 11.8125 13.25 11.8125 12.9375C11.8125 12.8125 11.75 12.7812 11.75 12.625C11.75 11.7812 12.6875 12.0625 12.6875 11.3438C12.6875 11.0625 12.5 11 12.5 10.6562C12.5 9.71875 13.5938 10.125 13.5938 9.34375C13.5938 8.9375 13.2188 8.9375 13.2188 8.4375C13.2188 8.0625 13.5312 7.71875 13.9375 7.6875C14.25 7.6875 14.5 7.40625 14.5 7.09375C14.5 6.78125 14.2188 6.53125 13.875 6.5H9.59375C9.1875 6.5 8.84375 6.21875 8.84375 5.78125C8.84375 5.65625 8.875 5.53125 8.9375 5.40625C9.5 4.40625 9.75 3.40625 9.75 3.15625C9.75 2.90625 9.5625 2.53125 9.03125 2.53125C8 2.53125 8.59375 4.4375 6.1875 6.34375C6.0625 6.46875 5.90625 6.5 5.75 6.5C5.3125 6.5 5 6.15625 5 5.75C5 4.90625 5.875 5.375 6.875 2.84375C7.1875 2.03125 7.59375 1.03125 9.03125 1.03125C10.4062 1.03125 11.25 2.09375 11.25 3.15625C11.25 3.5625 11.0625 4.25 10.7812 5.03125H13.9062C15.0312 5.03125 16 5.96875 16 7.09375Z"
                            fill="currentColor"
                          />
                        </svg>
                      }
                      // icon={<AiOutlineLike fontSize="24px" />}
                      onClick={() => {
                        if (vote === 1) {
                          setVote(0);
                        } else {
                          setVote(1);
                        }
                        voteAction(tweet.data, 1);
                      }}
                    />
                  </Flex>
                )}
                {!disableSubMenu && (
                  <Box position="relative">
                    <IconButton
                      aria-label="Bookmark"
                      // color="twitter.600"
                      // variant="tertiary"
                      variant="secondary"
                      size="sm"
                      // height="30px"
                      // width="10px"
                      // colorScheme={isSaved ? "gray" : "gray"}
                      icon={<FiMoreHorizontal fontSize="12px" />}
                      // mr={1}
                      onClick={() => {
                        setIsOpenSubMenu(!isOpenSubMenu);
                      }}
                    />
                    {isOpenSubMenu && (
                      <Box
                        position="absolute"
                        top="40px"
                        right="0"
                        // bg={subMenuBg}
                        // boxShadow="rgba(100, 100, 111, 0.15) 0px 2px 19px 0px;"
                        shadow="lg"
                        rounded="md"
                        zIndex="1"
                        ref={refSubMenu}
                        layerStyle={layerStyle["bg.border.rounded"]}
                      >
                        <Stack
                          p={2}
                          justifyContent="left"
                          textAlign="left"
                          justifySelf="left"
                        >
                          {tweet?.data?.id_str && tweet?.data?.twUserName && (
                            <CopyToClipboard
                              text={`https://twitter.com/${tweet.data.twUserName}/status/${tweet.data.id_str}`}
                              onCopy={() => {
                                analytics.log(
                                  "hit_copy_url_clipboard",
                                  analytics.formatTweet(tweet.data)
                                );
                                setIsOpenSubMenu(false);
                                toast.success("Tweet URL copied to clipboard");
                              }}
                            >
                              <Button
                                variant="action"
                                leftIcon={<BiCopy />}
                                justifyContent="left"
                              >
                                Copy tweet URL
                              </Button>
                            </CopyToClipboard>
                          )}
                          {!isVariation && (
                            <Button
                              variant="action"
                              leftIcon={<ExternalLinkIcon />}
                              as="a"
                              target="_blank"
                              //@ts-ignore
                              href={
                                tweet.data.type == "linkedin"
                                  ? tweet.data.postUrl
                                  : `https://twitter.com/${
                                      tweet.data.twUserName ??
                                      tweet.data.user?.screen_name
                                    }/status/${tweet.data.id_str}`
                              }
                              onClick={() => {
                                analytics.log(
                                  "hit_open_tweet",
                                  analytics.formatTweet(tweet.data)
                                );
                                setIsOpenSubMenu(false);
                              }}
                              justifyContent="left"
                            >
                              {tweet.data.type == "linkedin"
                                ? "See on Linkedin"
                                : "See on Twitter"}
                            </Button>
                          )}
                          {!disableHideButton &&
                            removeTweet &&
                            !isVariation && (
                              <Button
                                variant="action"
                                leftIcon={<ViewOffIcon />}
                                onClick={async () => {
                                  analytics.log(
                                    "hit_hide_tweet",
                                    analytics.formatTweet(tweet.data)
                                  );
                                  //@ts-ignore
                                  removeTweet(tweet.data);
                                  await hideTweet(
                                    tweet.data,
                                    session?.user?.uid,
                                    -1
                                  );
                                  setIsOpenSubMenu(false);
                                }}
                                justifyContent="left"
                              >
                                Hide this tweet
                              </Button>
                            )}
                          {
                            // session?.user?.data?.isBeta && !disableRequest && !isVariation && (
                            // session?.user?.uid && !disableRequest && !isVariation && (
                            //   <Button
                            //     leftIcon={<BiGhost />}
                            //     onClick={async () => {
                            //       analytics.log("hit_request_variation", analytics.formatTweet(tweet.data));
                            //       //@ts-ignore
                            //       tweetContext.requestVariation(tweet.data);
                            //       setIsOpenSubMenu(false);
                            //     }}
                            //     justifyContent="left"
                            //   >
                            //     Request variations
                            //   </Button>
                            // )
                          }
                          {
                            // session?.user?.data?.isAdmin && (
                            isAuthenticated && !disableTranslate && (
                              <Box display="flex" alignItems="center">
                                <Button
                                  // w="200px"
                                  variant="action"
                                  leftIcon={<SiGoogletranslate />}
                                  isLoading={isLoadingTrans}
                                  onClick={async () => {
                                    analytics.log(
                                      "hit_translate",
                                      analytics.formatTweet(tweet.data)
                                    );
                                    setIsLoadingTrans(true);
                                    let response = await fetch(
                                      "/api/translate?text=" +
                                        encodeURIComponent(
                                          tweet.data.full_text
                                        ) +
                                        "&to=" +
                                        languageTrans,
                                      {
                                        method: "GET",
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                      }
                                    );
                                    setIsLoadingTrans(false);
                                    let data = await response.json();
                                    if (data.translation) {
                                      tweetContext.newTweet({
                                        text: data.translation,
                                      });
                                      tweetContext.setSelectedTweet(tweet.data);
                                      tweetContext.open();
                                    } else {
                                      toast.error("Translation failed", {
                                        style: {
                                          background: "gray.600",
                                          color: "#222",
                                        },
                                      });
                                    }
                                  }}
                                  justifyContent="left"
                                >
                                  Translate
                                </Button>
                                <Select
                                  w="50px"
                                  h="100%"
                                  ml={5}
                                  variant="unstyled"
                                  size="sm"
                                  value={languageTrans}
                                  onChange={async (e) => {
                                    e.stopPropagation();
                                    setLanguageTrans(e.target.value);
                                    if (session?.user?.data) {
                                      const db = firebaseClient.firestore();
                                      await db
                                        .collection("users")
                                        .doc(session?.user?.uid)
                                        .update({ language: e.target.value });
                                      session.user.data.language =
                                        e.target.value;
                                    }
                                  }}
                                >
                                  <option value="es">🇪🇸</option>
                                  <option value="fr">🇫🇷</option>
                                  <option value="de">🇩🇪</option>
                                  <option value="it">🇮🇹</option>
                                  <option value="ru">🇷🇺</option>
                                  <option value="id">🇮🇩</option>
                                  <option value="tr">🇹🇷</option>
                                </Select>
                              </Box>
                            )
                          }
                          {session?.user?.data?.isAdmin && (
                            <Button
                              variant="action"
                              leftIcon={<AiFillEye />}
                              onClick={async () => {
                                console.log(tweet.data);
                                toast.success("Tweet pasted in the JS console");
                              }}
                              justifyContent="left"
                            >
                              Debug Tweet (admin)
                            </Button>
                          )}
                          {!disableRequest && isAuthenticated && (
                            <Button
                              variant="action"
                              leftIcon={
                                <Icon viewBox="0 0 13 16">
                                  <defs>
                                    <linearGradient
                                      id="Gradient"
                                      x1="0"
                                      x2="0"
                                      y1="0"
                                      y2="1"
                                    >
                                      <stop
                                        offset="20%"
                                        stopColor={"#e86d8a"}
                                      />
                                      <stop
                                        offset="80%"
                                        stopColor={"#527ff0"}
                                      />
                                    </linearGradient>
                                  </defs>
                                  <path
                                    d="M9.69791 0.03125C10.2917 0.03125 10.7292 0.59375 10.5729 1.15625L8.88541 7H11.5729C12.1667 7 12.6667 7.5 12.6667 8.09375C12.6667 8.4375 12.5104 8.71875 12.2604 8.9375L4.16666 15.8125C4.01041 15.9375 3.82291 16 3.60416 16C3.01041 16 2.57291 15.4375 2.72916 14.875L4.41666 9H1.69791C1.13541 9 0.666656 8.53125 0.666656 7.96875C0.666656 7.65625 0.791656 7.34375 1.01041 7.15625L9.13541 0.21875C9.29166 0.09375 9.47916 0 9.69791 0V0.03125ZM8.57291 2.65625L2.91666 7.5H5.41666C5.63541 7.5 5.85416 7.625 6.01041 7.8125C6.13541 8 6.19791 8.25 6.13541 8.46875L4.72916 13.375L10.4479 8.5H7.91666C7.66666 8.5 7.44791 8.40625 7.29166 8.21875C7.16666 8.03125 7.10416 7.78125 7.16666 7.5625L8.57291 2.65625Z"
                                    fill="url(#Gradient)"
                                  />
                                </Icon>
                              }
                              onClick={handleVariations}
                              justifyContent="left"
                            >
                              Generate variations
                            </Button>
                          )}

                          {!disableBookmarkButton && isAuthenticated && (
                            <Button
                              onClick={async () => {
                                setIsOpenBookmark(!isOpenBookmark);
                                // onClose();
                                // closePopOver()
                                // setIsOpenBookmark(false);
                                setIsOpenSubMenu(!isOpenSubMenu);
                              }}
                              variant="action"
                              leftIcon={
                                <Icon viewBox="0 0 17 17">
                                  {isSaved ? (
                                    <svg
                                      width="17"
                                      height="17"
                                      viewBox="0 0 17 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9.32289 0.4375C9.19789 0.1875 8.94789 0 8.63539 0C8.35414 0 8.10414 0.1875 7.97914 0.4375L5.82289 4.84375L1.04164 5.5625C0.760388 5.59375 0.541638 5.8125 0.447888 6.0625C0.354138 6.34375 0.416638 6.625 0.635388 6.84375L4.10414 10.2812L3.26039 15.125C3.22914 15.4062 3.35414 15.7188 3.57289 15.875C3.82289 16.0312 4.10414 16.0625 4.35414 15.9375L8.63539 13.625L12.9166 15.9375C13.1666 16.0625 13.4791 16.0312 13.7291 15.875C13.9479 15.7188 14.0729 15.4062 14.0104 15.125L13.1979 10.2812L16.6666 6.84375C16.8854 6.625 16.9479 6.34375 16.8541 6.0625C16.7604 5.8125 16.5416 5.59375 16.2604 5.5625L11.4791 4.84375L9.32289 0.4375Z"
                                        fill="#ED8936"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      width="17"
                                      height="17"
                                      viewBox="0 0 17 17"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.63541 0C8.94791 0 9.19791 0.1875 9.32291 0.4375L11.4792 4.84375L16.2604 5.5625C16.5417 5.59375 16.7604 5.8125 16.8542 6.0625C16.9479 6.34375 16.8854 6.625 16.6667 6.84375L13.1979 10.2812L14.0104 15.125C14.0729 15.4062 13.9479 15.7188 13.7292 15.875C13.4792 16.0312 13.1667 16.0625 12.9167 15.9375L8.63541 13.625L4.35416 15.9375C4.10416 16.0625 3.82291 16.0312 3.57291 15.875C3.35416 15.7188 3.22916 15.4062 3.26041 15.125L4.10416 10.2812L0.635406 6.84375C0.416656 6.625 0.354156 6.34375 0.447906 6.0625C0.541656 5.8125 0.760406 5.59375 1.04166 5.5625L5.82291 4.84375L7.97916 0.4375C8.10416 0.1875 8.35416 0 8.63541 0ZM8.63541 2.46875L7.01041 5.875C6.88541 6.09375 6.69791 6.25 6.44791 6.28125L2.72916 6.8125L5.41666 9.46875C5.60416 9.65625 5.66666 9.90625 5.63541 10.1562L5.01041 13.875L8.29166 12.125C8.51041 12 8.79166 12 9.01041 12.125L12.2917 13.875L11.6667 10.1562C11.6042 9.90625 11.6979 9.65625 11.8854 9.46875L14.5417 6.8125L10.8542 6.28125C10.6042 6.25 10.3854 6.09375 10.2917 5.875L8.63541 2.46875Z"
                                        fill="#ED8936"
                                      />
                                    </svg>
                                  )}
                                </Icon>
                              }
                              justifyContent="left"
                            >
                              {isSaved
                                ? "Edit collection"
                                : "Save to collection"}
                            </Button>
                          )}
                          {!disableReport &&
                            !isVariation &&
                            isAuthenticated && (
                              <Button
                                variant="actionDestructive"
                                leftIcon={<CgDanger />}
                                onClick={async () => {
                                  analytics.log(
                                    "report_tweet",
                                    analytics.formatTweet(tweet.data)
                                  );
                                  postData({
                                    url: "user-reportTweet",
                                    data: { tweet: tweet.data },
                                    session,
                                    token: "",
                                  });
                                  toast.success(
                                    "Tweet reported, we will have a look"
                                  );
                                }}
                                justifyContent="left"
                              >
                                Report tweet
                              </Button>
                            )}
                        </Stack>
                      </Box>
                    )}
                  </Box>
                )}
              </Stack>
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Tweet;

export const TweetMemoed = memo(Tweet);
