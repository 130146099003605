import { Flex, Box } from "@chakra-ui/react";
import PulseLoader from "react-spinners/PulseLoader";
import { colors } from "theme/colors/colors";
import { SvgLogoIcon } from "components/logo/LogoNew";

export function LoadingMessage() {
  return (
    <Flex alignItems="self-start" my={2} p="2.5" rounded="lg">
      <Box {...{ w: 8, h: 8 }}>
        <SvgLogoIcon ai />
      </Box>
      <Flex ml="3" rounded="lg" minH="100%" alignItems="center">
        <Flex flexDir="column">
          <PulseLoader
            color={colors.ai.lightMode[100]}
            loading={true}
            size="9"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
