import React from "react";
import NextLink from "next/link";
import { ListItem, ListIcon, Tooltip, Link, Button } from "@chakra-ui/react";
import { MdCheckCircle, MdOutlineCircle } from "react-icons/md";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { TweetContext } from "context/tweetContext";

export type TaskTypes =
  | "generateTweets"
  | "aiInpiration"
  | "searchInspiration"
  | "aiWriting"
  | "schedule"
  | "evergreen"
  | "engage";
export interface Task {
  id: TaskTypes;
  title: string;
  helperText: string;
  isComplete?: boolean;
  link?: string;
  thTokens: number;
  isSkipable?: boolean;
  handleSkip?: () => void;
}

export function CustomListItem({
  id,
  title,
  helperText,
  isComplete,
  link,
  thTokens = 0,
  isSkipable = false,
  handleSkip = () => {},
}: Task) {
  const tweetContext: any = React.useContext(TweetContext);

  const getLink = () => {
    if (link == "compose") {
      return (
        <Link
          onClick={(e) => {
            tweetContext.open();
          }}
          textDecoration="underline"
        >
          {title}
        </Link>
      );
    } else {
      return (
        <Link href={link} textDecoration="underline">
          {title}
        </Link>
      );
    }
  };

  return (
    <ListItem>
      <ListIcon
        as={isComplete ? MdCheckCircle : MdOutlineCircle}
        color="green.500"
      />
      {link ? getLink() : title}
      <Tooltip hasArrow label={helperText}>
        <ListIcon
          as={QuestionOutlineIcon}
          color="gray.500"
          marginInlineStart="2"
        />
      </Tooltip>
      <NextLink href="/tokens" passHref legacyBehavior>
        <Link ml="2" _hover={{ textDecor: "none" }} fontSize="sm" fontWeight="medium">
          +{thTokens} pts
        </Link>
      </NextLink>
      {isSkipable && (
        <Button size="sm" variant="tertiary" onClick={() => handleSkip()}>
          Skip
        </Button>
      )}
    </ListItem>
  );
}
