import { colors } from "theme/colors/colors";
import { radius } from "theme/foundations/radius";
import { secondary } from "./secondary";


export const filter = {
  ...secondary,
  color: colors.primary.lightMode.default,
  bg: colors.primary.lightMode[110],
  borderRadius: radius.sm,
  border: "none",
  fontWeight: "600",
  p: 4,
  _hover: {
    bg: colors.primary.lightMode[120],
  },
  _dark: {
    color: colors.primary.lightMode[110],
    bg: colors.primary.lightMode[500],
    _hover: {
      bg: colors.primary.lightMode[400],
    }
  },
 
  // _dark: {
  //   // color: "text.darkMode.standard",
  //   border: "none",
  //   color: "primary.darkMode.100",
  // },
  // _hover: {
  //   color: "primary.lightMode.default",
  //   border: "none",
  //   // border: "1px solid",
  //   // borderColor: "primary.lightMode.100",
  //   bg: "white",
  //   _dark: {
  //     border: "none",
  //     color: "primary.darkMode.default",
  //     // border: "1px solid",
  //     // borderColor: "primary.darkMode.100",
  //     bg: "#1E1E1E",
  //   },
  // },
  // _active: {
  //   border: "none",
  //   color: "primary.lightMode.default",
  //   bg: "white",
  //   _dark: {
  //     border: "none",
  //     color: "primary.darkMode.default",
  //     bg: "primary.darkMode.default",
  //   },
  // },
};
