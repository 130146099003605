import { secondary } from "./secondary";

export const invisible = 
    {
      ...secondary,
      minW: "40px",
      border: "none",
      color: "text.lightMode.light",
      bg: "transparent",
      _dark: {
        color: "text.darkMode.light",
        bg: "transparent",
      },
      _hover: {
        bg: "transparent",
        color: "text.lightMode.standard",
        _dark: {
          color: "text.darkMode.standard",
        },
      },
      _active: {
        bg: "transparent",
        color: "text.lightMode.standard",
        _dark: {
          color: "text.darkMode.standard",
        },
      },
    }
