import { text } from "theme/foundations/text";

export const tertiary = {
  background: "white",
  p: "0",
  ...text.body.medium.standard.light,
  textDecoration: "underline",
  textUnderlineOffset: "3px",
  textDecorationThickness: "1.5px",
  minW: "fit-content",
  _hover: {
    color: "primary.lightMode.400",
  },
  _active: {
    color: "primary.lightMode.500",
  },
  _disabled: {
    color: "text.lightMode.inactive",
    _hover: {
      color: "text.lightMode.inactive",
    },
  },
  _dark: {
    ...text.body.medium.standard.dark,
    background: "#1E1E1E",
    _hover: {
      borderColor: "border.darkMode.hover",
      color: "primary.darkMode.400",
    },
    _active: {
      color: "primary.darkMode.500",
    },
    _disabled: {
      color: "text.darkMode.inactive",
      _hover: {
        color: "text.darkMode.inactive",
      },
    },
  },
};
export const tertiaryLight = {
  background: "white",
  p: "0",
  ...text.body.medium.light.light,
  textDecoration: "underline",
  textUnderlineOffset: "3px",
  textDecorationThickness: "1.5px",
  minW: "fit-content",
  _hover: {
    color: "primary.lightMode.400",
  },
  _active: {
    color: "primary.lightMode.500",
  },
  _disabled: {
    color: "text.lightMode.inactive",
    _hover: {
      color: "text.lightMode.inactive",
    },
  },
  _dark: {
    ...text.body.medium.light.dark,
    background: "#1E1E1E",
    _hover: {
      borderColor: "border.darkMode.hover",
      color: "primary.darkMode.400",
    },
    _active: {
      color: "primary.darkMode.500",
    },
    _disabled: {
      color: "text.darkMode.inactive",
      _hover: {
        color: "text.darkMode.inactive",
      },
    },
  },
};
export const tertiaryNoBackground = {
  background: "transparent",
  p: "0",
  ...text.body.medium.standard.light,
  textDecoration: "underline",
  textUnderlineOffset: "3px",
  textDecorationThickness: "1.5px",
  minW: "fit-content",
  _hover: {
    color: "primary.lightMode.400",
  },
  _active: {
    color: "primary.lightMode.500",
  },
  _disabled: {
    color: "text.lightMode.inactive",
    _hover: {
      color: "text.lightMode.inactive",
    },
  },
  _dark: {
    background: "transparent",
    ...text.body.medium.standard.dark,
    _hover: {
      borderColor: "border.darkMode.hover",
      color: "primary.darkMode.400",
    },
    _active: {
      color: "primary.darkMode.500",
    },
    _disabled: {
      color: "text.darkMode.inactive",
      _hover: {
        color: "text.darkMode.inactive",
      },
    },
  },
};
