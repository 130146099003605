import React, { useEffect } from "react";
import { firebaseClient } from "firebaseClient";
import { postData } from "../utils/helpers";
import toast from "react-hot-toast";
import { setInLocalStorage } from "../utils/helpers";
import { getRandomInList } from "../utils/tweetUtils";
import { signOut } from "next-auth/client";
import * as Sentry from "@sentry/nextjs";

const localUrl = "http://localhost:5001/discovr-3175b/us-central1/";
const prodUrl = "https://us-central1-discovr-3175b.cloudfunctions.net/";

let accountFields = [
  "description",
  "bio",
  "bioFormatted",
  "who",
  "topics",
  "keywords",
  "tempLevel",
  "thWriteAccessToken",
  "thWriteSecretToken",
  "thApp",
  "twUserName",
  "provider",
  "name",
  "image",
  "needInit",
  "engageAccounts",
  "engagementDistribution",
  "schedule",
  "isAutoRetweet",
  "isAutoPlug",
  "autoRetweetHours",
  "autoRetweetTimes",
  "autoPlugText",
  "autoPlugTrigger",
  "autoPlugList",
  "autoDmText",
  "autoDmTrigger",
  "isAutoDeleteRt",
  "autoDeleteRtHours",
  "preventLinkExpand",
  "preventLinkExpandAutoPlug",
  "isPublicStats",
  "accountForPrediction",
  "autoAddEvergreenTrigger",
  "fanListToNotify",
  "isPushTaplio",
  "lastFetchStatsDate",
  "idParent",
  "idAccount",
  "dateAdded",
  "followers_count",
  "favourites_count",
  "friends_count",
  "statuses_count",
  "isWatchThreads",
  "addSecondaryCta",
  "isSubscriberWebhookActive",
  "ctaButtonText",
  "ctaButtonLink",
  "threadSubscriberWebhooks",
  "suggestions",
  "dynamicBanner",
  "autoDelete",
  "repostAutoDelete",
  "autoDMPinned",
  "fineTunedModel",
  "isDMsPaused",
];

let forceProd = true;
let baseAuthUrl = (!process.env.NODE_ENV || process.env.NODE_ENV === "development") && !forceProd ?
  "http://localhost:3001" :
  "https://auth.tweethunter.io"; // T_TWEETHUTLERBOT_AUTO_REPLY
// "https://auth2.tweethunter.io"; // T_TWEETHUNTERWRITE

export const twitterApps = [
  // {
  //   thApp: "T_TWEETHUNTERWRITE",
  //   url: "https://auth2.tweethunter.io",
  // },
  // {
  //   thApp: "T_SCHEDULERFORTWITTER",
  //   url: "https://auth3.tweethunter.io",
  // },
  {
    thApp: "T_TWITTERGROWTHCONTEST",
    url: "https://auth4.tweethunter.io",
  },
  // {
  //   thApp: "T_APP_MI_LEM",
  //   url: "https://auth6.tweethunter.io",
  // },
  {
    thApp: "T_LEM_AUTH50",
    url: "https://auth50.tweethunter.io",
  },
  {
    thApp: "T_LEM_AUTH51",
    url: "https://auth51.tweethunter.io",
  },
  {
    thApp: "T_LEM_AUTH52",
    url: "https://auth52.tweethunter.io",
  },
];

export const getAuthUrl = (session) => {
  // return getRandomInList(twitterApps, 1)[0].url + "/signth?masterUserId=" + session?.user?.uid;
  // return twitterApps[4].url + "/signth?masterUserId=" + session?.user?.uid;
  return baseAuthUrl + "/signth?masterUserId=" + session?.user?.uid;
}

export function pickRandomTwitterApp() {
  return getRandomInList(twitterApps, 1)[0];
  // return twitterApps[0];
}

export const stopImpersonating = async (session) => {
  console.log(session?.user?.data?.impersonatingOriginUser);
  let response = await fetch('/api/impersonate/stop?id=' + session?.user?.data?.impersonatingOriginUser?.id);
  let data = await response.json();
  if (data?.success)
    location.reload();
  else
    toast.error("Error while stopping impersonation");
}

export const selectAccount = (session, acc, type) => {
  console.log("selectAccount " + acc.id + " / uid: " + session?.user?.uid + " / type: " + type);
  console.log("selectAccount", session);
  if (session?.user?.uid) {
    session.user.selectedAccountType = type;
    session.user.selectedAccountId = acc.id;
    session.user.selectedAccount = null;
    setInLocalStorage("selectedAccountId", acc.id);
    setInLocalStorage("selectedAccountType", type);
    localStorage.removeItem("selectedAccount");
    getAccount(session);
  }
}

export const deleteCredentialsIfExpired = async (session, code) => {
  if (code && [89, 401, 215].includes(code)) {
    updateUser(session, { thWriteAccessToken: "", thWriteSecretToken: "", thApp: "" });
  }
}

export const hasEditRight = (session) => {

  if (getAccount(session)?.shareRole == "editor") {
    toast.error("You don't have the authorization to do this.");
    return false;
  }

  return true;
}

export const updateUser = async (session, data, showToast = false, updateTweetButlerDB = false, conf = {} as any) => {

  const db = firebaseClient.firestore();
  let account = getAccount(session);
  let trueAccount = account;

  // console.log("updateUser of type " + account?.type + " with role " + account.shareRole);
  // console.log(session?.user?.linkAccounts);

  if (!hasEditRight(session))
    return;

  if (account?.type === "owned") {
    let match = getAccount(session)?.accounts?.find(x => x.id == getAccount(session).idAccount);
    if (match) {
      trueAccount = match;
    }
  }

  let toSaveGlobal: any = {};
  let toSaveLocal: any = {};
  for (const [key, value] of Object.entries(data)) {
    if (accountFields.includes(key)) {
      // console.log(`update ${key} with value: ${value}`);
      if (!conf.disableUpdateUser) {
        account[key] = value;
        trueAccount[key] = value;
      }
      toSaveLocal[key] = value;
    }
    else {
      if (!conf.disableUpdateUser)
        session.user.data[key] = value;
      toSaveGlobal[key] = value;
    }

    // update current cached user
    if (!conf.disableUpdateUser && session?.user?.selectedAccount)
      session.user.selectedAccount[key] = value;
  }

  if (account?.type == "owned") {
    console.log("update account");

    if (Object.keys(toSaveLocal)?.length > 0)
      await db.collection("users").doc(session?.user?.uid).collection("accounts").doc(account.idAccount).update(toSaveLocal);

    if (Object.keys(toSaveGlobal)?.length > 0)
      await db.collection("users").doc(session?.user?.uid).update(toSaveGlobal);
    // console.log(getAccount(session));
  }
  else {
    await db.collection("users").doc(getAccount(session).id).update(data);

    if (Object.keys(toSaveLocal)?.length > 0) {
      let matchMainAccount = getAccount(session)?.accounts?.find(x => x.id == getAccount(session).id);
      const hasFullAcccess = getAccount(session)?.shareRole === "full access"
      if (matchMainAccount || hasFullAcccess) {
        // console.log(matchMainAccount);
        await db.collection("users").doc(getAccount(session).id).collection("accounts").doc(getAccount(session).id).update(toSaveLocal);
      }
    }
  }

  if (showToast) {
    toast.success("Data updated", { style: { background: "gray.600", color: "#222" }, duration: 10000 });
  }

  // if (updateTweetButlerDB) {
  //   postData({
  //     url: "user-updateDataTweetButlerDb",
  //     token: "",
  //     data: {
  //       idUser: getAccount(session)?.idAccount,
  //       data: data,
  //       user: getAccount(session),
  //     },
  //   });
  // }
}

export const logout = async (isRedirect = true) => {
  try {
    localStorage.clear();
  }
  catch (e) {
    console.log("localStorage error", e);
    Sentry.captureException(e);
  }
  try {
    await deleteAllCookies();
  }
  catch (e) {
    console.log("deleteAllCookies error", e);
    Sentry.captureException(e);
  }
  if (isRedirect)
    signOut({ callbackUrl: "https://tweethunter.io" });
  else
    signOut({ redirect: false });
}

export const deleteAllCookies = async function () {
  console.log('deleteAllCookies')
  // document.cookie = '__Secure-next-auth.session-token=; path=/; domain=.tweethunter.io; expires=' + new Date(0).toUTCString();
  await fetch("/api/auth/logout");

  // var cookies = document.cookie.split(";");
  // for (var i = 0; i < cookies?.length; i++) {
  //     var cookie = cookies[i];
  //     var eqPos = cookie.indexOf("=");
  //     var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  // }
}

export const getAccountId = (session) => {
  let selectedAccountId: any = session?.user?.selectedAccountId;

  if (!selectedAccountId)
    selectedAccountId = localStorage.getItem("selectedAccountId");

  if (!selectedAccountId)
    selectedAccountId = session.user.uid;

  return selectedAccountId;
}

export const getAccount = (session) => {

  if (!session?.user?.uid)
    return null;

  let selectedAccountId = session?.user?.selectedAccountId;
  // console.log("in session: " + selectedAccountId);

  if (!selectedAccountId && typeof window != "undefined")
    selectedAccountId = localStorage.getItem("selectedAccountId");

  // console.log("selectedAccountId: " + selectedAccountId);

  if (session?.user?.selectedAccount) {
    // console.log("getAccount", session?.user?.selectedAccount);
    // console.log("return from cache");
    return session.user.selectedAccount;
  }
  else if (selectedAccountId) {

    let type = session?.user?.selectedAccountType || (typeof window != "undefined" && localStorage.getItem("selectedAccountType"));

    if (type == "shared") {
      let account = getSharedAccount(session, selectedAccountId);
      // console.log("getSharedAccount", account);
      if (account)
        return account;
    }
    else if (type == "owned") {
      let account = getOwnedAccount(session, selectedAccountId);
      // console.log("getOwnedAccount", account);
      if (account)
        return account;
    }
  }

  if (session?.user?.data) {
    let account = getMainAccount(session);
    // console.log("return self", account);
    return account;
  }

  return null;
}

function getMainAccount(session) {

  if (session?.user?.data?.accounts?.length) {
    // console.log("return matching account");
    // console.log(session.user.linkAccounts);
    let match = session.user.data.accounts.find(x => x.id === session?.user?.uid);
    if (match) {
      let account = session.user.data as any;

      // pull data from match account if they exist
      for (const [key, value] of Object.entries(match)) {
        if (accountFields.includes(key) && value !== undefined && value !== null) {
          account[key] = value;
        }
      }

      account.idAccount = session?.user?.uid;
      account.idParent = session?.user?.uid;
      session.user.selectedAccount = account;
      return account;
    }
  }

  return session.user.data;
}

function getOwnedAccount(session, selectedAccountId) {

  if (session?.user?.data?.accounts) {
    // console.log("return matching account");
    // console.log(session.user.linkAccounts);
    let match = session.user.data.accounts.find(x => x.id === selectedAccountId);
    if (match) {
      session.user.selectedAccountId = selectedAccountId;

      let dataFromMainAccount = {} as any;
      for (const [key, value] of Object.entries(session.user.data)) {
        if (!accountFields.includes(key)) {
          dataFromMainAccount[key] = value;
        }
      }

      let selectedAccount = {
        ...match,
        ...dataFromMainAccount,
        idAccount: match.idAccount ?? session?.user?.data?.id,
        type: "owned",
      }
      console.log("getOwnedAccount", { selectedAccount });
      session.user.selectedAccount = selectedAccount;
      return selectedAccount;
    }
  }

  return null;
}

function getSharedAccount(session, selectedAccountId) {

  if (session?.user?.linkAccounts) {
    // console.log("return matching account");
    // console.log(session.user.linkAccounts);
    let match = session.user.linkAccounts.find(x => x.id === selectedAccountId);
    if (match) {
      session.user.selectedAccount = match;
      session.user.selectedAccountId = selectedAccountId;
      return match;
    }
    else {

      let selectedAccount: any = localStorage.getItem("selectedAccount");
      if (selectedAccount) {
        selectedAccount = JSON.parse(selectedAccount);
        if (selectedAccount) {
          session.user.selectedAccount = selectedAccount;
          session.user.selectedAccountId = selectedAccount.id;
          return selectedAccount;
        }
      }
      // else {
      //   // console.log("no matching account");
      //   let db = firebaseClient.firestore();
      //   db.collection("user").doc(selectedAccountId).get().then((doc) => {
      //     let user = doc.data();
      //     setInLocalStorage("selectedAccount", JSON.stringify(user));
      //     location?.reload && location.reload();
      //   });
      //   return null;
      // }
    }
  }

  return null;
}