import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";
import { text } from "theme/foundations/text";
import { textStyles } from "theme/textStyles/textStyles";


const defaultText = defineStyle({
  ...text.body.medium.light.light,
  _placeholder: {
    ...text.body.medium.inactive.light,
  },
  _dark: {
    ...text.body.medium.light.dark,
    _placeholder: {
      ...text.body.medium.inactive.dark,
    },
  },
});

export const Text = defineStyleConfig({
  baseStyle: {
    // overrides all textStyles, not recommended
  },
  sizes: {
  },
  variants: {
    defaultText,
  },
  defaultProps: {
    // overrides all textStyles, not recommended
    // variant: "defaultText"
  },
});
