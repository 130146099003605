import React, { useState, useEffect } from "react";
import { useSession } from "next-auth/client";
import {
  Box,
  Flex,
  Image,
  Text,
  HStack,
  Icon,
  IconButton,
  Button,
  useColorModeValue,
  Tooltip,
} from "@chakra-ui/react";
import { formatDistance } from "date-fns";
import styled from "@emotion/styled";
import twitter from "twitter-text";
import theme from "@chakra-ui/theme";
import { isNumeric, formatUrl, roundUp } from "utils/helpers";
import { getTweet } from "controllers/search";
import { getAccount } from "utils/sessionHelper";
import { TwitterCard } from "components/twitter-card";
import { ImagePreview } from "./image-preview";
import { FaRegHeart, FaRetweet } from "react-icons/fa";
import { CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import * as analytics from "utils/analytics";
import { hideTweet } from "controllers/bookmark";
import { RiDislikeLine } from "react-icons/ri";
import { BiHide } from "react-icons/bi";
import toast from "react-hot-toast"

const StyledText = styled(Text)`
  a {
    color: ${() => theme.colors.twitter[400]};
  }
`;

type PreviewKeys = "tweetText" | "cardData" | "cardType";

interface Props {
  imageSrc: string;
  createdAt: number;
  name: string;
  followers?: number;
  text: string;
  isCreatorYou: boolean;
  mentions?: string[];
  entities: any;
  tweet: any;
  extended_entities: any;
  isThread?: boolean;
  isParent?: boolean;
  hasParent?: boolean;
  showFooter?: boolean;
  showHide?: boolean;
  showLikeAndHide?: boolean;
  showTimeOfTweet?: boolean;
  handleLike?: () => void;
  handleRt?: () => void;
  removeTweet?: () => void;
}

export function TweetPreview({
  imageSrc,
  createdAt,
  name,
  followers,
  text,
  isCreatorYou,
  mentions = [],
  entities,
  tweet,
  extended_entities,
  isThread = false,
  isParent = false,
  hasParent = false,
  showFooter = true,
  showHide = false,
  showLikeAndHide = false,
  showTimeOfTweet = true,
  handleLike = () => { },
  handleRt = () => { },
  removeTweet = () => { },
}: Props) {
  const [tweetText, setTweetText] = useState<string>(text);
  const [data, setData] = useState<any>();
  const [cardType, setCardType] = useState<"tweet" | "metacard" | "">("");
  const [medias, setMedias] = useState<any>();
  const [isLikingAndHiding, setIsLikingAndHiding] = useState<boolean>(false);
  const [isHiding, setIsHiding] = useState<boolean>(false)
  const [session] = useSession();

  const updateTweet = (key: PreviewKeys, value: any) => {
    // @ts-ignore
    if (tweet.id_str) {
      // @ts-ignore
      if (!tweet.preview) {
        // @ts-ignore
        tweet.preview = {};
      }
      // @ts-ignore
      tweet.preview[key] = value;
    }
  };

  const getTweetPreviewStuff = (key: PreviewKeys) => {
    // @ts-ignore
    if (tweet.id_str) {
      // @ts-ignore
      return tweet.preview[key];
    }
    return null;
  };

  useEffect(() => {
    if (extended_entities && extended_entities.media?.length > 0) {
      const mediaUrls = extended_entities.media.map((m) => m.media_url_https);
      const shortLinks = extended_entities.media.map((m) => m.url);

      let updatedTweetText = tweetText;
      shortLinks.forEach((url) => {
        updatedTweetText = updatedTweetText.replace(url, "").trim();
      });

      if (entities.urls?.length > 0) {
        updatedTweetText = replaceTweetAndLinkUrls(
          updatedTweetText,
          entities.urls
        );
      }
      setTweetText(updatedTweetText);
      setMedias(mediaUrls);
      return;
    }

    if (tweet.preview) {
      setData(getTweetPreviewStuff("cardData"));
      setCardType(getTweetPreviewStuff("cardType"));
      setTweetText(getTweetPreviewStuff("tweetText"));
      return;
    }

    if (!entities || !entities.urls || entities.urls?.length === 0) {
      updateTweet("tweetText", text);
      setTweetText(text);
      return;
    }

    let updatedTweetText = text;
    updatedTweetText = replaceTweetAndLinkUrls(text, entities.urls);

    const urls = entities.urls.map((u) => u.expanded_url);
    const twitterUrls = urls.filter(
      (url) => (url.includes("twitter.com") || url.includes("x.com")) && url.includes("/status/")
    );

    if (twitterUrls?.length > 0) {
      let selectedUrl = twitterUrls[twitterUrls?.length - 1];
      selectedUrl = selectedUrl.split("?")[0];

      const split = selectedUrl.split("/status/");
      if (isNumeric(split[split?.length - 1])) {
        fetchTweet(split[split?.length - 1], updatedTweetText, selectedUrl);
      }
    } else {
      const selectedUrl = urls[urls?.length - 1];

      fetchMetaData(selectedUrl, updatedTweetText);
    }
  }, []);

  const fetchTweet = async (
    tweetId: string,
    tweetText: string,
    url: string
  ) => {
    const tweet = await getTweet(session, tweetId);

    updateTweet("cardData", tweet.tweet || {});
    updateTweet("cardType", "tweet");
    setData(tweet.tweet || {});
    setCardType("tweet");

    removeUrlIfLast(tweetText, url);
  };

  const fetchMetaData = async (url: string, tweetText: string) => {
    try {
      const response = await fetch("/api/metadata", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url }),
      });
      const data = await response.json();
      if (data.success) {
        delete data.success;
        updateTweet("cardType", "metacard");
        updateTweet("cardData", data);
        setCardType("metacard");
        setData(data);

        removeUrlIfLast(tweetText, url);
      } else {
        updateTweet("cardData", null);
        updateTweet("cardType", "");
        setData(null);
        setCardType("");
      }
    }
    catch (e) {
      console.log("Error in fetchMetaData: " + e.message);
      updateTweet("cardData", null);
      updateTweet("cardType", "");
      setData(null);
      setCardType("");
    }
  };

  const removeUrlIfLast = (tweetText: string, url: string) => {
    const trimText = tweetText.trim();
    const formattedUrl = formatUrl(url);
    const startLength = trimText?.length - formattedUrl?.length;

    if (
      trimText.trim().substring(startLength, trimText?.length) === formattedUrl
    ) {
      updateTweet("tweetText", trimText.substring(0, startLength - 1));
      setTweetText(trimText.substring(0, startLength - 1));
    }
  };

  const replaceTweetAndLinkUrls = (text: string, urls: any[]) => {
    let updatedText = text;
    urls.forEach((u) => {
      updatedText = updatedText.replaceAll(u.url, formatUrl(u.expanded_url));
    });

    return updatedText;
  };

  // Mentions stuff
  let mentionsDisplayArray = [...mentions].map((m) =>
    m === getAccount(session)?.twUserName ? "me" : "@" + m
  );

  if (mentionsDisplayArray?.length > 1) {
    mentionsDisplayArray.splice(mentionsDisplayArray?.length - 1, 0, "and"); // to display "and" before last handle
  }
  // ---------- **** ----------- //

  return (
    <Box>
      <Flex>
        <Flex flexDirection="column" justifyContent="start" alignItems="center">
          <Box w="8">
            <Image
              w="8"
              h="8"
              borderRadius="full"
              src={imageSrc}
              fallbackSrc="/assets/resources/emptyProfile.png"
            />
          </Box>
          {isParent && (
            <Box
              m={2}
              bg={useColorModeValue("border.lightMode.light", "border.darkMode.light")}
              h="100%"
              w="2px"
            />
          )}
        </Flex>

        <Box ml="2" w="85%">
          <Box>
            <Flex justifyContent="space-between" alignItems="center" w="100%">
              <Text maxW="150px" fontWeight="700" noOfLines={1} wordBreak="break-all">
                {isCreatorYou ? "You" : name}
              </Text>
              {showTimeOfTweet ? (
                <Text fontSize="xs" color="gray.500">
                  {formatDistance(new Date(createdAt), new Date(), {
                    addSuffix: true,
                  })}
                </Text>
              ) : null}
            </Flex>
            {!isCreatorYou && followers ? (
              <Text w="100%" fontSize="xs" mt="-0.5">
                {roundUp(followers, 0)}{" "}
                {followers > 1 ? "followers" : "follower"}
              </Text>
            ) : null}
          </Box>
          <Box>
            {!isParent &&
              !isThread &&
              hasParent &&
              mentionsDisplayArray?.length > 0 && (
                <Text
                  as="i"
                  fontSize="xs"
                  color={useColorModeValue("gray.500", "gray.400")}
                >
                  replying to {mentionsDisplayArray.join(" ")}
                </Text>
              )}

            <StyledText
              color={isParent ? "gray.500" : "inherit"}
              mt="1"
              mb={isParent ? "5" : "0"}
              dir={getAccount(session)?.isRtl ? "rtl" : "ltr"}
              whiteSpace="pre-wrap"
              dangerouslySetInnerHTML={{
                __html: tweetText
                  ? twitter.autoLinkUsernamesOrLists(tweetText, {
                    usernameIncludeSymbol: true,
                    targetBlank: true,
                  })
                  : tweetText,
              }}
            />
            {medias && medias?.length > 0 ? (
              <Box mt="5" mb={isParent ? "5" : "0"}>
                <ImagePreview medias={medias} />
              </Box>
            ) : cardType && data ? (
              <Box mt="5" mb={isParent ? "5" : "0"}>
                <TwitterCard type={cardType} data={data} />
              </Box>
            ) : null}
          </Box>
          {showFooter && (
            <Flex mt="2">
              <Flex
                justifyContent="space-between"
                mt="3"
                alignItems="center"
                w="100%"
              >
                <HStack spacing="4">
                  <Flex
                    alignItems="center"
                    cursor="pointer"
                    onClick={handleLike}
                  >
                    <Icon as={FaRegHeart} color="red.300" mr="3" />
                    {roundUp(tweet.favorite_count)}
                  </Flex>
                  <Flex alignItems="center" cursor="pointer" onClick={handleRt}>
                    <Icon as={FaRetweet} color="green.300" mr="3" />
                    {roundUp(tweet.retweet_count)}
                  </Flex>
                </HStack>
                <Box>
                  <IconButton
                    aria-label="See link on twitter"
                    variant="tertiary"
                    icon={<ExternalLinkIcon />}
                    size="sm"
                    as="a"
                    target="_blank"
                    href={`https://twitter.com/${tweet.user.screen_name}/status/${tweet.id_str}`}
                  />
                  {showHide && (
                    <Tooltip label={
                      //@ts-ignore
                      typeof window !== "undefined" && window?.navigator?.userAgentData?.platform?.includes("mac") ? "Cmd-i" : "Ctrl-i"}
                    >
                      <Button
                        variant={"secondary"}
                        size="sm"
                        ml="2"
                        _hover={{
                          colorScheme: "red",
                          bg: "red.500",
                          color: "white",
                        }}
                        leftIcon={<Icon as={CloseIcon} fontSize="xs" />}
                        isLoading={isHiding}
                        onClick={async () => {
                          try {
                            setIsHiding(true)
                            analytics.log("hit_hide_tweet", analytics.formatTweet(tweet.data));
                            await hideTweet(tweet, session?.user?.uid, -1);
                            //@ts-ignore
                            removeTweet(tweet);
                          } catch (e) {
                            console.error("Error in hiding tweet: ", e)
                            toast.error("Error in hiding tweet: " + e.message)
                          } finally {
                            setIsHiding(false)
                          }
                        }}
                      >
                        {"Hide"}
                      </Button>
                    </Tooltip>
                  )}
                </Box>
              </Flex>
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
}
