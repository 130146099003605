import { createContext, useState, useContext, useEffect } from "react";
import { useSession } from "next-auth/client";
import { useDisclosure } from "@chakra-ui/react";
import { EnterpriseCampaign } from "hooks/useEnterpriseCampaign";
import { RevampPopup } from "components/popups/revamp-popup";
import { getAccount, updateUser } from "utils/sessionHelper";

const MiscellaneousContext = createContext({});

export function MiscellaneousWrapper({ children }) {
  const [crmEngage, setCrmEngage] = useState<string>("");
  const [crmDm, setCrmDm] = useState<any[]>([]);
  const [isPersonSidebarOpen, setIsPersonSidebarOpen] =
    useState<boolean>(false);
  const [sidebarPerson, setSidebarPerson] = useState<any>();
  const [newListMembers, setNewListMembers] = useState<any[]>([]);
  const [last100Replies, setLast100Replies] = useState<any[]>([]);
  const [campaigns, setCampaigns] = useState<EnterpriseCampaign[]>([]);
  const [hasShownCampaignPopup, setHasShownCampaignPopup] =
    useState<boolean>(false);
  const [refreshPublishedPanel, publishedPanelRefresher] = useState({});
  const [refreshLabels, labelsRefresher] = useState({});
  const [hasCheckedRevamp, setHasCheckedRevamp] = useState<boolean>(false);
  const {
    isOpen: isOpenRevamp,
    onClose: onCloseRevamp,
    onOpen: onOpenRevamp,
  } = useDisclosure();
  const [session] = useSession();

  const sharedState = {
    crmEngage,
    setCrmEngage,
    crmDm,
    setCrmDm,
    isPersonSidebarOpen,
    setIsPersonSidebarOpen,
    sidebarPerson,
    setSidebarPerson,
    newListMembers,
    setNewListMembers,
    last100Replies,
    setLast100Replies,
    campaigns,
    setCampaigns,
    hasShownCampaignPopup,
    setHasShownCampaignPopup,
    refreshPublishedPanel,
    publishedPanelRefresher,
    refreshLabels,
    labelsRefresher,
  };

  useEffect(() => {
    if (!sidebarPerson) {
      setIsPersonSidebarOpen(false);
    }
  }, [sidebarPerson]);

  useEffect(() => {
    if (session && !hasCheckedRevamp) {
      const signUpDate = new Date(getAccount(session)?.dateSignUp?._seconds * 1000);
      const june20 = new Date(2023, 6, 20);
      setHasCheckedRevamp(true);
      if (!getAccount(session)?.hideRevampPopup && (!signUpDate || signUpDate && signUpDate < june20)) {
        setTimeout(onOpenRevamp, 3000);
      }
    }
  }, [session]);

  return (
    <MiscellaneousContext.Provider value={sharedState}>
      {children}
      <RevampPopup
        isOpen={isOpenRevamp}
        onClose={() => {
          updateUser(session, { hideRevampPopup: true });
          onCloseRevamp();
        }}
      />
    </MiscellaneousContext.Provider>
  );
}

export function useMiscellaneousContext() {
  return useContext(MiscellaneousContext);
}
