import React, { memo, useEffect, useState} from 'react';
import {
    Button,
    Stack,
    Box,
    Text,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    IconButton,
    Link,
    useDisclosure,
    useMediaQuery,
    FormLabel,
    ModalFooter,
    VStack,
    useColorModeValue,
    Flex,
    Image,
} from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/tooltip";
import * as analytics from "../../utils/analytics";
import { Profile } from "components/person-sidebar/profile";
import { TweetContext } from "../../context/tweetContext";
import { pushToCreator, incognitoSignIn } from "controllers/incognito";
import { useFirebaseUser } from "utils/useFirebaseUser";
import { useSession } from "next-auth/client";
import { useRouter } from "next/dist/client/router";
import toast from "react-hot-toast";
import { firebaseClient, getToken } from "firebaseClient";
import { AiOutlineDelete } from 'react-icons/ai';
import { ConfirmPopup } from "components/popups/confirmPopup";
import { DeleteIcon } from 'components/icons/DeleteIcon';



export const CreatorListItem = ({creator, index}) => {

    const tweetContext: any = React.useContext(TweetContext);
    const isAuthenticated = useFirebaseUser();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [refresh, refresher] = useState({});
    const [session, loading] = useSession();
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Flex justifyContent="space-between" alignItems="center" w="100%">
            <Flex>
                <Image src={creator.image ?? "/assets/resources/emptyProfile.png"} w="6" h="6" rounded="full" />
                <Text ml={2}>{"@" + creator.twUserName}</Text>
            </Flex>
            <IconButton
                aria-label="remove-creator"
                variant="tertiaryNoBackground"
                size="xs"
                isLoading={isLoading}
                onClick={async () => {
                    onOpen();
                }}
                icon={DeleteIcon}
            />
            <ConfirmPopup
                isOpen={isOpen}
                onClose={onClose}
                title="Remove account from list"
                allowClickBackground={true}
                body={
                    "Are you sure you want to remove this creator from your list?"
                }
                callback={async () => {
                    setIsLoading(true);
                    let linkAccounts = session?.user?.data?.linkAccounts ?? [];
                    linkAccounts.splice(index, 1);
                    const db = firebaseClient.firestore();
                    await db.collection("users").doc(session?.user?.uid).update({linkAccounts});
                    setIsLoading(false);
                    tweetContext.refresher({});
                    toast.success("Creator remove from client list");
                }}
            />
        </Flex>
    )
}