import React, { createContext, useState, useEffect } from 'react';
import {
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalBody,
    ModalContent,
    ModalCloseButton,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    VStack
} from "@chakra-ui/react";
import { useSession } from "next-auth/client";
import { useRouter } from "next/dist/client/router";
import toast from "react-hot-toast";
import { getAccount, updateUser, getAuthUrl } from 'utils/sessionHelper';
import { AuthCustom } from 'components/auth-custom';
import { useFirebaseUser } from "utils/useFirebaseUser";
import { setInLocalStorage } from "utils/helpers";
import { firebaseClient, getToken } from "firebaseClient";

export const MainContext = createContext({});

export function MainWrapper({ children }) {

    const { isOpen: isOpenUpgrade, onOpen: onOpenUpgrade, onClose: onCloseUpgrade } = useDisclosure();
    const { isOpen: isOpenApiKeyAuth, onOpen: onOpenApiKeyAuth, onClose: onCloseApiKeyAuthInternal } = useDisclosure();
    const [hasFetched, setHasFetched] = useState(false);
    const [session, loading] = useSession();
    const router = useRouter();
    const isAuthenticated = useFirebaseUser();

    const onCloseApiKeyAuth = async () => {
      setInLocalStorage("closedApiKeyAuthInternal", "true");
      onCloseApiKeyAuthInternal();
    }
    
    const getFreeMonthCustomAuth = async () => {
      if (session?.user?.data && getAccount(session)?.thApp === "CUSTOM") {
        session.user.data.hasReceivedFreeMonthCustomAuth = true;
        await fetch("/api/getFreeMonthCustomAuth", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tokenUserId: session?.user?.uid,
            token: await getToken(session, "getFreeMonthCustomAuth"),
          }),
        })
      }
    }

    useEffect(() => {
      if (session && isAuthenticated && !hasFetched) {
        setHasFetched(true);
        // if (!getAccount(session)?.thCustomClientId && !localStorage.getItem("closedApiKeyAuthInternal")) {
        //   onOpenApiKeyAuth();
        // }
      }
    }, [session, isAuthenticated])

    useEffect(() => {
      if (session && isAuthenticated && !session?.user?.data?.hasReceivedFreeMonthCustomAuth) {
        getFreeMonthCustomAuth();
      }
    }, [session, isAuthenticated])

    let sharedState = {
        onOpenUpgrade: onOpenUpgrade,
        onCloseUpgrade: onCloseUpgrade,
        onOpenApiKeyAuth
    }


    return (
        <MainContext.Provider value={sharedState}>
            {children}
            <Modal isOpen={isOpenUpgrade} onClose={onCloseUpgrade}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Time to upgrade</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    This feature belongs to a higher plan. Upgrade your account to grow even faster. 
                </ModalBody>

                <ModalFooter p={3} >
                    <Button variant={"primary"} onClick={() => {
                        router.push("/pricing");
                        onCloseUpgrade();
                    }}>
                        Upgrade
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenApiKeyAuth} onClose={onCloseApiKeyAuth} size="lg">
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Use your own API key and get 1 month free</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <AuthCustom />
                  </ModalBody>
                </ModalContent>
            </Modal>
        </MainContext.Provider>
    );
}