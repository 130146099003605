import * as React from "react";
import dynamic from "next/dynamic";
import type { AppProps } from "next/app";
import { ChakraProvider } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast";
import { Provider } from 'next-auth/client';
import { TweetWrapper } from '../context/tweetContext'; 
import { MainWrapper } from '../context/mainContext'; 
import { MiscellaneousWrapper } from "context/miscellaneousContext"
import Scheduler from "components/scheduler";
import { useRouter } from "next/dist/client/router";
import { pageView } from "utils/analytics";
import { PersonSidebar } from "components/person-sidebar"
import "@fontsource/poppins"; 
import 'react-vis/dist/style.css';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import "./styles.css";
import "remirror/styles/all.css";
import * as analytics from "utils/analytics";
import { ChatWrapper } from "context/chatContext";
import "@fontsource/inter/100.css";
import "@fontsource/inter/200.css";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import { customTheme } from "theme/customTheme";


function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const handleRouteChange = (url) => {
    pageView(url)
  }

  React.useEffect(() => {
    // When the component is mounted, subscribe to router changes and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  let isLightRoute = (router?.pathname?.includes("/tweets") 
    || router?.pathname?.includes("/resources")
    || router?.pathname?.includes("/trending")
    || router?.pathname?.includes("/statspublic")
    || router?.pathname?.includes("/join")
    || router?.pathname?.includes("/post")
    || router?.pathname?.includes("/thread-finder")
    || router?.pathname?.includes("/generate-tweets")
    || router?.pathname?.includes("/marketplace")
    || router?.pathname?.includes("/chat")
    || router?.pathname?.includes("/value")
    // || (router?.pathname?.includes("/generate") && typeof window !== "undefined" && !window?.location?.href?.includes("app")) // nt working
  );

  React.useEffect(() => {
    if (isLightRoute)
      analytics.init({});
  }, []);

  if (isLightRoute) {
    return (
      <ChakraProvider theme={customTheme}>
        <Component {...pageProps} />
        <div>
          <Toaster />
        </div>
      </ChakraProvider>
    )
  }

  return ( 
    <ChakraProvider theme={customTheme}> 
      <Provider session={pageProps.session}>
        <MainWrapper>
          <MiscellaneousWrapper>
            <TweetWrapper>
              <ChatWrapper>
                <Scheduler />
                <Component {...pageProps} />
                <PersonSidebar />
                <div>
                  <Toaster />
                </div>
              </ChatWrapper>
            </TweetWrapper>
          </MiscellaneousWrapper>
        </MainWrapper>
      </Provider>
    </ChakraProvider>
  );
}
export default MyApp;
